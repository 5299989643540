import { customElement, property } from "lit/decorators.js";
import { ClassInfo } from "lit/directives/class-map.js";

import { Watch } from "@/decorators/watch";

import AtlasButton, { type ButtonProps } from "@/components/display/atlas-button/atlas-button";
import { SocialNetwork, SocialNetworkInfo } from "./types";
import styles from "./atlas-social-button.scss";

export type SocialButtonProps = ButtonProps & {
    "media": SocialNetwork;
    "show-description": boolean;
};

/**
 * Descrição do componente
 *
 * @attr {SocialNetwork} media - Rede social que será renderizada com sua respectiva cor e ícone
 * @attr {boolean} show-description - Valor booleano indicado mostrar o nome da rede social no botão
 * @tag atlas-social-button
 */
@customElement("atlas-social-button")
export default class AtlasSocialButton extends AtlasButton {
    static styles = styles;

    @property({ type: String, reflect: true }) media: SocialNetwork = "general";

    @property({ type: Boolean, attribute: "show-description", reflect: true }) showDescription = false;

    connectedCallback(): void {
        super.connectedCallback?.();
        this.changeSocialInfo();
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();
    }

    @Watch("media")
    toggleButtonMedia() {
        this.changeSocialInfo();
    }

    getButtonClasses(): ClassInfo {
        const typeInfo = this.getSocialInfo();

        return {
            ...super.getButtonClasses(),
            [typeInfo?.className]: typeInfo && !!typeInfo.className,
            [`btn-${this.type}-${this.media}`]: this.type !== "filled"
        };
    }

    getSocialInfo(): SocialNetworkInfo {
        return {
            linkedin: { className: "btn-linkedin", icon: "linkedin", description: "Linkedin" },
            instagram: { className: "btn-instagram", icon: "instagram", description: "Instagram" },
            whatsapp: { className: "btn-whatsapp", icon: "whatsapp", description: "Whatsapp" },
            facebook: { className: "btn-facebook", icon: "facebook", description: "Facebook" },
            general: { icon: "link", description: "Link" }
        }[this.media] as SocialNetworkInfo;
    }

    changeSocialInfo() {
        const typeInfo = this.getSocialInfo();

        if (typeInfo && !this.icon) {
            this.icon = typeInfo.icon;

            if (this.showDescription) this.description = typeInfo.description;
        }
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-social-button": AtlasSocialButton;
    }
}
