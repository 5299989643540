import { property } from "lit/decorators.js";

import { Watch } from "@/decorators/watch";

import FloatingUiController from "@/controllers/floating-ui-controller";
import TriggerController, { TriggeredElement } from "@/controllers/trigger-controller";
import { emit } from "@/internals/events";

import { OverlayPlacement, OverlayTrigger } from "./overlay";
import AtlasElement from "@/components/atlas-element";

export type OverlayElementProps = {
    open: boolean;
    disabled: boolean;
    placement: OverlayPlacement;
    trigger: OverlayTrigger;
};

/**
 * O OverlayElement é a classe base para elementos do tipo overlay, ex: Tooltip, Dropdown, Popover. Ele define algumas propriedades padrão e alguns comportamentos presentes em todos os overlays
 */
export default abstract class OverlayElement extends AtlasElement implements TriggeredElement {
    /** Indica se o {overlay} está aberto */
    @property({ type: Boolean, reflect: true }) open: boolean;

    /** Indica se o {overlay} está desabilitado */
    @property({ type: Boolean }) disabled: boolean;

    /** O gatilho que irá acionar o {overlay} */
    @property({ type: String }) trigger: OverlayTrigger;

    /** A posição do {overlay} em relação ao elemento que o acionou */
    @property({ type: String }) placement: OverlayPlacement;

    protected _triggerController: TriggerController;

    protected _floatingUiController: FloatingUiController;

    private _defaultTrigger: OverlayTrigger;

    private _defaultPlacement: OverlayPlacement;

    constructor(defaultTrigger: OverlayTrigger, defaultPlacement: OverlayPlacement) {
        super();

        this._defaultPlacement = defaultPlacement || "bottom";
        this._defaultTrigger = defaultTrigger || "hover focus";

        this.placement = this._defaultPlacement;
        this.trigger = this._defaultTrigger;

        this._triggerController = new TriggerController(this, this.trigger);
        this._floatingUiController = new FloatingUiController(this, this.placement);

        this.updatePosition = this.updatePosition.bind(this);
    }

    public connectedCallback() {
        super.connectedCallback?.();
        this.setAttribute("tabindex", "-1");
    }

    /**
     * @internal
     */
    @Watch("open")
    public onOpenChange() {
        if (this.open) {
            this.onOpenOverlay();
        } else {
            this.onCloseOverlay();
        }
    }

    /**
     * Mostra o {overlay}
     */
    public show() {
        this._triggerController.show();
    }

    /**
     * Esconde o {overlay}
     */
    public hide() {
        this._triggerController.hide();
    }

    /**
     * Alterna a visibilidade do {overlay}
     */
    public toggle() {
        this._triggerController.toggle();
    }

    /**
     * @internal
     */
    public onTriggerActivate() {
        this.open = this._triggerController.open;
    }

    /**
     * @internal
     */
    public getFloatingElement(): HTMLElement {
        return this;
    }

    /**
     * @internal
     */
    public syncTriggerElement(triggerElement?: HTMLElement) {
        this._triggerController.syncTriggerElement(triggerElement);
    }

    /**
     * @internal
     */
    public updatePosition() {
        if (this.open) {
            this._floatingUiController.updatePosition();
        }
    }

    /**
     * @internal
     */
    @Watch(["placement", "trigger", "disabled"])
    public onUpdateProperties() {
        this._floatingUiController.placement = this.placement || this._defaultPlacement;
        this._triggerController.trigger = this.trigger || this._defaultTrigger;
        this._triggerController.disabled = this.disabled;
    }

    protected async onOpenOverlay() {
        await this.updateComplete;

        this.removeAttribute("tabindex");
        this._floatingUiController.anchorElement = this._triggerController.triggerElement;
        this._floatingUiController.floatingElement = this.getFloatingElement();
        this._floatingUiController.registerFloatingElement();

        setTimeout(() => {
            emit(this, `${this.tagName.toLowerCase()}-opened`, { trackDisable: true });
        }, 0);
    }

    protected async onCloseOverlay() {
        await this.updateComplete;

        this.setAttribute("tabindex", "-1");
        this._floatingUiController.unregisterFloatingElement();

        setTimeout(() => {
            emit(this, `${this.tagName.toLowerCase()}-closed`, { trackDisable: true });
        }, 0);
    }
}
