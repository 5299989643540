import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

import DeviceController from "@/controllers/device-controller";
import { StyleInfo, styleMap } from "lit/directives/style-map.js";
import styles from "./atlas-divider.scss";

/**
 * Divider serve para separar conteúdos ou agrupar elementos
 *
 * @prop {boolean} vertical - Booleano que indica se o divider aparece na vertical
 * @prop {boolean} mobile-vertical - Booleano que indica se mesmo no mobile o divider aparece na vertical
 * @prop {number} spacing - O espaçamento entre os conteúdos
 * @prop {string} color - Uma cor alternativa para o divider
 * @prop {number} thickness - A espessura do divider
 *
 */
@customElement("atlas-divider")
export default class AtlasDivider extends LitElement {
    static styles = styles;

    @property({ type: Boolean }) vertical = false;

    @property({ type: Boolean, attribute: "mobile-vertical" }) mobileVertical = false;

    @property({ type: Number }) spacing = 16;

    @property({ type: String }) color: string;

    @property({ type: Number }) thickness: number;

    protected _deviceController = new DeviceController(this);

    getIsVertical() {
        const { isMobile } = this._deviceController;
        return (!isMobile && this.vertical) || (isMobile && this.mobileVertical);
    }

    render() {
        const thickness = this.thickness > 1 ? this.thickness : 1;
        const spacing = !this.spacing ? 16 : this.spacing;

        const isVertical = this.getIsVertical();

        const layoutStyle: StyleInfo = {
            "margin": isVertical ? `0 ${spacing}px` : `${spacing}px 0`,
            "width": isVertical ? `${thickness}px` : "100%",
            "height": isVertical ? "100%" : `${thickness}px`,
            "display": isVertical ? "inline-block" : "block"
        };

        if (this.color) {
            layoutStyle["background-color"] = this.color;
        }

        return html`
            <div class="atlas-divider" style=${styleMap(layoutStyle)}></div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-divider": AtlasDivider;
    }
}
