import { html, nothing } from "lit";
import { customElement, property, queryAsync, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import DropzoneExtended, { type DropzoneFileExt } from "@/vendors/dropzone-utils";
import { showAlert } from "@/helpers/notifications";
import { emit } from "@/internals/events";
import { Watch } from "@/decorators/watch";
import { downloadBlob } from "@/helpers/request";
import UploaderValidator from "@/internals/validators/uploader-validator";

import { FileAction } from "./types";
import { UploaderFile } from "@/internals/basic-types";
import { PreviewToolbarAction } from "@/components/display/atlas-preview/types";
import AtlasUploaderFile from "@/components/form/atlas-uploader-file/atlas-uploader-file";
import FormElement, { FormElementProps } from "@/components/form/form-element";
import styles from "./atlas-uploader.scss";

import "@/components/display/atlas-button-group/atlas-button-group";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-link/atlas-link";
import "@/components/display/atlas-preview/atlas-preview";
import "@/components/display/atlas-progress-bar/atlas-progress-bar";
import "@/components/display/atlas-text/atlas-text";
import "@/components/table/atlas-table-body/atlas-table-body";
import "@/components/table/atlas-table-col/atlas-table-col";
import "@/components/table/atlas-table-header/atlas-table-header";
import "@/components/table/atlas-table-row/atlas-table-row";
import "@/components/table/atlas-table/atlas-table";

export type UploaderProps = FormElementProps & {
    "url": string;
    "preview": boolean;
    "files-list": boolean;
    "accepted-files": string;
    "max-files": number;
    "max-file-size": number;
    "warning-max-files-exceeded": string;
    "droppable-area-selector": string;
    "files-list-actions": FileAction[];
};

/**
 * @dependency atlas-button-group
 * @dependency atlas-icon
 * @dependency atlas-icon-button
 * @dependency atlas-layout
 * @dependency atlas-link
 * @dependency atlas-preview
 * @dependency atlas-progress-bar
 * @dependency atlas-text
 * @dependency atlas-table
 * @dependency atlas-table-body
 * @dependency atlas-table-col
 * @dependency atlas-table-header
 * @dependency atlas-table-row
 *
 * @attr {string} url - A Url de upload de arquivos
 * @attr {boolean} preview - Indica se o preview das imagens deve ser exibido
 * @attr {boolean} files-list - Indica se os arquivos carregados devem ser listados em uma tabela
 * @attr {FileAction[]} files-list-actions - Ações que podem ser executadas nos arquivos listados
 * @attr {string} accepted-files - Os tipos de arquivos aceitos pelo dropzone, pode seguir tipos definidos igual "images", "pdf", ou pode ser uma coletânea escolhendo quais devem ser utilizado ex: ".pdf, .jpg, .gif".
 * @attr {number} max-files - A quantidade de arquivos que podem ser enviados.
 * @attr {number} max-file-size - O tamanho máximo que o arquivo pode ter em (MiB).
 * @attr {string} warning-max-files-exceeded - O texto que será emitido no alerta quando o número máximo de imagens for alcançado.
 * @attr {string} droppable-area-selector - O seletor desejado para que se expanda a área de drop, ex: ".js-major-drag-area".
 *
 * @event {CustomEvent} atlas-uploader-addedfile - Evento disparado quando é adicionado um arquivo.
 * @event {CustomEvent} atlas-uploader-success - Evento disparado quando o arquivo é adicionado com sucesso.
 * @event {CustomEvent} atlas-uploader-error - Evento disparado quando no processo de adicionar um arquivo ocorre um erro.
 * @event {CustomEvent} atlas-uploader-complete - Evento disparado quando o arquivo acabou de ser enviado para a url.
 * @event {CustomEvent} atlas-uploader-queue-complete - Evento disparado quando a fila de envio dos arquivos é finalizada.
 * @event {CustomEvent} atlas-uploader-file-action-button-click - Evento disparado quando um botão de ação de um arquivo é clicado na listagem de arquivos carregados.
 *
 * @tag atlas-uploader
 */
@customElement("atlas-uploader")
export default class AtlasUploader extends FormElement {
    static styles = styles;

    @property({ type: String }) url: string;

    @property({ type: Boolean }) preview: boolean;

    @property({ type: Boolean, attribute: "files-list" }) filesList: boolean;

    @property({ type: String, attribute: "accepted-files" }) acceptedFiles: string;

    @property({ type: Number, attribute: "max-files" }) maxFiles: number;

    @property({ type: Number, attribute: "max-file-size" }) maxFileSize: number = 20;

    @property({ type: String, attribute: "warning-max-files-exceeded" }) warningMaxFilesExceeded: string =
        "Quantidade máxima de arquivos atingida";

    @property({ type: String, attribute: "droppable-area-selector" }) droppableAreaSelector: string;

    @property({ type: Array, attribute: "preview-actions" }) previewActions: PreviewToolbarAction[] = [];

    @property({ type: Array, attribute: "files-list-actions" }) filesListActions: FileAction[] = [];

    @state() private _isDragOver = false;

    @state() private _addedFiles: Array<DropzoneFileExt> = [];

    @state() private _uploadedFiles: Array<UploaderFile> = [];

    @state() private _uploadingFiles: Array<string> = [];

    @state() private _uploadedCount = 0;

    @queryAsync(".uploader")
    private _uploaderReference: HTMLElement;

    @queryAsync(".uploader-wrapper")
    private _uploaderWrapper: HTMLElement;

    private _droppableReference: HTMLElement;

    private _dropzone: DropzoneExtended;

    private _parallelUploadFiles = 4;

    connectedCallback(): void {
        super.connectedCallback?.();

        this.onDropzoneDragOver = this.onDropzoneDragOver.bind(this);
        this.onDropzoneDragOut = this.onDropzoneDragOut.bind(this);
        this.onUploadAddedFile = this.onUploadAddedFile.bind(this);
        this.onUploadRemovedFile = this.onUploadRemovedFile.bind(this);
        this.onUploadThumbnail = this.onUploadThumbnail.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
        this.onUploadError = this.onUploadError.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
        this.onUploadComplete = this.onUploadComplete.bind(this);
        this.onUploadQueueComplete = this.onUploadQueueComplete.bind(this);

        this.updateComplete.then(() => {
            this.addValidator(new UploaderValidator());

            this.updateDroppableReference();
            this.createDropzoneInstance();
        });
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        if (this.droppableAreaSelector) {
            this._droppableReference.querySelector(".atlas-droppable-area")?.remove();
        }
    }

    isUploadingFiles() {
        return this._uploadingFiles.length > 0;
    }

    getDropzoneInstance() {
        return this._dropzone;
    }

    getElementValue() {
        return this.url ? this._uploadedFiles : this._addedFiles;
    }

    updateValue() {
        const values = this._uploadedFiles.map((uploadedFile) => uploadedFile.tempFileId || uploadedFile.uuid);

        this.value = values.length === 0 ? "" : values.toString();
    }

    getAcceptedExtensions() {
        const filesByType = {
            images: ".jpg, .jpeg, .gif, .png",
            docs: ".pdf, .doc, .docx",
            pdf: ".pdf",
            imagesOrPdf: ".jpg, .jpeg, .gif, .png, .pdf",
            all: "*"
        }[this.acceptedFiles];

        return filesByType || this.acceptedFiles;
    }

    getFileByUUID(uuid: string) {
        return this._addedFiles.find((addedFile) => addedFile.upload.uuid === uuid);
    }

    getAllFiles() {
        return this._addedFiles;
    }

    updateAddedFile(file: DropzoneFileExt) {
        this._addedFiles = this._addedFiles.map((addedFile) => {
            if (addedFile.upload.uuid !== file.upload.uuid) {
                return addedFile;
            }

            return file;
        });
    }

    removeFile(fileUUID: string) {
        const file = this.getFileByUUID(fileUUID);

        this._addedFiles = this._addedFiles.filter((addedFile) => addedFile.upload.uuid !== fileUUID);
        this._uploadedFiles = this._uploadedFiles.filter((uploadedFile) => uploadedFile.uuid !== fileUUID);

        this.updateValue();

        if (file) {
            this.getDropzoneInstance().removeFile(file);
        }
    }

    async clear() {
        await this.updateComplete;

        this._addedFiles = [];
        this._uploadedFiles = [];

        this.updateValue();
        this.getDropzoneInstance()?.removeAllFiles(true);
    }

    downloadFile(fileUUID: string) {
        const file = this.getFileByUUID(fileUUID);
        downloadBlob(file, file.name);
    }

    getPreviewURL(fileUUID: string) {
        return this.getFileByUUID(fileUUID)?.dataURL;
    }

    async updateDroppableReference() {
        if (!this.droppableAreaSelector) {
            this._droppableReference = await this._uploaderReference;
            return;
        }

        this._droppableReference = document.querySelector(`${this.droppableAreaSelector}`);
        this._droppableReference.classList.add("atlas-uploader-expanded-drop");

        const droppableElement = document.createElement("div");
        droppableElement.classList.add("atlas-droppable-area");
        droppableElement.innerHTML = '<atlas-text size="lg" theme="primary">Solte os arquivos aqui</atlas-text>';

        this._droppableReference.appendChild(droppableElement);
    }

    @Watch("disabled")
    async onUpdateDisabled() {
        await this.updateComplete;

        if (this.disabled) {
            this.getDropzoneInstance()?.disable();
        } else {
            this.getDropzoneInstance()?.enable();
        }
    }

    async createDropzoneInstance() {
        const uploaderRef = await this._uploaderReference;
        const uploaderWrapper = await this._uploaderWrapper;

        this._dropzone = new DropzoneExtended(this._droppableReference, {
            url: this.url,
            paramName: this.name,
            acceptedFiles: this.getAcceptedExtensions(),
            parallelUploads: this._parallelUploadFiles,
            maxFiles: this.maxFiles ? this.maxFiles : null,
            maxFilesize: this.maxFileSize,
            maxThumbnailFilesize: this.maxFileSize,
            // @ts-expect-error
            disablePreviews: true,
            dictUploadCanceled: "Envio cancelado.",
            dictMaxFilesExceeded: this.warningMaxFilesExceeded,
            dictInvalidFileType: "Formato de arquivo não permitido.",
            dictFileTooBig: "Tamanho de arquivo(s) ultrapassa o permitido.",
            hiddenInputContainer: uploaderWrapper,
            clickable: uploaderRef
        });

        this._dropzone.on("drop", this.onDropzoneDragOut);
        this._dropzone.on("dragend", this.onDropzoneDragOut);
        this._dropzone.on("dragleave", this.onDropzoneDragOut);
        this._dropzone.on("dragenter", this.onDropzoneDragOver);
        this._dropzone.on("dragover", this.onDropzoneDragOver);

        this._dropzone.on("addedfile", this.onUploadAddedFile);
        this._dropzone.on("removedfile", this.onUploadRemovedFile);
        this._dropzone.on("thumbnail", this.onUploadThumbnail);
        this._dropzone.on("uploadprogress", this.onUploadProgress);
        this._dropzone.on("error", this.onUploadError);
        this._dropzone.on("success", this.onUploadSuccess);
        this._dropzone.on("complete", this.onUploadComplete);
        this._dropzone.on("queuecomplete", this.onUploadQueueComplete);

        if (this.disabled) {
            this._dropzone.disable();
        }
    }

    onDropzoneDragOver() {
        this._isDragOver = true;
    }

    onDropzoneDragOut() {
        this._isDragOver = false;
    }

    onUploadAddedFile(file: DropzoneFileExt) {
        this._addedFiles = [...this._addedFiles, file];
        this._uploadingFiles.push(file.upload.uuid);

        if (!file.alreadyUploaded) {
            emit(this, "atlas-uploader-addedfile", { detail: { file } });
        }
    }

    onUploadRemovedFile(file: DropzoneFileExt) {
        this._uploadingFiles = this._uploadingFiles.filter((uuid) => uuid !== file.upload.uuid);
        emit(this, "atlas-uploader-removedfile", { detail: { file } });
    }

    onUploadThumbnail(file: DropzoneFileExt) {
        this.updateAddedFile(file);
    }

    onUploadProgress(file: DropzoneFileExt) {
        this.updateAddedFile(file);

        if (!file.alreadyUploaded) {
            emit(this, "atlas-uploader-progress", { detail: { file } });
        }
    }

    onUploadError(file: DropzoneFileExt, error: string | { [key: string]: any } | Error) {
        const message = error instanceof Error ? error.message : error;

        if (typeof message === "string") {
            if (/<\/?[a-z][\s\S]*>/i.test(message)) {
                const responseHTML = new DOMParser().parseFromString(message, "text/html");
                showAlert(responseHTML.body.textContent, "warning");
            } else {
                showAlert(message, "warning");
            }
        } else {
            showAlert(message?.text, "warning");
        }

        this.removeFile(file.upload.uuid);

        if (!file.alreadyUploaded) {
            emit(this, "atlas-uploader-error", { detail: { file, error } });
        }
    }

    onUploadSuccess(file: DropzoneFileExt, response: { [key: string]: any }) {
        if (!response.success) {
            this.onUploadError(file, response.message);
            return;
        }

        const { tempFileId, tempFileName } = response;

        this._uploadedFiles = [
            ...this._uploadedFiles,
            {
                uuid: file.upload.uuid,
                name: file.name,
                type: file.type,
                size: file.size,
                tempFileId,
                tempFileName
            }
        ];

        this._uploadingFiles = this._uploadingFiles.filter((uuid) => uuid !== file.upload.uuid);
        this.updateValue();

        if (!file.alreadyUploaded) {
            emit(this, "atlas-uploader-success", { detail: { file, response } });
        }
    }

    onUploadComplete(file: DropzoneFileExt) {
        this.updateAddedFile(file);
        this._uploadedCount += 1;

        if (!file.alreadyUploaded) {
            emit(this, "atlas-uploader-complete", { detail: { file } });
        }
    }

    onUploadQueueComplete() {
        this._uploadingFiles = [];
        this._uploadedCount = 0;
        this.checkValidity();

        emit(this, "atlas-uploader-queue-complete");
    }

    onUploadLinkClick(event: KeyboardEvent | PointerEvent) {
        if (event instanceof PointerEvent || event.code === "Space") {
            this.getDropzoneInstance().element.click();
        }
    }

    onCancelLinkClick(event: KeyboardEvent | PointerEvent) {
        if (event instanceof PointerEvent || event.code === "Space") {
            this.getDropzoneInstance().cancelAllUploads();
        }
    }

    onPreviewButtonClick(event: CustomEvent) {
        const { button } = event.detail;
        const target = event.target as HTMLElement;
        const file = this.getFileByUUID(target.dataset.fileUuid);

        const clickEvent = emit(this, "atlas-uploader-preview-button-click", {
            detail: {
                button,
                file
            }
        });

        if (clickEvent.defaultPrevented) return;

        if (button === "delete") {
            this.removeFile(file.upload.uuid);
        } else if (button === "download") {
            this.downloadFile(file.upload.uuid);
        }
    }

    async onFilesSlotChange() {
        await this.updateComplete;

        const slot = this.shadowRoot.querySelector("slot");
        const slottedFiles = slot
            .assignedElements()
            .filter((element) => element.tagName === "ATLAS-UPLOADER-FILE") as AtlasUploaderFile[];

        for (const uploaderFile of slottedFiles) {
            const fileObject = await uploaderFile.getFileObject();
            fileObject.alreadyUploaded = true;
            fileObject.dataset = uploaderFile.dataset;
            fileObject.badgeText = uploaderFile.badgeText;
            fileObject.badgeIcon = uploaderFile.badgeIcon;
            fileObject.badgeTheme = uploaderFile.badgeTheme;

            this.getDropzoneInstance().addFile(fileObject);
        }
    }

    onFileActionButtonClick(event: CustomEvent) {
        const { button } = event.detail;
        const file = this.getFileByUUID(button.dataset.fileUuid);
        const actionName = button.dataset.action;

        const clickEvent = emit(this, "atlas-uploader-file-action-button-click", {
            detail: {
                button,
                file
            },
            trackDisable: true
        });

        if (clickEvent.defaultPrevented) return;

        if (actionName === "delete") {
            this.removeFile(file.upload.uuid);
        } else if (actionName === "download") {
            this.downloadFile(file.upload.uuid);
        }
    }

    renderFileActions(file: DropzoneFileExt) {
        const defaultActions = [
            { name: "download", label: "Baixar", icon: "download" },
            { name: "delete", label: "Excluir", icon: "trash", theme: "danger" }
        ] as FileAction[];

        const actions = this.filesListActions.length > 0 ? this.filesListActions : defaultActions;

        return html`
            <atlas-button-group
                slot="actions"
                group-all
                @atlas-button-group-button-click=${this.onFileActionButtonClick}
            >
                ${actions.map(
                    (action) => html`
                        <atlas-icon-button
                            name=${action.name}
                            description=${action.label}
                            icon=${action.icon}
                            theme=${action.theme}
                            tooltip=${action.label}
                            ?disabled=${action.disabled}
                            data-file-uuid=${file.upload?.uuid}
                            data-action=${action.name}
                        ></atlas-icon-button>
                    `
                )}
            </atlas-button-group>
        `;
    }

    renderFileRow(file: DropzoneFileExt) {
        return html`
            <atlas-table-row data-file-uuid=${file.upload.uuid}>
                <atlas-table-col column-label="Nome do arquivo">
                    <atlas-layout inline alignment="center" gap="2">
                        <atlas-icon size="2x" name="file"></atlas-icon>
                        <atlas-text>${file.name}</atlas-text>
                    </atlas-layout>
                </atlas-table-col>
                ${this.renderFileActions(file)}
            </atlas-table-row>
        `;
    }

    renderFilesList() {
        if (!this.filesList) return nothing;

        return html`
            <atlas-table has-actions>
                <atlas-table-header slot="header">
                    <atlas-table-col size="lg" ellipsis>Nome do arquivo</atlas-table-col>
                </atlas-table-header>
                <atlas-table-body slot="body">
                    ${this._addedFiles.map((file) => this.renderFileRow(file))}
                </atlas-table-body>
            </atlas-table>
        `;
    }

    renderPreviewElements() {
        if (!this.preview) return nothing;

        return this._addedFiles.map((file) => {
            const previewDefaultActions = [
                { name: "download", label: "Baixar", icon: "download" },
                { name: "delete", label: "Excluir", icon: "trash", theme: "danger" }
            ] as PreviewToolbarAction[];

            const previewActions = this.previewActions.length > 0 ? this.previewActions : previewDefaultActions;

            return html`
                <atlas-preview
                    image=${file.dataURL}
                    image-name=${file.name}
                    badge-icon=${file.badgeIcon}
                    badge-text=${file.badgeText}
                    badge-theme=${file.badgeTheme || "primary"}
                    ?loading=${file.status !== "success"}
                    loading-progress=${file.upload?.progress}
                    .actions=${previewActions}
                    @atlas-preview-button-click=${this.onPreviewButtonClick}
                    data-file-uuid=${file.upload?.uuid}
                ></atlas-preview>
            `;
        });
    }

    renderUploadLinkOrProgress() {
        return when(
            !this.preview && this._uploadingFiles.length > 0,
            () => html`
                <atlas-progress-bar
                    value=${this._uploadedCount}
                    max-value=${this._uploadingFiles.length}
                    unit="integer"
                    theme=${this.getStatusTheme() || "primary"}
                    show-label
                    muted-label
                    label-prefix="Carregando"
                >
                    <atlas-link
                        slot="action"
                        href="javascript:void(0)"
                        size="sm"
                        @keydown=${this.onCancelLinkClick}
                        @click=${this.onCancelLinkClick}
                    >
                        Cancelar
                    </atlas-link>
                </atlas-progress-bar>
            `,
            () => html`
                <atlas-link
                    href="javascript:void(0)"
                    size="sm"
                    @keydown=${this.onUploadLinkClick}
                    @click=${this.onUploadLinkClick}
                >
                    Adicione ou arraste os arquivos aqui
                </atlas-link>
            `
        );
    }

    renderContentOrDragging() {
        return when(
            this._isDragOver && !this.droppableAreaSelector,
            () => html`<atlas-text size="sm" theme="primary">Solte os arquivos aqui</atlas-text>`,
            () => html`
                <atlas-icon name="upload" size="5x" theme=${this.getStatusTheme() || "primary"}></atlas-icon>
                ${this.renderUploadLinkOrProgress()}
            `
        );
    }

    render() {
        const wrapperClass = {
            "uploader-wrapper": true,
            "has-preview": this.preview,
            "has-files-list": this.filesList,
            "has-files": this._addedFiles.length > 0,
            "has-max-files": this.maxFiles > 0 && this._addedFiles.length >= this.maxFiles,
            [`is-${this._status}`]: this.getShowStatus()
        };

        const uploaderClass = {
            uploader: true,
            disabled: this.disabled
        };

        return html`
            <div class=${classMap(wrapperClass)}>
                <div class=${classMap(uploaderClass)}>
                    <div class="uploader-content">${this.renderContentOrDragging()}</div>
                </div>
                ${this.renderPreviewElements()} ${this.renderFilesList()}
            </div>
            ${this.renderStatusMessage()}
            <slot @slotchange=${this.onFilesSlotChange}></slot>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-uploader": AtlasUploader;
    }
}
