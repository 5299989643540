import { HTMLTemplateResult, LitElement, TemplateResult, html } from "lit";
import { property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

export type AtlasElementProps = {
    "skeleton-loading": boolean;
    "hide-on-desktop": boolean;
    "hide-on-mobile": boolean;
};

/**
 * Classe base para definir elementos do Atlas
 */
export default class AtlasElement extends LitElement {
    /** Indica se o componente deve ser escondido no desktop */
    @property({ type: Boolean, attribute: "hide-on-desktop", reflect: true }) hideOnDesktop: boolean;

    /** Indica se o componente deve ser escondido no mobile */
    @property({ type: Boolean, attribute: "hide-on-mobile", reflect: true }) hideOnMobile: boolean;

    /** Indica se o componente está sendo carregado fornecendo um placeholder visual */
    @property({ type: Boolean, attribute: "skeleton-loading", reflect: true }) skeletonLoading: boolean;

    /** @internal */
    @Watch("skeletonLoading")
    public async applySkeleton() {
        await this.updateComplete;

        const allElements = this.shadowRoot.querySelectorAll("*");

        allElements.forEach((element: HTMLElement) => {
            if (element.tagName === "SLOT") {
                this.setLoadingSlot(element as HTMLSlotElement);
            } else {
                element.toggleAttribute("skeleton-loading", this.skeletonLoading);
            }
        });

        emit(this, "atlas-element-change-skeleton", { trackDisable: true });
    }

    protected update(changedProperties: Map<string, any>) {
        super.update(changedProperties);

        if (this.hasUpdated) {
            this.updateComplete.then(() => {
                emit(this, "atlas-element-update", { detail: changedProperties, trackDisable: true });
            });
        }
    }

    protected renderSkeleton(): HTMLTemplateResult | TemplateResult | Generator<unknown, void, unknown> {
        return html``;
    }

    protected renderElement(): HTMLTemplateResult | TemplateResult | Generator<unknown, void, unknown> {
        return html``;
    }

    private setLoadingSlot(slot: HTMLSlotElement) {
        const elements = slot.assignedElements();

        elements.forEach((element: HTMLElement) => {
            element.toggleAttribute("skeleton-loading", this.skeletonLoading);
        });
    }

    /** @internal */
    public render() {
        return when(
            this.skeletonLoading,
            () => this.renderSkeleton(),
            () => this.renderElement()
        );
    }
}
