import { LitElement, html } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import sidebarMenuStyles from "./atlas-sidebar-menu-item.scss";

import "@/components/layout/atlas-collapse/atlas-collapse";
import "@/components/structure/atlas-sidebar-menu/atlas-sidebar-menu";
import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-tooltip/atlas-tooltip";

/**
 * @dependency atlas-collapse
 * @dependency atlas-sidebar-menu
 * @dependency atlas-badge
 * @dependency atlas-icon
 * @dependency atlas-tooltip
 *
 * @slot - Usado para incluir os itens filhos como um submenu
 *
 * @prop {string} owner - O valor definido no item pai. É atribuido automaticamente nos itens filhos
 * @prop {string} value - Valor do item. É passado para o evento de clique do item
 * @prop {string} icon - Ícone que aparece ao lado esquerdo do nome
 * @prop {string} text - O nome do menu
 * @prop {string} href - Link que será aberto ao clicar no item
 * @prop {string} target - Indica onde o link deve ser aberto
 * @prop {boolean} active - Booleano que informa se o item está ativo ou não
 * @prop {boolean} nested - Booleano que informa se o item faz parte de um submenu
 * @prop {boolean} shrinked - Booleano que informa se o item está em um menu encolhido
 *
 * @event {CustomEvent} atlas-menu-item-click - Evento disparado quando o item é clicado
 * @event {CustomEvent} atlas-menu-item-collapse - Evento disparado quando o item é collapsado
 *
 */
@customElement("atlas-sidebar-menu-item")
export default class AtlasSidebarMenuItem extends LitElement {
    static styles = sidebarMenuStyles;

    @property({ type: String }) owner!: string;

    @property({ type: String }) value: string;

    @property({ type: String }) icon: string;

    @property({ type: String }) text: string;

    @property({ type: String }) href: string;

    @property({ type: String }) target: string;

    @property({ type: Boolean, reflect: true }) active = false;

    @property({ type: Boolean }) nested = false;

    @property({ type: Boolean }) shrinked = false;

    @property({ type: Boolean, attribute: "is-new" }) isNew = false;

    @state() private _collapsed = true;

    @state() private _hasChildren = false;

    @state() private _isNewChildrenCount = 0;

    @state() private _hasTooltip = false;

    @queryAssignedElements({ selector: "atlas-sidebar-menu-item", flatten: true })
    private slottedChildren!: Array<HTMLElement>;

    setCollapsed(collapsed: boolean) {
        this._collapsed = collapsed;
    }

    onClickItem(event: PointerEvent) {
        if (this._hasChildren) {
            event.preventDefault();
            this._collapsed = !this._collapsed;

            emit(this, "atlas-menu-item-collapse", {
                detail: {
                    item: this.value,
                    collapsed: this._collapsed
                }
            });
        } else {
            emit(this, "atlas-menu-item-click", {
                detail: {
                    item: this.value,
                    owner: this.owner
                }
            });
        }
    }

    @Watch("active", true)
    onChangeActive() {
        if (this.active) {
            if (this._hasChildren) {
                this.setCollapsed(false);
            } else if (this.owner) {
                const rootNode = this.assignedSlot.getRootNode() as ShadowRoot;
                const host = rootNode.host as AtlasSidebarMenuItem;

                host.setAttribute("active", "");
            }
        } else if (!this.active && this._hasChildren) {
            this.setCollapsed(true);

            this.slottedChildren.forEach((element: AtlasSidebarMenuItem) => {
                element.removeAttribute("active");
            });
        }
    }

    @Watch("isNew")
    async onChangeIsNew() {
        await this.updateComplete;

        let isNewChildrenCount = 0;

        this.slottedChildren.forEach((element) => {
            if (element.hasAttribute("is-new")) {
                isNewChildrenCount += 1;
            }
        });

        this._isNewChildrenCount = isNewChildrenCount;
    }

    async onChangeChildrens() {
        await this.updateComplete;

        this._hasChildren = this.slottedChildren && this.slottedChildren.length > 0;

        this.slottedChildren.forEach((element) => {
            element.setAttribute("owner", this.value);
        });

        if (this.active && this._hasChildren) {
            this.setCollapsed(false);
        }
    }

    checkIfTextOverflows() {
        const itemText = this.shadowRoot.querySelector(".item-text");
        const contentWidth = itemText.getBoundingClientRect().width;

        return Math.ceil(contentWidth) < itemText.scrollWidth;
    }

    renderCollapseIcon() {
        return when(
            this._hasChildren,
            () => html`<atlas-icon name="chevron-down" size="2x" class="collapse-icon"></atlas-icon>`
        );
    }

    renderBadge() {
        return when(
            this.isNew || this._isNewChildrenCount > 0,
            () => html`<atlas-badge text="${this._isNewChildrenCount || 1}" is-counter></atlas-badge>`
        );
    }

    @Watch(["shrinked", "nested"], false)
    async setTooltip() {
        await this.updateComplete;

        setTimeout(() => {
            if (this.shrinked) {
                this._hasTooltip = true;
                return;
            }

            this._hasTooltip = this.checkIfTextOverflows() && !this.nested;
        }, 500);
    }

    renderTooltip() {
        return html`
            <atlas-tooltip id="menu-item-tooltip" placement="right" trigger="hover" ?disabled=${!this._hasTooltip}>
                ${this.text}
            </atlas-tooltip>
        `;
    }

    render() {
        const itemClass = {
            "sidebar-menu-item": true,
            "collapsed": this._collapsed,
            "active": this.active,
            "nested": this.nested,
            "shrinked": this.shrinked
        };

        return html`
            <div class="${classMap(itemClass)}">
                <a
                    href=${ifDefined(this.href)}
                    class="item-button"
                    role="button"
                    data-atlas-tooltip="menu-item-tooltip"
                    target=${ifDefined(this.target ? this.target : undefined)}
                    @click=${this.onClickItem}
                >
                    <atlas-icon name="${this.icon}" size="3x"></atlas-icon>
                    <span class="item-text">${this.text}</span>
                    ${this.renderBadge()} ${this.renderCollapseIcon()}
                </a>
                ${this.renderTooltip()}
                <atlas-collapse ?show=${!this._collapsed}>
                    <atlas-sidebar-menu
                        ?shrinked=${this.shrinked}
                        nested
                        tabindex=${ifDefined(this._collapsed ? "-1" : undefined)}
                    >
                        <slot @slotchange=${this.onChangeChildrens}></slot>
                    </atlas-sidebar-menu>
                </atlas-collapse>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-sidebar-menu-item": AtlasSidebarMenuItem;
    }
}
