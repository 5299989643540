import { LitElement, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";
import { emit } from "@/internals/events";
import { Watch } from "@/decorators/watch";

import type AtlasSelectItem from "@/components/form/atlas-select-item/atlas-select-item";

import styles from "./atlas-bottom-sheet.scss";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/display/atlas-button/atlas-button";
import "@/components/display/atlas-text/atlas-text";

export type BottomSheetProps = {
    "header": string;
    "open": boolean;
    "fullscreen": boolean;
    "has-footer": boolean;
};

/**
 * @dependency atlas-heading
 * @dependency atlas-icon-button
 * @dependency atlas-text
 *
 * @prop {string} header - Título do bottom-sheet.
 * @prop {boolean} open - Booleano que indica se o bottom-sheet está aberto
 * @prop {boolean} fullscreen - Booleano que indica se o bottom-sheet será aberto ocupando todo o espaço da tela
 *
 * @slot - Slot padrão usado para definir o conteúdo do bottom-sheet
 * @slot subheading - Slot para adicionar um conteúdo extra no cabeçalho do bottom sheet
 *
 * @event {CustomEvent} atlas-bottom-sheet-close - Evento disparado para indicar que o bottom-sheet deve ser fechado
 *
 * @tag atlas-bottom-sheet
 */
@customElement("atlas-bottom-sheet")
export default class AtlasBottomSheet extends LitElement {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: Boolean }) open: boolean;

    @property({ type: Boolean }) fullscreen: boolean;

    @property({ type: Boolean, attribute: "has-footer" }) hasFooter: boolean;

    @property({ type: Boolean, attribute: "spaced-content" }) spacedContent: boolean;

    @property({ type: Boolean, attribute: "show-close-button" }) showCloseButton: boolean;

    @state() private _bottomSheetHeight: number;

    @state() private _currentTranslate: number;

    @state() private _startPosition: number;

    @state() private _dragging: boolean;

    @state() private _selectedCount: number = 0;

    private _previousSelectedCount: number = 0;

    @query(".bottom-sheet")
    private _bottomSheet: HTMLElement;

    connectedCallback() {
        super.connectedCallback?.();

        this.onAnchorDragStart = this.onAnchorDragStart.bind(this);
        this.onAnchorDrag = this.onAnchorDrag.bind(this);
        this.onAnchorDragEnd = this.onAnchorDragEnd.bind(this);
        this.onClickBottomSheet = this.onClickBottomSheet.bind(this);

        this.updateComplete.then(() => {
            this._bottomSheet.addEventListener("touchstart", this.onAnchorDragStart);
            this._bottomSheet.addEventListener("touchmove", this.onAnchorDrag);
            this._bottomSheet.addEventListener("touchend", this.onAnchorDragEnd);
            this.addEventListener("atlas-select-item-click", this.changeOptionsCount);
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback?.();

        this._bottomSheet.removeEventListener("touchstart", this.onAnchorDragStart);
        this._bottomSheet.removeEventListener("touchmove", this.onAnchorDrag);
        this._bottomSheet.removeEventListener("touchend", this.onAnchorDragEnd);
        this.removeEventListener("atlas-select-item-click", this.changeOptionsCount);
    }

    changeOptionsCount(event: CustomEvent) {
        const { shouldRemove } = event.detail;
        const isSelectAll = (event.target as AtlasSelectItem).selectAll;

        if (isSelectAll) {
            this._selectedCount = shouldRemove ? 0 : this.getSelectItems().length;
        } else {
            this._selectedCount = this._previousSelectedCount + (shouldRemove ? -1 : 1);
        }

        this._previousSelectedCount = this._selectedCount;
    }

    canResizeOnDrag(event: TouchEvent) {
        const bottomSheetBody = this.shadowRoot.querySelector(".bottom-sheet-body");
        const composedPath = event.composedPath();
        const isBodyTarget = composedPath.includes(bottomSheetBody);

        if (!isBodyTarget) {
            return true;
        }

        return bottomSheetBody.scrollTop === 0;
    }

    onAnchorDragStart(event: TouchEvent) {
        if (this.canResizeOnDrag(event)) {
            this._dragging = true;
            this._startPosition = event.changedTouches[0].clientY;
            this._bottomSheetHeight = this._bottomSheet.getBoundingClientRect().height;
        }
    }

    onAnchorDrag(event: TouchEvent) {
        if (!this._dragging) return;

        const positionY = event.touches[0].clientY;
        const heightDiff = positionY - this._startPosition;

        if (heightDiff > 0) {
            this._currentTranslate = (heightDiff * 100) / this._bottomSheetHeight;
        }
    }

    onAnchorDragEnd() {
        if (!this._dragging) return;

        this._dragging = false;

        if (this._currentTranslate > 30) {
            this._currentTranslate = 100;

            setTimeout(() => {
                this.closeBottomSheet();
            }, 200);
        } else {
            this._currentTranslate = null;
            this._startPosition = null;
        }
    }

    onClickBottomSheet(event: PointerEvent) {
        if (!this.open) {
            return;
        }

        const composedPath = event.composedPath();
        const isBottomSheetTarget = composedPath.includes(this._bottomSheet);

        if (!isBottomSheetTarget) {
            this.closeBottomSheet();
        }
    }

    closeBottomSheet() {
        emit(this, "atlas-bottom-sheet-close", { trackDisable: true });
    }

    getSelectItems(): AtlasSelectItem[] {
        return (this.querySelector("slot:not([name])") as HTMLSlotElement)
            .assignedElements()
            .filter(
                (element) =>
                    element.tagName === "ATLAS-SELECT-ITEM" &&
                    !(element as AtlasSelectItem).isGroupTitle &&
                    !(element as AtlasSelectItem).selectAll
            ) as AtlasSelectItem[];
    }

    countOptions() {
        const selectedOptions = this.getSelectItems().filter((element) => (element as AtlasSelectItem).selected);

        this._previousSelectedCount = selectedOptions.length;
        this._selectedCount = 0;
    }

    @Watch("open")
    onOpenChange() {
        if (!this.open) return;

        this._currentTranslate = null;
        this._startPosition = null;
        this.countOptions();
    }

    renderCloseButton() {
        return html`
            <atlas-icon-button
                icon="x"
                size="3x"
                theme="secondary"
                @click=${this.closeBottomSheet}
            ></atlas-icon-button>
        `;
    }

    renderTitle() {
        if (!this.header) return null;

        return html`
            <atlas-heading size="h5" muted>${this.header}</atlas-heading>
            ${when(this.fullscreen || this.showCloseButton, () => this.renderCloseButton())}
        `;
    }

    getSelectButtonDescription() {
        return this._selectedCount > 0 ? `Selecionar (${this._selectedCount})` : "Selecionar";
    }

    renderFooter() {
        return when(
            this.hasFooter && this.fullscreen,
            () => html`
                <div class="dropdown-buttons">
                    <atlas-button
                        theme="secondary"
                        description="Fechar"
                        @click=${this.closeBottomSheet}
                        class="dropdown-button"
                    >
                        Cancelar
                    </atlas-button>
                    <atlas-button
                        theme="primary"
                        description="${this.getSelectButtonDescription()}"
                        @click=${this.closeBottomSheet}
                        class="dropdown-button"
                    >
                        Selecionar
                    </atlas-button>
                </div>
            `
        );
    }

    render() {
        const bottomSheetWrapperClass = {
            "bottom-sheet-wrapper": true,
            "fade": true,
            "show": this.open
        };

        const bottomSheetClass = {
            "bottom-sheet": true,
            "fullscreen": this.fullscreen,
            "dragging": this._dragging,
            "spaced-content": this.spacedContent
        };

        const bottomSheetStyle = {
            transform: this._currentTranslate ? `translateY(${this._currentTranslate}%)` : null
        };

        return html`
            <div class=${classMap(bottomSheetWrapperClass)} @click=${this.onClickBottomSheet}>
                <div class=${classMap(bottomSheetClass)} style=${styleMap(bottomSheetStyle)} draggable="true">
                    <div class="bottom-sheet-header">
                        <div class="drag-anchor"></div>
                        <div class="bottom-sheet-title">${this.renderTitle()}</div>
                        <slot name="subheading"></slot>
                    </div>
                    <div class="bottom-sheet-body">
                        <slot></slot>
                    </div>
                    ${this.renderFooter()}
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-bottom-sheet": AtlasBottomSheet;
    }
}
