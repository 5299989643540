import { LitElement, html } from "lit";
import { customElement, property, queryAssignedElements } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";

import { WithCollapseMixin, type WithCollapseProps } from "@/internals/mixins/with-collapse-mixin";
import { WithTooltipMixin, type WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";

import type AtlasSidebarMenuItem from "@/components/structure/atlas-sidebar-menu-item/atlas-sidebar-menu-item";

import styles from "./atlas-sidebar-menu-group.scss";

const ComposedClass = WithTooltipMixin(WithCollapseMixin(LitElement));

export type SidebarMenuGroupProps = WithTooltipProps &
    WithCollapseProps & {
        "text": string;
        "enable-divider": boolean;
        "icon": string;
        "shrinked": boolean;
        "active": boolean;
    };

/**
 * @slot - Usado para incluir os itens de menu pertencentes ao grupo
 */
@customElement("atlas-sidebar-menu-group")
export default class AtlasSidebarMenuGroup extends ComposedClass {
    static styles = styles;

    /** O nome do grupo */
    @property({ type: String }) text: string;

    /** Indica que um divisor deve ser renderizado acima do grupo */
    @property({ type: Boolean, attribute: "enable-divider", reflect: true }) enableDivider: boolean = false;

    /** Ícone que aparece ao lado esquerdo do nome */
    @property({ type: String }) icon: string;

    /** Booleano que informa se o menu está exibindo uma versão encolhida */
    @property({ type: Boolean, reflect: true }) shrinked = false;

    /** Booleano que informa se o group possui um item ativo  */
    @property({ type: Boolean, reflect: true }) active = false;

    @queryAssignedElements({ selector: "atlas-sidebar-menu-item", flatten: true })
    private _slottedItems: AtlasSidebarMenuItem[];

    private _tooltipId: string = "";

    constructor() {
        super();

        this.onGroupIconClick = this.onGroupIconClick.bind(this);
    }

    public connectedCallback(): void {
        super.connectedCallback?.();

        if (this.text?.trim()) {
            this.tooltip = this.text;
            this.tooltipPlacement = "right";
            this.tooltipTrigger = "hover";
            this._tooltipId = this.text.trim().toLowerCase().replace(/\s+/g, "-");
        }

        this.updateComplete.then(() => {
            this.syncActive();
            this.syncCollapsed();

            if (this.collapsed && this.expanded) {
                this.collapsed = false;
            }
        });
    }

    /**
     * @override
     * @internal
     */
    public hasTooltip(): boolean {
        return !!this.tooltip && this.shrinked && !!this.text;
    }

    /**
     * Retorna os itens do grupo
     * @returns {AtlasSidebarMenuItem[]} - Os itens do grupo.
     */
    public getItems(): AtlasSidebarMenuItem[] {
        return this._slottedItems;
    }

    /**
     * Sincroniza o estado colapsado do grupo de acordo com o estado dos seus itens.
     * Se o grupo for colapsável, ele será colapsado se todos os itens do grupo não estiverem ativos.
     */
    public syncCollapsed() {
        if (!this.collapsible) return;

        this.collapsed = this._slottedItems.every((item) => !item.active);
    }

    /**
     * Sincroniza o estado de ativo do grupo de acordo com o estado dos seus itens.
     * Ele será definido como ativo se um item do grupo estiver ativo.
     */
    public syncActive() {
        this.active = this._slottedItems.some((item) => item.active);
    }

    private getTheme() {
        return this.active ? "primary" : "secondary";
    }

    private onGroupIconClick() {
        if (!this.collapsible || !this.shrinked) return;

        this.collapsed = !this.collapsed;
    }

    /**
     * @override
     * @internal
     */
    public renderCollapseButton() {
        const theme = this.getTheme();
        return when(this.collapsible && !this.shrinked, () => super.renderCollapseButton("none", theme));
    }

    private renderDivider() {
        return when(
            this.text || this.collapsible || this.enableDivider,
            () => html`<atlas-divider spacing="8"></atlas-divider>`
        );
    }

    private renderHeaderIconButton() {
        return when(
            !!this.icon,
            () => html`
                <atlas-icon
                    size="3x"
                    name="${this.icon}"
                    theme=${this.getTheme()}
                    data-atlas-tooltip=${this._tooltipId}
                ></atlas-icon>
            `
        );
    }

    private renderHeaderTitle() {
        const textStyle = {
            display: this.shrinked ? "none" : ""
        };

        return when(
            this.text,
            () => html`
                ${this.renderHeaderIconButton()}
                <atlas-heading
                    ellipsis
                    size="h6"
                    ?muted=${!this.active}
                    theme=${this.getTheme()}
                    style=${styleMap(textStyle)}
                >
                    ${this.text}
                </atlas-heading>
            `
        );
    }

    private renderHeader() {
        if (this.collapsible) {
            return html`
                <a class="sidebar-menu-group-header collapsible" role="button" @click=${this.toggleCollapse}>
                    <atlas-layout
                        gap="2"
                        inline
                        mobile-inline
                        alignment="center"
                        justify=${this.shrinked ? "center" : "space-between"}
                    >
                        ${this.renderHeaderTitle()} ${this.renderCollapseButton()}
                    </atlas-layout>
                </a>
            `;
        }

        return when(
            this.text,
            () => html`
                <div class="sidebar-menu-group-header">
                    ${this.renderHeaderTitle()}
                </div>
            `
        );
    }

    private renderContent() {
        const contentHtml = html`
            <atlas-layout gap="2">
                <slot></slot>
            </atlas-layout>
        `;

        return when(
            this.collapsible,
            () => this.renderContentWithCollapse(contentHtml),
            () => contentHtml
        );
    }

    /** @internal */
    public render() {
        return html`
            ${this.renderHeader()} ${this.renderDivider()} ${this.renderContent()}
            ${this.renderTooltip(this._tooltipId)}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-sidebar-menu-group": AtlasSidebarMenuGroup;
    }
}
