import Dropzone, { DropzoneOptions, DropzoneFile } from "dropzone";
import { Theme } from "@/internals/theme";

export type DropzoneFileExt = DropzoneFile & {
    alreadyUploaded: boolean;
    badgeText: string;
    badgeIcon: string;
    badgeTheme: Theme;
    dataset: { [key: string]: any };
};

export default class DropzoneExtended extends Dropzone {
    private _hasFakeUrl = false;

    constructor(container: string | HTMLElement, options?: DropzoneOptions) {
        let hasFakeUrl = false;

        if (!options.url) {
            Object.assign(options, { url: "fakeUrl", parallelUploads: 1 });
            hasFakeUrl = true;
        }

        super(container, options);
        this._hasFakeUrl = hasFakeUrl;
    }

    _uploadData(files: DropzoneFileExt[], dataBlocks: any): void {
        if (!this._hasFakeUrl && !files[0].alreadyUploaded) {
            // @ts-expect-error
            super._uploadData(files, dataBlocks);
        } else {
            this._finishedUploading(files, null, null);
        }
    }

    _finishedUploading(files: DropzoneFileExt[], xhr: any, e: any) {
        if (!this._hasFakeUrl && !files[0].alreadyUploaded) {
            // @ts-expect-error
            super._finishedUploading(files, xhr, e);
        } else {
            // @ts-expect-error
            this._finished(files, { success: true }, e);
        }
    }

    cancelAllUploads() {
        this.getActiveFiles().forEach((file) => {
            this.cancelUpload(file);
        });
    }
}
