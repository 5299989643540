import { LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

import { DropzoneFileExt } from "@/vendors/dropzone-utils";
import { Theme } from "@/internals/theme";

/**
 * @attr {string} src - Url do arquivo
 * @attr {string} name - Nome do arquivo
 * @attr {string} badge-text - Texto do badge que irá aparecer no preview da imagem
 * @attr {string} badge-icon - Ícone do badge que irá aparecer no preview da imagem
 * @attr {string} badge-theme - Tema do badge que irá aparecer no preview da imagem
 *
 * @tag atlas-uploader-file
 */
@customElement("atlas-uploader-file")
export default class AtlasUploaderFile extends LitElement {
    @property({ type: String }) src: string;

    @property({ type: String }) name: string;

    @property({ type: String, attribute: "badge-text" }) badgeText: string;

    @property({ type: String, attribute: "badge-icon" }) badgeIcon: string;

    @property({ type: String, attribute: "badge-theme" }) badgeTheme: Theme = "primary";

    async getFileObject() {
        const fileObject = await fetch(this.src).then(async (response) => {
            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const file = new File([blob], this.name, { type: contentType });

            return file;
        });

        return fileObject as DropzoneFileExt;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-uploader-file": AtlasUploaderFile;
    }
}
