import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { getClassifiedContent } from "@/helpers/privacy";
import { emit } from "@/internals/events";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-private-content.scss";

export type PrivateContentProps = AtlasElementProps & {
    "private-key": string;
    "cover": boolean;
    "placeholder": string;
    "placeholder-prefix": string;
};

/**
 * @prop {string} private-key - Chave que identifica o grupo ao qual o elemento pertence (Para esconder vários elementos de um mesmo grupo)
 * @prop {boolean} cover - Indica se o conteúdo real está escondido, mostrando o placeholder
 * @prop {string} placeholder - Conteúdo que aparece ao esconder o conteúdo real
 * @prop {string} placeholder-prefix - Prefixo do conteúdo que aparece ao esconder o conteúdo real
 *
 * @event {CustomEvent} atlas-private-content-visible - Evento disparado ao tornar conteúdo visível
 * @event {CustomEvent} atlas-private-content-hidden - Evento disparado ao esconder conteúdo
 *
 * @tag atlas-private-content
 */
@customElement("atlas-private-content")
export default class AtlasPrivateContent extends AtlasElement {
    static styles = styles;

    @property({ type: String, attribute: "private-key" }) privateKey: string;

    @property({ type: Boolean, reflect: true }) cover: boolean;

    @property({ type: String }) placeholder = "***";

    @property({ type: String, attribute: "placeholder-prefix" }) placeholderPrefix = "";

    connectedCallback(): void {
        super.connectedCallback?.();

        this.toggleIsCovered = this.toggleIsCovered.bind(this);
        window.addEventListener("atlas-private-state-change", this.toggleIsCovered);

        this.toggleIsCovered();
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        window.removeEventListener("atlas-private-state-change", this.toggleIsCovered);
    }

    emitVisibilityEvent() {
        emit(this, `atlas-private-content-${!this.cover ? "visible" : "hidden"}`, { trackDisable: true });
    }

    async toggleIsCovered() {
        await this.updateComplete;

        if (!this.privateKey) return;

        this.cover = getClassifiedContent().includes(this.privateKey);

        this.emitVisibilityEvent();
    }

    renderPlaceholderContent() {
        return html`${this.placeholderPrefix} ${this.placeholder}`;
    }

    render() {
        return when(
            this.cover,
            () => this.renderPlaceholderContent(),
            () => html`<slot></slot>`
        );
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-private-content": AtlasPrivateContent;
    }
}
