import { html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import { Watch } from "@/decorators/watch";
import { getAssetPath } from "@/helpers/base-path";
import { closeAllOverlays } from "@/helpers/overlay";
import { debounce } from "@/internals/debounce";
import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";

import type AtlasText from "@/components/display/atlas-text/atlas-text";
import { PreviewToolbarAction } from "./types";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-preview.scss";
import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-dropdown/atlas-dropdown";
import "@/components/display/atlas-dropdown-item/atlas-dropdown-item";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-progress-bar/atlas-progress-bar";
import "@/components/display/atlas-text/atlas-text";
import "@/components/display/atlas-tooltip/atlas-tooltip";

export type PreviewProps = AtlasElementProps & {
    "image": string;
    "image-name": string;
    "loading": boolean;
    "loading-progress": number;
    "badge-icon": string;
    "badge-text": string;
    "badge-theme": Theme;
    "actions": Array<PreviewToolbarAction>;
};

/**
 * @attr {string} image - Imagem que irá aparecer no preview
 * @attr {string} image-name - Nome da imagem
 * @attr {boolean} loading - Indica se deve aparecer um progresso no preview
 * @attr {number} loading-progress - Porcentagem do progresso que aparece no preview
 * @attr {string} badge-icon - Ícone do badge que aparece sobre o preview
 * @attr {string} badge-text - Texto do badge
 * @attr {Theme} badge-theme - Tema do badge
 * @attr {Array<PreviewToolbarAction>} actions - Ações que devem aparecer na toolbar do preview
 *
 * @emit {CustomEvent} atlas-preview-button-click - Evento lançado ao clicar em uma ação do preview
 *
 * @tag atlas-preview
 */
@customElement("atlas-preview")
export default class AtlasPreview extends AtlasElement {
    static styles = styles;

    @property({ type: String }) image: string;

    @property({ type: String, attribute: "image-name" }) imageName: string;

    @property({ type: Boolean, reflect: true }) loading: boolean;

    @property({ type: Number, attribute: "loading-progress" }) loadingProgress: number = 0;

    @property({ type: String, attribute: "badge-icon" }) badgeIcon: string;

    @property({ type: String, attribute: "badge-text" }) badgeText: string;

    @property({ type: String, attribute: "badge-theme" }) badgeTheme: Theme;

    @property({ type: Array }) actions: Array<PreviewToolbarAction> = [];

    @state() private _showFloatingElements = false;

    @state() private _showNameTooltip = false;

    @state() private _isMouseOverPreview = false;

    private _deviceController = new DeviceController(this);

    connectedCallback(): void {
        super.connectedCallback?.();

        this.toggleNameTooltipVisibility = this.toggleNameTooltipVisibility.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onChangeFloatingVisibility = debounce(this.onChangeFloatingVisibility.bind(this), 100);

        document.addEventListener("click", this.onDocumentClick);
        this._deviceController.setScreenChangeCallback(this.toggleNameTooltipVisibility);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        document.removeEventListener("click", this.onDocumentClick);
    }

    onDocumentClick(event: PointerEvent) {
        if (!this._showFloatingElements) return;

        const composedPath = event.composedPath();
        const isInsidePreview = composedPath.includes(this);

        if (!isInsidePreview) {
            this._showFloatingElements = false;
        }
    }

    onClickPreview() {
        this._showFloatingElements = true;
    }

    onMouseOverPreview() {
        this._isMouseOverPreview = true;
    }

    onMouseOutPreview() {
        this._isMouseOverPreview = false;
    }

    onClickToolbarButton(event: PointerEvent | CustomEvent) {
        const target = event.currentTarget as HTMLElement;
        event.stopPropagation();

        emit(this, "atlas-preview-button-click", {
            detail: {
                button: target.dataset.name
            }
        });

        closeAllOverlays(this.shadowRoot);
    }

    @Watch(["_showFloatingElements", "_isMouseOverPreview"], true)
    onChangeFloatingVisibility() {
        if (!this._showFloatingElements && !this._isMouseOverPreview) {
            closeAllOverlays(this.shadowRoot);
        }
    }

    @Watch(["loading", "name"])
    async toggleNameTooltipVisibility() {
        await this.updateComplete;

        const imageNameRef = this.shadowRoot.querySelector(".preview-file-name-text") as AtlasText;

        if (!imageNameRef) {
            this._showNameTooltip = false;
            return;
        }

        const textWrapper = imageNameRef.getTextWrapper();
        const textVisibleWidth = textWrapper.getBoundingClientRect().width;
        const textScrollWidth = textWrapper.scrollWidth;

        this._showNameTooltip = Math.ceil(textVisibleWidth) < textScrollWidth;
    }

    renderToolbarOuterButtons() {
        return this.actions.slice(0, 3).map(
            (button) => html`
                <button
                    class=${classMap({
                        "preview-toolbar-button": true,
                        "disabled": button.disabled,
                        [`color-${button.theme || "secondary"}`]: true,
                        [`active-${button.theme || "secondary"}`]: true
                    })}
                    @click=${this.onClickToolbarButton}
                    data-name=${button.name}
                >
                    <atlas-icon name=${button.icon} size="2x"></atlas-icon>
                </button>
            `
        );
    }

    renderToolbarMoreButton() {
        if (this.actions.length < 4) return nothing;

        return html`
            <button
                class=${classMap({
                    "preview-toolbar-button": true,
                    "color-secondary": true,
                    "active-secondary": true
                })}
                data-atlas-dropdown="more-dropdown"
            >
                <atlas-icon name="three-dots" size="2x"></atlas-icon>
            </button>
        `;
    }

    renderDropdownButtons(buttons: PreviewToolbarAction[]) {
        return buttons.map(
            (button) => html`
                <atlas-dropdown-item
                    icon=${button.icon}
                    theme=${button.theme}
                    ?disabled=${button.disabled}
                    @atlas-dropdown-item-click=${this.onClickToolbarButton}
                    data-name=${button.name}
                >
                    ${button.label}
                </atlas-dropdown-item>
            `
        );
    }

    renderMoreButtonDropdown() {
        if (this.loading || this._deviceController.isMobile || this.actions.length < 4) return nothing;

        return html`
            <atlas-dropdown id="more-dropdown" no-gap auto-close auto-close-trigger="any">
                ${this.renderDropdownButtons(this.actions.slice(3))}
            </atlas-dropdown>
        `;
    }

    renderPreviewToolbar() {
        if (!this.actions || this.actions.length === 0) return nothing;

        return html`
            <div class="preview-toolbar">${this.renderToolbarOuterButtons()}${this.renderToolbarMoreButton()}</div>
        `;
    }

    renderPreviewBadge() {
        return when(
            !!this.badgeText,
            () => html`
                <div class="preview-badge">
                    <atlas-badge
                        icon=${this.badgeIcon}
                        text=${!this._deviceController.isMobile ? this.badgeText : ""}
                        theme=${this.badgeTheme}
                    ></atlas-badge>
                </div>
            `
        );
    }

    renderFloatingElements() {
        return when(
            !this.loading && !this._deviceController.isMobile,
            () => html`
                ${this.renderPreviewToolbar()}
                <div class="preview-file-name">
                    <atlas-text
                        class="preview-file-name-text"
                        size="xs"
                        alignment="center"
                        muted
                        ellipsis
                        hide-ellipsis-tooltip
                        data-atlas-tooltip="image-name-tooltip"
                    >
                        ${this.imageName}
                    </atlas-text>
                </div>
            `
        );
    }

    renderMobileDropdown() {
        return when(
            !this.loading && this._deviceController.isMobile,
            () => html`
                <atlas-dropdown
                    id="preview-dropdown"
                    header=${this.imageName}
                    no-gap
                    auto-close
                    auto-close-trigger="any"
                >
                    ${this.renderDropdownButtons(this.actions)}
                </atlas-dropdown>
            `
        );
    }

    renderNameTooltip() {
        return when(
            !this.loading && !this._deviceController.isMobile && this._showNameTooltip,
            () => html`<atlas-tooltip id="image-name-tooltip" placement="bottom">${this.imageName}</atlas-tooltip>`
        );
    }

    render() {
        const previewWrapperClass = {
            "preview-wrapper": true,
            "show-floating-elements": this._showFloatingElements || this._isMouseOverPreview
        };

        const previewClass = {
            "preview": true,
            "show-progress": this.loading,
            "show-badge": !this.loading
        };

        return html`
            <div
                class=${classMap(previewWrapperClass)}
                @mouseover=${this.onMouseOverPreview}
                @mouseout=${this.onMouseOutPreview}
            >
                <div class="preview-content">
                    <button
                        class=${classMap(previewClass)}
                        ?disabled=${this.loading}
                        @click=${this.onClickPreview}
                        data-atlas-dropdown="preview-dropdown"
                    >
                        <img src=${this.image || getAssetPath("assets/images/image-placeholder.svg")} alt="" />
                        <div class="preview-overlay"></div>
                        ${this.renderPreviewBadge()}
                        <div class="preview-progress">
                            <atlas-progress-bar value=${this.loadingProgress}></atlas-progress-bar>
                        </div>
                    </button>
                    ${this.renderFloatingElements()}
                </div>
                ${this.renderMoreButtonDropdown()}${this.renderMobileDropdown()}${this.renderNameTooltip()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-preview": AtlasPreview;
    }
}
