import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import AtlasElement, { type AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-page-content.scss";

export type PageContentProps = AtlasElementProps & {
    "fluid": boolean;
};

/**
 * @tag atlas-page-content
 */
@customElement("atlas-page-content")
export default class AtlasPageContent extends AtlasElement {
    static styles = styles;

    /** 
     * Define se o page-content deve ser fluido. 
     * Quando ativo, ajusta a altura do page-content e seu conteúdo para ocupar todo o espaço disponível.
     */
    @property({ type: Boolean, reflect: true, attribute: "fluid" }) fluid: boolean;

    public render() {
        return html`
            <div class="page-content">
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-page-content": AtlasPageContent;
    }
}
