import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { filterEmptyNodes } from "@/internals/elements";
import { Theme } from "@/internals/theme";

import AtlasElement from "@/components/atlas-element";
import styles from "./atlas-helper-text.scss";
import "@/components/display/atlas-icon/atlas-icon";

/**
 * @dependency atlas-icon
 *
 * @prop {string} icon - Ícone que irá aparecer ao lado do helper-text
 * @prop {Theme} icon-theme - Tema do ícone
 *
 * @tag atlas-helper-text
 */
@customElement("atlas-helper-text")
export default class AtlasHelperText extends AtlasElement {
    static styles = styles;

    @property({ type: String }) icon: string;

    @property({ type: String, attribute: "icon-theme" }) iconTheme: Theme;

    @property({ type: Boolean, attribute: "is-toggle-helper" }) isToggleHelper = false;

    @property({ type: Boolean }) white: boolean = false;

    @state() private _hasSlottedContent = true;

    onChangeContent() {
        const slottedNodes = this.shadowRoot.querySelector("slot").assignedNodes({ flatten: true });
        const filteredNodes = filterEmptyNodes(slottedNodes);

        this._hasSlottedContent = filteredNodes.length > 0;
    }

    renderIcon() {
        return when(
            !!this.icon,
            () => html`<atlas-icon name=${this.icon} theme=${this.iconTheme} size="2x"></atlas-icon>`
        );
    }

    render() {
        const helperTextClass = {
            "form-text": true,
            "form-text-toggle": this.isToggleHelper,
            "empty-helper": !this._hasSlottedContent
        };

        return html`
            <div id="${this.id}-helper" class="${classMap(helperTextClass)}">
                ${this.renderIcon()}
                <atlas-text size="xs" muted ?white=${this.white}>
                    <slot @slotchange=${this.onChangeContent}></slot>
                </atlas-text>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-helper-text": AtlasHelperText;
    }
}
