import { emit } from "@/internals/events";

const ATLAS_CLASSIFIED_CONTENT_KEY = "atlas-classified-content";

export const getClassifiedContent = (): string[] => {
    const currentContent = localStorage.getItem(ATLAS_CLASSIFIED_CONTENT_KEY);

    if (currentContent) {
        return JSON.parse(currentContent);
    }

    return [];
};

export const toggleClassifiedContent = (privateKey: string, forceState?: boolean) => {
    if (!privateKey) return;

    let classifiedContent = getClassifiedContent();
    let showContent = false;

    if (forceState !== undefined && forceState !== null) {
        showContent = forceState;
    } else {
        showContent = classifiedContent.includes(privateKey);
    }

    if (showContent) {
        classifiedContent = classifiedContent.filter((key) => key !== privateKey);
    } else {
        classifiedContent.push(privateKey);
        classifiedContent = [...new Set(classifiedContent)];
    }

    localStorage.setItem(ATLAS_CLASSIFIED_CONTENT_KEY, JSON.stringify(classifiedContent));

    emit(window, "atlas-private-state-change", {
        detail: classifiedContent
    });
};

export const showClassifiedContent = (privateKey: string) => {
    toggleClassifiedContent(privateKey, true);
};

export const hideClassifiedContent = (privateKey: string) => {
    toggleClassifiedContent(privateKey, false);
};
