import { LitElement, html } from "lit";
import { customElement, property, queryAssignedElements } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";

import AtlasSummaryTableCol from "@/components/wizard/atlas-summary-table-col/atlas-summary-table-col";
import styles from "./atlas-summary-table-row.scss";

/**
 * Linhass das tabelas usadas no resumo da wizard
 *
 * @prop {boolean} is-header - Indica se é uma linha que aparece no cabeçalho
 *
 * @slot - Slot onde devem ser adicionadas as colunas da linha
 *
 * @tag atlas-summary-table-row
 */
@customElement("atlas-summary-table-row")
export default class AtlasSummaryTableRow extends LitElement {
    static styles = styles;

    @property({ type: Boolean, attribute: "is-header" }) isHeader = false;

    @queryAssignedElements({ selector: "atlas-summary-table-col" })
    private _columns: AtlasSummaryTableCol[];

    getColumns() {
        return this._columns;
    }

    async onChangeColumns() {
        await this.updateComplete;

        this.getColumns().forEach((column: AtlasSummaryTableCol) => {
            column.toggleAttribute("is-header", this.isHeader);
        });
    }

    render() {
        const rowClass = {
            "table-row": true,
            "is-header": this.isHeader
        };

        return html`
            <div class=${classMap(rowClass)}>
                <slot @slotchange=${this.onChangeColumns}></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-summary-table-row": AtlasSummaryTableRow;
    }
}
