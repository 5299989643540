import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { ifDefined } from "lit/directives/if-defined.js";

import { Theme } from "@/internals/theme";
import { emit } from "@/internals/events";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-calendar-timeline-item.scss";

import { isEmptySlot } from "@/internals/slot";
import { Watch } from "@/decorators/watch";

import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-text/atlas-text";

export type CalendarTimelineItemProps = AtlasElementProps & {
    "label": string;
    "description": string;
    "icon": string;
    "icon-theme": Theme;
    "href": string;
};

/**
 * @dependency atlas-heading
 * @dependency atlas-icon
 * @dependency atlas-text
 *
 * @event {CustomEvent} atlas-timeline-item-click - Evento disparado quando é feito o clique no item
 */
@customElement("atlas-calendar-timeline-item")
export default class AtlasCalendarTimelineItem extends AtlasElement {
    static styles = styles;

    /** Label do item */
    @property({ type: String }) label: string;

    /** Descrição do item */
    @property({ type: String }) description: string;

    /** Ícone que aparece do lado esquerdo do item */
    @property({ type: String }) icon: string;

    /** Tema do ícone */
    @property({ type: String, attribute: "icon-theme" }) iconTheme: Theme;

    /** Link para qual o usuário será redirecionado ao clicar no item */
    @property({ type: String }) href: string;

    /** Indica se este é o último item na lista. Geralmente usado para aplicar estilos especiais ao último item, como remover a borda inferior */
    @property({ type: Boolean, attribute: "is-last-child" }) isLastChild = false;

    /** Controla a visibilidade do item. Se definido como `true`, o item será ocultado */
    @property({ type: Boolean }) hidden = false;

    @state() private _hasSlottedDescription = false;

    /** @internal */
    @Watch("hidden", true)
    public onChangeHidden() {
        this.toggleAttribute("hidden", this.hidden);
        emit(this, "atlas-timeline-item-hidden-change", { trackDisable: true });
    }

    private handleItemClick() {
        emit(this, "atlas-timeline-item-click");
    }

    private onDescriptionChange() {
        this._hasSlottedDescription = !isEmptySlot(this);
    }

    private renderDescription() {
        if (this.description) {
            return html`<atlas-heading size="h5">${this.description}</atlas-heading>`;
        }

        return html`
            <atlas-heading size="h5" style="${!this._hasSlottedDescription ? "display: none;" : ""}">
                <slot @slotchange=${this.onDescriptionChange}></slot>
            </atlas-heading>
        `;
    }

    protected renderElement() {
        return html`
            <a class="timeline-item" href=${ifDefined(this.href)} @click=${this.handleItemClick} tabindex="0">
                <atlas-icon
                    name=${this.icon}
                    theme=${this.iconTheme}
                    size="3x"
                    theme-variation=${this.iconTheme === "warning" ? 700 : 500}
                ></atlas-icon>
                <div class="timeline-item-content">
                    <atlas-text size="xs">${this.label}</atlas-text>
                    ${this.renderDescription()}
                </div>
                <atlas-icon name="chevron-right" theme="secondary" size="3x"></atlas-icon>
            </a>
        `;
    }

    protected renderSkeleton() {
        return html`
            <div class="timeline-item">
                <atlas-heading size="h5"></atlas-heading>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-calendar-timeline-item": AtlasCalendarTimelineItem;
    }
}
