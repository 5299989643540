export enum CardPreviewBrandEnum {
    ELO = "ELO",
    MASTERCARD = "MASTERCARD"
}

export type CardPreviewOrientation = "horizontal" | "vertical";

export type CardPreviewImageInfo = {
    "front-image": string;
    "back-image"?: string;
};

export type CardPreviewInfo = { [key in CardPreviewOrientation]?: CardPreviewImageInfo };

export const CARD_PREVIEWS: Record<CardPreviewBrandEnum, CardPreviewInfo> = {
    [CardPreviewBrandEnum.MASTERCARD]: {
        horizontal: {
            "front-image": "assets/images/card-previews/mastercard/mastercard-horizontal-front.svg"
        },
        vertical: {
            "front-image": "assets/images/card-previews/mastercard/mastercard-vertical-front.svg",
            "back-image": "assets/images/card-previews/mastercard/mastercard-vertical-back.svg"
        }
    },
    [CardPreviewBrandEnum.ELO]: {
        horizontal: {
            "front-image": "assets/images/card-previews/elo/elo-horizontal-front.svg"
        }
    }
};

export function getCardPreviewImageInfo(brand: CardPreviewBrandEnum, orientation: CardPreviewOrientation) {
    return CARD_PREVIEWS[brand]?.[orientation] || ({} as CardPreviewImageInfo);
}
