import qs from "qs";

function buildFormData(formData: FormData, params: any, parentKey?: string) {
    if (params && typeof params === "object" && !(params instanceof Date) && !(params instanceof File)) {
        Object.keys(params).forEach((key) => {
            buildFormData(formData, params[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = params == null ? "" : params;

        formData.append(parentKey, value);
    }
}

function objectToFormData(params: { [key: string]: any }) {
    const formData = new FormData();

    buildFormData(formData, params);
    return formData;
}

function getRequestHeaders(jsonRequest?: boolean) {
    const headers = new Headers();

    headers.append("X-Requested-With", "XMLHttpRequest");

    if (jsonRequest) {
        headers.append("content-type", "application/json");
    }

    return headers;
}

function getCredentialTypeFromUrl(url: string): 'include' | 'same-origin' | 'omit' {
    if (url.toLowerCase().includes('hashedfingerprint')) {
        return 'include';
    }
    return 'same-origin';
}

export const post = async (
    url: string,
    params: object,
    jsonRequest?: boolean,
    parseResponse: boolean = true,
    timeout?: number
): Promise<any> => {
    const headers = getRequestHeaders(jsonRequest);
    const body = jsonRequest ? JSON.stringify(params) : objectToFormData(params);

    let signal = null;

    if (timeout > 0) {
        const controller = new AbortController();
        signal = controller.signal;

        setTimeout(() => controller.abort(), timeout);
    }

    const fetchOptions: RequestInit = {
        method: "POST",
        headers,
        body
    };

    if (signal) fetchOptions.signal = signal;

    const response = await fetch(url, fetchOptions);

    if (parseResponse) {
        const responseData = await response.json();
        return responseData;
    }

    return response;
};

export const get = async (
    url: string, 
    params: object, 
    parseResponse: boolean = true, 
    timeout?: number
): Promise<any> => {
    let fullPath = url;

    if (params && Object.keys(params).length > 0) {
        const separator = fullPath.includes('?') ? '&' : '?';
        fullPath += `${separator}${qs.stringify(params, { indices: false })}`;
    }

    let signal = null;

    if (timeout > 0) {
        const controller = new AbortController();
        signal = controller.signal;

        setTimeout(() => controller.abort(), timeout);
    }

    const fetchOptions: RequestInit = {
        method: "GET",
        credentials: getCredentialTypeFromUrl(url),
        headers: getRequestHeaders(false)
    };

    if (signal) fetchOptions.signal = signal;

    const response = await fetch(fullPath, fetchOptions);

    if (parseResponse) {
        const responseData = await response.json();
        return responseData;
    }

    return response;
};

export const downloadBlob = (blob: Blob, fileName: string) => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);

    link.dispatchEvent(
        new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window
        })
    );

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

export const downloadResponseFile = async (response: Response) => {
    const filename = response.headers
        .get("content-disposition")
        .split(";")
        .find((disposition) => disposition.includes("filename="))
        .replace("filename=", "")
        .trim();

    const responseBlob = await response.blob();

    downloadBlob(responseBlob, filename);
};
