import { FormatDateString } from "vanilla-calendar-pro/types";

export const DATE_FORMAT = "dd/mm/yyyy";

export function isYearMonthDay(date: string) {
    return /^\d{4}-\d{2}-\d{2}$/.test(date);
}

export function stripTime(date: Date | number) {
    const dateObj = new Date(date);
    dateObj.setHours(0, 0, 0, 0);

    return dateObj;
}

export function getTodayDate() {
    return stripTime(Date.now());
}

export function getDateFromString(date: string) {
    const isYearMonthDayFormat = isYearMonthDay(date);
    const dateParts = date.split(isYearMonthDayFormat ? "-" : "/");

    const day = parseInt(isYearMonthDayFormat ? dateParts[2] : dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const year = parseInt(isYearMonthDayFormat ? dateParts[0] : dateParts[2], 10);

    const dateObj = new Date(year, month, day);
    return stripTime(dateObj);
}

export function parseDate(date: string | number | Date | FormatDateString) {
    if (date instanceof Date || typeof date === "number") {
        return stripTime(date);
    }

    if (!date) {
        return undefined;
    }

    return getDateFromString(date);
}

export function formatDate(date: string | number | Date | FormatDateString) {
    if (!date) return "";
    const parsedDate = parseDate(date);

    const day = parsedDate.getDate();
    const month = parsedDate.getMonth() + 1;
    const year = parsedDate.getFullYear();

    return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}`;
}

export function formatDateToYearMonthDay(date: string | number | Date | FormatDateString) {
    if (!date) return "" as FormatDateString;
    const parsedDate = parseDate(date);

    const day = parsedDate.getDate();
    const month = parsedDate.getMonth() + 1;
    const year = parsedDate.getFullYear();

    return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}` as FormatDateString;
}
