import "./atlas-loading";

const LOADING_BACKDROP_CLASS = "atlas-loading-backdrop";
const LOADING_BACKDROP_SELECTOR = `.${LOADING_BACKDROP_CLASS}`;

interface ShowLoadingOptions {
    message: string;
    element?: HTMLElement;
}

export function showLoading(options?: string | ShowLoadingOptions) {
    let context: HTMLElement;
    let message: string;
    let isInsideElement = false;

    if (!options || typeof options === "string") {
        message = options ? `${options}` : "";
        context = document.body;
    } else {
        message = options.message;
        context = options.element || document.body;
        isInsideElement = !!options.element;
    }

    if (context.querySelector(LOADING_BACKDROP_SELECTOR)) {
        context.querySelector("atlas-loading").setAttribute("message", message || "");
        return;
    }

    const loadingBackdrop = document.createElement("div");
    loadingBackdrop.classList.add(LOADING_BACKDROP_CLASS);
    loadingBackdrop.classList.toggle("inside-element", isInsideElement);

    const loadingElement = document.createElement("atlas-loading");
    loadingElement.message = message;
    loadingElement.contentLoading = isInsideElement;

    loadingBackdrop.prepend(loadingElement);
    context.prepend(loadingBackdrop);
}

export function closeLoading(element?: HTMLElement) {
    const context = element || document.body;

    context.querySelector("atlas-loading")?.destroy();
    context.querySelector(LOADING_BACKDROP_SELECTOR)?.remove();
}
