import { ReactiveController, LitElement } from "lit";
import { Middleware, autoUpdate, computePosition, flip, offset, shift } from "@floating-ui/dom";

import { OverlayPlacement } from "@/components/display/overlay";

export default class FloatingUiController implements ReactiveController {
    host: LitElement;

    placement: OverlayPlacement;

    anchorElement: HTMLElement;

    floatingElement: HTMLElement;

    customMiddlewares: Middleware[] = [];

    getCustomMiddlewares?: () => Middleware[];

    floatingUiCleanup?: () => void;

    constructor(host: LitElement, placement: OverlayPlacement) {
        this.host = host;
        this.placement = placement || "bottom";

        host.addController(this);
        this.updatePosition = this.updatePosition.bind(this);
    }

    hostConnected() {}

    hostDisconnected() {}

    getMiddlewares() {
        const defaultMiddlewares = [
            offset(8),
            flip(),
            shift({
                padding: 8
            })
        ];

        return [...defaultMiddlewares, ...(this.getCustomMiddlewares?.() || [])];
    }

    updatePosition() {
        computePosition(this.anchorElement, this.floatingElement, {
            placement: this.placement,
            strategy: "fixed",
            middleware: this.getMiddlewares()
        }).then(({ x, y, placement, middlewareData }) => {
            this.floatingElement.setAttribute("data-popper-placement", placement);

            Object.assign(this.floatingElement.style, {
                left: `${x}px`,
                top: `${y}px`
            });

            const arrowMiddleware = this.getMiddlewares().find((middleware) => middleware.name === "arrow");

            const { arrow } = middlewareData;
            const arrowEl = arrowMiddleware?.options?.element as HTMLElement;

            if (arrow && arrowEl) {
                Object.assign(arrowEl.style, {
                    left: arrow.x != null ? `${arrow.x}px` : "",
                    top: arrow.y != null ? `${arrow.y}px` : ""
                });
            }
        });
    }

    registerFloatingElement() {
        this.floatingUiCleanup = autoUpdate(this.anchorElement, this.floatingElement, this.updatePosition);
    }

    unregisterFloatingElement() {
        this.floatingUiCleanup?.();
    }
}
