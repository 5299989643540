import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";

import AtlasSection from "@/components/layout/atlas-section/atlas-section";
import styles from "./atlas-section-group.scss";

/**
 * @slot - Slot padrão onde vão as seções
 * @tag atlas-section-group
 */
@customElement("atlas-section-group")
export default class AtlasSectionGroup extends LitElement {
    static styles = styles;

    public connectedCallback() {
        super.connectedCallback?.();

        this.addEventListener("atlas-section-expand", this.onSectionExpand);
    }

    public disconnectedCallback() {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-section-expand", this.onSectionExpand);
    }

    private getSlottedSections() {
        return this.shadowRoot.querySelector("slot").assignedElements() || [];
    }

    private onSectionExpand(event: CustomEvent) {
        this.getSlottedSections().forEach((section: AtlasSection) => {
            if (event.target !== section && section.collapsed !== true) {
                section.close();
            }
        });
    }

    public render() {
        return html`
            <div class="section-group">
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-section-group": AtlasSectionGroup;
    }
}
