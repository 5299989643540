import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { WithTooltipMixin } from "@/internals/mixins/with-tooltip-mixin";
import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";

import styles from "./atlas-tag.scss";
import "@/components/display/atlas-icon/atlas-icon";

export type TagProps = {
    text: string;
    disabled: boolean;
    theme: Theme;
};

/**
 * @dependecy atlas-icon
 *
 * @prop {string} text - Texto que aparece na tag
 * @prop {boolean} disabled - Indica se a tag está desabilitada
 * @prop {Theme} theme - Define o tema da tag
 * @prop {number} maxlength - Define o tamanho máximo de caracteres na tag
 *
 * @event {CustomEvent} atlas-tag-click - Evento disparado ao clicar sobre a tag
 * @event {CustomEvent} atlas-tag-close-click - Evento disparado ao clicar no fechar da tag
 *
 * @tag atlas-tag
 */
@customElement("atlas-tag")
export default class AtlasTag extends WithTooltipMixin(LitElement) {
    static styles = styles;

    @property({ type: String }) text: string;

    @property({ type: Boolean }) disabled: boolean;

    @property({ type: String }) theme: Theme = "secondary";

    @property({ type: Number }) maxlength: number;

    onClickTag() {
        emit(this, "atlas-tag-click");
    }

    onClickClose(event: MouseEvent) {
        if (this.disabled) return;

        event.stopPropagation();
        emit(this, "atlas-tag-close-click");
    }

    getTagText() {
        if (!this.maxlength) return this.text;

        if (this.text.length <= this.maxlength) return this.text;

        this.tooltip = this.text;
        return `${this.text.substring(0, this.maxlength)}...`;
    }

    render() {
        const tagClass = {
            "atlas-tag": true,
            [`bg-${this.theme}`]: !!this.theme
        };

        return html`
            <button 
                class=${classMap(tagClass)} 
                @click=${this.onClickTag} 
                ?disabled=${this.disabled}
                data-atlas-tooltip="tag-tooltip"
            >
                <span>${this.getTagText()}</span>
                <atlas-icon name="x" size="2x" @click=${this.onClickClose}></atlas-icon>
                ${this.renderTooltip("tag-tooltip")}
            </button>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-tag": AtlasTag;
    }
}
