import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { emit } from "@/internals/events";
import loadingStyles from "./atlas-loading.scss";

import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-text/atlas-text";

export type LoadingProps = {
    "message": string;
    "content-loading": boolean;
    "src": string;
    "alt": string;
    "width": number;
    "height": number;
};

/**
 * Loading é um componente que representa um estado de carregamento na tela após uma ação ser tomada
 *
 * @prop {string} message - A mensagem que informará a ação que está sendo carregada
 * @prop {boolean} content-loading - Indica que o loading não será utilizado na tela toda
 * @prop {string} src - Propriedade src que define o caminho que definirá aonde está imagem
 * @prop {string} alt - Propriedade alt que define o que será apresentado caso a imagem não carregue
 * @prop {number} width - Propriedade que define a largura da imagem
 * @prop {number} height - Propriedade que define a altura da imagem
 *
 * @event {CustomEvent} atlas-loading-show - Evento disparado quando o Loading é exibido
 * @event {CustomEvent} atlas-loading-close - Evento disparado quando o Loading é fechado
 *
 * @tag atlas-loading
 */
@customElement("atlas-loading")
export default class AtlasLoading extends LitElement {
    static styles = loadingStyles;

    @property({ type: String }) message: string;

    @property({ type: Boolean, attribute: "content-loading" }) contentLoading: boolean = false;

    @property({ type: String }) src: string;

    @property({ type: String }) alt: string;

    @property({ type: Number }) width: number;

    @property({ type: Number }) height: number;

    @state() visible = false;

    constructor() {
        super();

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
    }

    firstUpdated() {
        setTimeout(this.show, 100);
    }

    show() {
        this.visible = true;
        emit(this, "atlas-loading-show", { trackDisable: true });
    }

    close() {
        this.visible = false;
        emit(this, "atlas-loading-close", { trackDisable: true });
    }

    destroy() {
        this.close();

        setTimeout(() => {
            this.remove();
        }, 150);
    }

    renderCustomImage() {
        return html`
            <img
                class="logo-loading"
                src=${this.src}
                width=${this.width}
                height=${this.height}
                alt=${this.alt || "loading image"}
            />
        `;
    }

    renderDefaultImage() {
        /* eslint-disable max-len */
        return when(
            this.contentLoading,
            () => html`
                <svg class="logo-loading" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M46.2004 20.0293C45.1224 20.6835 46.6433 21.0047 39.0958 25.5836C38.1968 26.1292 30.5232 30.9105 33.7674 36.5279C34.2397 37.3463 35.3118 39.1635 35.8354 40.0523H29.1621C29.6857 39.1635 30.7578 37.3463 31.2301 36.5279C34.4744 30.9105 26.8008 26.1292 25.9017 25.5836C18.3542 21.0047 19.8737 20.6849 18.7972 20.0293C18.5273 19.8665 18.2032 20.4268 18.102 20.6013C17.4097 21.7996 18.0125 26.6396 20.3342 28.0491C19.6948 29.1549 20.8388 32.2496 23.2324 33.7016C22.235 35.4264 23.8498 37.3404 27.6074 39.7765L25.2138 43.6192C25.1286 43.7614 25.084 43.9242 25.0848 44.09C25.0848 44.5623 25.5086 44.9333 26.0484 44.9333C26.3975 44.9435 26.7258 44.7677 26.9108 44.4713L28.6018 41.7228H36.4177L38.1132 44.4581C38.2877 44.7559 38.6118 44.9333 38.9844 44.9333C39.5241 44.9333 39.948 44.5623 39.948 44.09C39.9505 43.9158 39.8965 43.7454 39.794 43.6045L37.4004 39.7692C41.1506 37.3375 42.761 35.4249 41.7652 33.7016C44.1588 32.2496 45.3028 29.1549 44.6633 28.0491C46.985 26.6411 47.5878 21.8011 46.8956 20.6028C46.7944 20.4268 46.4702 19.8665 46.2004 20.0308V20.0293Z"
                    ></path>
                </svg>
            `,
            () => html`
                <svg class="logo-loading" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="80">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M60.5525 22.044C58.9355 23.0252 61.2169 23.507 49.8957 30.3754C48.5471 31.1938 37.0367 38.3658 41.9031 46.7918C42.6115 48.0194 44.2197 50.7452 45.0051 52.0784H34.9951C35.7805 50.7452 37.3887 48.0194 38.0971 46.7918C42.9635 38.3658 31.4531 31.1938 30.1045 30.3754C18.7833 23.507 21.0625 23.0274 19.4477 22.044C19.0429 21.7998 18.5567 22.6402 18.4049 22.902C17.3665 24.6994 18.2707 31.9594 21.7533 34.0736C20.7941 35.7324 22.5101 40.3744 26.1005 42.5524C24.6045 45.1396 27.0267 48.0106 32.6631 51.6648L29.0727 57.4288C28.9449 57.6421 28.8779 57.8863 28.8791 58.135C28.8791 58.8434 29.5149 59.4 30.3245 59.4C30.8482 59.4153 31.3407 59.1515 31.6181 58.707L34.1547 54.5842H45.8785L48.4217 58.6872C48.6835 59.1338 49.1697 59.4 49.7285 59.4C50.5381 59.4 51.1739 58.8434 51.1739 58.135C51.1777 57.8736 51.0967 57.6181 50.9429 57.4068L47.3525 51.6538C52.9779 48.0062 55.3935 45.1374 53.8997 42.5524C57.4901 40.3744 59.2061 35.7324 58.2469 34.0736C61.7295 31.9616 62.6337 24.7016 61.5953 22.9042C61.4435 22.6402 60.9573 21.7998 60.5525 22.0462V22.044Z"
                    ></path>
                </svg>
            `
        );
        /* eslint-enable max-len */
    }

    renderSpinnerLogo() {
        if (this.src) return this.renderCustomImage();

        return this.renderDefaultImage();
    }

    renderHeading() {
        return when(
            this.contentLoading,
            () => html`
                <atlas-heading size="h6" theme="primary">Aguarde</atlas-heading>
                <atlas-text size="sm" theme="primary" alignment="center">
                    <slot>${this.message}</slot>
                </atlas-text>
            `,
            () => html`
                <atlas-heading size="h3" theme="primary">Aguarde</atlas-heading>
                <atlas-heading size="h4" theme="primary" alignment="center">
                    <slot>${this.message}</slot>
                </atlas-heading>
            `
        );
    }

    render() {
        const spinnerClass = {
            "circular-loader": true,
            "bigger-spinner": !this.contentLoading
        };

        const loadingClass = {
            loading: true,
            show: this.visible
        };

        return html`
            <div class="${classMap(loadingClass)}">
                <div class="loading-image">
                    <svg class="${classMap(spinnerClass)}" viewBox="25 25 50 50">
                        <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke-width="2"></circle>
                    </svg>
                    ${this.renderSpinnerLogo()}
                </div>
                <div class="loading-description">${this.renderHeading()}</div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-loading": AtlasLoading;
    }
}
