import {css} from "lit-element/lit-element.js";
export default css`.breadcrumb {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.breadcrumb .breadcrumb-items {
  display: flex;
  gap: 0.5rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2NvZGVidWlsZC9vdXRwdXQvc3JjMzc3NDY2NjIwNC9zcmMvcGFja2FnZXMvd2ViY29tcG9uZW50cy9zcmMvY29tcG9uZW50cy9kaXNwbGF5L2F0bGFzLWJyZWFkY3J1bWIiLCJzb3VyY2VzIjpbImF0bGFzLWJyZWFkY3J1bWIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJO0VBQ0E7RUFDQTs7QUFFQTtFQUNJO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuYnJlYWRjcnVtYiB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGdhcDogMC4yNXJlbTtcblxuICAgIC5icmVhZGNydW1iLWl0ZW1zIHtcbiAgICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgICAgZ2FwOiAwLjVyZW07XG4gICAgfVxufVxuIl19 */`;
