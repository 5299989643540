import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { emit } from "@/internals/events";
import { isEmptySlot } from "@/internals/slot";
import { Watch } from "@/decorators/watch";

import FormElement, { FormElementProps } from "@/components/form/form-element";

import styles from "./atlas-radio.scss";

export type RadioProps = FormElementProps & {
    checked: boolean;
};

/**
 * @slot - Usado para escrever a label que irá acompanhar o input
 *
 * @prop {boolean} checked - Booleano que define se o radio está marcado
 *
 * @event {CustomEvent} atlas-radio-check - Evento disparado quando o radio é marcado
 * @event {CustomEvent} atlas-radio-uncheck - Evento disparado quando o radio é desmarcado
 * @event {CustomEvent} atlas-radio-change - Evento disparado quando o radio sofre alteração
 *
 */
@customElement("atlas-radio")
export default class AtlasRadio extends FormElement {
    static styles = styles;

    @property({ type: Boolean, reflect: true }) checked = false;

    @state() private _hasSlottedLabel = false;

    connectedCallback(): void {
        super.connectedCallback?.();

        this.onCustomRadioCheck = this.onCustomRadioCheck.bind(this);
        window.addEventListener("atlas-radio-check", this.onCustomRadioCheck);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();
        window.removeEventListener("atlas-radio-check", this.onCustomRadioCheck);
    }

    getElementValue(): string {
        return this.checked ? this.value : "";
    }

    focus() {
        (this.shadowRoot.querySelector(".form-check-input") as HTMLInputElement).focus();
    }

    check() {
        this.checked = true;
    }

    uncheck() {
        this.checked = false;
    }

    toggleChecked(checked?: boolean) {
        if (typeof checked === "boolean") {
            this.checked = checked;
        } else {
            this.checked = !this.checked;
        }
    }

    changeChecked() {
        this.checked = !this.checked;
        this.onChange();
    }

    @Watch("checked", true)
    onChangeChecked() {
        const eventObject = {
            detail: {
                elementId: this.id,
                elementName: this.name,
                elementValue: this.value,
                checked: this.checked
            },
            trackDisable: true
        };

        emit(this, `atlas-radio-${this.checked ? "check" : "uncheck"}`, eventObject);
        emit(this, "atlas-radio-change", eventObject);
        emit(this, "atlas-form-element-touch", { trackDisable: true });
    }

    onCustomRadioCheck(event: CustomEvent) {
        const target = event.target as AtlasRadio;
        const { checked } = event.detail;

        const hasSameName = target.name === this.name;
        const hasDifferentValue = target.value !== this.value;

        if (checked && this.checked && hasSameName && hasDifferentValue) {
            this.uncheck();
        }
    }

    onLabelChange() {
        this._hasSlottedLabel = !isEmptySlot(this);
    }

    render() {
        const checkContainerClass = {
            "form-check": true,
            "form-check-disabled": this.disabled,
            "has-label": this._hasSlottedLabel
        };

        const formCheckClass = {
            "form-check-input": true,
            [`is-${this._status}`]: this.getShowStatus()
        };

        return html`
            <div class="${classMap(checkContainerClass)}">
                <input
                    class="${classMap(formCheckClass)}"
                    type="radio"
                    id="${this.id || "radio_element"}"
                    name="${this.name}"
                    .value="${this.value}"
                    .checked=${this.checked}
                    ?disabled=${this.disabled}
                    aria-checked="${this.checked}"
                    aria-disabled="${this.disabled}"
                    @change="${this.changeChecked}"
                />
                <label class="form-check-label" for="${this.id || "radio_element"}">
                    <slot @slotchange=${this.onLabelChange}></slot>
                </label>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-radio": AtlasRadio;
    }
}
