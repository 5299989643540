import { literal, html } from "lit/static-html.js";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { Theme } from "@/internals/theme";
import { emit } from "@/internals/events";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-card-button.scss";
import "@/components/display/atlas-icon/atlas-icon";

export type CardButtonProps = AtlasElementProps & {
    "theme": Theme;
    "description": string;
    "disabled": boolean;
    "icon": string;
    "href": string;
    "is-external-link": string;
    "atlas-card-button-click": string;
};

/**
 * @dependency atlas-icon
 */
@customElement("atlas-card-button")
export default class AtlasCardButton extends AtlasElement {
    static styles = styles;

    /** O tema do botão */
    @property({ type: String }) theme: Theme = "primary";

    /** O texto do botão */
    @property({ type: String }) description: string;

    /** Booleano que informa se o botão está desabilitado */
    @property({ type: Boolean }) disabled: boolean;

    /** O nome do ícone que será visível ao lado esquerdo do conteúdo do botão */
    @property({ type: String }) icon: string;

    /** Link da página que o usuário vai ser levado ao clicar no botão */
    @property({ type: String }) href: string;

    /** Indica se a página que o botão vai redirecionar é uma página externa */
    @property({ type: Boolean, attribute: "is-external-link" }) isExternalLink: boolean;

    private handleButtonClick() {
        emit(this, "atlas-card-button-click");
    }

    private renderButtonIcon(icon: string) {
        return when(!!icon, () => html`<atlas-icon size="2x" name=${icon} theme=${this.theme}></atlas-icon>`);
    }

    /** @override */
    protected renderElement() {
        const buttonClass = {
            btn: true,
            disabled: this.disabled,
            [`btn-${this.theme}`]: true
        };

        const tag = this.href ? literal`a` : literal`button`;

        /* eslint-disable lit/binding-positions, lit/no-invalid-html */
        return html`
            <${tag}
                class=${classMap(buttonClass)}
                ?disabled=${this.disabled}
                @click=${this.handleButtonClick}
                href=${ifDefined(this.href ? this.href : undefined)}
                rel="${ifDefined(this.href && this.isExternalLink ? "noreferrer" : undefined)}"
                target="${ifDefined(this.href && this.isExternalLink ? "_blank" : undefined)}"
                tabindex="${ifDefined(this.disabled ? "-1" : "0")}"
            >
                ${this.renderButtonIcon(this.icon)}
                <span>${this.description}</span>
                ${this.renderButtonIcon("chevron-right")}
            </${tag}>
        `;
        /* eslint-enable lit/binding-positions, lit/no-invalid-html */
    }

    /** @override */
    protected renderSkeleton() {
        return html`
            <div class="btn skeleton">
                <atlas-icon size="2x"></atlas-icon>
                <span class="skeleton"></span>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-card-button": AtlasCardButton;
    }
}
