import { customElement } from "lit/decorators.js";

import AtlasWizardStep from "@/components/wizard/atlas-wizard-step/atlas-wizard-step";

/**
 * @tag atlas-wizard-intro-step
 */
@customElement("atlas-wizard-intro-step")
export default class AtlasWizardIntroStep extends AtlasWizardStep {
    constructor() {
        super();

        this._offStep = true;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-wizard-intro-step": AtlasWizardIntroStep;
    }
}
