import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import { WithBadgeMixin, type WithBadgeProps } from "@/internals/mixins/with-badge-mixin";
import { WithPopoverMixin, type WithPopoverProps } from "@/internals/mixins/with-popover-mixin";
import { isEmptySlot } from "@/internals/slot";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";
import type { ImagePosition } from "./types";

import styles from "./atlas-card.scss";

import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon-button/atlas-icon-button";

const ComposedClass = WithBadgeMixin(WithPopoverMixin(AtlasElement));

export type CardProps = WithPopoverProps &
    WithBadgeProps &
    AtlasElementProps & {
        "image": string;
        "image-description": string;
        "image-position": ImagePosition;
        "header": string;
        "background": "white" | "light";
    };

/**
 * @dependency atlas-heading
 * @dependency atlas-icon-button
 *
 * @tag atlas-card
 */
@customElement("atlas-card")
export default class AtlasCard extends ComposedClass {
    static styles = styles;

    /** Imagem que aparece no topo do card */
    @property({ type: String }) image: string;

    /** Texto de descrição da imagem (Para acessibilidade) */
    @property({ type: String, attribute: "image-description" }) imageDescription: string;

    /** Posição da imagem (Caso haja um corte para deixar a imagem proporcional) */
    @property({ type: String, attribute: "image-position" }) imagePosition: ImagePosition;

    /** Título do card */
    @property({ type: String }) header: string;

    /** Cor de fundo do card */
    @property({ type: String }) background: "white" | "light" = "white";

    @state() private _showBody = false;

    @state() private _showFooter = false;

    private _deviceController = new DeviceController(this, this.onChangeScreenType.bind(this));

    constructor() {
        super();

        this.badgeTheme = this.badgeTheme || "primary";
    }

    private getSlottedActions() {
        const actionsSlot = this.shadowRoot.querySelector("slot[name=actions]") as HTMLSlotElement;
        return actionsSlot.assignedElements();
    }

    private onChangeSlotBody() {
        this._showBody = !isEmptySlot(this);
    }

    private onChangeSlotActions() {
        this._showFooter = !isEmptySlot(this, "actions");
    }

    private async onChangeScreenType() {
        await this.updateComplete;

        this.getSlottedActions()
            .filter((element) => element.tagName === "ATLAS-BUTTON")
            .forEach((element) => element.toggleAttribute("block", this._deviceController.isMobile));
    }

    private renderSkeletonImage() {
        return when(
            this.skeletonLoading,
            () => html` <div class="skeleton"></div> `,
            () => html` <img src=${this.image} alt=${this.imageDescription} /> `
        );
    }

    private renderHeroImage() {
        const heroImgClass = {
            "hero-image": true,
            [`position-${this.imagePosition}`]: !!this.imagePosition
        };

        return when(
            !!this.image,
            () => html` <div class=${classMap(heroImgClass)}>${this.renderSkeletonImage()}</div> `
        );
    }

    private renderInfoIcon() {
        return when(!this.badgeText, () => html` ${this.renderPopoverInfoIcon()} `);
    }

    private renderHeader() {
        return when(
            !!this.header,
            () => html`
                <div class="card-header">
                    <atlas-heading size="h6" ellipsis>${this.header}</atlas-heading>
                    ${this.renderBadge()} ${this.renderInfoIcon()}
                </div>
            `
        );
    }

    private renderFooter() {
        return html`
            <div class="card-footer">
                <slot name="actions" @slotchange=${this.onChangeSlotActions}></slot>
            </div>
        `;
    }

    /**
     * @internal
     * @override
     */
    public hasBadge(): boolean {
        return (!!this.badgeText || !!this.badgeIcon) && !this.skeletonLoading;
    }

    /** @internal */
    public render() {
        const cardClass = {
            "card": true,
            "bg-light": this.background === "light",
            "has-body": this._showBody,
            "has-footer": this._showFooter,
            "has-badge": !!this.badgeText
        };

        return html`
            <div class="card-wrapper">
                ${this.renderHeroImage()}
                <div class=${classMap(cardClass)}>
                    ${this.renderHeader()}
                    <div class="card-body">
                        <slot @slotchange=${this.onChangeSlotBody}></slot>
                    </div>
                    ${this.renderFooter()}
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-card": AtlasCard;
    }
}
