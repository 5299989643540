import { validateCnpj, validateCpf, validateCpfCnpj } from "@/helpers/cpf-cnpj";
import { validateCellPhone, validateLandline, validatePhone } from "@/helpers/phone-number";
import { validateMFA } from "@/helpers/mfa";
import { validateEmail } from "@/helpers/email";

export enum MaskAliasEnum {
    CPF = "cpf",
    CNPJ = "cnpj",
    CPF_CNPJ = "cpf-cnpj",
    LANDLINE = "landline",
    CELL_PHONE = "cell-phone",
    PHONE = "phone",
    MFA = "mfa",
    EMAIL = "email"
}

export type MaskAliasType = `${MaskAliasEnum}`;

export const MASK_MAPPING = {
    [MaskAliasEnum.CPF]: {
        maskConfig: {
            mask: ["999.999.999-99"],
            jitMasking: true,
            keepStatic: true,
            showMaskOnHover: false
        },
        placeholder: "000.000.000-00",
        inputmode: "numeric",
        validator: validateCpf,
        validatorMessage: "Informe um CPF válido"
    },
    [MaskAliasEnum.CNPJ]: {
        maskConfig: {
            mask: ["99.999.999/9999-99"],
            jitMasking: true,
            keepStatic: true,
            showMaskOnHover: false
        },
        placeholder: "00.000.000/0000-00",
        inputmode: "numeric",
        validator: validateCnpj,
        validatorMessage: "Informe um CNPJ válido"
    },
    [MaskAliasEnum.CPF_CNPJ]: {
        maskConfig: {
            mask: ["999.999.999-99", "99.999.999/9999-99"],
            jitMasking: true,
            keepStatic: true,
            showMaskOnHover: false
        },
        placeholder: "000.000.000-00",
        inputmode: "numeric",
        validator: validateCpfCnpj,
        validatorMessage: "Informe um CPF ou um CNPJ válido"
    },
    [MaskAliasEnum.LANDLINE]: {
        maskConfig: {
            mask: ["(99) 9999-9999"],
            jitMasking: true,
            keepStatic: true,
            showMaskOnHover: false
        },
        placeholder: "(00) 0000-0000",
        inputmode: "tel",
        validator: validateLandline,
        validatorMessage: "Informe um telefone válido"
    },
    [MaskAliasEnum.CELL_PHONE]: {
        maskConfig: {
            mask: ["(99) 99999-9999"],
            jitMasking: true,
            keepStatic: true,
            showMaskOnHover: false
        },
        placeholder: "(00) 00000-0000",
        inputmode: "tel",
        validator: validateCellPhone,
        validatorMessage: "Informe um celular válido"
    },
    [MaskAliasEnum.PHONE]: {
        maskConfig: {
            mask: ["(99) 9999-9999", "(99) 99999-9999"],
            jitMasking: true,
            keepStatic: true,
            showMaskOnHover: false
        },
        placeholder: "(00) 0000-0000",
        inputmode: "tel",
        validator: validatePhone,
        validatorMessage: "Informe um telefone ou um celular válido"
    },
    [MaskAliasEnum.MFA]: {
        maskConfig: {
            mask: ["9 - 9 - 9 - 9 - 9 - 9"],
            jitMasking: true,
            keepStatic: true,
            showMaskOnHover: false
        },
        placeholder: "0 - 0 - 0 - 0 - 0 - 0",
        inputmode: "numeric",
        validator: validateMFA,
        validatorMessage: "Informe um código de verificação válido"
    },
    [MaskAliasEnum.EMAIL]: {
        maskConfig: {
            jitMasking: true,
            showMaskOnHover: false
        },
        placeholder: "",
        inputmode: "email",
        validator: validateEmail,
        validatorMessage: "Informe um email válido"
    }
};

export function getMaskPlaceholder(maskAlias: MaskAliasType) {
    return MASK_MAPPING[maskAlias].placeholder;
}

export function getMaskInputMode(maskAlias: MaskAliasType) {
    return MASK_MAPPING[maskAlias].inputmode;
}

export function getMaskValidationFunction(maskAlias: MaskAliasType) {
    return MASK_MAPPING[maskAlias].validator;
}

export function getMaskValidatorMessage(maskAlias: MaskAliasType) {
    return MASK_MAPPING[maskAlias].validatorMessage;
}
