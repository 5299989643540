import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";

import styles from "./atlas-summary-table-col.scss";
import "@/components/display/atlas-icon/atlas-icon";

/**
 * Coluna das tabelas usadas no resumo da wizard
 *
 * @prop {string} icon - Ícone que será exibido do lado esquerdo da informação
 * @prop {string} column-name - Noma da coluna ao qual essa está associada (É definida automaticamente pegando o texto da coluna do cabeçalho)
 * @prop {number} size - Tamanho da coluna em porcentagem (Deve ser definido apenas na linha do cabeçalho, pois nas outras é definido automaticamente)
 * @prop {boolean} is-header - Indica se a coluna está aparecendo no cabeçalho
 *
 * @slot - Conteúdo da coluna
 *
 * @tag atlas-summary-table-col
 */
@customElement("atlas-summary-table-col")
export default class AtlasSummaryTableCol extends LitElement {
    static styles = styles;

    @property({ type: String }) icon: string;

    @property({ type: String, attribute: "column-name" }) columnName: string;

    @property({ type: Number }) size: number = 100;

    @property({ type: Boolean, attribute: "is-header" }) isHeader: boolean;

    private _deviceController = new DeviceController(this);

    render() {
        const columnClass = {
            "table-column": true,
            "is-header": this.isHeader
        };

        const columnStyles = {
            width: this._deviceController.isMobile ? "100%" : `${this.size}%`
        };

        return html`
            <div class=${classMap(columnClass)} style=${styleMap(columnStyles)}>
                ${when(!!this.icon, () => html`<atlas-icon name=${this.icon} size="3x"></atlas-icon>`)}
                ${when(this._deviceController.isMobile, () => html`<span class="title">${this.columnName}</span>`)}
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-summary-table-col": AtlasSummaryTableCol;
    }
}
