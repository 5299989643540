import { removeNonNumeric } from "./formatters";

const MFA_LENGTH = 6;

export function validateMFA(mfa: string) {
    if (!mfa) {
        return false;
    }

    const mfaValue = removeNonNumeric(mfa);

    return mfaValue.length === MFA_LENGTH;
}
