import { html, TemplateResult } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import { OverlayPlacement } from "@/components/display/overlay";
import type AtlasDropdown from "@/components/display/atlas-dropdown/atlas-dropdown";
import AtlasButtonBase, { type ButtonBaseProps } from "@/components/display/atlas-button/atlas-button-base";
import styles from "./atlas-dropdown-button.scss";

import "@/components/display/atlas-dropdown/atlas-dropdown";

export type DropdownButtonProps = ButtonBaseProps & {
    "split": boolean;
    "hide-arrow": boolean;
    "atlas-split-button-click": boolean;
};

/**
 * @dependency atlas-dropdown
 *
 * @prop {boolean} split - Booleano para mostrar o botão como um Split Button
 * @prop {boolean} hide-arrow - Booleano que informa se a seta ao lado do botão deve ser exibida ou não (apenas para o tipo dropdown)
 *
 * @slot - Slot padrão usado para definir o conteúdo do dropdown
 *
 * @event {CustomEvent} atlas-split-button-click - Evento disparado quando é feito o clique no botão que abre o dropdown (apenas para o tipo split)
 *
 * @tag atlas-dropdown-button
 */
@customElement("atlas-dropdown-button")
export default class AtlasDropdownButton extends AtlasButtonBase {
    static styles = styles;

    @property({ type: Boolean }) filter: boolean;

    @property({ type: Boolean }) split: boolean;

    @property({ type: Boolean, attribute: "hide-arrow" }) hideArrow: boolean;

    @property({ type: String, attribute: "dropdown-placement" }) dropdownPlacement: OverlayPlacement;

    private _dropdownRef: AtlasDropdown;

    constructor() {
        super();

        this.syncDropdownRef();
    }

    async syncDropdownRef() {
        await this.updateComplete;
        this._dropdownRef = this.shadowRoot.querySelector("atlas-dropdown");
    }

    getDropdownRef() {
        return this._dropdownRef;
    }

    @Watch("split")
    onChangeSplit() {
        this.updateComplete.then(() => {
            this._dropdownRef?.syncTriggerElement();
        });
    }

    handleButtonClick() {
        if (this.split) {
            super.handleButtonClick();
        }
    }

    handleSplitButtonClick() {
        emit(this, "atlas-split-button-click");
    }

    renderSplitMode(): TemplateResult {
        const mainButtonClass = {
            ...this.getButtonClasses(),
            "main-button": true,
            "loading": this.loading
        };

        const splitButtonClass = {
            ...this.getButtonClasses(),
            "btn-block": false,
            "dropdown-toggle": true,
            "dropdown-toggle-split": true
        };

        return html`
            <button class=${classMap(mainButtonClass)} ?disabled=${this.isDisabled()} @click=${this.handleButtonClick}>
                ${this.getButtonIcon(this.getCurrentIcon())} ${this.getButtonText()}
            </button>
            <button
                class=${classMap(splitButtonClass)}
                ?disabled=${this.isDisabled()}
                @click=${this.handleSplitButtonClick}
                data-atlas-dropdown="btn-dropdown"
            >
                ${this.getButtonIcon("chevron-down")}
            </button>
        `;
    }

    renderDropdownMode(): TemplateResult {
        const mainButtonClass = {
            "dropdown-toggle": true,
            ...this.getButtonClasses()
        };

        return html`
            <button
                class=${classMap(mainButtonClass)}
                ?disabled=${this.isDisabled()}
                @click=${this.handleButtonClick}
                data-atlas-dropdown="btn-dropdown"
            >
                ${this.getButtonIcon(this.getCurrentIcon())} ${this.getButtonText()}
                ${when(!this.hideArrow, () => this.getButtonIcon("chevron-down"))}
            </button>
        `;
    }

    renderElement() {
        const containerClass = {
            "btn-group": true,
            "btn-block": this.block,
            "rounded-pill": this.pill
        };

        const button = when(
            this.split,
            () => this.renderSplitMode(),
            () => this.renderDropdownMode()
        );

        return html`
            <div
                tabindex=${this.isDisabled() ? "0" : "-1"}
                class="${classMap(containerClass)}"
                data-atlas-tooltip="dropdown-button-tooltip"
            >
                ${this.renderHotspotSlot()} ${button}
                <atlas-dropdown
                    id="btn-dropdown"
                    no-gap
                    auto-close
                    auto-close-trigger="${this.filter ? "outside" : "any"}"
                    max-width=${this.filter ? "unset" : ""}
                    ?overflow=${this.filter}
                    placement=${this.dropdownPlacement}
                >
                    <slot></slot>
                </atlas-dropdown>
            </div>
            ${this.renderTooltip("dropdown-button-tooltip")}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-dropdown-button": AtlasDropdownButton;
    }
}
