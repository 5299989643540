import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import { SelectOption } from "./types";
import type AtlasDropdown from "@/components/display/atlas-dropdown/atlas-dropdown";
import type AtlasSelectItem from "@/components/form/atlas-select-item/atlas-select-item";

import styles from "./atlas-select-dropdown.scss";

import "@/components/form/atlas-input/atlas-input";
import "@/components/form/atlas-select-item/atlas-select-item";
import "@/components/display/atlas-dropdown/atlas-dropdown";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-text/atlas-text";

/**
 * @dependency atlas-input
 * @dependency atlas-select-item
 * @dependency atlas-dropdown
 *
 * @prop {string} header - O cabeçalho do dropdown (para versão mobile)
 * @prop {string} loading - Indica se o select está carregando opções
 * @prop {string} options - As opções disponíveis no select
 * @prop {string} selectedOptions - As opções que já foram selecionadas
 * @prop {string} search-placeholder - O placeholder do input de pesquisa
 * @prop {string} empty-state-text - Mensagem que é exibida no dropdown quando o select não possui opções
 * @prop {string} extra-keys - Chave dos conteúdos extras que serão exibidos na opção do select, separados por ";"
 * @prop {string} groups - Objeto contendo o nome e a chave dos grupos do select
 *
 * @event {CustomEvent} atlas-select-dropdown-change - Evento disparado quando uma opção é selecionada (Via teclado ou clique)
 *
 * @tag atlas-select-dropdown
 */
@customElement("atlas-select-dropdown")
export default class AtlasSelectDropdown extends LitElement {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: Boolean }) loading: boolean;

    @property({ type: Boolean }) multiselect: boolean;

    @property({ type: Boolean }) disabled: boolean;

    @property({ type: Boolean, attribute: "enable-select-all" }) enableSelectAll: boolean = false;

    @property({ type: Boolean, attribute: "enable-new" }) enableNew: boolean;

    @property({ type: String, attribute: "new-item-prefix" }) newItemPrefix: string = "Criar";

    @property({ type: Array }) options: SelectOption[] = [];

    @property({ type: Array }) selectedOptions: SelectOption[] = [];

    @property({ type: String, attribute: "search-value" }) searchValue: string;

    @property({ type: String, attribute: "search-placeholder" }) searchPlaceholder: string;

    @property({ type: String, attribute: "empty-state-text" }) emptyStateText: string = "Sem escolhas para fazer";

    @property({ type: String, attribute: "extra-keys" }) extraKeys: string;

    @property({ type: Boolean, attribute: "is-typing" }) isTyping: boolean;

    @property({ type: Boolean, attribute: "enable-search" }) enableSearch: boolean;

    @property({ type: Object }) groups: { [key: string]: string } = {};

    @state() private _isDropdownOpen = false;

    @state() private _focusedOption = 0;

    private _dropdown: AtlasDropdown;

    private _deviceController = new DeviceController(this);

    connectedCallback() {
        super.connectedCallback?.();

        this.onDropdownOpen = this.onDropdownOpen.bind(this);
        this.onDropdownClose = this.onDropdownClose.bind(this);
        this.onMouseOverOption = this.onMouseOverOption.bind(this);
        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
        this.onSearchInputChange = this.onSearchInputChange.bind(this);
        this.onSearchInputKeyDown = this.onSearchInputKeyDown.bind(this);

        this.updateComplete.then(() => {
            this._dropdown = this.shadowRoot.querySelector("atlas-dropdown");
        });
    }

    canCreateNew() {
        return this.enableNew && this.searchValue && this.searchValue.trim() !== "";
    }

    findOptionWithValue(value: string) {
        return this._dropdown.querySelector(`atlas-select-item[value="${value}"]`) as HTMLElement;
    }

    findOptionWithIndex(index: number) {
        return this._dropdown.querySelector(`atlas-select-item[data-option-index="${index}"]`) as HTMLElement;
    }

    openDropdown() {
        this._dropdown.show();
    }

    closeDropdown() {
        this._dropdown.hide();
    }

    updateDropdownPosition() {
        this._dropdown.updatePosition();
    }

    onDropdownOpen() {
        this._isDropdownOpen = true;

        if (this._deviceController.isMobile) {
            const searchInput = this._dropdown.querySelector(".dropdown-search-input") as HTMLElement;

            setTimeout(() => {
                searchInput?.focus();
            }, 250);
        } else {
            document.addEventListener("keydown", this.onDocumentKeyDown);
            this.setFirstFocus();
        }

        emit(this, "atlas-select-dropdown-opened");
    }

    onDropdownClose() {
        this._isDropdownOpen = false;

        document.removeEventListener("keydown", this.onDocumentKeyDown);
        this.removeFocusFromOptions();

        emit(this, "atlas-select-dropdown-closed");
    }

    onDocumentKeyDown(event: KeyboardEvent) {
        const escapeKeys = ["Tab", "Escape"];
        const arrowKeys = ["ArrowUp", "ArrowDown"];
        const mappedKeys = ["Enter", ...escapeKeys, ...arrowKeys];

        if (!mappedKeys.includes(event.key)) return;

        if (arrowKeys.includes(event.key)) {
            event.preventDefault();
            this.changeFocusedOption(event);
        } else if (escapeKeys.includes(event.key)) {
            this.closeDropdown();
        } else if (event.key === "Enter") {
            this.selectFocusedOption();
        }
    }

    onClickOption(event: CustomEvent) {
        const option = event.target as HTMLElement;
        const optionValue = option.getAttribute("value");

        const shouldRemove = event.detail?.shouldRemove;

        if (shouldRemove) {
            this.removeOption(optionValue);
            return;
        }

        this.selectOption(optionValue);
    }

    onSearchInputChange(event: CustomEvent) {
        emit(this, "atlas-select-dropdown-search", {
            detail: event.detail
        });
    }

    onSearchInputKeyDown() {
        emit(this, "atlas-select-dropdown-input-keydown");
    }

    onMouseOverOption(event: MouseEvent) {
        this.removeFocusFromOptions();
        const targetOption = event.target as HTMLElement;
        this._focusedOption = parseInt(targetOption.dataset.optionIndex, 10);
    }

    @Watch("searchValue", true)
    setFirstFocus() {
        const firstSelected = this.selectedOptions[0]?.value;
        let focusedIndex = 0;

        if (firstSelected) {
            const option = this.findOptionWithValue(firstSelected);

            if (option) {
                focusedIndex = parseInt(option.dataset.optionIndex, 10);
            }
        }

        this._focusedOption = focusedIndex;
        this.removeFocusFromOptions();
    }

    removeFocusFromOptions() {
        this._dropdown?.querySelectorAll(`atlas-select-item`).forEach((option) => {
            option.removeAttribute("focused");
        });
    }

    async applyFocusOnOption() {
        await this.updateComplete;

        const option = this.findOptionWithIndex(this._focusedOption);

        option?.toggleAttribute("focused", true);
        option?.scrollIntoView({
            block: "nearest",
            behavior: "smooth"
        });
    }

    changeFocusedOption(event: KeyboardEvent) {
        if (!this.findOptionWithIndex(this._focusedOption)?.hasAttribute("focused")) {
            this.applyFocusOnOption();
            return;
        }

        this.removeFocusFromOptions();

        let numberOfGroups = 1;

        if (Object.keys(this.groups).length > 0) {
            numberOfGroups = Object.keys(this.groups).length;
        }

        const optionsLength = this.canCreateNew() ? this.options.length + numberOfGroups : this.options.length;
        const nextOption = event.key === "ArrowDown" ? this._focusedOption + 1 : this._focusedOption - 1;

        if (nextOption < 0) {
            this._focusedOption = optionsLength - 1;
        } else if (nextOption >= optionsLength) {
            this._focusedOption = 0;
        } else {
            this._focusedOption = nextOption;
        }

        this.applyFocusOnOption();
    }

    selectOrRemoveFocusedOption(option: AtlasSelectItem) {
        const isFocusedOptionSelected = this.selectedOptions.some(
            (selected) => `${selected.value}` === `${option.value}`
        );

        if (isFocusedOptionSelected) {
            this.removeOption(option.value);
        } else {
            this.selectOption(option.value);
        }

        if (option?.hasAttribute("data-is-create-new")) {
            option.dispatchEvent(new CustomEvent("atlas-select-item-click"));
        }
    }

    selectFocusedOption() {
        const option = this.findOptionWithIndex(this._focusedOption) as AtlasSelectItem;

        if (this.multiselect) {
            this.selectOrRemoveFocusedOption(option);
            return;
        }

        if (option) {
            this.selectOption(option.value);
        }

        if (option?.hasAttribute("data-is-create-new")) {
            option.dispatchEvent(new CustomEvent("atlas-select-item-click"));
        }
    }

    selectOption(optionValue: string) {
        emit(this, "atlas-select-dropdown-change", {
            detail: {
                option: optionValue
            }
        });

        if (this.shouldHideDropdown()) {
            this.closeDropdown();
        }
    }

    removeOption(optionValue: string) {
        emit(this, "atlas-select-dropdown-change", {
            detail: {
                option: optionValue,
                removeOption: true
            }
        });

        if (this.shouldHideDropdown()) {
            this.closeDropdown();
        }
    }

    createNewOption(event?: CustomEvent) {
        const { group } = (event.target as AtlasSelectItem).dataset;

        emit(this, "atlas-select-dropdown-create-new", {
            detail: {
                optionLabel: this.searchValue,
                group
            }
        });

        if (this.shouldHideDropdown()) {
            this.closeDropdown();
        }
    }

    selectAllOptions() {
        const selectedValues = this.selectedOptions.map((option) => option.value);
        const allValues = this.options.map((option) => option.value);

        if (selectedValues.length === allValues.length) {
            emit(this, "atlas-select-dropdown-change", {
                detail: {
                    option: "",
                    removeOption: true
                }
            });

            return;
        }

        emit(this, "atlas-select-dropdown-change", {
            detail: {
                option: allValues.join(",")
            }
        });
    }

    shouldHideDropdown() {
        return !this.multiselect;
    }

    shouldRenderFooterButtons() {
        return this._deviceController.isMobile && this.multiselect && this.enableSearch;
    }

    renderDropdownSearch() {
        return when(
            this._deviceController.isMobile && this.enableSearch,
            () => html`
                <atlas-input
                    slot="subheading"
                    size="lg"
                    icon="magnifier"
                    class="dropdown-search-input"
                    placeholder=${this.searchPlaceholder}
                    value=${this.searchValue}
                    ?loading=${this.loading}
                    @atlas-input-change=${this.onSearchInputChange}
                    @keydown=${this.onSearchInputKeyDown}
                ></atlas-input>
            `
        );
    }

    renderOptionsFromGroup(optionsFromGroup: SelectOption[], baseIndex: number) {
        const extraKeys = this.extraKeys && this.extraKeys.trim() ? this.extraKeys.split(";") : [];

        return optionsFromGroup.map(
            (option, index) => html`
                <atlas-select-item
                    value=${option.value}
                    label=${option.label}
                    highlighted-text=${this.isTyping ? this.searchValue : ""}
                    ?selected=${this.selectedOptions.some((selected) => `${selected.value}` === `${option.value}`)}
                    ?disabled=${option.disabled}
                    ?multiselect=${this.multiselect}
                    .extraContent=${extraKeys.map((key) => option.customProperties[key])}
                    @atlas-select-item-click=${this.onClickOption}
                    @mouseover=${this.onMouseOverOption}
                    data-option-index=${baseIndex + index}
                ></atlas-select-item>
            `
        );
    }

    renderSelectOptions() {
        const groupKeys = Object.keys(this.groups);

        const enableSelectAll = this.enableSelectAll && this.options.length > 0;
        const selectAll = enableSelectAll ? this.renderSelectAll() : html``;

        if (groupKeys.length > 0) {
            return html`${selectAll}${this.renderSelectOptionWithGroup()}`;
        }

        return html`${selectAll}${this.renderOptionsFromGroup(this.options, 0)}${this.renderCreateNew()}`;
    }

    renderSelectOptionWithGroup() {
        const groupKeys = Object.keys(this.groups);
        let countOptions = 0;

        return groupKeys.map((groupName, groupIndex) => {
            const groupOptions = this.options.filter((option) => option.group === groupName);

            countOptions += groupOptions.length;

            if (groupOptions.length === 0 && !this.canCreateNew()) return html``;

            const baseIndex = this.canCreateNew()
                ? countOptions - groupOptions.length + groupIndex
                : countOptions - groupOptions.length;

            const createNewIndex = countOptions + groupIndex;
            return html`
                <atlas-select-item label=${this.groups[groupName]} is-group-title></atlas-select-item>
                ${this.renderOptionsFromGroup(groupOptions, baseIndex)}
                ${this.renderCreateNew(groupName, createNewIndex)}
            `;
        });
    }

    renderCreateNew(groupName?: string, index?: number) {
        return when(
            this.canCreateNew(),
            () => html`
                <atlas-select-item
                    label=${`${this.newItemPrefix} ${this.searchValue}`}
                    icon="plus"
                    highlighted-text=${this.isTyping ? this.searchValue : ""}
                    @atlas-select-item-click=${this.createNewOption}
                    @mouseover=${this.onMouseOverOption}
                    data-option-index=${index ? index : this.options.length}
                    data-group=${groupName}
                    data-is-create-new
                ></atlas-select-item>
            `
        );
    }

    renderSelectAll() {
        return when(
            this.enableSelectAll,
            () => html`
                <atlas-select-item
                    label="Selecionar todos"
                    multiselect
                    select-all
                    ?selected=${this.options.length === this.selectedOptions.length}
                    ?indeterminate=${this.selectedOptions.length > 0 &&
                    this.selectedOptions.length < this.options.length}
                    @atlas-select-item-click=${this.selectAllOptions}
                ></atlas-select-item>
            `
        );
    }

    renderDropdownContent() {
        if (this.loading) {
            return html`
                <div class="dropdown-empty-state">
                    <atlas-icon name="loader" size="2x"></atlas-icon>
                    <atlas-text muted>Carregando opções</atlas-text>
                </div>
            `;
        }

        if (this.options.length === 0 && !this.canCreateNew()) {
            return html`
                <div class="dropdown-empty-state">
                    <atlas-icon name="magnifier" size="2x"></atlas-icon>
                    <atlas-text muted>
                        ${this.multiselect 
                            ? "Nenhum resultado encontrado. Tente novamente com outros termos."
                            : this.emptyStateText}
                    </atlas-text>
                </div>
            `;
        }

        return html`${this.renderSelectOptions()}`;
    }

    render() {
        return html`
            <atlas-dropdown
                no-gap
                auto-close
                ?mobile-fullscreen=${this.enableSearch}
                ?disabled=${this.disabled}
                ?has-footer=${this.shouldRenderFooterButtons()}
                auto-close-trigger=${this.multiselect ? "outside" : "any"}
                max-height=${this.extraKeys ? 340 : 420}
                header=${this._deviceController.isMobile ? this.header : ""}
                @atlas-dropdown-opened=${this.onDropdownOpen}
                @atlas-dropdown-closed=${this.onDropdownClose}
                tabindex=${ifDefined(this._isDropdownOpen ? undefined : "-1")}
                id="select-dropdown"
            >
                ${this.renderDropdownSearch()} ${this.renderDropdownContent()}
            </atlas-dropdown>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-select-dropdown": AtlasSelectDropdown;
    }
}
