import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-row.scss";

export type RowProps = AtlasElementProps & {
    "margin-top": number;
    "margin-bottom": number;
    "gap": number;
};

/**
 * @prop {number} margin-top - A margem que irá em cima da linha (Deve ser um número entre 0 e 9)
 * @prop {number} margin-bottom - A margem que irá embaixo da linha (Deve ser um número entre 0 e 9)
 * @prop {number} gap - O distânciamento entre elementos filhos no caso os atlas-col (Deve ser um número entre 0 e 9)
 *
 * @slot - Usado para incluir os componentes de atlas-col
 */
@customElement("atlas-row")
export default class AtlasRow extends AtlasElement {
    static styles = styles;

    @property({ type: Number, attribute: "margin-top" }) marginTop: number;

    @property({ type: Number, attribute: "margin-bottom" }) marginBottom: number;

    @property({ type: Number }) gap: number;

    render() {
        const rowClass = {
            "atlas-row": true,
            [`mt-${this.marginTop}`]: !!this.marginTop,
            [`mb-${this.marginBottom}`]: !!this.marginBottom,
            [`gap-${this.gap}`]: !!this.gap
        };

        return html`
            <div class=${classMap(rowClass)}>
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-row": AtlasRow;
    }
}
