import { html, literal } from "lit/static-html.js";
import { customElement, property } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { emit } from "@/internals/events";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";
import type AtlasCalendarTimelineItem from "@/components/dashboard/atlas-calendar-timeline-item/atlas-calendar-timeline-item";

import styles from "./atlas-calendar-timeline.scss";

import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon/atlas-icon";

export type CalendarTimelineProps = AtlasElementProps & {
    "header": string;
    "icon": string;
    "button-text": string;
    "button-link": string;
    "button-disabled": boolean;
};

/**
 * @dependency atlas-heading
 * @dependency atlas-icon
 *
 * @event {CustomEvent} atlas-timeline-button-click - Evento disparado quando é feito o clique no botão
 */
@customElement("atlas-calendar-timeline")
export default class AtlasCalendarTimeline extends AtlasElement {
    static styles = styles;

    /** Título da timeline */
    @property({ type: String }) header: string;

    /** Ícone que aparece ao lado do título */
    @property({ type: String }) icon: string;

    /** Texto do botão que aparece no direito do título */
    @property({ type: String, attribute: "button-text" }) buttonText: string;

    /** Link para qual o usuário será redirecionado ao clicar no botão */
    @property({ type: String, attribute: "button-link" }) buttonLink: string;

    /** Indica se o botão está desabilitado */
    @property({ type: Boolean, attribute: "button-disabled" }) buttonDisabled: boolean;

    public connectedCallback() {
        super.connectedCallback?.();
        this.onItemsChange = this.onItemsChange.bind(this);

        this.addEventListener("atlas-timeline-item-hidden-change", this.onItemsChange);
    }

    public disconnectedCallback() {
        super.disconnectedCallback?.();
        this.removeEventListener("atlas-timeline-item-hidden-change", this.onItemsChange);
    }

    protected onItemsChange() {
        const slotElement = this.shadowRoot.querySelector("slot") as HTMLSlotElement;
        const timelineCalendarItems = slotElement.assignedElements();

        timelineCalendarItems.forEach((timelineCalendarElement) => {
            timelineCalendarElement.toggleAttribute("is-last-child", false);
        });

        const visibleElements = timelineCalendarItems.filter((element) => !(element as HTMLElement).hidden);

        if (visibleElements.length === 0) return;

        const lastVisibleElement = visibleElements[visibleElements.length - 1] as AtlasCalendarTimelineItem;
        lastVisibleElement.toggleAttribute("is-last-child", true);
    }

    protected handleButtonClick() {
        emit(this, "atlas-timeline-button-click");
    }

    protected renderButton() {
        const tag = this.buttonLink ? literal`a` : literal`button`;

        /* eslint-disable lit/binding-positions, lit/no-invalid-html */
        return when(
            !!this.buttonText,
            () => html`
                <${tag} 
                    class="timeline-button" 
                    ?disabled=${this.buttonDisabled}
                    href=${ifDefined(this.buttonLink ? this.buttonLink : undefined)}
                >
                    <span>${this.buttonText}</span>
                    <atlas-icon name="chevron-right" theme="primary" size="3x"></atlas-icon>
                </${tag}>
            `
        );
        /* eslint-enable lit/binding-positions, lit/no-invalid-html */
    }

    protected renderElement() {
        return html`
            <div class="timeline">
                <div class="timeline-header">
                    <div class="timeline-title">
                        <atlas-icon name=${this.icon} theme="primary" size="3x"></atlas-icon>
                        <atlas-heading size="h6" theme="primary">${this.header}</atlas-heading>
                    </div>
                    ${this.renderButton()}
                </div>
                <div class="timeline-body">
                    <slot @slotchange=${this.onItemsChange}></slot>
                </div>
            </div>
        `;
    }

    protected renderSkeleton() {
        return html`
            <div class="timeline">
                <div class="timeline-header" style="margin-bottom: 16px">
                    <div class="skeleton" style="width: 86px">
                        <atlas-text></atlas-text>
                    </div>
                    <div class="skeleton" style="width: 56px">
                        <atlas-text></atlas-text>
                    </div>
                </div>
                <div class="timeline-body">
                    <slot></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-calendar-timeline": AtlasCalendarTimeline;
    }
}
