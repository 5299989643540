import { emit } from "./events";

const findUndefinedElements = (root: Element | ShadowRoot) => {
    const rootTagName = root instanceof Element ? root.tagName.toLowerCase() : "";
    const rootIsCustomElement = rootTagName?.startsWith("atlas-");
    const tags = [...root.querySelectorAll(":not(:defined)")]
        .map((el) => el.tagName.toLowerCase())
        .filter((tag) => tag.startsWith("atlas-"));

    if (rootIsCustomElement && !customElements.get(rootTagName)) {
        tags.push(rootTagName);
    }

    return [...new Set(tags)];
};

const waitAllElementsDefine = async () => {
    const atlasElements = findUndefinedElements(document.body);
    const definePromises = atlasElements.map((tagName) => window.customElements.whenDefined(tagName));

    await Promise.all(definePromises);
    emit(document, "AtlasContentLoaded", { trackDisable: true });
};

document.addEventListener("DOMContentLoaded", () => {
    waitAllElementsDefine();
});
