import { customElement, property } from "lit/decorators.js";

import { Watch } from "@/decorators/watch";
import Inputmask from "@/vendors/inputmask-utils";
import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";

import RangeValidator from "@/internals/validators/range-validator";

export type FloatInputProps = InputProps & {
    "decimal-precision": number;
    "max-value": number;
    "max-value-error-message": string;
    "min-value": number;
    "min-value-error-message": string;
    "allow-negative": boolean;
};

/**
 * @extends atlas-input
 *
 * @tag atlas-float-input
 */
@customElement("atlas-float-input")
export default class AtlasFloatInput extends AtlasInput {
    /** Precisão de casas decimais do input */
    @property({ type: Number, attribute: "decimal-precision" }) decimalPrecision = 2;

    /** Valor máximo que o input pode receber */
    @property({ type: Number, attribute: "max-value" }) maxValue: number;

    /** Mensagem exibida no input caso o valor seja maior que o valor máximo */
    @property({ type: String, attribute: "max-value-error-message" }) maxValueErrorMessage: string;

    /** Valor mínimo que o input pode receber */
    @property({ type: Number, attribute: "min-value" }) minValue: number;

    /** Mensagem exibida no input caso o valor seja menor que o valor mínimo */
    @property({ type: String, attribute: "min-value-error-message" }) minValueErrorMessage: string;

    /** Indica que o campo aceita valores negativos */
    @property({ type: Boolean, attribute: "allow-negative" }) allowNegative = false;

    private maskInstance: Inputmask.Instance;

    /** 
     * @internal 
     * @override 
     */
    public connectedCallback() {
        super.connectedCallback?.();

        this.value = this.getValueReplacedDotByComma(this.value);

        this.fixCarretPosition = this.fixCarretPosition.bind(this);

        this.updateComplete.then(() => {
            this.addValidator(new RangeValidator(this.maxValueErrorMessage, this.minValueErrorMessage));

            if (!this.placeholder) {
                this.placeholder = this.decimalPrecision <= 0 ? "0" : `0,${"0".repeat(this.decimalPrecision)}`;
            }

            this.inputMode = "decimal";

            this.maskInstance = Inputmask({
                alias: "float",
                allowMinus: this.allowNegative,
                digits: this.decimalPrecision,
                onKeyDown: (event) => {
                    const arrowKeys = ["ArrowLeft", "ArrowUp", "ArrowDown", "ArrowRight"];

                    if (arrowKeys.includes(event.key)) {
                        event.preventDefault();
                    }
                }
            }).mask(this._input);

            this._input.addEventListener("click", this.fixCarretPosition);
        });
    }

    /** @internal */
    @Watch(["maxValueErrorMessage", "minValueErrorMessage"], true)
    public onChangeAttributes() {
        const validator = this.getValidator("range") as RangeValidator;
        validator.maxValueErrorMessage = this.maxValueErrorMessage;
        validator.minValueErrorMessage = this.minValueErrorMessage;

        this.reportValidity();
    }

    /** 
     * @internal 
     * @override 
     */
    public onChangeValue() {
        if (Inputmask.isValid(this.value, { alias: "float", digits: this.decimalPrecision })) {
            super.onChangeValue();
            return;
        }

        this.value = this.getValueReplacedDotByComma(this.value);

        this.value = Inputmask.format(this.value, { alias: "float", digits: this.decimalPrecision });

        super.onChangeValue();
    }

    /** 
     * @override 
     * @inheritdoc 
     */
    public focus() {
        this._input.focus();
    }

    /** 
     * Retorna o valor do campo sem a máscara
     * @returns {string | number} - O valor do campo sem a máscara
     */
    public getUnmaskedValue(): string | number {
        return this.maskInstance.unmaskedvalue();
    }

    /** @override */
    protected handleFocus() {
        super.handleFocus();
        this.fixCarretPosition();
    }

    private fixCarretPosition() {
        setTimeout(() => {
            const length = this.value ? this.value.length : this.decimalPrecision + 2;

            if (this._input.setSelectionRange) {
                this._input.setSelectionRange(length, length);
            }
        }, 0);
    }

    private getValueReplacedDotByComma(value: any): string {
        if (!value) return "";

        const stringValue = String(value);
        const hasDot = stringValue.includes(".");
        const hasComma = stringValue.includes(",");

        if (!hasDot && !hasComma) return stringValue;

        if (!hasComma) return stringValue.replace(".", ",");

        return stringValue.replaceAll(".", "");
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-float-input": AtlasFloatInput;
    }
}
