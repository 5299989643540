import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { isEmptySlot } from "@/internals/slot";
import { WithCollapseMixin, WithCollapseProps } from "@/internals/mixins/with-collapse-mixin";

import type AtlasCriticalActionList from "../atlas-critical-action-list/atlas-critical-action-list";
import styles from "./atlas-critical-action.scss";
import "@/components/display/atlas-text/atlas-text";
import "@/components/display/atlas-icon/atlas-icon";

export type CriticalActionProps = WithCollapseProps & {
    header: string;
    blocked: boolean;
};

/**
 * @dependency atlas-text
 * @dependency atlas-icon
 *
 * @prop {string} header - Define qual será o texto do cabeçalho do componente
 * @prop {boolean} blocked - Define quando a ação crítica está bloqueada
 *
 * @tag atlas-critical-action
 */
@customElement("atlas-critical-action")
export default class AtlasCriticalAction extends WithCollapseMixin(LitElement) {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: Boolean }) blocked: boolean = false;

    private _actionList: AtlasCriticalActionList;

    getCheckedActions() {
        return this._actionList?.getCheckedActions();
    }

    getCheckedActionsCounter() {
        return this._actionList?.getCheckedActionsCounter();
    }

    onSlotChange() {
        if (isEmptySlot(this)) return;

        const criticalActionContent = this.shadowRoot.querySelector(".critical-action-content") as HTMLElement;
        criticalActionContent.style.display = "flex";

        const slottedElements = criticalActionContent.querySelector("slot").assignedElements();
        const actionListElement = slottedElements.find(
            (slotted: HTMLElement) => slotted.tagName.toLowerCase() === "atlas-critical-action-list"
        );

        if (!actionListElement) return;

        this._actionList = actionListElement as AtlasCriticalActionList;
    }

    renderHeader() {
        return when(
            !!this.header,
            () => html`
                <div class="critical-action-header">
                    <atlas-icon name="${this.blocked ? "x" : "alert-triangle"}"></atlas-icon>
                    <atlas-text bold>${this.header}</atlas-text>
                    <slot name="header-action"></slot>
                    ${this.renderHeaderCollapseButton()}
                </div>
            `
        );
    }

    renderHeaderCollapseButton() {
        return when(
            this.collapsible && !this.blocked,
            () => html` ${this.renderCollapseButton("critical-action", "critical-action")} `
        );
    }

    renderContent() {
        return this.renderContentWithCollapse(html`
            <div class="critical-action-content" style="display: none">
                <slot @slotchange=${this.onSlotChange}></slot>
            </div>
        `);
    }

    render() {
        return html` <div class="critical-action-container">${this.renderHeader()} ${this.renderContent()}</div> `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-critical-action": AtlasCriticalAction;
    }
}
