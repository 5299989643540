import { html } from "lit";
import { customElement, property, query } from "lit/decorators.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import DateValidator from "@/internals/validators/date-validator";
import Datepicker from "@/vendors/datepicker-utils";
import Inputmask from "@/vendors/inputmask-utils";

import AtlasButton from "@/components/display/atlas-button/atlas-button";
import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";

import "@/components/display/atlas-icon/atlas-icon";

import styles from "./atlas-datepicker.scss";
import type { ViewMode } from "./types";

export type DatepickerProps = InputProps & {
    "min-date": string;
    "max-date": string;
    "prevent-past-date": string;
    "prevent-later-date": string;
};

/**
 * O componente datepicker é um input que possui um calendário para escolha de datas
 *
 * @extends atlas-input
 * @dependency atlas-icon
 * @dependency atlas-button
 *
 * @prop {string} min-date - Define uma data mínima que o input deve aceitar
 * @prop {string} max-date - Define uma data máxima que o input deve aceitar
 * @prop {boolean} prevent-past-date - Define se o datepicker pode ou não aceitar datas anteriores ao dia de hoje
 * @prop {boolean} prevent-later-date - Define se o datepicker pode ou não aceitar datas posteriores ao dia de hoje
 *
 * @event {CustomEvent} atlas-datepicker-change - Evento lançado quando a data selecionada é alterada
 *
 * @tag atlas-datepicker
 */
@customElement("atlas-datepicker")
export default class AtlasDatepicker extends AtlasInput {
    static styles = styles;

    @property({ type: String }) placeholder = "___/___/____";

    @property({ type: String, attribute: "min-date" }) minDate: string;

    @property({ type: String, attribute: "max-date" }) maxDate: string;

    @property({ type: Boolean, reflect: true, attribute: "prevent-past-date" }) preventPastDate: boolean;

    @property({ type: Boolean, reflect: true, attribute: "prevent-later-date" }) preventLaterDate: boolean;

    @query(".atlas-datepicker")
    private _datepickerContainer: HTMLElement;

    private _datepicker: Datepicker;

    private _maskInstance: Inputmask.Instance;

    private _dateMinDigits = 8;

    private _viewModeList: ViewMode[] = ["day", "month", "year"];

    private _currentViewMode: ViewMode = "day";

    private _hasSelectedDate = false;

    constructor() {
        super();

        this.onShowDatepicker = this.onShowDatepicker.bind(this);
        this.onHideDatepicker = this.onHideDatepicker.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeView = this.onChangeView.bind(this);
        this.onPrevNextButtonClick = this.onPrevNextButtonClick.bind(this);
        this.onClickDayButton = this.onClickDayButton.bind(this);
        this.onClickCalendarIcon = this.onClickCalendarIcon.bind(this);
        this.onChangeScreenType = this.onChangeScreenType.bind(this);
    }

    connectedCallback(): void {
        super.connectedCallback?.();

        this._deviceController.setScreenChangeCallback(this.onChangeScreenType);
        this.updateIconType();

        this.updateComplete.then(() => {
            this.createDatepicker();
            this.addValidator(new DateValidator());

            this.inputMode = "numeric";
            this._maskInstance = Inputmask({ alias: "datepicker" }).mask(this._input);
        });
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-input-icon-click", this.onClickCalendarIcon);

        if (!this._input) return;

        this._input.removeEventListener("show", this.onShowDatepicker);
        this._input.removeEventListener("hide", this.onHideDatepicker);
        this._input.removeEventListener("changeDate", this.onChangeDate);
    }

    getDatepickerInstance() {
        return this._datepicker;
    }

    getDateFromString(dateStr: string) {
        return new Date(Datepicker.parseDate(dateStr, Datepicker.dateFormat));
    }

    getUnmaskedValue() {
        return this._maskInstance.unmaskedvalue();
    }

    createDatepicker() {
        if (this._datepicker) {
            return;
        }

        let defaultDate = Datepicker.getTodayDate();
        let title = Datepicker.getDateTitle(defaultDate);

        if (this.value !== "") {
            defaultDate = this.getDateFromString(this.value);
            title = Datepicker.getDateTitle(defaultDate);
        }

        this._datepicker = new Datepicker(this._input, {
            autohide: true,
            format: Datepicker.dateFormat,
            maxView: 2,
            language: "pt-BR",
            buttonClass: "atlas-datepicker-button",
            nextArrow: "",
            prevArrow: "",
            minDate: Datepicker.getDatepickerMinDate(this.preventPastDate, this.minDate),
            maxDate: Datepicker.getDatepickerMaxDate(this.preventLaterDate, this.maxDate),
            defaultViewDate: defaultDate,
            title,
            container: this._datepickerContainer,
            beforeShowDay: (date: any) => ({ content: `<span>${date.getDate()}</span>` }),
            beforeShowMonth: (date: any) => ({
                content: `<span>${Datepicker.locales["pt-BR"].monthsShort[date.getMonth()]}</span>`
            }),
            beforeShowYear: (date: any) => ({ content: `<span>${date.getFullYear()}</span>` }),
            beforeShowDecade: (date: any) => ({ content: `<span>${date.getFullYear()}</span>` })
        });

        this.onHoverDayPreview();
        this.createNextPrevArrows();
        this.createCloseButton();
        this.createConfirmButton();

        this._input.addEventListener("show", this.onShowDatepicker);
        this._input.addEventListener("hide", this.onHideDatepicker);
        this._input.addEventListener("changeDate", this.onChangeDate);
        this._input.addEventListener("changeView", this.onChangeView);

        this._datepickerContainer
            .querySelectorAll(".datepicker-cell.day")
            .forEach((element: HTMLElement) => element.addEventListener("click", this.onClickDayButton));

        this._datepickerContainer.querySelectorAll(".atlas-datepicker-button").forEach((button: HTMLButtonElement) => {
            if (button.classList.contains("next-button") || button.classList.contains("prev-button")) {
                button.addEventListener("click", this.onPrevNextButtonClick);
            }
        });

        this.onChangeScreenType();
    }

    createNextPrevArrows() {
        const prevArrow = document.createElement("icon") as HTMLElement;
        prevArrow.classList.add("atlas-icon", "ati-chevron-left", "ati-2x");
        prevArrow.ariaHidden = "true";

        const nextArrow = document.createElement("icon") as HTMLElement;
        nextArrow.classList.add("atlas-icon", "ati-chevron-right", "ati-2x");
        nextArrow.ariaHidden = "true";

        this.shadowRoot.querySelector(".next-button").append(nextArrow);
        this.shadowRoot.querySelector(".prev-button").append(prevArrow);
    }

    createConfirmButton() {
        const confirmButton = document.createElement("atlas-button") as AtlasButton;
        confirmButton.tabIndex = -1;
        confirmButton.theme = "primary";
        confirmButton.type = "ghost";
        confirmButton.description = "Confirmar";
        confirmButton.classList.add("atlas-datepicker-button", "confirm-btn");
        confirmButton.addEventListener("atlas-button-click", () => {
            const selectedDayElement = this.getSelectedDayElement();

            if (selectedDayElement) {
                const date = new Date(parseInt(selectedDayElement.dataset.date));
                this._datepicker.setDate(date);
            }

            this.resetInputPlaceholder();
            this._datepicker.hide();
        });

        const footerControls = this.shadowRoot.querySelector(".datepicker-footer .datepicker-controls");
        footerControls.appendChild(confirmButton);
    }

    createCloseButton() {
        const closeButton = document.createElement("atlas-button") as AtlasButton;
        closeButton.tabIndex = -1;
        closeButton.theme = "primary";
        closeButton.type = "ghost";
        closeButton.description = "Fechar";
        closeButton.classList.add("atlas-datepicker-button", "close-btn");
        closeButton.addEventListener("atlas-button-click", () => {
            this.resetInputPlaceholder();
            this._hasSelectedDate = !!this.value;
            this._datepicker.hide();
        });

        const footerControls = this.shadowRoot.querySelector(".datepicker-footer .datepicker-controls");
        footerControls.appendChild(closeButton);
    }

    getSelectedDayElement() {
        return this.shadowRoot.querySelector(".datepicker-cell.day.selected") as HTMLElement;
    }

    async handleInputKeyDown(event: KeyboardEvent) {
        super.handleInputKeyDown(event);
        this.preventsKeyBehaviorAddedByDatepickerLib(event);

        this.onKeyArrowUpOrDownPressed(event);
        this.onKeyEscPressed(event);
    }

    onKeyEscPressed(event: KeyboardEvent) {
        if (event.key !== "Escape") return;
        if (!this._datepicker.active || !this._deviceController.isMobile) return;

        this._datepicker.hide();
    }

    onKeyArrowUpOrDownPressed(event: KeyboardEvent) {
        const key = event.key;
        const validKeys = ["ArrowUp", "ArrowDown"];

        if (!validKeys.includes(key)) return;
        if (this._deviceController.isMobile && this._datepicker.active) return;

        const todayDate = Datepicker.getTodayDate();
        let newDate = todayDate;
        const factor = key === "ArrowUp" ? 1 : -1;

        if (!!this.value) {
            newDate = this.getDateFromString(this.value);
            newDate.setDate(newDate.getDate() + factor);
        }

        if (key === "ArrowUp") {
            if (this.preventLaterDate && newDate > todayDate) return;
            if (!!this.maxDate && newDate > this.getDateFromString(this.maxDate)) return;
        }

        if (key === "ArrowDown") {
            if (this.preventPastDate && newDate < todayDate) return;
            if (!!this.minDate && newDate < this.getDateFromString(this.minDate)) return;
        }

        this.value = Datepicker.formatDate(newDate, Datepicker.dateFormat);
    }

    onClickDayButton(event: MouseEvent) {
        if (!this._deviceController.isMobile) return;

        event.preventDefault();
        event.stopPropagation();
        const clickedDayElement = (event.target as HTMLElement).closest(".datepicker-cell.day");
        const selectedDayElement = this.getSelectedDayElement();
        selectedDayElement?.classList.remove("selected");
        clickedDayElement.classList.add("selected");
        this._hasSelectedDate = true;
    }

    updateTitle(element: any) {
        const title = element.innerHTML.split("/");

        element.innerHTML = "";

        const titleYear = document.createElement("span");
        titleYear.classList.add("datepicker-title-year");
        const year = title[0];
        titleYear.innerHTML = year;

        const titleDate = document.createElement("span");
        titleDate.classList.add("datepicker-title-date");
        const date = title[1];
        titleDate.innerHTML = date;

        const icon = document.createElement("atlas-icon");
        icon.name = "keyboard";
        icon.size = "4x";

        element.appendChild(titleYear);
        element.appendChild(titleDate);
        element.appendChild(icon);
    }

    onHoverDayPreview() {
        const daysContainer = this.shadowRoot.querySelector(".datepicker-view .days .datepicker-grid");

        daysContainer.addEventListener("mouseover", (event: MouseEvent) => {
            if (this._hasSelectedDate) return;

            const dayElement = (event.target as HTMLElement).closest(".day") as HTMLElement;
            if (!dayElement?.dataset.date) return;

            const date = new Date(Number(dayElement.dataset.date));
            this._input.placeholder = Datepicker.formatDate(date, Datepicker.dateFormat);
        });

        daysContainer.addEventListener("mouseout", (event: MouseEvent) => {
            if (this._hasSelectedDate) return;

            if ((event.target as HTMLElement).closest(".day")) {
                this._input.placeholder = this.placeholder;
            }
        });
    }

    @Watch(["minDate", "maxDate", "preventPastDate", "preventLaterDate"], true)
    onChangeOptions() {
        this._datepicker?.setOptions({
            minDate: Datepicker.getDatepickerMinDate(this.preventPastDate, this.minDate),
            maxDate: Datepicker.getDatepickerMaxDate(this.preventLaterDate, this.maxDate)
        });
    }

    onChangeScreenType() {
        if (!this._datepicker) return;

        this.hasIconEvent = this._deviceController.isMobile;
        this.updateIconType();

        this._datepicker.setOptions({
            showOnFocus: !this._deviceController.isMobile,
            showOnClick: !this._deviceController.isMobile
        });
    }

    onClickCalendarIcon() {
        this._datepicker.show();
    }

    onChangeValue() {
        if (this.value.length >= this._dateMinDigits && !Inputmask.isValid(this.value, { alias: "datepicker" })) {
            this.value = Inputmask.format(this.value, { alias: "datepicker" });
        }

        if (this.value !== this._input.value) {
            this._input.value = "";
            this._input.value = this.value;
            this.getDatepickerInstance().update();
            emit(this, "atlas-datepicker-change");
        }

        this._hasSelectedDate = !!this.value;

        this.resetInputPlaceholder();
        super.onChangeValue();
    }

    onChangeView(event: CustomEvent) {
        this._currentViewMode = this._viewModeList[event.detail.viewId];
        this.updateTodayClassInDatepicker();
    }

    onHideDatepicker() {
        const datepickerTitleElement = this._datepickerContainer.querySelector(".datepicker-title");

        datepickerTitleElement.innerHTML = Datepicker.getDateTitle(this._datepicker.getDate());

        if (this._deviceController.isMobile) {
            const backdrop = this.shadowRoot.querySelector(".atlas-datepicker-backdrop");
            backdrop.classList.remove("show");
        }

        this._input.setAttribute("inputmode", "numeric");
    }

    onPrevNextButtonClick() {
        this.updateTodayClassInDatepicker();
    }

    onShowDatepicker() {
        const datepickerTitleElement = this._datepickerContainer.querySelector(".datepicker-title");
        this.updateTitle(datepickerTitleElement);
        this.updateTodayClassInDatepicker();

        if (this._deviceController.isMobile) {
            const backdrop = this.shadowRoot.querySelector(".atlas-datepicker-backdrop");
            backdrop.classList.add("show");
        }

        this._input.setAttribute("inputmode", "none");
    }

    onChangeDate() {
        if (this.value !== this._input.value) {
            this._hasSelectedDate = false;
            this.value = this._input.value;
            emit(this, "atlas-datepicker-change");
        }
    }

    updateIconType() {
        if (this._deviceController.isMobile) {
            this.addEventListener("atlas-input-action-button-click", this.onClickCalendarIcon);
            this.icon = "";
            this.actionButtonIcon = "calendar";
        } else {
            this.removeEventListener("atlas-input-action-button-click", this.onClickCalendarIcon);
            this.icon = "calendar";
            this.actionButtonIcon = "";
        }
    }

    updateTodayClassInDatepicker() {
        let selector = `.datepicker-cell`;
        const todayDate = Datepicker.getTodayDate();

        if (this._currentViewMode === "day") {
            selector += `.day[data-date="${todayDate.getTime()}"]`;
        }

        if (this._currentViewMode === "month") {
            const viewSwitch = this.shadowRoot.querySelector(".atlas-datepicker-button.view-switch") as HTMLElement;
            const viewYear = parseInt(viewSwitch.textContent);
            if (viewYear !== todayDate.getFullYear()) return;

            selector += `.month[data-month="${todayDate.getMonth()}"]`;
        }

        if (this._currentViewMode === "year") {
            selector += `.year[data-year="${todayDate.getFullYear()}"]`;
        }

        this.shadowRoot.querySelector(selector)?.classList.add("today");
    }

    preventsKeyBehaviorAddedByDatepickerLib(event: KeyboardEvent) {
        const keysToPrevent = [
            "ArrowUp",
            "ArrowDown",
            "ArrowLeft",
            "ArrowRight",
            "Enter",
            "Escape",
            "Backspace",
            "Delete",
            "."
        ];
        
        if (keysToPrevent.includes(event.key) || (event.key === "Tab" && this._deviceController.isMobile)) {
            event.stopImmediatePropagation();
        }
    }

    resetInputPlaceholder() {
        this._input.placeholder = this.placeholder;
    }

    render() {
        return html`
            <div class="atlas-datepicker-backdrop"></div>
            <div class="atlas-datepicker">${super.render()}</div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-datepicker": AtlasDatepicker;
    }
}
