import { customElement, property } from "lit/decorators.js";

import Inputmask from "@/vendors/inputmask-utils";
import { Watch } from "@/decorators/watch";
import { getAssetPath } from "@/helpers/base-path";
import { CardBrandEnum, CardType, detectCardBrand, getCardBrandInfo } from "@/helpers/card";

import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";
import CardValidator from "@/internals/validators/card-validator";

export type CardNumberProps = InputProps & {
    "card-type": CardType;
    "accepted-brands": Array<string>;
    "card-brand": CardBrandEnum;
    "detect-card-brand": boolean;
};

/**
 * @extends atlas-input
 * 
 * @tag atlas-card-number
 */
@customElement("atlas-card-number")
export default class AtlasCardNumber extends AtlasInput {
    /** Tipo de cartão, se é crédito ou débito */
    @property({ type: String, attribute: "card-type" }) cardType: CardType = "credit";

    /** Lista de bandeiras de cartão que serão aceitas */
    @property({ type: Array, attribute: "accepted-brands" }) acceptedBrands: Array<CardBrandEnum> = [];

    /** Bandeira do cartão */
    @property({ type: String, attribute: "card-brand" }) cardBrand: CardBrandEnum = CardBrandEnum.UNKNOWN;

    /** Define que a detecção da bandeira do cartão é feita pelo Atlas */
    @property({ type: Boolean, attribute: "detect-card-brand" }) detectCardBrand: boolean;

    private _maskInstance: Inputmask.Instance;

    private _mask: object = {
        mask: ["9999 9999 9999 9999"],
        jitMasking: true,
        keepStatic: true,
        showMaskOnHover: false
    };

    /** @internal */
    public connectedCallback() {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            this.buildMask();
        });
    }

    /** @internal */
    @Watch("cardBrand", true)
    public onChangeCardBrand() {
        const brandInfo = getCardBrandInfo(this.cardBrand);

        if (!brandInfo) return;

        this._maskInstance.option({
            mask: brandInfo.mask
        });

        this.sideImage = getAssetPath(brandInfo.brandFlag);
    }

    /**
     * Retorna o valor do campo sem a máscara
     * @returns {string} - O valor do campo sem a máscara
     */
    public getUnmaskedValue(): string {
        return this._maskInstance.unmaskedvalue();
    }

    /**
     * Retorna a bandeira do cartão
     * @returns {CardBrandEnum} - A bandeira do cartão
     */
    public getCardBrand(): CardBrandEnum {
        return this.cardBrand;
    }

    private buildMask() {
        this.placeholder = this.placeholder || "0000 0000 0000 0000";
        this.inputMode = "numeric";

        this._maskInstance = Inputmask({
            ...this._mask,
            onKeyDown: () => {
                if (!this.detectCardBrand) return;

                setTimeout(() => {
                    this.cardBrand = detectCardBrand(this._input.value, this.cardType === "debit", this.acceptedBrands);
                }, 100);
            }
        }).mask(this._input);

        // @ts-expect-error
        this._maskInstance.shadowRoot = this.shadowRoot;

        this.addValidator(new CardValidator());
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-card-number": AtlasCardNumber;
    }
}
