import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { type StyleInfo, styleMap } from "lit/directives/style-map.js";

import type { AtlasElementProps } from "@/components/atlas-element";
import AtlasElement from "@/components/atlas-element";
import type AtlasButtonBase from "@/components/display/atlas-button/atlas-button-base";
import DeviceController from "@/controllers/device-controller";

import { Alignment, Justify } from "./types";

import styles from "./atlas-layout.scss";

export type LayoutProps = AtlasElementProps & {
    "gap": number;
    "auto": boolean;
    "inline": boolean;
    "fluid": boolean;
    "alignment": Alignment;
    "justify": Justify;
    "mobile-inline": boolean;
    "mobile-gap": number;
    "grid": boolean;
    "grid-cols": number;
};

/**
 * @prop {number} gap - Propriedade que define o espaco entre elementos (Deve ser um número entre 0 e 9)
 * @prop {boolean} auto - Propriedade que se ativa transforma o layout para disposição automática
 * @prop {boolean} inline - Propriedade que se ativa transforma o layout para disposição em linha
 * @prop {boolean} fluid - Indica se o layout deve ser fluido(se encaixar inteiramente no container pai)
 * @prop {boolean} grid - Indica se o layout deve se comportar como uma grid
 * @prop {number} grid-cols - Indica o número de colunas da grid (Deve ser um número entre 1 e 12)
 * @prop {boolean} wrap - Indica se o layout deve deve quebrar o conteúdo em linhas quando não houver espaço
 * @prop {Alignment} alignment - Alinhamento dos itens do layout
 * @prop {Justify} justify - Justificação dos itens do layout
 * @prop {number} mobileGap - Propriedade que define o espaco entre elementos (Deve ser um número entre 0 e 9), no mobile
 * @prop {boolean} mobileInline - Indica se mesmo no mobile, os itens devem permanecer lado a lado
 *
 * @tag atlas-layout
 */
@customElement("atlas-layout")
export default class AtlasLayout extends AtlasElement {
    static styles = styles;

    @property({ type: Number }) gap: number;

    @property({ type: Boolean }) auto: boolean;

    @property({ type: Boolean }) inline: boolean;

    @property({ type: Boolean }) fluid: boolean;

    @property({ type: Boolean }) grid: boolean;

    @property({ type: Number, attribute: "grid-cols" }) gridCols: number;

    @property({ type: Boolean }) wrap: boolean;

    @property({ type: String }) alignment: Alignment;

    @property({ type: String }) justify: Justify;

    @property({ type: Number, attribute: "mobile-gap" }) mobileGap: number;

    @property({ type: Boolean, attribute: "mobile-inline" }) mobileInline: boolean;

    protected _deviceController = new DeviceController(this, this.onSlotChange.bind(this));

    private _maxGridCols = 12;

    getIsInline() {
        return (
            (!this._deviceController.isMobile && this.inline) || (this._deviceController.isMobile && this.mobileInline)
        );
    }

    getGridCols() {
        if (!this.gridCols || this.gridCols < 1) return 1;
        if (this.gridCols > this._maxGridCols) return this._maxGridCols;

        return this.gridCols;
    }

    getSlottedElementColSpan(slottedElement: Element) {
        const colSpan = parseInt(slottedElement.getAttribute("data-atlas-layout-col-span"), 10) || 1;
        const gridCols = this.getGridCols();
        if (colSpan > gridCols) return gridCols;
        if (colSpan < 1) return 1;

        return colSpan;
    }

    async onSlotChange() {
        await this.updateComplete;

        const slottedElements = this.shadowRoot.querySelector("slot").assignedElements();
        const buttonElements = ["ATLAS-BUTTON", "ATLAS-DROPDOWN-BUTTON"];

        slottedElements
            .filter((element) => buttonElements.includes(element.tagName))
            .forEach((element: AtlasButtonBase) => {
                const hasBlock = this._deviceController.isMobile || element.initialBlock;
                element.toggleAttribute("block", hasBlock);
            });

        slottedElements
            .filter((element) => element.hasAttribute("data-atlas-layout-col-span"))
            .forEach((element) => {
                element.setAttribute("style", `grid-column: span ${this.getSlottedElementColSpan(element)}`);
            });
    }

    render() {
        const layoutClass = {
            "atlas-layout": true,
            "inline": this.getIsInline(),
            "mobile": this._deviceController.isMobile,
            "fluid": this.fluid,
            "grid": this.grid,
            "wrap": this.wrap,
            [`align-${this.alignment}`]: !!this.alignment,
            [`justify-${this.justify}`]: !!this.justify,
            [`gap-${this.gap}`]: !!this.gap,
            [`mobile-gap-${this.mobileGap}`]: !!this.mobileGap && this._deviceController.isMobile
        };

        const shouldApplyGridCols = this.grid && !this._deviceController.isMobile;

        const layoutStyle: StyleInfo = {
            "grid-template-columns": shouldApplyGridCols ? `repeat(${this.getGridCols()}, 1fr)` : null
        };

        return html`
            <div class="${classMap(layoutClass)}" style="${styleMap(layoutStyle)}">
                <slot @slotchange=${this.onSlotChange}></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-layout": AtlasLayout;
    }
}
