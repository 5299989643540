import { removeNonNumeric } from "./formatters";

const POSTAL_CODE_LENGTH = 8;
const POSTAL_CODE_PATTERN = /^[0-9]{5}-[0-9]{3}$/;

export function validatePostalCode(postalCode: string) {
    const postalCodeValue = removeNonNumeric(postalCode);

    return postalCodeValue.length === POSTAL_CODE_LENGTH && POSTAL_CODE_PATTERN.test(postalCode);
}
