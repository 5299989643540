import FormElement from "@/components/form/form-element";
import FieldValidator, { FieldValidatorStatus } from "./field-validator";

import { validatePostalCode } from "@/helpers/postal-code";

export default class PostalCodeValidator implements FieldValidator {
    name = "postal-code";

    status: FieldValidatorStatus = "error";

    getInvalidMessage(): string {
        return "Informe um CEP válido";
    }

    validate(element: FormElement): boolean {
        const elementValue = element.getElementValue();

        if (elementValue) {
            return validatePostalCode(elementValue);
        }

        return true;
    }
}
