import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { WithTooltipMixin } from "@/internals/mixins/with-tooltip-mixin";

import { Theme } from "@/internals/theme";

import styles from "./atlas-bar-chart-item.scss";

export type BarChartItemProps = {
    text: string;
};

@customElement("atlas-bar-chart-item")
export default class AtlasBarChartItem extends WithTooltipMixin(LitElement) {
    static styles = styles;

    /** Define o tema do item */
    @property({ type: String }) theme: Theme;

    /** Define a variação de cor do item */
    @property({ type: String }) variation: string;

    /** Define o tamanho do item */
    @property({ type: Number }) width: number = 100;

    public render() {
        const barChartClass = {
            "bar-chart": true,
            [`bar-chart-${this.theme}`]: !!this.theme,
            [`bar-chart-${this.variation}`]: !!this.variation
        };

        return html`
            <div
                class="${classMap(barChartClass)}"
                data-atlas-tooltip="bar-chart-tooltip"
                style="width: ${this.width}%"
            ></div>
            ${this.renderTooltip("bar-chart-tooltip")}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-bar-chart-item": AtlasBarChartItem;
    }
}
