import type AtlasCardNumber from "@/components/form/atlas-card-number/atlas-card-number";
import FieldValidator, { FieldValidatorStatus } from "./field-validator";

import { removeNonNumeric } from "@/helpers/formatters";

export default class CardValidator implements FieldValidator {
    name = "card";

    status: FieldValidatorStatus = "error";

    invalidMessage = "";

    getInvalidMessage(): string {
        return this.invalidMessage;
    }

    validate(element: AtlasCardNumber): boolean {
        const elementValue = element.value;
        const cardNumber = removeNonNumeric(elementValue);

        if (cardNumber.length < 6) {
            this.invalidMessage = "Informe um número de cartão válido";
            return false;
        }

        return true;
    }
}
