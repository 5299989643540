import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";

import { emit } from "@/internals/events";
import DeviceController from "@/controllers/device-controller";

import { WithBadgeMixin, type WithBadgeProps } from "@/internals/mixins/with-badge-mixin";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import type AtlasIllustration from "@/components/display/atlas-illustration/atlas-illustration";

import { type PromotionalBannerType } from "./types";
import styles from "./atlas-promotional-banner.scss";

import "@/components/display/atlas-illustration/atlas-illustration";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/form/atlas-helper-text/atlas-helper-text";

export type PromotionalBannerProps = WithBadgeProps &
    AtlasElementProps & {
        "type": PromotionalBannerType;
        "illustration": string;
        "header": string;
        "with-background": boolean;
        "helper-text": string;
        "show-close-button": boolean;
    };

/**
 * @dependency atlas-illustration
 * @dependency atlas-icon
 * @dependency atlas-heading
 * @dependency atlas-helper-text
 *
 * @event {CustomEvent} atlas-promotional-banner-close - Evento disparado quando é fechado o banner promocional
 *
 * @tag atlas-promotional-banner
 */
@customElement("atlas-promotional-banner")
export default class AtlasPromotionalBanner extends WithBadgeMixin(AtlasElement) {
    static styles = styles;

    /** Define o tipo de banner */
    @property({ type: String }) type: PromotionalBannerType = "light-blue";

    /** Define a ilustração do banner */
    @property({ type: String }) illustration: string;

    /** Define o texto do header do banner */
    @property({ type: String }) header: string;

    /** Define se a ilustração do banner possui background */
    @property({ type: Boolean, attribute: "with-background" }) withBackground = false;

    /** Define o texto de ajuda do banner, pode também ser passado através do slot helper-text */
    @property({ type: String, attribute: "helper-text" }) helperText: string;

    /** Define se o banner deve conter o botão de fechar */
    @property({ type: Boolean, attribute: "show-close-button" }) showCloseButton: boolean = false;

    @state() private _hasActions = false;

    @state() private _hasText = false;

    @state() private _hasSlottedHelperText = false;

    @query(".atlas-promotional-banner")
    private _bannerRef?: HTMLElement;

    @query(".illustration")
    private _illustrationRef?: AtlasIllustration;

    private _deviceController: DeviceController = new DeviceController(
        this,
        this.setAttributesForMobileBreakpoint.bind(this)
    );

    /** Fecha o banner */
    public close() {
        this.collapseEffect();
        setTimeout(() => {
            this.remove();
        }, 280);

        emit(this, "atlas-promotional-banner-close");
    }

    private getIllustrationSize() {
        if (this._deviceController.isMobile) return "xs";

        return this._hasActions ? "sm" : "xs";
    }

    private isWhiteType() {
        return this.type === "white";
    }

    private collapseEffect() {
        const sectionHeight = this._bannerRef.getBoundingClientRect().height;

        requestAnimationFrame(() => {
            this._bannerRef.style.height = `${sectionHeight}px`;

            requestAnimationFrame(() => {
                this._bannerRef.style.height = "0";
                this._bannerRef.style.padding = "0";
            });
        });
    }

    private setAttributesForMobileBreakpoint() {
        if (!this._hasActions) return;

        const actionSlot = this.shadowRoot.querySelector("slot[name='actions']") as HTMLSlotElement;
        const actions = actionSlot.assignedElements();

        actions.forEach((action) => {
            action.toggleAttribute("block", this._deviceController.isMobile);
        });

        this._illustrationRef.setAttribute("size", this.getIllustrationSize());
    }

    private onSlotChange() {
        const slot = this.shadowRoot.querySelector("slot") as HTMLSlotElement;
        const actionSlot = this.shadowRoot.querySelector("slot[name='actions']") as HTMLSlotElement;

        const slotElements = slot.assignedElements({ flatten: true }) || [];
        const actionsElements = actionSlot.assignedElements() || [];

        this._hasText = slotElements.length > 0;
        this._hasActions = actionsElements.length > 0;

        const elements = [...slotElements, ...actionsElements];

        elements.forEach((element) => {
            element.toggleAttribute("white", !this.isWhiteType());
        });

        actionsElements.forEach((element) => {
            element.toggleAttribute("block", this._deviceController.isMobile);
        });
    }

    private onHelperTextSlotChange() {
        const helperTextSlot = this.shadowRoot.querySelector("slot[name=helper-text]") as HTMLSlotElement;
        const slottedHelperTexts = helperTextSlot
            .assignedElements()
            .filter((element) => element.tagName === "ATLAS-HELPER-TEXT");

        slottedHelperTexts.forEach((element) => {
            element.setAttribute("id", this.id);
            element.toggleAttribute("white", !this.isWhiteType());
        });

        this._hasSlottedHelperText = slottedHelperTexts.length > 0;
    }

    private renderDescription() {
        const descriptionClasses = {
            description: true,
            hide: !this._hasText
        };

        return html`
            <div class="${classMap(descriptionClasses)}">
                <slot @slotchange=${this.onSlotChange}></slot>
            </div>
        `;
    }

    private renderActions() {
        const actionsClasses = {
            actions: true,
            hide: !this._hasActions
        };

        const helperTextClasses = {
            "helper-text": true,
            "hide": !this.helperText && !this._hasSlottedHelperText
        };

        return html`
            <div class="${classMap(actionsClasses)}">
                <slot name="actions" @slotchange=${this.onSlotChange}></slot>
                <div class="${classMap(helperTextClasses)}">${this.renderHelperText()}</div>
            </div>
        `;
    }

    private renderHelperText() {
        return when(
            this.helperText,
            () => html`
                <atlas-helper-text id=${this.id} ?white=${!this.isWhiteType()}> ${this.helperText} </atlas-helper-text>
            `,
            () => html` <slot name="helper-text" @slotchange=${() => this.onHelperTextSlotChange()}></slot> `
        );
    }

    private renderContent() {
        const headerClasses = {
            "header": true,
            "hide": !this.header,
            "has-close-button": this.showCloseButton && !this.badgeText
        };

        return html`
            <div class="content">
                ${this.renderBadge()}
                <atlas-heading class="${classMap(headerClasses)}" size="h4" ?white=${!this.isWhiteType()}
                    >${this.header}</atlas-heading
                >
                ${this.renderDescription()} ${this.renderActions()}
            </div>
        `;
    }

    private renderCloseButton() {
        return when(
            this.showCloseButton,
            () => html`
                <div class="close">
                    <atlas-icon name="x" ?white=${!this.isWhiteType()} @click=${this.close}></atlas-icon>
                </div>
            `
        );
    }

    /** @internal */
    public render() {
        const promotionalBannerClasses = {
            "atlas-promotional-banner": true,
            "with-background": this.withBackground,
            [`${this.type}`]: !!this.type
        };

        return html`
            <div class="${classMap(promotionalBannerClasses)}">
                ${when(this.withBackground, () => html`<div class="background ${this.getIllustrationSize()}"></div>`)}
                <atlas-illustration
                    class="illustration"
                    name="${this.illustration}"
                    alt="${this.header}"
                    size="${this.getIllustrationSize()}"
                ></atlas-illustration>
                ${this.renderContent()} ${ this.renderCloseButton()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-promotional-banner": AtlasPromotionalBanner;
    }
}
