export const BillingType = [
    "UNDEFINED",
    "BOLETO",
    "CREDIT_CARD",
    "MUNDIPAGG_CIELO",
    "TRANSFER",
    "DEPOSIT",
    "DEBIT_CARD",
    "PIX",
    "RECEIVED_IN_CASH"
];

export const BillingTypeTooltips = {
    RECEIVED_IN_CASH: "em Dinheiro",
    CREDIT_CARD: "no Cartão de Crédito",
    MUNDIPAGG_CIELO: "no Cartão de Crédito",
    DEBIT_CARD: "no Cartão de Débito",
    BOLETO: "no Boleto Bancário",
    PIX: "no Pix",
    UNDEFINED: "no Pergunte ao cliente",
    DEPOSIT: "no Depósito",
    TRANSFER: "pro Transferência",
    BANK_SLIP_WTIH_PIX: "no Boleto Bancário/Pix"
};
