import { ReactiveController, LitElement } from "lit";

import { OverlayTrigger } from "@/components/display/overlay";
import type AtlasInput from "@/components/form/atlas-input/atlas-input";

export interface TriggeredElement extends LitElement {
    onTriggerActivate: () => void;
}

export default class TriggerController implements ReactiveController {
    host: TriggeredElement;

    open: boolean;

    disabled: boolean;

    trigger: OverlayTrigger;

    hostParentNode: Node | DocumentFragment | ShadowRoot;

    triggerElement: HTMLElement;

    constructor(host: TriggeredElement, trigger: OverlayTrigger) {
        this.host = host;
        this.trigger = trigger;

        host.addController(this);

        this.syncTriggerElement = this.syncTriggerElement.bind(this);
        this.handleTriggerFocus = this.handleTriggerFocus.bind(this);
        this.handleTriggerBlur = this.handleTriggerBlur.bind(this);
        this.handleTriggerClick = this.handleTriggerClick.bind(this);
        this.handleTriggerMouseEnter = this.handleTriggerMouseEnter.bind(this);
        this.handleTriggerMouseLeave = this.handleTriggerMouseLeave.bind(this);
    }

    /**
     * @internal
     */
    public hostConnected() {
        if (!this.hostParentNode) {
            this.hostParentNode = this.host.parentNode;
        }

        this.syncTriggerElement();
    }

    /**
     * @internal
     */
    public hostDisconnected() {
        this.clearTriggerEvents();
    }

    /**
     * @internal
     */
    public show() {
        if (this.disabled) {
            return;
        }

        this.open = true;
        this.host.onTriggerActivate();
    }

    /**
     * @internal
     */
    public hide() {
        this.open = false;
        this.host.onTriggerActivate();
    }

    /**
     * @internal
     */
    public toggle() {
        if (this.open) {
            this.hide();
        } else {
            this.show();
        }
    }

    /**
     * @internal
     */
    public syncTriggerElement(triggerElement?: HTMLElement) {
        this.clearTriggerEvents();
        this.triggerElement = triggerElement || this.findTriggerElement(this.hostParentNode);

        this.bindTriggerEvents();
    }

    private bindTriggerEvents() {
        this.triggerElement?.addEventListener("focus", this.handleTriggerFocus, true);
        this.triggerElement?.addEventListener("blur", this.handleTriggerBlur, true);
        this.triggerElement?.addEventListener("click", this.handleTriggerClick);
        this.triggerElement?.addEventListener("mouseenter", this.handleTriggerMouseEnter);
        this.triggerElement?.addEventListener("mouseleave", this.handleTriggerMouseLeave);
    }

    private clearTriggerEvents() {
        this.triggerElement?.removeEventListener("focus", this.handleTriggerFocus, true);
        this.triggerElement?.removeEventListener("blur", this.handleTriggerBlur, true);
        this.triggerElement?.removeEventListener("click", this.handleTriggerClick);
        this.triggerElement?.removeEventListener("mouseenter", this.handleTriggerMouseEnter);
        this.triggerElement?.removeEventListener("mouseleave", this.handleTriggerMouseLeave);
    }

    private findTriggerElement(parentNode: Node | DocumentFragment | ShadowRoot | HTMLElement): HTMLElement {
        if (!this.host.id) return null;

        const hostTagName = this.host.tagName.toLowerCase();
        const selector = `[data-${hostTagName}=${this.host.id}]`;

        let element: HTMLElement = null;

        if (parentNode instanceof ShadowRoot) {
            element = parentNode.querySelector(selector);

            if (!element) {
                element = this.findTriggerElement(parentNode.host.parentNode);
            }
        } else if (parentNode instanceof Document) {
            element = parentNode.querySelector(selector);
        } else if (parentNode === parentNode.getRootNode()) {
            element = parentNode as HTMLElement;
        } else {
            element = this.findTriggerElement(parentNode.getRootNode());
        }

        if (element && element.tagName === "ATLAS-INPUT") {
            element = (element as AtlasInput)._formControl;
        }

        return element;
    }

    private hasTrigger(triggerType: string) {
        return this.trigger.split(" ").includes(triggerType);
    }

    private handleTriggerFocus() {
        if (this.hasTrigger("focus")) {
            this.show();
        }
    }

    private handleTriggerBlur() {
        if (this.hasTrigger("focus")) {
            this.hide();
        }
    }

    private handleTriggerClick() {
        if (this.hasTrigger("click")) {
            this.toggle();
        }
    }

    private handleTriggerMouseEnter() {
        if (this.hasTrigger("hover")) {
            this.show();
        }
    }

    private handleTriggerMouseLeave() {
        if (this.hasTrigger("hover")) {
            this.hide();
        }
    }
}
