import { ReactiveController, ReactiveControllerHost } from "lit";

import { isMobileScreen } from "@/helpers/device";

export default class DeviceController implements ReactiveController {
    private host: ReactiveControllerHost;

    private cbChangeScreenType: () => void;

    public isMobile = false;

    constructor(host: ReactiveControllerHost, onChangeScreenType?: () => void) {
        this.host = host;
        this.cbChangeScreenType = onChangeScreenType;

        host.addController(this);
        this.onScreenResize = this.onScreenResize.bind(this);
    }

    hostConnected() {
        window.addEventListener("resize", this.onScreenResize);
        this.onScreenResize();
    }

    hostDisconnected() {
        window.removeEventListener("resize", this.onScreenResize);
    }

    setScreenChangeCallback(callback: () => void) {
        this.cbChangeScreenType = callback;
    }

    onScreenResize() {
        const { isMobile: prevIsMobile } = this;

        this.isMobile = isMobileScreen();

        if (prevIsMobile !== this.isMobile) {
            this.host.requestUpdate();
            this.cbChangeScreenType?.();
        }
    }
}
