import AtlasDropdownButton from "@/components/display/atlas-dropdown-button/atlas-dropdown-button";
import OverlayElement from "@/components/display/overlay-element";

const OVERLAY_ELEMENTS = ["atlas-dropdown", "atlas-dropdown-button", "atlas-popover", "atlas-tooltip"];

export const closeAllOverlays = (host?: HTMLElement | DocumentFragment) => {
    const container = host || document.body;

    container.querySelectorAll(OVERLAY_ELEMENTS.toString()).forEach((overlayElement) => {
        if (overlayElement instanceof AtlasDropdownButton) {
            (overlayElement as AtlasDropdownButton).getDropdownRef()?.hide();
        } else if (overlayElement instanceof OverlayElement) {
            (overlayElement as OverlayElement).hide();
        }
    });
};
