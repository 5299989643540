import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { emit } from "@/internals/events";
import DeviceController from "@/controllers/device-controller";

import type AtlasPopover from "@/components/display/atlas-popover/atlas-popover";
import AtlasElement, { type AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-hotspot.scss";

export type HotspotProps = AtlasElementProps & {
    "highlight-target": boolean;
    "target": string;
};

/**
 * @event {CustomEvent} atlas-hotspot-click - Evento disparado ao clicar no hotspot
 *
 * @tag atlas-hotspot
 */
@customElement("atlas-hotspot")
export default class AtlasHotspot extends AtlasElement {
    static styles = styles;

    /** Indica se o hotspot ao ser renderizado, vai criar um overlay na tela dando foco apenas ao elemento alvo */
    @property({ type: Boolean, attribute: "highlight-target" }) highlightTarget: boolean;

    /** Seletor do elemento alvo que o hotspot vai destacar */
    @property({ type: String }) target: string;

    @state() private _isHotspotVisible = true;

    @state() private _isOverlayVisible = false;

    private _deviceController = new DeviceController(this);

    public connectedCallback(): void {
        super.connectedCallback();

        this.onClickHotspot = this.onClickHotspot.bind(this);
        this.onClickOverlay = this.onClickOverlay.bind(this);
        this.onCloseHotspotPopover = this.onCloseHotspotPopover.bind(this);

        this.onRenderHighlightHotspot();
    }

    /**
     * Retorna o popover associado ao hotspot
     * @returns {AtlasPopover} Instância do popover associado ao hotspot
     */
    public getHotspotPopover(): AtlasPopover {
        const popoverId = this.getAttribute("data-atlas-popover");
        return document.querySelector(`#${popoverId}`) as AtlasPopover;
    }

    private async onRenderHighlightHotspot() {
        await this.updateComplete;
        if (!this.highlightTarget) return;

        setTimeout(() => {
            if (this.isConnected) {
                this.toggleHighlightOverlay();
                this.openHotspotPopoverAndBindEvents();
            }
        }, 1000);
    }

    private onClickHotspot(event: MouseEvent) {
        event.preventDefault();
        emit(this, "atlas-hotspot-click");
    }

    private onClickOverlay(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    private onCloseHotspotPopover() {
        const popover = this.getHotspotPopover();
        if (!popover) return;

        this._isHotspotVisible = false;
        this._isOverlayVisible = false;
        document.body.classList.remove("disable-scroll-hotspot");

        popover.removeEventListener("atlas-popover-closed", this.onCloseHotspotPopover);
        popover.hide();

        setTimeout(() => {
            popover.remove();
            this.remove();
        }, 350);
    }

    private toggleHighlightOverlay() {
        const targetElement = document.querySelector(this.target);
        if (!targetElement || this._deviceController.isMobile) return;

        const rect = targetElement.getBoundingClientRect();
        const extraPadding = 8;
        const top = rect.top - extraPadding;
        const left = rect.left - extraPadding;
        const width = rect.width + 2 * extraPadding;
        const height = rect.height + 2 * extraPadding;

        const hotspotOverlay = this.shadowRoot.querySelector(".hotspot-overlay") as HTMLElement;
        hotspotOverlay.style.clipPath = `polygon(
            0 0, 
            100% 0, 
            100% 100%, 
            0 100%, 
            0 ${top}px, 
            ${left}px ${top}px, 
            ${left}px ${top + height}px, 
            ${left + width}px ${top + height}px, 
            ${left + width}px ${top}px, 
            0 ${top}px
        )`;

        this._isOverlayVisible = true;
        document.body.classList.add("disable-scroll-hotspot");
    }

    private openHotspotPopoverAndBindEvents() {
        const popover = this.getHotspotPopover();
        if (!popover) return;

        popover.show();
        popover.addEventListener("atlas-popover-closed", this.onCloseHotspotPopover);
    }

    public render() {
        return html`
            <button class=${classMap({ hotspot: true, visible: this._isHotspotVisible })} @click=${this.onClickHotspot}>
                <span class="inner-circle"></span>
            </button>
            ${when(
                this.highlightTarget,
                () => html`
                    <div
                        class=${classMap({ "hotspot-overlay": true, "visible": this._isOverlayVisible })}
                        @click=${this.onClickOverlay}
                    ></div>
                `
            )}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-hotspot": AtlasHotspot;
    }
}
