import FormElement from "@/components/form/form-element";
import FieldValidator, { FieldValidatorStatus } from "./field-validator";

import { getMaskValidationFunction, getMaskValidatorMessage, MaskAliasType } from "@/internals/mask-config";

export default class MaskValidator implements FieldValidator {
    name = "mask";

    status: FieldValidatorStatus = "error";

    type: MaskAliasType;

    customErrorMessage: string;

    constructor(type: MaskAliasType, customErrorMessage: string) {
        this.type = type;
        this.customErrorMessage = customErrorMessage;
    }

    getInvalidMessage(): string {
        return this.customErrorMessage || getMaskValidatorMessage(this.type);
    }

    validate(element: FormElement): boolean {
        const elementValue = element.getElementValue();
        const validationFn = getMaskValidationFunction(this.type);

        if (elementValue) {
            return validationFn(elementValue);
        }

        return true;
    }
}
