import { isEmptyValue } from "@/helpers/validations";

const ALLOWED_ELEMENT_ATTRIBUTES = ["label", "placeholder", "header", "description", "id"];

interface AtlasEventInit extends CustomEventInit {
    trackDisable?: boolean;
}

function extractElementProps(el: HTMLElement) {
    const attrs = el.attributes;
    const extractedProps: { [key: string]: any } = {};

    for (let i = 0; i < attrs.length; i++) {
        const isInWhiteList = ALLOWED_ELEMENT_ATTRIBUTES.includes(attrs[i].nodeName);
        const isTrackProp = attrs[i].nodeName.startsWith("data-track-");

        if (isInWhiteList || isTrackProp) {
            extractedProps[attrs[i].nodeName.replace("data-track-", "").replace(/-/g, "_")] = attrs[i].nodeValue;
        }
    }

    return extractedProps;
}

function getElementContainers(el: HTMLElement) {
    return {
        "panel": el.closest("atlas-panel")?.getAttribute("header"),
        "section": el.closest("atlas-section")?.getAttribute("header"),
        "card": el.closest("atlas-card")?.getAttribute("header"),
        "wizard-step": el.closest("atlas-wizard-step")?.getAttribute("header")
    };
}

function canEmitTrack(el: HTMLElement | Window | Document, options?: AtlasEventInit) {
    if (options?.trackDisable) return false;

    if (!(el instanceof HTMLElement)) return true;
    if (el.hasAttribute("data-track-enable")) return true;
    if (el.hasAttribute("data-track-disable")) return false;

    const closestTrackDisable = el.closest("[data-track-disable]");
    const closestTrackEnable = el.closest("[data-track-enable]");

    return closestTrackDisable ? closestTrackDisable?.contains(closestTrackEnable) : true;
}

function getTrackDetails(el: HTMLElement | Window | Document, options?: AtlasEventInit) {
    let trackDetails = options?.detail || {};

    if (el instanceof HTMLElement) {
        trackDetails = {
            ...trackDetails,
            ...getElementContainers(el),
            ...extractElementProps(el)
        };
    }

    return Object.keys(trackDetails).reduce((acc, detail) => {
        if (isEmptyValue(trackDetails[detail])) {
            return acc;
        }

        return { ...acc, [detail]: trackDetails[detail] };
    }, {});
}

/**
 * Emite um evento customizado para fazer tracks nos sistemas
 * @param {HTMLElement} element - O elemento que emitiu o evento principal
 * @param {string} name - O nome do evento que foi emitido
 * @param {object} options - Opções que serão passado ao evento (opcional)
 */
function emitTrackEvent(el: HTMLElement | Window | Document, name: string, options?: AtlasEventInit): void {
    if (!canEmitTrack(el, options)) return;

    const trackDetails = getTrackDetails(el, options);

    if (Object.keys(trackDetails).length === 0) return;

    const trackEvent = new CustomEvent("atlas-track-event", {
        bubbles: true,
        cancelable: false,
        composed: true,
        detail: {
            action: name,
            ...trackDetails
        }
    });

    el.dispatchEvent(trackEvent);
}

/**
 * Emite um evento customizado, aplicando algumas opções padrões
 * @param {HTMLElement | Window} element - O elemento que vai emitir o evento
 * @param {string} name - O nome do evento a ser emitido
 * @param {AtlasEventInit} options - Opções que serão passado ao evento (opcional)
 * @returns Evento que foi emitido
 */
export function emit(el: HTMLElement | Window | Document, name: string, options?: AtlasEventInit): CustomEvent {
    const event = new CustomEvent(name, {
        bubbles: true,
        cancelable: true,
        composed: true,
        detail: {},
        ...options
    });

    el.dispatchEvent(event);

    emitTrackEvent(el, name, options);
    return event;
}
