import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";

import { TextSize } from "./types";

import TextElement, { TextElementProps } from "../text-element";
import styles from "./atlas-text.scss";

export type TextProps = TextElementProps & {
    size: TextSize;
    bold: boolean;
    italic: boolean;
    underline: boolean;
    strikethrough: boolean;
};

/**
 * @dependency atlas-tooltip
 *
 * @tag atlas-text
 */
@customElement("atlas-text")
export default class AtlasText extends TextElement {
    static styles = styles;

    /** O tamanho do texto desejado, dentre os tamanhos possíveis */
    @property({ type: String }) size: TextSize = "md";

    /** Define o texto em negrito */
    @property({ type: Boolean }) bold = false;

    /** Define o texto em itálico */
    @property({ type: Boolean }) italic = false;

    /** Define o texto sublinhado */
    @property({ type: Boolean }) underline = false;

    /** Define o texto riscado */
    @property({ type: Boolean }) strikethrough = false;

    /** @internal */
    public renderSkeleton() {
        const skeletonClass = {
            "atlas-text": true,
            "skeleton": true,
            [this.size]: !!this.size
        };

        return html`<div class="${classMap(skeletonClass)}"></div>`;
    }

    /** @internal */
    protected renderElement() {
        const textClass = {
            "atlas-text": true,
            "italic": this.italic,
            "underline": this.underline,
            "bold": this.bold,
            "strikethrough": this.strikethrough,
            [this.size]: !!this.size,
            ...this.getTextClasses()
        };

        // prettier-ignore
        // eslint-disable-next-line max-len
        return html`<span class="${classMap(textClass)}" style=${styleMap(this.getTextStyles())} data-atlas-tooltip="text-tooltip">${this.renderContentSlot()}</span>${this.renderEllipsisTooltip()}`;
        // eslint-enable-next-line max-len
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-text": AtlasText;
    }
}
