import { LitElement, html, HTMLTemplateResult } from "lit";
import { property, state } from "lit/decorators.js";

import { when } from "lit/directives/when.js";
import { styleMap } from "lit/directives/style-map.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";

import { Watch } from "@/decorators/watch";
import type { Constructor } from "@/internals/util-types";

import "@/components/display/atlas-popover/atlas-popover";
import type AtlasPopover from "@/components/display/atlas-popover/atlas-popover";

export type WithPopoverProps = {
    "popover-title": string;
    "popover-content": string;
};

export declare class WithPopoverInterface {
    popoverTitle: string;

    popoverContent: string;

    renderPopover(id: string): HTMLTemplateResult;

    renderPopoverInfoIcon(): HTMLTemplateResult;

    renderNamedPopoverSlot(): HTMLTemplateResult;
}

export const WithPopoverMixin = <T extends Constructor<LitElement>>(superClass: T) => {
    /**
     * @dependency atlas-popover
     *
     * @prop {string} popover-title - O título que será exibido no popover
     * @prop {string} popover-content - O conteúdo do popover
     */
    class WithPopoverClass extends superClass {
        @property({ type: String, attribute: "popover-title" }) popoverTitle: string;

        @property({ type: String, attribute: "popover-content" }) popoverContent: string;

        @state() private _hasSlottedPopover = false;

        public renderPopover(id: string): HTMLTemplateResult {
            return when(
                !!this.popoverContent,
                () => this.renderPopoverText(id),
                () => this.renderPopoverSlot(id)
            );
        }

        public renderPopoverInfoIcon() {
            return html`
                <atlas-icon-button
                    icon="info"
                    theme="primary"
                    size="2x"
                    popover-title=${this.popoverTitle}
                    popover-content=${this.popoverContent}
                >
                    ${this.renderNamedPopoverSlot()}
                </atlas-icon-button>
            `;
        }

        public renderNamedPopoverSlot() {
            return html` <slot name="popover" slot="popover"></slot> `;
        }

        /** @internal */
        @Watch("popoverContent", true)
        public async onPopoverContentChange() {
            await this.updateComplete;

            if (!this.popoverContent) return;

            const popoverElement = this.shadowRoot.querySelector("atlas-popover");
            this.syncPopoverTriggerElement(popoverElement);
        }

        private renderPopoverText(id: string) {
            return html`
                <atlas-popover id=${id} header=${this.popoverTitle}> ${unsafeHTML(this.popoverContent)} </atlas-popover>
            `;
        }

        private renderPopoverSlot(id: string) {
            const slotStyle = {
                display: this._hasSlottedPopover ? "contents" : "none"
            };

            return html`
                <slot
                    name="popover"
                    style=${styleMap(slotStyle)}
                    @slotchange=${() => this.onPopoverSlotChange(id)}
                ></slot>
            `;
        }

        private async onPopoverSlotChange(id: string) {
            await this.updateComplete;

            const slot = this.shadowRoot.querySelector("slot[name=popover]") as HTMLSlotElement;
            if (!slot) return;

            const assignedElements = slot.assignedElements({ flatten: true });
            const popoverElement = assignedElements.find(({ tagName }) => tagName === "ATLAS-POPOVER") as AtlasPopover;

            this.syncPopoverTriggerElement(popoverElement);

            this._hasSlottedPopover = !!popoverElement;
            if (!this._hasSlottedPopover) return;

            popoverElement.setAttribute("id", id);
        }

        private syncPopoverTriggerElement(popoverElement: AtlasPopover) {
            const triggerElement = this.shadowRoot.querySelector("[data-atlas-popover]") as HTMLElement;
            if (!triggerElement) return;

            if (!popoverElement) {
                this.style.display = "none";
                return;
            }

            this.style.display = "";
            popoverElement.syncTriggerElement(triggerElement);
        }
    }

    return WithPopoverClass as Constructor<WithPopoverInterface> & T;
};
