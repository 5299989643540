import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";

import TextElement, { TextElementProps } from "../text-element";
import styles from "./atlas-caption.scss";

export type CaptionProps = TextElementProps & {
    small: boolean;
};

/**
 * @tag atlas-caption
 */
@customElement("atlas-caption")
export default class AtlasCaption extends TextElement {
    static styles = styles;

    /** Indica se o texto do caption será menor que o padrão */
    @property({ type: Boolean }) small: boolean;

    /** @internal */
    public renderSkeleton() {
        const skeletonClass = {
            "atlas-caption": true,
            "skeleton": true,
            "small": this.small
        };

        return html`<div class="${classMap(skeletonClass)}"></div>`;
    }

    /** @internal */
    public renderElement() {
        const captionClass = {
            "atlas-caption": true,
            "small": this.small,
            ...this.getTextClasses()
        };

        return html`
            <span
                class=${classMap(captionClass)}
                style=${styleMap(this.getTextStyles())}
                data-atlas-tooltip="text-tooltip"
            >
                ${this.renderContentSlot()}
            </span>
            ${this.renderEllipsisTooltip()}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-caption": AtlasCaption;
    }
}
