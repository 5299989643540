declare global {
    interface HTMLElement {
        showElement(): void;
        hideElement(): void;
        toggleElement(forceState?: boolean): void;
    }
}

HTMLElement.prototype.showElement = function showElement() {
    this.hidden = false;

    if (this.classList.contains("hide")) {
        this.classList.remove("hide");
    }
};

HTMLElement.prototype.hideElement = function hideElement() {
    this.hidden = true;

    if (!this.classList.contains("hide")) {
        this.classList.add("hide");
    }
};

HTMLElement.prototype.toggleElement = function toggleElement(forceState?: boolean) {
    if (forceState !== undefined && forceState !== null) {
        this.hidden = !forceState;

        if (forceState) {
            this.classList.remove("hide");
        } else {
            this.classList.add("hide");
        }
    } else {
        this.hidden = !this.hidden;

        if (this.hidden) {
            this.classList.add("hide");
        } else {
            this.classList.remove("hide");
        }
    }
};

export default {};
