import FormElement from "@/components/form/form-element";
import FieldValidator, { FieldValidatorStatus } from "./field-validator";

import { formatDate, parseDate } from "../date-utils";
import type AtlasDateRange from "@/components/form/atlas-date-range/atlas-date-range";

const FULL_DATE_DIGITS = 10;

const MESSAGES_MAP = {
    format: "Formato de data inválido, utilize o formato DD/MM/AAAA",
    range: "A data final deve ser igual ou maior que a data inicial",
    minStart: "A data inicial informada não deve ser menor que {{minDate}}",
    maxStart: "A data inicial informada não deve ser maior que {{maxDate}}",
    bothStart: "Informe uma data inicial entre {{minDate}} e {{maxDate}}",
    minEnd: "A data final informada não deve ser menor que {{minDate}}",
    maxEnd: "A data final informada não deve ser maior que {{maxDate}}",
    bothEnd: "Informe uma data final entre {{minDate}} e {{maxDate}}",
    bothRequired: "Informe a data inicial e final",
    none: ""
};

export default class DateRangeValidator implements FieldValidator {
    name = "date";

    status: FieldValidatorStatus = "error";

    errorType:
        | "none"
        | "format"
        | "range"
        | "minStart"
        | "maxStart"
        | "bothStart"
        | "minEnd"
        | "maxEnd"
        | "bothEnd"
        | "bothRequired" = "none";

    minDate: Date;

    maxDate: Date;

    getInvalidMessage(): string {
        return MESSAGES_MAP[this.errorType]
            .replace("{{minDate}}", formatDate(this.minDate))
            .replace("{{maxDate}}", formatDate(this.maxDate));
    }

    validate(element: FormElement): boolean {
        const dateRangeElement = element as AtlasDateRange;
        const selectedRange = dateRangeElement.getSelectedRange();

        if (!selectedRange.start && !selectedRange.end) return true;

        if (dateRangeElement.requireFullRange && (!selectedRange.start || !selectedRange.end)) {
            this.errorType = "bothRequired";
            return false;
        }

        if (
            (selectedRange.start && selectedRange.start.length < FULL_DATE_DIGITS) ||
            (selectedRange.end && selectedRange.end.length < FULL_DATE_DIGITS)
        ) {
            this.errorType = "format";
            return false;
        }

        this.errorType = "none";

        const rangeStart = parseDate(selectedRange.start);
        const rangeEnd = parseDate(selectedRange.end);

        if (rangeStart && rangeEnd && rangeStart.getTime() > rangeEnd.getTime()) {
            this.errorType = "range";
        }

        this.maxDate = dateRangeElement.getCalendarMaxDate();
        this.minDate = dateRangeElement.getCalendarMinDate();

        if (rangeStart && this.minDate && rangeStart.getTime() < this.minDate.getTime()) {
            this.errorType = this.maxDate ? "bothStart" : "minStart";
        } else if (rangeStart && this.maxDate && rangeStart.getTime() > this.maxDate.getTime()) {
            this.errorType = this.minDate ? "bothStart" : "maxStart";
        }

        if (rangeEnd && this.minDate && rangeEnd.getTime() < this.minDate.getTime()) {
            this.errorType = this.maxDate ? "bothEnd" : "minEnd";
        } else if (rangeEnd && this.maxDate && rangeEnd.getTime() > this.maxDate.getTime()) {
            this.errorType = this.minDate ? "bothEnd" : "maxEnd";
        }

        return this.errorType === "none";
    }
}
