import { customElement, property } from "lit/decorators.js";
import AtlasFloatInput, { FloatInputProps } from "@/components/form/atlas-float-input/atlas-float-input";

export type MoneyProps = FloatInputProps;

/**
 * @extends atlas-float-input
 *
 * @tag atlas-money
 */
@customElement("atlas-money")
export default class AtlasMoney extends AtlasFloatInput {
    /** O tamanho máximo de caracteres do input */
    @property({ type: Number }) maxlength = 15;

    /** @internal */
    public connectedCallback() {
        super.connectedCallback?.();
        this.inputGroupPrefix = "R$";
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-money": AtlasMoney;
    }
}
