import { html, literal } from "lit/static-html.js";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";
import { WithTooltipMixin, WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";

import DeviceController from "@/controllers/device-controller";

import styles from "./atlas-badge.scss";

import "@/components/display/atlas-caption/atlas-caption";
import "@/components/display/atlas-icon/atlas-icon";
import AtlasElement from "@/components/atlas-element";

import type { BadgeType } from "./types";

export type BadgeProps = WithTooltipProps & {
    "type": BadgeType;
    "text": string;
    "icon": string;
    "theme": Theme;
    "is-counter": boolean;
    "show-zero": boolean;
};

/**
 * @dependency atlas-icon
 * @dependency atlas-caption
 * @dependency atlas-tooltip
 *
 * @event {CustomEvent} atlas-badge-click - Evento disparado quando é feito o clique no badge
 *
 * @tag atlas-badge
 */
@customElement("atlas-badge")
export default class AtlasBadge extends WithTooltipMixin(AtlasElement) {
    static styles = styles;

    /** Tipo do tema do badge */
    @property({ type: String }) type: BadgeType = "filled";

    /** Texto de conteúdo do badge */
    @property({ type: String }) text: string;

    /** Nome do ícone de apoio visual do badge */
    @property({ type: String }) icon: string;

    /** Tema do badge */
    @property({ type: String }) theme: Theme = "danger";

    /** Indica se é um badge de contador */
    @property({ type: Boolean, attribute: "is-counter" }) isCounter = false;

    /** Indica se o badge deve mostrar o valor 0 quando ele for um contador */
    @property({ type: Boolean, attribute: "show-zero" }) showZero = false;

    private deviceController = new DeviceController(this);

    private onClickBadge() {
        emit(this, "atlas-badge-click");
    }

    private getTextContent(): string {
        if (this.isCounter) {
            let number = parseInt(this.text, 10);

            if (Number.isNaN(number)) {
                number = 0;
            }

            if (!this.showZero && number === 0) {
                return "";
            }

            return number > 9 ? "9+" : `${number}`;
        }

        return this.text;
    }

    private renderIcon() {
        return when(
            !!this.icon && !this.isCounter,
            () => html`
                <atlas-icon
                    size="2x"
                    name="${this.icon}"
                    theme=${this.type === "outlined" ? this.theme : ""}
                    ?white=${this.type === "filled"}
                ></atlas-icon>
            `
        );
    }

    private renderBadgeText(textContent: string) {
        return when(
            !!textContent,
            () => html`
                <atlas-caption
                    theme=${this.type === "outlined" ? this.theme : ""}
                    ?white=${this.type === "filled"}
                    ?small=${this.isCounter}
                >
                    ${textContent}
                </atlas-caption>
            `
        );
    }

    /**
     * @internal
     */
    public renderElement() {
        const textContent = this.getTextContent();
        const hasContent = textContent || this.icon;

        const badgeClass = {
            "badge": true,
            "small": this.deviceController.isMobile && (this.isCounter || !hasContent),
            "counter": this.isCounter,
            "with-content": hasContent,
            "with-tooltip": !!this.tooltip,
            [`badge-${this.type}-${this.theme}`]: !!this.theme
        };

        const tag = this.tooltip ? literal`button` : literal`span`;

        /* eslint-disable lit/binding-positions, lit/no-invalid-html */
        return html`
            <${tag}
                class=${classMap(badgeClass)}
                data-atlas-tooltip="badge-tooltip"
                @click=${ifDefined(this.tooltip ? this.onClickBadge : undefined)}
            >
                ${this.renderIcon()}
                ${this.renderBadgeText(textContent)}
            </${tag}>
            ${this.renderTooltip("badge-tooltip")}
        `;
        /* eslint-enable lit/binding-positions, lit/no-invalid-html */
    }

    /**
     * @internal
     */
    public renderSkeleton() {
        return html`
            <div class="badge skeleton"></div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-badge": AtlasBadge;
    }
}
