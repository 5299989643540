import { html } from "lit";
import { customElement, query, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { arrow } from "@floating-ui/dom";

import OverlayElement from "@/components/display/overlay-element";
import styles from "./atlas-tooltip.scss";
import "@/components/display/atlas-private-content/atlas-private-content";

/**
 * Tooltip é um box flutuante que abre quando passa-se o mouse sobre um elemento da interface e que contém uma explicação adicional sobre tal elemento.
 * @dependency atlas-private-content
 *
 * @prop {boolean} open - Indica se o tooltip está aberto
 * @prop {boolean} disabled - Indica se o tooltip está desabilitado
 * @prop {OverlayPlacement} placement - A posição do tooltip em relação ao elemento que o acionou
 * @prop {OverlayTrigger} trigger - O gatilho que irá acionar o tooltip
 * @prop {string} private-key - Chave que identifica o grupo ao qual o elemento pertence (Para esconder vários elementos de um mesmo grupo)
 * @prop {string} placeholder-prefix - Prefixo do conteúdo que aparece ao esconder o conteúdo real do tooltip
 *
 * @slot - O slot padrão serve para adicionar conteúdos ao tooltip
 *
 * @event {CustomEvent} atlas-tooltip-opened - Evento disparado quando o tooltip é aberto
 * @event {CustomEvent} atlas-tooltip-closed - Evento disparado quando o tooltip é fechado
 *
 * @tag atlas-tooltip
 */
@customElement("atlas-tooltip")
export default class AtlasTooltip extends OverlayElement {
    static styles = styles;

    @property({ type: String, attribute: "private-key" }) privateKey: string;

    @property({ type: String, attribute: "placeholder-prefix" }) placeholderPrefix = "";

    @query(".tooltip")
    private tooltip?: HTMLElement;

    constructor() {
        super("hover focus", "bottom");

        this._floatingUiController.getCustomMiddlewares = this.getFloatingCustomMiddlewares.bind(this);
    }

    getFloatingElement(): HTMLElement {
        return this.tooltip || this;
    }

    getFloatingCustomMiddlewares() {
        return [arrow({ element: this.shadowRoot.querySelector(".tooltip-arrow") })];
    }

    async onOpenOverlay() {
        await super.onOpenOverlay();
        this.tooltip.hidden = false;
    }

    async onCloseOverlay() {
        await super.onCloseOverlay();

        setTimeout(() => {
            this.tooltip.hidden = true;
        }, 250);
    }

    render() {
        const tooltipClass = {
            "tooltip": true,
            "tooltip-auto": true,
            "fade": true,
            "show": this.open
        };

        return html`
            <div
                class=${classMap(tooltipClass)}
                role="tooltip"
                aria-hidden=${this.open ? "false" : "true"}
                aria-labelledby="tooltip-slot"
            >
                <div class="tooltip-arrow"></div>
                <div class="tooltip-inner">
                    <atlas-private-content private-key=${this.privateKey} placeholder-prefix=${this.placeholderPrefix}>
                        <slot id="tooltip-slot"></slot>
                    </atlas-private-content>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-tooltip": AtlasTooltip;
    }
}
