import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { live } from "lit/directives/live.js";
import { when } from "lit/directives/when.js";

import { emit } from "@/internals/events";
import { filterEmptyNodes } from "@/internals/elements";
import { Watch } from "@/decorators/watch";

import FormElement, { FormElementProps } from "@/components/form/form-element";
import { WithTooltipMixin, WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";

import styles from "./atlas-checkbox.scss";

import "@/components/display/atlas-icon/atlas-icon";

export type CheckboxProps = FormElementProps &
    WithTooltipProps & {
        "icon": string;
        "checked": boolean;
        "indeterminate": boolean;
        "button-style": boolean;
        "expired": boolean;
    };

/**
 * @dependency atlas-icon
 *
 * @slot - Usado para escrever a label que irá acompanhar o input
 *
 * @prop {string} icon - Serve para definir um ícone que aparece ao lado da label (Apenas quando for definido o estilo de botão para o checkbox)
 * @prop {boolean} checked - Booleano que define se o checkbox está marcado
 * @prop {boolean} indeterminate - Booleano que define se o checkbox é indeterminado
 * @prop {boolean} button-style - Booleano que define se o checkbox terá o estilo de um botão
 * @prop {string} tooltip - O texto do tooltip
 * @prop {OverlayPlacement} tooltip-placement - A posição em relação ao elemento que o tooltip será exibido
 * @prop {OverlayTrigger} tooltip-trigger - O gatilho que irá acionar o tooltip
 *
 * @event {CustomEvent} atlas-checkbox-check - Evento disparado quando o checkbox é marcado
 * @event {CustomEvent} atlas-checkbox-uncheck - Evento disparado quando o checkbox é desmarcado
 * @event {CustomEvent} atlas-checkbox-change - Evento disparado quando o checkbox sofre alteração
 *
 */
@customElement("atlas-checkbox")
export default class AtlasCheckbox extends WithTooltipMixin(FormElement) {
    static styles = styles;

    @property({ type: String }) icon: string = "";

    @property({ type: Boolean, reflect: true }) checked: boolean = false;

    @property({ type: Boolean, reflect: true }) indeterminate: boolean = false;

    @property({ type: Boolean, reflect: true }) expired: boolean = false;

    @property({ type: Boolean, attribute: "button-style" }) buttonStyle: boolean = false;

    @state() private _hasSlottedLabel = false;

    getElementValue(): string {
        if (this.indeterminate) {
            return "indeterminate";
        }

        return this.checked ? this.value : "";
    }

    focus() {
        (this.shadowRoot.querySelector(".form-check-input") as HTMLInputElement).focus();
    }

    check() {
        this.checked = true;
        this.indeterminate = false;
    }

    uncheck() {
        this.checked = false;
        this.indeterminate = false;
    }

    toggleChecked(checked?: boolean) {
        if (typeof checked === "boolean") {
            this.checked = checked;
        } else {
            this.checked = !this.checked;
        }
    }

    changeChecked() {
        this.checked = !this.checked;
        this.onChange();
    }

    @Watch("checked", true)
    onChangeChecked() {
        const eventObject = {
            detail: {
                elementId: this.id,
                elementName: this.name,
                elementValue: this.value,
                checked: this.checked,
                trackDisable: true
            }
        };

        emit(this, `atlas-checkbox-${this.checked ? "check" : "uncheck"}`, eventObject);
        emit(this, "atlas-checkbox-change", eventObject);
        emit(this, "atlas-form-element-touch", { trackDisable: true });
    }

    onLabelChange() {
        const slottedNodes = this.shadowRoot.querySelector("slot").assignedNodes({ flatten: true });
        const filteredNodes = filterEmptyNodes(slottedNodes);

        this._hasSlottedLabel = filteredNodes.length > 0;
    }

    renderIcon() {
        return when(this.buttonStyle && this.icon, () => html`<atlas-icon size="3x" name="${this.icon}"></atlas-icon>`);
    }

    render() {
        const checkContainerClass = {
            "form-check": !this.buttonStyle,
            "form-check-disabled": this.disabled,
            "has-label": this._hasSlottedLabel,
            "block": this.buttonStyle,
            "form-check-expired": this.expired
        };

        const formCheckClass = {
            "form-check-input": !this.buttonStyle,
            "form-check-input-expired": this.expired,
            "btn-check": this.buttonStyle,
            [`is-${this._status}`]: this.getShowStatus()
        };

        const labelClass = {
            "form-check-label": !this.buttonStyle,
            "btn": this.buttonStyle,
            "btn-outline-primary": this.buttonStyle,
            "btn-outline-danger": this.buttonStyle && this.getShowStatus() && this._status === "error",
            "btn-outline-warning": this.buttonStyle && this.getShowStatus() && this._status === "warning",
            "btn-outline-success": this.buttonStyle && this.getShowStatus() && this._status === "success",
            "rounded-pill": this.buttonStyle,
            "block": this.buttonStyle
        };

        return html`
            <div class="${classMap(checkContainerClass)}" data-atlas-tooltip="checkbox-tooltip">
                <input
                    class="${classMap(formCheckClass)}"
                    type="checkbox"
                    id="${this.id || "checkbox_id"}"
                    name="${this.name}"
                    .value=${ifDefined(this.value ? this.value : undefined)}
                    .checked=${live(this.checked)}
                    .indeterminate=${live(this.indeterminate)}
                    ?required=${this.required}
                    ?disabled=${this.disabled}
                    aria-checked=${this.checked ? "true" : "false"}
                    aria-labelledby="${this.id}"
                    aria-disabled="${this.disabled}"
                    aria-required="${this.required}"
                    @change="${this.changeChecked}"
                />
                <label class=${classMap(labelClass)} for="${this.id || "checkbox_id"}">
                    ${this.renderIcon()}
                    <slot @slotchange=${this.onLabelChange}></slot>
                </label>
            </div>
            ${this.renderTooltip("checkbox-tooltip")}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-checkbox": AtlasCheckbox;
    }
}
