import type { ChartOptions } from "chart.js";

export { externalLegendHandler, externalTooltipHandler } from "./chart-plugins-handlers";

export const chartBaseOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 4,
    layout: {
        padding: {
            top: 0
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            }
        },
        y: {
            display: true,
            max: 10000,
            ticks: {
                count: 5
            }
        }
    },
    elements: {
        point: {
            pointStyle: false
        }
    },
    interaction: {
        intersect: false,
        mode: "nearest",
        axis: "x"
    }
};

export const flowChartBaseOptions: ChartOptions = {
    ...chartBaseOptions,
    datasets: {
        bar: {
            barThickness: 16,
            borderRadius: 4
        }
    }
};

export const flowChartInflowPattern = "assets/images/chart-inflow-pattern.svg";

export const flowChartOutflowPattern = "assets/images/chart-outflow-pattern.svg";
