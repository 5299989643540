import { customElement, property } from "lit/decorators.js";

import AtlasWizardStep from "@/components/wizard/atlas-wizard-step/atlas-wizard-step";

/**
 * @tag atlas-wizard-finish-step
 */
@customElement("atlas-wizard-finish-step")
export default class AtlasWizardFinishStep extends AtlasWizardStep {
    @property({ type: Boolean, attribute: "only-info" }) onlyInfo: boolean;

    constructor() {
        super();

        this._offStep = true;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-wizard-finish-step": AtlasWizardFinishStep;
    }
}
