import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";

import { emit } from "@/internals/events";

import { AlertType, AlertTypeInfo } from "./types";
import styles from "./atlas-alert.scss";

import "@/components/display/atlas-icon/atlas-icon";

export type AlertProps = {
    message: string;
    type: AlertType;
};

/**
 * Alerts são mensagens que avisam que uma ação foi executada ou dão feedback sobre a ação que o usuário executou.
 *
 * @dependency atlas-icon
 *
 * @event {CustomEvent} atlas-alert-show - Evento disparado quando o alert é exibido
 * @event {CustomEvent} atlas-alert-close - Evento disparado quando o alert é fechado
 *
 * @slot - Conteúdo do alert
 *
 * @tag atlas-alert
 */
@customElement("atlas-alert")
export default class AtlasAlert extends LitElement {
    static styles = styles;

    /** A mensagem do alert */
    @property({ type: String }) message = "";

    /** O tipo de alert */
    @property({ type: String }) type: AlertType = "info";

    /** Indica se o alert está visível */
    @state() private _visible = false;

    constructor() {
        super();

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        this.destroy = this.destroy.bind(this);
    }

    /** @internal */
    public firstUpdated() {
        setTimeout(this.show, 100);
    }

    /**
     * Exibe o alert
     */
    public show() {
        this._visible = true;
        emit(this, "atlas-alert-show", { trackDisable: true });
    }

    /**
     * Fecha o alert
     */
    public close() {
        this._visible = false;
        emit(this, "atlas-alert-close", { trackDisable: true });
    }

    /**
     * Fecha o alert e remove o elemento do DOM
     */
    public destroy() {
        this.close();

        setTimeout(() => {
            this.remove();
        }, 150);
    }

    private getTypeInfo(): AlertTypeInfo {
        return {
            success: { className: "alert-success", wrapperClass: "js-alert-success", icon: "check" },
            error: { className: "alert-error", wrapperClass: "js-alert-error", icon: "alert-triangle" },
            warning: { className: "alert-warning", wrapperClass: "js-alert-warning", icon: "alert-triangle" },
            info: { className: "alert-info", wrapperClass: "js-alert-info", icon: "info" },
            primary: { className: "alert-primary", wrapperClass: "js-alert-primary", icon: "primary" }
        }[this.type] as AlertTypeInfo;
    }

    private renderCloseButton() {
        return when(
            this.type !== "success",
            () => html`
                <button type="button" class="alert-close close" aria-label="Fechar" @click=${this.destroy}>
                    <atlas-icon name="x" size="3x"></atlas-icon>
                </button>
            `
        );
    }

    public render() {
        const typeInfo = this.getTypeInfo();

        const alertClass = {
            "alert": true,
            "alert-dismissible": true,
            "fade": true,
            "show": this._visible,
            [typeInfo.className]: true
        };

        return html`
            <div role="alert" class=${classMap(alertClass)}>
                <atlas-icon name="${typeInfo.icon}" size="3x"></atlas-icon>
                <span class="alert-text">
                    ${when(
                        !!this.message,
                        () => this.message,
                        () => html`<slot></slot>`
                    )}
                </span>
                ${this.renderCloseButton()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-alert": AtlasAlert;
    }
}
