import { html } from "lit";
import { property, query, state } from "lit/decorators.js";
import { ClassInfo } from "lit/directives/class-map.js";
import { StyleInfo } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { TextAlignment } from "@/internals/basic-types";
import { getSlotTextContent, isEmptySlot } from "@/internals/slot";
import { Theme, ThemeVariation } from "@/internals/theme";

import "@/components/display/atlas-tooltip/atlas-tooltip";
import AtlasElement, { AtlasElementProps } from "../atlas-element";

export type TextElementProps = AtlasElementProps & {
    "theme": Theme;
    "theme-variation": ThemeVariation;
    "muted": boolean;
    "white": boolean;
    "ellipsis": boolean;
    "line-clamp": number;
    "alignment": TextAlignment;
};

/**
 * @dependency atlas-tooltip
 */
export default abstract class TextElement extends AtlasElement {
    /** Cor do texto */
    @property({ type: String }) theme: Theme;

    /** A variação da tonalidade da cor do tema */
    @property({ type: Number, attribute: "theme-variation" }) themeVariation: ThemeVariation;

    /** Indica se o texto terá a cor cinza clara */
    @property({ type: Boolean }) muted: boolean;

    /** Indica se o texto terá a cor branca */
    @property({ type: Boolean }) white: boolean;

    /** Indica se o texto possuirá ellipsis (...) ao exceder a largura do elemento pai */
    @property({ type: Boolean }) ellipsis: boolean;

    /** Indica se o texto deve quebrar em uma certa linha e mostrar o ellipsis no final da linha definida */
    @property({ type: Number, attribute: "line-clamp" }) lineClamp: number;

    /** Alinhamento do texto */
    @property({ type: String, reflect: true }) alignment: TextAlignment;

    /** Indica se o tooltip de ellipsis deve ser ocultado */
    @property({ type: Boolean, attribute: "hide-ellipsis-tooltip" }) hideEllipsisTooltip: boolean;

    @state() private _tooltipText = "";

    @query(".text-wrapper")
    protected _textWrapperReference: HTMLElement;

    private _resizeObserver: ResizeObserver;

    /** @internal */
    public connectedCallback(): void {
        super.connectedCallback?.();
        this.observeTextResize();
    }

    /** @internal */
    public disconnectedCallback(): void {
        super.disconnectedCallback?.();
        this._resizeObserver?.disconnect();
    }

    /** @internal */
    public getTextWrapper(): HTMLElement {
        return this._textWrapperReference;
    }

    /** @internal */
    @Watch("skeletonLoading", true)
    public async observeTextResize() {
        this._resizeObserver?.disconnect();

        await this.updateComplete;

        if (!this._textWrapperReference) return;

        this._resizeObserver = new ResizeObserver(() => {
            this.onTextResize();
        });

        this._resizeObserver.observe(this._textWrapperReference);
    }

    protected getTextClasses(): ClassInfo {
        const themeClass = this.getThemeClass();

        return {
            "text-wrapper": true,
            "text-muted": this.muted,
            "text-white": this.white,
            "ellipsis": this.ellipsis,
            "line-clamp": !!this.lineClamp,
            [`text-align-${this.alignment}`]: !!this.alignment,
            [themeClass]: !!themeClass
        };
    }

    protected getTextStyles(): StyleInfo {
        return {
            "-webkit-line-clamp": this.lineClamp
        };
    }

    protected async onTextResize() {
        await this.updateComplete;

        this._tooltipText = "";

        if (!this.ellipsis || this.skeletonLoading || isEmptySlot(this)) return;

        const contentWrapper = this._textWrapperReference;
        const contentWidth = contentWrapper.getBoundingClientRect().width;

        if (Math.ceil(contentWidth) < contentWrapper.scrollWidth) {
            this._tooltipText = getSlotTextContent(this.shadowRoot.querySelector("slot:not([name])"), true);
        }
    }

    protected renderEllipsisTooltip() {
        return when(
            !!this._tooltipText && !this.hideEllipsisTooltip,
            () => html`<atlas-tooltip id="text-tooltip" placement="bottom">${this._tooltipText}</atlas-tooltip>`
        );
    }

    protected renderContentSlot() {
        return html`<slot></slot>`;
    }

    private getThemeClass() {
        if (this.theme) {
            if (this.themeVariation) {
                return `color-${this.theme}-${this.themeVariation}`;
            }

            return `color-${this.theme}`;
        }

        return "";
    }
}
