const PRODUCT_MAPPING = {
    asaas: {
        name: "Asaas",
        description: "Simplifique o recebimento de cobranças e automatize seu negócio.",
        alt: "Logomarca Asaas",
        logoDefault: "assets/images/logos/asaas-logo.svg",
        logoMini: "assets/images/logos/asaas-small-logo.svg",
        logoPresentation: "assets/images/logos/asaas-logo.svg",
        homePage: "https://www.asaas.com/"
    },
    base: {
        name: "BASE by Asaas",
        description: "O sistema de gestão financeira e fiscal ideal para a sua empresa.",
        alt: "Logomarca BASE",
        logoDefault: "assets/images/logos/base-logo.svg",
        logoMini: "assets/images/logos/base-small-logo.svg",
        logoPresentation: "assets/images/logos/base-small-logo.svg",
        homePage: "https://apps.baseerp.com.br"
    },
    money: {
        name: "Asaas Money",
        description: "O seu aplicativo de pagamentos. Agilidade na hora de pagar e receber.",
        alt: "Logomarca Asaas money",
        logoDefault: "assets/images/logos/money-logo.svg",
        logoMini: "assets/images/logos/money-small-logo.svg",
        logoPresentation: "assets/images/logos/money-logo-alt.svg",
        homePage: "https://asaasmoney.com.br/"
    }
};

export type Product = "asaas" | "base" | "money";

export type ProductInfo = {
    name: string;
    description: string;
    alt: string;
    logoDefault: string;
    logoMini: string;
    logoPresentation: string;
    homePage: string;
};

export function getProductInfo(product: Product): ProductInfo {
    return PRODUCT_MAPPING[product] as ProductInfo;
}

export function getHomePage(product: Product): string {
    return getProductInfo(product).homePage;
}

export default {
    getProductInfo,
    getHomePage
};
