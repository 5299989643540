import { LitElement, html } from "lit";
import { customElement, queryAssignedElements } from "lit/decorators.js";

import styles from "./atlas-critical-action-list.scss";

import type AtlasCriticalActionItem from "@/components/structure/atlas-critical-action-item/atlas-critical-action-item";

export type CriticalActionListProps = object;

/**
 * @tag atlas-critical-action-list
 */
@customElement("atlas-critical-action-list")
export default class AtlasCriticalActionList extends LitElement {
    static styles = styles;

    @queryAssignedElements({ selector: "atlas-critical-action-item", flatten: false })
    public _actionItems: Array<AtlasCriticalActionItem>;

    getCheckedActionsCounter() {
        const actions = this.getCheckedActions();

        return actions.length;
    }

    getCheckedActions() {
        const checkedItems: Array<AtlasCriticalActionItem> = this._actionItems.filter(
            (actionItem: AtlasCriticalActionItem) => actionItem.checked
        );

        const checkedSubItems: Array<AtlasCriticalActionItem> = [];

        checkedItems.forEach((actionItem: AtlasCriticalActionItem) => {
            if (!actionItem.hasSubItems()) return;

            checkedSubItems.push(
                ...(actionItem.subItems.filter(
                    (actionSubItem: AtlasCriticalActionItem) => actionSubItem.checked
                ) as Array<AtlasCriticalActionItem>)
            );
        });

        checkedItems.push(...checkedSubItems);

        const actions: Array<object> = [];

        checkedItems.forEach((actionItem: AtlasCriticalActionItem) => {
            if (actionItem.hasSubItems()) return;

            actions.push({
                name: actionItem.name,
                value: actionItem.value
            });
        });

        return actions;
    }

    render() {
        return html`
            <div class="critical-action-list">
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-critical-action-list": AtlasCriticalActionList;
    }
}
