import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { getFormValues, reportFormValidity } from "@/helpers/form";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-form-step.scss";

export type FormStepProps = AtlasElementProps & {
    name: string;
};

/**
 * Passo de um formulário do Atlas
 *
 * @prop {string} name - Nome do passo
 *
 * @slot - Conteúdo do passo, onde estaram os campos do formulário relacionados a esse passo
 *
 * @tag atlas-form-step
 */
@customElement("atlas-form-step")
export default class AtlasFormStep extends AtlasElement {
    static styles = styles;

    @property({ type: String }) name: string;

    @state() private _visible = false;

    toggleStep(show: boolean) {
        this._visible = show;
        this.toggleAttribute("visible", this._visible);
    }

    getStepValues(asFormData?: boolean) {
        return getFormValues(this, asFormData);
    }

    validate() {
        return reportFormValidity(this);
    }

    render() {
        return html`<slot></slot>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-form-step": AtlasFormStep;
    }
}
