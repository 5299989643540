import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import AtlasElement from "@/components/atlas-element";
import styles from "./atlas-collapse-button.scss";
import "@/components/display/atlas-icon/atlas-icon";

@customElement("atlas-collapse-button")
export default class AtlasCollapseButton extends AtlasElement {
    static styles = styles;

    @property({ type: Boolean }) collapsed: boolean;

    @property({ type: String }) size: "auto" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x" = "3x";

    @property({ type: String }) theme: string = "primary";

    @property({ type: String, attribute: "icon-color" }) iconColor: string = "secondary";

    render() {
        const collapseButtonClass = {
            "collapse-button": true,
            "collapsed": this.collapsed,
            [`theme-${this.theme}`]: !!this.theme,
            [`color-${this.iconColor}`]: !!this.iconColor
        };

        return html`
            <button type="button" class="${classMap(collapseButtonClass)}">
                <atlas-icon name="chevron-up" size="${this.size}" class="collapse-icon"></atlas-icon>
            </button>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-collapse-button": AtlasCollapseButton;
    }
}
