import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";

import { Theme } from "@/internals/theme";

import { ProgressBarOrientation, ProgressBarUnit } from "./types";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-progress-bar.scss";
import "@/components/display/atlas-caption/atlas-caption";
import "@/components/display/atlas-text/atlas-text";

export type ProgressBarProps = AtlasElementProps & {
    "min-value": number;
    "max-value": number;
    "value": number;
    "unit": ProgressBarUnit;
    "show-label": boolean;
    "muted-label": boolean;
    "label-prefix": string;
    "orientation": ProgressBarOrientation;
};

/**
 * @attr {number} min-value - Valor mínimo da progress bar
 * @attr {number} max-value - Valor máximo da progress bar
 * @attr {number} value - Valor atual da progress bar
 * @attr {ProgressBarUnit} unit - Unidade de medida que deve ser considerada
 * @attr {boolean} show-label - Indica se deve aparecer o rótulo com o progresso
 * @attr {boolean} muted-label - Indica se deve o rótulo do progresso deve ter o texto mais claro
 * @attr {boolean} label-prefix - Prefixo que aparece antes do rótulo do progresso, ex: Carregando 1/5
 * @attr {ProgressBarOrientation} orientation - Orientação da progress bar, mostra o rótulo ao lado ou abaixo da barra
 *
 * @tag atlas-progress-bar
 */
@customElement("atlas-progress-bar")
export default class AtlasProgressBar extends AtlasElement {
    static styles = styles;

    @property({ type: Number, attribute: "min-value" }) minValue: number = 0;

    @property({ type: Number, attribute: "max-value" }) maxValue: number = 100;

    @property({ type: Number }) value: number = 0;

    @property({ type: String }) unit: ProgressBarUnit = "percentage";

    @property({ type: String }) theme: Theme = "primary";

    @property({ type: Boolean, attribute: "show-label" }) showLabel: boolean;

    @property({ type: Boolean, attribute: "muted-label" }) mutedLabel: boolean;

    @property({ type: String, attribute: "label-prefix" }) labelPrefix: string;

    @property({ type: String }) orientation: ProgressBarOrientation = "vertical";

    getPercentageProgress() {
        if (!this.value) return 0;
        if (this.unit === "percentage") return this.value;

        return (this.value * 100) / this.maxValue;
    }

    renderLabel() {
        const labelText = this.unit === "percentage" ? `${this.value}%` : `${this.value}/${this.maxValue}`;

        return when(
            this.orientation === "vertical",
            () => html`<atlas-text size="sm" ?muted=${this.mutedLabel}>${this.labelPrefix} ${labelText}</atlas-text>`,
            () => html`<atlas-caption ?muted=${this.mutedLabel}>${this.labelPrefix} ${labelText}</atlas-caption>`
        );
    }

    renderLabelWithAction() {
        return when(
            this.showLabel,
            () => html`
                <div class="label-and-actions">
                    ${this.renderLabel()}
                    <slot name="action"></slot>
                </div>
            `
        );
    }

    render() {
        const wrapperClass = {
            "progress-bar-wrapper": true,
            "horizontal": this.orientation === "horizontal"
        };

        const currentProgressClass = {
            "current-progress": true,
            [`bg-${this.theme}`]: !!this.theme
        };

        const currentProgressStyles = {
            transform: `translateX(${this.getPercentageProgress() - 100}%)`
        };

        return html`
            <div class=${classMap(wrapperClass)}>
                <div
                    class="progress-bar"
                    role="progressbar"
                    aria-label="Progresso"
                    aria-valuemin=${this.minValue}
                    aria-valuemax=${this.maxValue}
                    aria-valuenow=${this.value}
                >
                    <div class=${classMap(currentProgressClass)} style=${styleMap(currentProgressStyles)}></div>
                </div>
                ${this.renderLabelWithAction()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-progress-bar": AtlasProgressBar;
    }
}
