import { html, TemplateResult } from "lit";
import { property } from "lit/decorators.js";

import { ClassInfo, classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { WithTooltipMixin, WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";
import { Theme } from "@/internals/theme";
import { emit } from "@/internals/events";
import DeviceController from "@/controllers/device-controller";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import buttonStyles from "./atlas-button.scss";

import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-tooltip/atlas-tooltip";

export type ButtonBaseProps = WithTooltipProps &
    AtlasElementProps & {
        "type": "filled" | "outlined" | "ghost";
        "size": "sm" | "md" | "lg";
        "theme": Theme;
        "description": string;
        "disabled": boolean;
        "block": boolean;
        "pill": boolean;
        "icon": string;
        "href": string;
        "show-badge": boolean;
        "badge-number": number;
        "is-external-link": string;
        "hide-description-on-mobile": boolean;
        "atlas-button-click": string;
    };

/**
 * @dependency atlas-icon
 * @dependency atlas-tooltip
 *
 * @prop {string} tooltip - O texto do tooltip
 * @prop {OverlayPlacement} tooltip-placement - A posição em relação ao elemento que o tooltip será exibido
 * @prop {OverlayTrigger} tooltip-trigger - O gatilho que irá acionar o tooltip
 *
 * @event {CustomEvent} atlas-button-click - Evento disparado quando é feito o clique no botão
 *
 */
export default class AtlasButtonBase extends WithTooltipMixin(AtlasElement) {
    static styles = buttonStyles;

    /** O tipo de estilo do botão */
    @property({ type: String, reflect: true }) type: "filled" | "outlined" | "ghost" = "filled";

    /** O tamanho do botão */
    @property({ type: String, reflect: true }) size: "sm" | "md" | "lg" = "md";

    /** O tema do botão */
    @property({ type: String, reflect: true }) theme: Theme = "primary";

    /** O texto do botão */
    @property({ type: String, reflect: true }) description: string;

    /** Booleano que informa se o botão está desabilitado */
    @property({ type: Boolean, reflect: true }) disabled: boolean;

    /** Booleano que mostra se o botão é exibido como um bloco, ocupando 100% da largura disponível */
    @property({ type: Boolean, reflect: true }) block: boolean;

    /** Booleano que informa se o botão possui bordas mais arredondadas */
    @property({ type: Boolean, reflect: true }) pill: boolean;

    /** Indica se o botão terá a cor branca */
    @property({ type: Boolean, reflect: true }) white: boolean;

    /** Booleano que coloca o botão em estado de carregamento */
    @property({ type: Boolean, reflect: true }) loading: boolean;

    /** O nome do ícone que será visível ao lado esquerdo do conteúdo do botão */
    @property({ type: String, reflect: true }) icon: string;

    /** Link da página que o usuário vai ser levado ao clicar no botão */
    @property({ type: String, reflect: true }) href: string;

    /** Indica se a página que o botão vai redirecionar é uma página externa */
    @property({ type: Boolean, attribute: "is-external-link", reflect: true }) isExternalLink: boolean;

    /** Booleano que define se a badge deve aparecer */
    @property({ type: Boolean, attribute: "show-badge", reflect: true }) showBadge = false;

    /** Indica o número que será colocado na badge */
    @property({ type: Number, attribute: "badge-number", reflect: true }) badgeNumber: number;

    /** Booleano que define se a descrição do botão deve ser ocultada quando mobile */
    @property({ type: Boolean, attribute: "hide-description-on-mobile" }) hideDescriptionOnMobile = false;

    private _deviceController = new DeviceController(this);

    private _initialBlock: boolean;

    public connectedCallback(): void {
        super.connectedCallback?.();

        this._initialBlock = this.block ?? false;
    }

    /**
     * @internal
     * Retorna o estado inicial do atributo block
     */
    public get initialBlock(): boolean {
        return this._initialBlock;
    }

    /**
     * Habilita o botão
     */
    public enable() {
        this.disabled = false;
    }

    /**
     * Desabilita o botão
     */
    public disable() {
        this.disabled = true;
    }

    protected handleButtonClick() {
        emit(this, "atlas-button-click");
    }

    protected isDisabled() {
        return this.disabled || this.loading;
    }

    protected shouldShowDescription() {
        if (!this.description) return false;

        if (!this._deviceController.isMobile) return true;

        return !this.hideDescriptionOnMobile;
    }

    protected isPrimaryWhiteButton() {
        return this.white && this.theme === "primary";
    }

    protected getButtonClasses(): ClassInfo {
        return {
            "btn": true,
            "btn-block": this.block,
            "disabled": this.disabled,
            [`btn-${this.size}`]: true,
            [`btn-${this.theme}`]: this.type === "filled",
            [`btn-outline-${this.theme}`]: this.type === "outlined",
            [`btn-ghost-${this.theme}`]: this.type === "ghost",
            "btn-primary-white": this.isPrimaryWhiteButton() && this.type === "filled",
            "btn-outline-primary-white": this.isPrimaryWhiteButton() && this.type === "outlined"
        };
    }

    protected getCurrentIcon() {
        return this.loading ? "loader" : this.icon;
    }

    protected getButtonIcon(icon: string): TemplateResult {
        return when(!!icon, () => html`<atlas-icon size="auto" name="${icon}"></atlas-icon>`);
    }

    protected getButtonText(): TemplateResult {
        return when(this.shouldShowDescription(), () => html`<span>${this.description}</span>`);
    }

    protected renderBadge() {
        return when(
            this.showBadge,
            () => html`<atlas-badge theme="${this.theme}" text=${this.badgeNumber} is-counter></atlas-badge>`
        );
    }

    protected renderHotspotSlot() {
        return html`<slot name="hotspot"></slot>`;
    }

    protected renderSkeleton() {
        const skeletonButtonClass = {
            "skeleton": true,
            "btn-block": this.block,
            [`btn-${this.size}`]: true
        };

        return html`<div class=${classMap(skeletonButtonClass)}></div>`;
    }
}
