import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import Inputmask from "@/vendors/inputmask-utils";
import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";
import styles from "./atlas-password-input.scss";

export type PasswordInputProps = InputProps & {
    "remove-visibility-icon": boolean;
    "only-numbers": boolean;
};

/**
 * @extends atlas-input
 *
 * @tag atlas-password-input
 */
@customElement("atlas-password-input")
export default class AtlasPasswordInput extends AtlasInput {
    static styles = styles;

    /** Propriedade que define se o input irá remover o ícone que altera a visibilidade dos caracteres */
    @property({ type: Boolean, attribute: "remove-visibility-icon" }) removeVisibilityIcon: boolean = false;

    /** Propriedade que define se o input irá aceitar apenas números */
    @property({ type: Boolean, attribute: "only-numbers" }) onlyNumbers: boolean = false;

    private _maskInstance: Inputmask.Instance;

    private _mask: Inputmask.Options = {
        regex: "\\d*",
        showMaskOnHover: false,
        jitMasking: true,
        keepStatic: true
    };

    /** @internal */
    public connectedCallback(): void {
        super.connectedCallback?.();

        this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
        this.addEventListener("atlas-input-icon-click", this.togglePasswordVisibility);

        this.hasIconEvent = true;
        this.setInputToPassword();

        if (!this.onlyNumbers) return;

        this.updateComplete.then(() => {
            this.buildOnlyNumbersMask();
        });
    }

    /** @internal */
    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-input-icon-click", this.togglePasswordVisibility);
    }

    /**
     * @internal
     * @override
     */
    public onChangeValue() {
        if (this.onlyNumbers && !Inputmask.isValid(this.value, this._mask)) {
            this.value = Inputmask.format(this.value, this._mask);
        }

        super.onChangeValue();
    }

    /** @override */
    protected renderIcon() {
        return when(!this.removeVisibilityIcon, () => super.renderIcon());
    }

    private togglePasswordVisibility() {
        if (this.type === "password") {
            this.setInputToText();
        } else {
            this.setInputToPassword();
        }
    }

    private setInputToPassword() {
        this.type = "password";
        this.icon = "eye-off";
    }

    private setInputToText() {
        this.type = "text";
        this.icon = "eye";
    }

    private buildOnlyNumbersMask() {
        this.inputMode = "numeric";
        if (!this.placeholder) this.placeholder = "0".repeat(this.maxlength || 0);

        this._maskInstance = Inputmask(this._mask).mask(this._input);

        // @ts-expect-error
        this._maskInstance.shadowRoot = this.shadowRoot;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-password-input": AtlasPasswordInput;
    }
}
