import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { getSlotInnerHTML } from "@/internals/slot";
import AtlasText, { TextProps } from "@/components/display/atlas-text/atlas-text";
import styles from "./atlas-collapsible-text.scss";
import "@/components/display/atlas-button/atlas-button";
import "@/components/layout/atlas-layout/atlas-layout";

export type CollapsibleTextProps = TextProps & {
    "load-more-button-label": string;
    "load-less-button-label": string;
};

/**
 * @dependency atlas-button
 * @dependency atlas-layout
 */
@customElement("atlas-collapsible-text")
export default class AtlasCollapsibleText extends AtlasText {
    static styles = styles;

    /** Label do botão para expandir o texto */
    @property({ type: String, attribute: "load-more-button-label" }) loadMoreButtonLabel = "Carregar mais informações";

    /** Label do botão para retrair o texto */
    @property({ type: String, attribute: "load-less-button-label" }) loadLessButtonLabel = "Carregar menos informações";

    @state() private _isExpanded = false;

    @state() private _shouldShowButton = false;

    @state() private _lastLineClamp: number = null;

    @state() private _elementWidth: number = null;

    @state() private _heightBeforeExpand: number = null;

    @state() private _trimmedHTML: string = "";

    private loadMoreText() {
        this._heightBeforeExpand = this._textWrapperReference.offsetHeight;

        this._textWrapperReference.style.maxHeight = `${this._heightBeforeExpand}px`;
        this._textWrapperReference.style.overflow = "hidden";

        this._lastLineClamp = this.lineClamp;
        this.lineClamp = null;

        setTimeout(() => {
            this._textWrapperReference.style.maxHeight = `${this._textWrapperReference.scrollHeight}px`;

            setTimeout(() => {
                this._textWrapperReference.style.maxHeight = null;
                this._textWrapperReference.style.overflow = null;
            }, 250);
        }, 0);
    }

    private async loadLessText() {
        this._textWrapperReference.style.maxHeight = `${this._textWrapperReference.offsetHeight}px`;
        this._textWrapperReference.style.overflow = "hidden";

        setTimeout(() => {
            this._textWrapperReference.style.maxHeight = `${this._heightBeforeExpand}px`;

            setTimeout(() => {
                this._textWrapperReference.style.maxHeight = null;
                this._textWrapperReference.style.overflow = null;

                this.lineClamp = this._lastLineClamp;
                this._lastLineClamp = null;
            }, 250);
        }, 0);
    }

    private toggleExpandText() {
        this._isExpanded = !this._isExpanded;

        if (this._isExpanded) {
            this.loadMoreText();
        } else {
            this.loadLessText();
        }
    }

    protected async onTextResize() {
        await super.onTextResize();

        const textVisibleHeight = this._textWrapperReference.offsetHeight;
        const textTotalHeight = this._textWrapperReference.scrollHeight;

        if (textVisibleHeight < textTotalHeight) {
            this._shouldShowButton = true;
            this._elementWidth = this._textWrapperReference.offsetWidth;
        } else if (this._isExpanded) {
            if (this._elementWidth === this._textWrapperReference.scrollWidth) return;

            this._isExpanded = false;
            this._shouldShowButton = true;
            this._elementWidth = null;

            this.loadLessText();
        } else {
            this._shouldShowButton = false;
            this._elementWidth = null;
        }
    }

    private onSlotChange() {
        const slotContent = getSlotInnerHTML(this.shadowRoot.querySelector("slot"));

        if (this._trimmedHTML === slotContent) return;

        this._trimmedHTML = slotContent.trim();
        this.innerHTML = this._trimmedHTML;
    }

    protected renderContentSlot() {
        return html`<slot class="collapsible-text-wrapper" @slotchange=${this.onSlotChange}></slot>`;
    }

    private renderToggleButton() {
        return when(
            this._shouldShowButton,
            () => html`
                <atlas-button
                    type="outlined"
                    description=${this._isExpanded ? this.loadLessButtonLabel : this.loadMoreButtonLabel}
                    @atlas-button-click=${this.toggleExpandText}
                ></atlas-button>
            `
        );
    }

    protected renderElement() {
        return html`<atlas-layout gap="2">${super.renderElement()} ${this.renderToggleButton()}</atlas-layout>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-collapsible-text": AtlasCollapsibleText;
    }
}
