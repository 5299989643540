import { html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { map } from "lit/directives/map.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";

import AtlasElement, { type AtlasElementProps } from "@/components/atlas-element";
import { getAssetPath } from "@/helpers/base-path";
import { type CardTier } from "@/helpers/card";
import {
    CardPreviewBrandEnum,
    type CardPreviewImageInfo,
    type CardPreviewOrientation,
    getCardPreviewImageInfo
} from "@/helpers/card-preview";

import "@/components/display/atlas-text/atlas-text";
import "@/components/layout/atlas-layout/atlas-layout";

import styles from "./atlas-card-preview.scss";
import type { CardPreviewSide } from "./types";
import type { Theme } from "@/internals/theme";

export type CardPreviewProps = AtlasElementProps & {
    "company": string;
    "card-number": string;
    "brand": CardPreviewBrandEnum;
    "holder": string;
    "initial-side": CardPreviewSide;
    "tier": CardTier;
    "width": number;
    "block": boolean;
    "disabled": boolean;
    "balance-value": string;
    "balance-label": string;
    "orientation": CardPreviewOrientation;
    "highlight-theme": Theme;
    "highlight-last-digits": boolean;
    "due-date": string;
    "cvc": string;
};

/**
 * @dependency atlas-text
 * @dependency atlas-layout
 *
 * @tag atlas-card-preview
 */
@customElement("atlas-card-preview")
export default class AtlasCardPreview extends AtlasElement {
    static styles = styles;

    /** O nome da empresa do cartão */
    @property({ type: String }) company: string;

    /** O número do cartão */
    @property({ type: String, attribute: "card-number" }) cardNumber: string;

    /** A bandeira do cartão */
    @property({ type: String }) brand: CardPreviewBrandEnum;

    /** O nome do titular do cartão */
    @property({ type: String }) holder: string;

    /** O lado que o cartão deve ser exibido inicialmente */
    @property({ type: String, reflect: true, attribute: "initial-side" }) initialSide: CardPreviewSide = "front";

    /** O nível do cartão */
    @property({ type: String }) tier: CardTier;

    /** A largura do cartão */
    @property({ type: Number }) width: number;

    /** A orientação do cartão */
    @property({ type: String }) orientation: CardPreviewOrientation = "vertical";

    /** Indica que o componente deve ocupar 100% da largura disponível */
    @property({ type: Boolean, reflect: true }) block: boolean = false;

    /** Indica que o componente está desabilitado */
    @property({ type: Boolean }) disabled: boolean;

    /** O valor do saldo do cartão */
    @property({ type: String, attribute: "balance-value" }) balanceValue: string;

    /** O rótulo do saldo do cartão */
    @property({ type: String, attribute: "balance-label" }) balanceLabel: string = "Saldo";

    /** Indica que os últimos dígitos do número do cartão devem ser destacados */
    @property({ type: Boolean, reflect: true, attribute: "highlight-last-digits" }) highlightLastDigits = false;

    /** O tema que deve ser usado para destacar os últimos dígitos do número do cartão */
    @property({ type: String, attribute: "highlight-theme" }) highlightTheme: Theme = "warning";

    /** A data de vencimento do cartão */
    @property({ type: String, attribute: "due-date" }) dueDate: string;

    /** O código de segurança do cartão */
    @property({ type: String }) cvc: string;

    @state() private _cardPreviewImageInfo: CardPreviewImageInfo;

    @state() private _currentSide: CardPreviewSide;

    private _cardNumberLength: number = 16;

    private _cvcPattern = /^[\d*]{3}$/;

    private _dueDatePattern = /^(?:(\d{2})\/)?(\d{2})\/(\d{2}|\d{4})$/;

    /** @internal */
    public connectedCallback(): void {
        super.connectedCallback?.();

        this.normalizeProperties();
    }

    /**
     * Alterna a visualização do cartão entre frente e verso.
     * @param {CardPreviewSide} forceSide - Lado que deve ser exibido.
     */
    public flip(forceSide?: CardPreviewSide) {
        if (!this.flipIsEnabled() || forceSide === this._currentSide) return;
        const acceptedValues = ["front", "back", undefined, null];
        if (!acceptedValues.includes(forceSide)) return;

        this._currentSide = forceSide || this.getOppositeSide();
    }

    private normalizeProperties() {
        this._cardPreviewImageInfo = getCardPreviewImageInfo(this.brand, this.orientation);
        this.initialSide = this.previewImageIsAvailable(this.initialSide) ? this.initialSide : "front";
        this._currentSide = this.initialSide;
        this.width = this.width || (this.isHorizontalOrientation() ? 408 : 256);
    }

    private previewIsAvailable() {
        return Object.keys(this._cardPreviewImageInfo).length > 0;
    }

    private previewImageIsAvailable(side: CardPreviewSide) {
        return this._cardPreviewImageInfo.hasOwnProperty(`${side}-image`);
    }

    private isHorizontalOrientation() {
        return this.orientation === "horizontal";
    }

    private flipIsEnabled() {
        if (!this.previewIsAvailable() || this.disabled) return false;

        const hasBothSides = ["front", "back"].every((side: CardPreviewSide) => this.previewImageIsAvailable(side));
        return hasBothSides && !this.skeletonLoading;
    }

    private hasBalance() {
        return !!this.balanceValue;
    }

    private hasCardNumber() {
        return this.cardNumber && this.getNormalizedCardNumber().length === this._cardNumberLength;
    }

    private hasDueDate() {
        return !!this.dueDate && this._dueDatePattern.test(String(this.dueDate));
    }

    private hasCvc() {
        return !!this.cvc && this._cvcPattern.test(String(this.cvc));
    }

    private hasDueDateAndCvcGroup() {
        return this.hasDueDate() && this.hasCvc();
    }

    private getOppositeSide() {
        return this._currentSide === "front" ? "back" : "front";
    }

    private getNormalizedCardNumber() {
        return String(this.cardNumber).replace(/[^\d*]/g, "");
    }

    private getFormattedBalanceValue() {
        if (isNaN(Number(this.balanceValue))) return this.balanceValue;

        const value = Number(this.balanceValue) || 0;
        return value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2
        });
    }

    private getFormattedDueDate() {
        if (!this.hasDueDate()) return "";

        const dueDate = String(this.dueDate);
        const [month, year] = dueDate.match(this._dueDatePattern).slice(2);

        return `${month}/${year.slice(-2)}`;
    }

    private getCapitalizedBrandName() {
        return this.brand.charAt(0).toUpperCase() + this.brand.slice(1).toLowerCase();
    }

    private getBackgroundImage(side: CardPreviewSide): string | undefined {
        if (!this.previewImageIsAvailable(side)) return undefined;

        const path = this._cardPreviewImageInfo[`${side}-image`];
        return getAssetPath(path);
    }

    private getBackgroundImageAlt(side: CardPreviewSide): string {
        if (!this.previewImageIsAvailable(side)) return "Prévia Indisponível";

        const sideLabel = side === "front" ? "Frente" : "Verso";
        const brandName = this.getCapitalizedBrandName();

        const imageAlt = `${sideLabel} do Cartão Asaas ${brandName}`;

        if (side === "back" && !!this.holder) {
            return `${imageAlt} com impressão do nome do portador ${this.holder}`;
        }

        if (side === "front" && !!this.company) {
            return `${imageAlt} com impressão do nome da empresa ${this.company}`;
        }

        return imageAlt;
    }

    private getWidthStyle() {
        if (this.block) return { width: "100%" };

        return { width: `${this.width}px` };
    }

    protected renderElement() {
        const cardPreviewClass = {
            "card-preview": true,
            "has-horizontal-orientation": this.isHorizontalOrientation()
        };

        return when(
            this.previewIsAvailable(),
            () => html`
                <div class=${classMap(cardPreviewClass)} style=${styleMap(this.getWidthStyle())}>
                    ${this.renderFrontSide()} ${this.renderBackSide()}
                </div>
            `,
            () => this.renderUnavailablePreview()
        );
    }

    protected renderSkeleton() {
        const skeletonClass = {
            "card-preview": true,
            "skeleton": true,
            "rounded": true,
            "has-horizontal-orientation": this.isHorizontalOrientation()
        };

        return html`<div class=${classMap(skeletonClass)} style=${styleMap(this.getWidthStyle())}></div>`;
    }

    private renderCompanyName() {
        return when(
            !!this.company,
            () => html`
                <atlas-text class="card-preview-element company" theme="primary" theme-variation="200" size="sm">
                    ${this.company}
                </atlas-text>
            `
        );
    }

    private renderFormattedCardNumber() {
        if (!this.hasCardNumber()) return nothing;

        const cardNumber = this.getNormalizedCardNumber();
        const groupDigits = cardNumber.match(/.{1,4}/g) || [];

        return html`
            ${map(groupDigits, (groupDigit, index) => {
                const shouldHighlight = this.highlightLastDigits && index === groupDigits.length - 1;

                const groupDigitClass = {
                    "group-digit": true,
                    "highlight": shouldHighlight,
                    [`highlight-theme-${this.highlightTheme}`]: shouldHighlight
                };

                return html`<atlas-text white bold class=${classMap(groupDigitClass)}>${groupDigit}</atlas-text>`;
            })}
        `;
    }

    private renderCardNumber() {
        const cardNumberClass = {
            "card-preview-element": true,
            "number": true,
            "has-due-date-and-cvc-group": this.hasDueDateAndCvcGroup()
        };

        return when(
            this.hasCardNumber(),
            () => html` <div class=${classMap(cardNumberClass)}>${this.renderFormattedCardNumber()}</div> `
        );
    }

    private renderHolderName() {
        const holderNameClass = {
            "card-preview-element": true,
            "holder": true,
            "has-due-date-and-cvc-group": this.hasDueDateAndCvcGroup()
        };

        return when(
            !!this.holder,
            () => html` <atlas-text class=${classMap(holderNameClass)} white size="sm"> ${this.holder} </atlas-text> `
        );
    }

    private renderDueDate() {
        return html`
            <div class="group-item">
                <atlas-text white size="xs"> Validade </atlas-text>
                <atlas-text white size="sm" bold> ${this.getFormattedDueDate()} </atlas-text>
            </div>
        `;
    }

    private renderCvc() {
        return html`
            <div class="group-item">
                <atlas-text white size="xs"> CVC </atlas-text>
                <atlas-text white size="sm" bold> ${this.cvc} </atlas-text>
            </div>
        `;
    }

    private renderHolderAndNumberGroup() {
        return html`
            <div class="card-preview-element holder-and-number-group">
                <div class="number">${this.renderFormattedCardNumber()}</div>
                <atlas-text class="holder" white size="xs" bold> ${this.holder} </atlas-text>
            </div>
        `;
    }

    private renderDueDateAndCvcGroup() {
        return when(
            this.hasDueDateAndCvcGroup(),
            () => html`
                <div class="card-preview-element due-date-and-cvc-group">
                    ${this.renderDueDate()} ${this.renderCvc()}
                </div>
            `
        );
    }

    private renderTier() {
        return when(
            !!this.tier,
            () => html`<atlas-text class="card-preview-element tier" white bold>${this.tier}</atlas-text>`
        );
    }

    private renderBalance() {
        return when(
            this.hasBalance(),
            () => html`
                <atlas-layout class="card-preview-element balance">
                    <atlas-text class="balance-label" white size="xs" ellipsis>${this.balanceLabel}</atlas-text>
                    <atlas-text white size="xs" bold>${this.getFormattedBalanceValue()}</atlas-text>
                </atlas-layout>
            `
        );
    }

    private renderBackgroundImage(side: CardPreviewSide) {
        const backgroundImageClass = {
            "card-preview-image": true,
            "disabled": this.disabled
        };

        return html`
            <img
                class=${classMap(backgroundImageClass)}
                src=${this.getBackgroundImage(side)}
                alt=${this.getBackgroundImageAlt(side)}
            />
        `;
    }

    private renderUnavailablePreview() {
        const unavailablePreviewClass = {
            "card-preview": true,
            "unavailable": true,
            "has-horizontal-orientation": this.isHorizontalOrientation()
        };

        return html`
            <div class=${classMap(unavailablePreviewClass)} style=${styleMap(this.getWidthStyle())}>
                <atlas-layout fluid justify="center" alignment="center" gap="2">
                    <atlas-icon name="alert-triangle" theme="warning"></atlas-icon>
                    <atlas-text theme="warning" size="lg">Prévia Indisponível</atlas-text>
                </atlas-layout>
            </div>
        `;
    }

    private renderFrontSideContent() {
        return when(
            this.isHorizontalOrientation(),
            () => html` ${this.renderBalance()} ${this.renderHolderAndNumberGroup()} `,
            () => html` ${this.renderCompanyName()} `
        );
    }

    private renderBackSideContent() {
        return when(
            !this.isHorizontalOrientation(),
            () => html`
                ${this.renderHolderName()} ${this.renderCardNumber()} ${this.renderDueDateAndCvcGroup()}
                ${this.renderTier()}
            `
        );
    }

    private renderFrontSide() {
        const frontSideClass = {
            "card-preview-side": true,
            "front": true,
            "show": this._currentSide === "front"
        };

        return html`
            <div class=${classMap(frontSideClass)}>
                ${this.renderBackgroundImage("front")} ${this.renderFrontSideContent()}
            </div>
        `;
    }

    private renderBackSide() {
        const backSideClass = {
            "card-preview-side": true,
            "back": true,
            "show": this._currentSide === "back"
        };

        return when(
            this.previewImageIsAvailable("back"),
            () => html`
                <div class=${classMap(backSideClass)}>
                    ${this.renderBackgroundImage("back")} ${this.renderBackSideContent()}
                </div>
            `
        );
    }
}
