import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { Watch } from "@/decorators/watch";
import { CardBrandEnum, getCardBrandInfo, CardBrandInfo, CARD_BRANDS } from "@/helpers/card";
import { getAssetPath } from "@/helpers/base-path";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-card-brand.scss";
import "@/components/layout/atlas-layout/atlas-layout";

export type CardBrandProps = AtlasElementProps & {
    "accepted-brands": Array<string>;
};

@customElement("atlas-card-brand")
export default class AtlasCardBrand extends AtlasElement {
    static styles = styles;

    /** Lista de cartões a serem exibidos */
    @property({ type: Array, attribute: "accepted-brands" }) acceptedBrands: Array<CardBrandEnum> = [];

    @state() private _cardBrandsInfo: Array<CardBrandInfo> = [];

    public connectedCallback() {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            if (this.acceptedBrands) this.setCardBrandsInfo();
        });
    }

    /**
     * @internal
     * Coleta informações das bandeiras de cartões aceitas
     */
    @Watch("acceptedBrands")
    public setCardBrandsInfo() {
        this._cardBrandsInfo = this.acceptedBrands.map((card) => getCardBrandInfo(card));
    }

    private renderFlags() {
        return this._cardBrandsInfo
            .filter((card) => card.brandName !== CARD_BRANDS[CardBrandEnum.UNKNOWN].brandName)
            .map(
                (card) => html`
                    <img src="${getAssetPath(card.brandFlag)}" alt="${card.brandName}" class="card-brand-flag" />
                `
            );
    }

    public render() {
        return html`
            <atlas-layout class="card-brands" inline mobile-inline wrap gap="2"> ${this.renderFlags()} </atlas-layout>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-card-brand": AtlasCardBrand;
    }
}
