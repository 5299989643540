import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";
import { html, literal } from "lit/static-html.js";

import DeviceController from "@/controllers/device-controller";
import { WithPopoverMixin, WithPopoverProps } from "@/internals/mixins/with-popover-mixin";
import { WithTooltipMixin, WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";
import { IconButtonSize } from "./types";
import styles from "./atlas-icon-button.scss";

import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-tooltip/atlas-tooltip";

const ComposedClass = WithPopoverMixin(WithTooltipMixin(AtlasElement));

export type IconProps = AtlasElementProps &
    WithTooltipProps &
    WithPopoverProps & {
        "icon": string;
        "theme": Theme;
        "size": "auto" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x";
        "disabled": boolean;
        "hoverable": boolean;
        "active": boolean;
        "dot": boolean;
        "badge-number": number;
        "href": string;
        "is-external-link": string;
    };

/**
 * Botões de ícone são usados para executar ações quando clicados e possuem algumas interações diferentes de um botão ou um ícone
 *
 * @dependency atlas-icon
 *
 * @prop {string} icon - O nome do ícone
 * @prop {Theme} theme - O tema do botão
 * @prop {IconSize} size - O tamanho do botão
 * @prop {boolean} disabled - Indica se o botão está desabilitado
 * @prop {boolean} hoverable -  Indica se o componente tem o comportamento de hover, atribuindo um padding adicional e efeitos ao passar o mouse sobre ele
 * @prop {boolean} active - Indica se o componente está ativo, deve ser utilizado em conjunto com a propriedade `hoverable`
 * @prop {boolean} dot - Indica se irá aparecer uma bolinha de notificação acima do ícone
 * @prop {number} badge-number - Indicador que aparece acima do botão, contendo o número informado
 * @prop {boolean} loading - Indica se o botão está em modo de loading, trocando o ícone pelo loader
 * @prop {string} description - Descrição do botão, ela não é vísivel, apenas será utilizada como acessibilidade ou no grupo de botões (caso o botão for agrupado, mostra a descrição como opção do dropdown)
 * @prop {string} tooltip - O texto do tooltip
 * @prop {OverlayPlacement} tooltip-placement - A posição em relação ao elemento que o tooltip será exibido
 * @prop {OverlayTrigger} tooltip-trigger - O gatilho que irá acionar o tooltip
 * @prop {string} popover-title - O título que será exibido no popover
 * @prop {string} popover-content - O conteúdo do popover
 * @prop {string} href - Link da página que o usuário vai ser levado ao clicar no botão
 * @prop {boolean} is-external-link - Indica se a página que o botão vai redirecionar é uma página externa
 *
 * @event {CustomEvent} atlas-icon-button-click - Evento lançado quando é clicado sobre o botão de ação com ícone
 *
 * @tag atlas-icon-button
 */
@customElement("atlas-icon-button")
export default class AtlasIconButton extends ComposedClass {
    static styles = styles;

    @property({ type: String, reflect: true }) icon: string;

    @property({ type: String, reflect: true }) theme: Theme;

    @property({ type: Boolean, reflect: true, attribute: "theme-on-interactions" }) themeOnInteractions: boolean;

    @property({ type: String, reflect: true }) size: IconButtonSize = "3x";

    @property({ type: Boolean, reflect: true }) disabled = false;

    @property({ type: Boolean, reflect: true }) hoverable = false;

    @property({ type: Boolean, reflect: true }) active = false;

    @property({ type: Boolean, attribute: "show-badge", reflect: true }) showBadge = false;

    @property({ type: Number, attribute: "badge-number", reflect: true }) badgeNumber: number;

    @property({ type: Boolean, reflect: true }) loading: boolean;

    @property({ type: String, reflect: true }) description: string;

    @property({ type: String }) href: string;

    @property({ type: Boolean, attribute: "is-external-link" }) isExternalLink: boolean;

    private deviceController = new DeviceController(this);

    click() {
        (this.shadowRoot.querySelector(".icon-button") as HTMLButtonElement).click();
    }

    onIconButtonClick() {
        emit(this, "atlas-icon-button-click");
    }

    renderBadge() {
        return when(this.showBadge, () => html`<atlas-badge text=${this.badgeNumber} is-counter></atlas-badge>`);
    }

    render() {
        const buttonClass = {
            "icon-button": true,
            "disabled": this.disabled || this.loading,
            "hoverable": this.hoverable && !this.deviceController.isMobile,
            "active": this.active,
            [`theme-${this.theme}`]: !!this.theme,
            "theme-on-interactions": this.themeOnInteractions
        };

        const tag = this.href ? literal`a` : literal`button`;

        /* eslint-disable lit/binding-positions, lit/no-invalid-html */
        return html`
            <div
                class="btn-wrapper"
                tabindex=${this.disabled ? "0" : "-1"}
                data-atlas-tooltip="btn-icon-tooltip"
                data-atlas-popover="btn-icon-popover"
            >
                <${tag}
                    class=${classMap(buttonClass)}
                    ?disabled=${this.disabled || this.loading}
                    aria-label=${this.description}
                    @click=${this.onIconButtonClick}
                    href=${ifDefined(this.href ? this.href : undefined)}
                    rel="${ifDefined(this.href && this.isExternalLink ? "noreferrer" : undefined)}"
                    target="${ifDefined(this.href && this.isExternalLink ? "_blank" : undefined)}"
                >
                    <atlas-icon
                        name=${this.loading ? "loader" : this.icon}
                        size=${this.size}
                        ?disabled=${this.disabled}
                    ></atlas-icon>
                    ${this.renderBadge()}
                </${tag}>
            </div>
            ${this.renderTooltip("btn-icon-tooltip")}${this.renderPopover("btn-icon-popover")}
        `;
        /* eslint-enable lit/binding-positions, lit/no-invalid-html */
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-icon-button": AtlasIconButton;
    }
}
