import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import Chart from "chart.js/auto";

import { Watch } from "@/decorators/watch";
import { formatMoney } from "@/helpers/formatters";
import { chartBaseOptions, externalLegendHandler, externalTooltipHandler } from "../chart-config";
import { getClassifiedContent } from "@/helpers/privacy";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import colorStyles from "../chart-colors.scss";
import styles from "./atlas-comparison-chart.scss";

import "@/components/display/atlas-private-content/atlas-private-content";

export type ComparisonChartProps = AtlasElementProps & {
    "x-axis-key": string;
    "y-axis-key": string;
    "goal-label": string;
    "real-label": string;
    "goal-data": string;
    "real-data": string;
    "max-y-value": number;
    "private-key": string;
};

/**
 * @dependency atlas-private-content
 *
 * @tag atlas-comparison-chart
 */
@customElement("atlas-comparison-chart")
export default class AtlasComparisonChart extends AtlasElement {
    static styles = styles;

    /** Propriedade que define a key utilizada para os valores do eixo X */
    @property({ type: String, attribute: "x-axis-key" }) xAxisKey: string = "key";

    /** Propriedade que define a key utilizada para os valores do eixo Y */
    @property({ type: String, attribute: "y-axis-key" }) yAxisKey: string = "value";

    /** Propriedade que define a legenda que se refere aos dados usado para comparação */
    @property({ type: String, attribute: "goal-label" }) goalLabel: string = "Meta";

    /** Propriedade que define a legenda que se refere aos dados reais a serem comparados */
    @property({ type: String, attribute: "real-label" }) realLabel: string = "Vendas";

    /** Propriedade que define qual o valor máximo no eixo Y */
    @property({ type: Number, attribute: "max-y-value" }) maxYValue: number;

    /** Propriedade que contém os dados dos valores comparativos */
    @property({ type: Array, attribute: "goal-data" }) goalData: Array<object>;

    /** Propriedade que contém os dados dos valores reais a serem comparados */
    @property({ type: Array, attribute: "real-data" }) realData: Array<object>;

    /** Propriedade que define chave privada de conteúdo sensível */
    @property({ type: String, attribute: "private-key", reflect: true }) privateKey: string;

    public legendList: HTMLElement;

    public legendItemTemplate: HTMLElement;

    public chartElement: HTMLCanvasElement;

    private _chartObject: Chart;

    private _chartColors: any;

    /** @internal */
    public connectedCallback(): void {
        super.connectedCallback?.();

        this.onPrivateStateChange = this.onPrivateStateChange.bind(this);

        this.buildColorsFromCSS();

        this.updateComplete.then(() => {
            this.legendList = this.shadowRoot.querySelector(".legend-list") as HTMLElement;
            this.legendItemTemplate = this.shadowRoot.querySelector(".legend-item") as HTMLElement;
            this.chartElement = this.shadowRoot.querySelector(".chart") as HTMLCanvasElement;

            this.buildChart();
        });

        window.addEventListener("atlas-private-state-change", this.onPrivateStateChange);
    }

    /** @internal */
    public disconnectedCallback(): void {
        super.disconnectedCallback?.();
        window.removeEventListener("atlas-private-state-change", this.onPrivateStateChange);
    }

    private buildColorsFromCSS() {
        const { cssRules } = colorStyles.styleSheet;

        const colors = {
            amount: "",
            goal: ""
        };

        for (const rule of cssRules) {
            const key = (rule as CSSStyleRule).selectorText;
            const value = (rule as CSSStyleRule).style.getPropertyValue("color");

            Object.assign(colors, { [`${key}`]: value });
        }

        this._chartColors = colors;
    }

    private getDataColor(dataType: any) {
        return this._chartColors[dataType];
    }

    private buildRealLine() {
        if (!this.realData || this.realData.length === 0) return null;

        const line = {
            type: "line",
            label: this.realLabel,
            backgroundColor: this.getDataColor("real"),
            borderColor: this.getDataColor("real"),
            borderWidth: 2,
            order: 1,
            data: this.realData
        };

        return line;
    }

    private buildGoalLine() {
        if (!this.goalData || this.goalData.length === 0) return null;

        const line = {
            type: "line",
            label: this.goalLabel,
            backgroundColor: this.getDataColor("goal"),
            borderColor: this.getDataColor("goal"),
            borderDash: [5, 5],
            borderWidth: 2,
            order: 2,
            data: this.goalData
        };

        return line;
    }

    private buildData() {
        const realLine = this.buildRealLine();
        const goalLine = this.buildGoalLine();

        return {
            datasets: [realLine, goalLine].filter((line) => line !== null)
        };
    }

    private buildOptions() {
        const options = chartBaseOptions;

        options.parsing = {
            xAxisKey: this.xAxisKey,
            yAxisKey: this.yAxisKey
        };

        options.plugins = {
            // @ts-expect-error
            externalLegendHandler: {
                isFlowChart: false
            },
            legend: {
                display: false
            },
            tooltip: {
                enabled: false,
                position: "average",
                external: externalTooltipHandler
            }
        };

        options.scales.y.max = this.maxYValue;
        options.scales.y.ticks.callback = (value: number) => {
            if (this.privateKey) {
                if (getClassifiedContent().includes(this.privateKey)) {
                    return `R$ ***`;
                }
            }

            return `R$ ${formatMoney(value)}`;
        };

        return options;
    }

    private buildChart() {
        if (this._chartObject) this._chartObject.destroy();

        const config: any = {
            data: this.buildData(),
            options: this.buildOptions(),
            plugins: [externalLegendHandler]
        };

        this._chartObject = new Chart(this.chartElement, config);
    }

    /** @internal */
    @Watch("maxYValue", true)
    public updateMaxValue() {
        if (!this._chartObject) return;

        this._chartObject.options.scales.y.max = this.maxYValue;
        this._chartObject.update();
    }

    /** @internal */
    @Watch("privateKey", true)
    public onPrivateStateChange() {
        this._chartObject.update();
    }

    private renderLegendItemTemplate() {
        return html`
            <li class="legend-item" hidden>
                <div class="legend-color"></div>
                <span class="legend-text"></span>
            </li>
        `;
    }

    private renderLegend() {
        return html`
            <ul class="legend-list"></ul>

            ${this.renderLegendItemTemplate()}
        `;
    }

    private renderTooltip() {
        return html`
            <div class="atlas-chart-tooltip" role="tooltip" aria-labelledby="tooltip-slot">
                <div class="tooltip-inner"></div>
            </div>
        `;
    }

    /** @internal */
    public render() {
        return html`
            ${this.renderLegend()}
            <div class="chart-container">
                <canvas class="chart"></canvas>
                ${this.renderTooltip()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-comparison-chart": AtlasComparisonChart;
    }
}
