import { removeNonNumeric } from "./formatters";

const CPF_LENGTH = 11;
const CNPJ_LENGTH = 14;

export function validateCpf(cpfValue: string) {
    const cpf = removeNonNumeric(cpfValue);

    const invalidCPFSequences = [
        "00000000000",
        "11111111111",
        "22222222222",
        "33333333333",
        "44444444444",
        "55555555555",
        "66666666666",
        "77777777777",
        "88888888888",
        "99999999999"
    ];

    if (invalidCPFSequences.includes(cpf) || cpf.length !== CPF_LENGTH) {
        return false;
    }

    const numbers = cpf.substring(0, 9);
    const digits = cpf.substring(9);
    let d1 = 0;

    for (let i = 0; i < 9; i++) {
        d1 += parseInt(numbers.charAt(i), 10) * (10 - i);
    }

    if (d1 === 0) return false;

    d1 = 11 - (d1 % 11);

    if (d1 > 9) d1 = 0;

    if (parseInt(digits.charAt(0), 10) !== d1) {
        return false;
    }

    d1 *= 2;

    for (let i = 0; i < 9; i++) {
        d1 += parseInt(numbers.charAt(i), 10) * (11 - i);
    }

    d1 = 11 - (d1 % 11);

    if (d1 > 9) d1 = 0;

    if (parseInt(digits.charAt(1), 10) !== d1) {
        return false;
    }

    return true;
}

export function validateCnpj(cnpjValue: string) {
    const cnpj = removeNonNumeric(cnpjValue);

    const invalidCNPJSequences = [
        "00000000000000",
        "11111111111111",
        "22222222222222",
        "33333333333333",
        "44444444444444",
        "55555555555555",
        "66666666666666",
        "77777777777777",
        "88888888888888",
        "99999999999999"
    ];

    if (invalidCNPJSequences.includes(cnpj) || cnpj.length !== CNPJ_LENGTH) {
        return false;
    }

    let size = cnpj.length - 2;
    const digits = cnpj.substring(size);
    let numbers = cnpj.substring(0, size);
    let total = 0;
    let pos = size - 7;

    for (let i = size; i >= 1; i--) {
        total += parseInt(numbers.charAt(size - i), 10) * pos;
        pos -= 1;

        if (pos < 2) {
            pos = 9;
        }
    }

    let result = total % 11 < 2 ? 0 : 11 - (total % 11);

    if (result !== parseInt(digits.charAt(0), 10)) {
        return false;
    }

    size += 1;
    numbers = cnpj.substring(0, size);
    total = 0;
    pos = size - 7;

    for (let i = size; i >= 1; i--) {
        total += parseInt(numbers.charAt(size - i), 10) * pos;
        pos -= 1;

        if (pos < 2) {
            pos = 9;
        }
    }

    result = total % 11 < 2 ? 0 : 11 - (total % 11);

    if (result !== parseInt(digits.charAt(1), 10)) {
        return false;
    }

    return true;
}

export function validateCpfCnpj(cpfCnpj: string) {
    const cpfCnpjValue = removeNonNumeric(cpfCnpj);

    if (cpfCnpjValue.length === CPF_LENGTH) {
        return validateCpf(cpfCnpjValue);
    }

    if (cpfCnpjValue.length === CNPJ_LENGTH) {
        return validateCnpj(cpfCnpjValue);
    }

    return false;
}
