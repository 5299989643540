import { LitElement, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { customElement, property, query, state } from "lit/decorators.js";

import { isEmptySlot } from "@/internals/slot";

import styles from "./atlas-page.scss";

export type PageProps = {
    "container": boolean;
    "fixed-header": boolean;
    "fluid-content": boolean;
};

/**
 * @slot header - Slot onde deve ser incluído o cabeçalho da página
 * @slot content - Slot onde deve ser incluído o conteúdo da página
 * @slot footer - Slot onde deve ser incluído o rodapé da página

 * @tag atlas-page
 */
@customElement("atlas-page")
export default class AtlasPage extends LitElement {
    static styles = styles;

    /* Indica se a página vai ter o comportamento de um container (Centralizando o conteúdo na página) */
    @property({ type: Boolean, reflect: true }) container: boolean = false;

    /* Indica que o cabeçalho da página será fixo */
    @property({ type: Boolean, reflect: true, attribute: "fixed-header" }) fixedHeader: boolean = false;

    /* Define se a altura do conteúdo da página deve ocupar 100% do espaço disponível */
    @property({ type: Boolean, reflect: true, attribute: "fluid-content" }) fluidContent: boolean;

    @query(".page-header") private _header: HTMLElement;

    @query(".page-content") private _content: HTMLElement;

    @state() private _hasHeader = false;

    @state() private _hasFooter = false;

    private _headerResizeObserver: ResizeObserver;

    public connectedCallback(): void {
        super.connectedCallback?.();

        this.onHeaderChange = this.onHeaderChange.bind(this);
        this.onFooterChange = this.onFooterChange.bind(this);
        this.updateMarginContent = this.updateMarginContent.bind(this);

        if (this.fixedHeader) this.initFixedHeaderObserver();
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this._headerResizeObserver?.disconnect();
    }

    private initFixedHeaderObserver() {
        this._headerResizeObserver = new ResizeObserver(this.updateMarginContent);

        this.updateComplete.then(() => {
            this._headerResizeObserver.observe(this._header);
        });
    }

    private async onHeaderChange() {
        await this.updateComplete;

        this._hasHeader = !isEmptySlot(this, "header");
    }

    private async onFooterChange() {
        await this.updateComplete;

        this._hasFooter = !isEmptySlot(this, "footer");
    }

    private async updateMarginContent() {
        await this.updateComplete;

        const headerHeight = `${this._header.offsetHeight}px`;
        if (headerHeight === this._content.style.marginTop) return;

        this._content.style.marginTop = this._hasHeader ? headerHeight : "0px";
    }

    private renderHeader() {
        const headerContentClass = {
            "page-header-content": true,
            "container": this.container
        };

        return html`
            <div class="page-header">
                <div class=${classMap(headerContentClass)}>
                    <slot name="header" @slotchange=${this.onHeaderChange}></slot>
                </div>
            </div>
        `;
    }

    private renderContent() {
        const contentClass = {
            "page-content": true,
            "container": this.container,
            "fluid-content": this.fluidContent
        };

        return html`
            <div class=${classMap(contentClass)}>
                <slot name="content"></slot>
            </div>
        `;
    }

    private renderFooter() {
        const footerClass = {
            "page-footer": true,
            "container": this.container
        };

        return html`
            <div class=${classMap(footerClass)}>
                <slot name="footer" @slotchange=${this.onFooterChange}></slot>
            </div>
        `;
    }

    /** @internal */
    public render() {
        const pageClass = {
            "page": true,
            "simple-layout": !this.fixedHeader && !this.container,
            "has-container": this.container,
            "has-header": this._hasHeader,
            "has-footer": this._hasFooter,
            "fixed-header": this.fixedHeader
        };

        return html`
            <div class=${classMap(pageClass)}>
                ${this.renderHeader()} ${this.renderContent()} ${this.renderFooter()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-page": AtlasPage;
    }
}
