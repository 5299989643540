import FroalaEditor from "froala-editor";

FroalaEditor.DefineIcon("variables", {
    NAME: "Variáveis",
    template: "text"
});

FroalaEditor.RegisterCommand("variables", {
    title: "Variáveis",
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
        this.events.trigger("variables:click", []);
    }
});

export default FroalaEditor;
