import FieldValidator, { FieldValidatorStatus } from "./field-validator";
import AtlasInput from "@/components/form/atlas-input/atlas-input";

export default class MultipleValueValidator implements FieldValidator {
    name = "multiple-value";

    status: FieldValidatorStatus = "error";

    global = true;

    reportOnChange = true;

    invalidMessage: string = "";

    getInvalidMessage(): string {
        return this.invalidMessage || "Existem valores que não foram preenchidos corretamente";
    }

    validate(element: AtlasInput): boolean {
        if (!element.multiple) {
            return true;
        }

        const multipleValues = element.getMultipleValuesObject();
        const firstInvalidValue = multipleValues.find((value) => !value.isValid);

        if (firstInvalidValue) {
            this.invalidMessage = firstInvalidValue.errorMessage;
            return false;
        }

        if (multipleValues.length > element.maxValues) {
            this.invalidMessage =
                element.maxValuesErrorMessage || `Você pode adicionar no máximo ${element.maxValues} valores`;
            return false;
        }

        return true;
    }
}
