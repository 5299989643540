import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-image.scss";

export type ImageProps = AtlasElementProps & {
    "src": string;
    "alt": string;
    "width": number;
    "height": number;
    "border-radius": string;
};

/**
 * @prop {string} src - Propriedade src que define o caminho que definirá aonde está imagem
 * @prop {string} alt - Propriedade alt que define o que será apresentado caso a imagem não carregue
 * @prop {number} width - Propriedade que define a largura da imagem
 * @prop {number} height - Propriedade que define a altura da imagem
 * @prop {string} border-radius - Propriedade que define o radius da borda da imagem
 *
 * @tag atlas-image
 */
@customElement("atlas-image")
export default class AtlasImage extends AtlasElement {
    static styles = styles;

    @property({ type: String }) src: string;

    @property({ type: String }) alt: string;

    @property({ type: Number }) width: number;

    @property({ type: Number }) height: number;

    @property({ type: String, attribute: "border-radius" }) borderRadius: string = "0px";

    render() {
        return html`
            <img
                src=${this.src}
                width=${this.width}
                height=${this.height}
                style="border-radius: ${this.borderRadius}"
                alt=${this.alt || "basic image"}
            />
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-image": AtlasImage;
    }
}
