let atlasBasePath = "";

export function setAtlasBasePath(path: string) {
    atlasBasePath = path;
}

export function getAtlasBasePath() {
    if (!atlasBasePath) {
        const scripts = [...document.getElementsByTagName("script")] as HTMLScriptElement[];
        const atlasScript = scripts.find((s) => /atlas(\.min)?\.js($|\?)/.test(s.src));

        let path = "";

        if (atlasScript) {
            path = atlasScript.getAttribute("src")!;
        }

        setAtlasBasePath(path.split("/").slice(0, -1).join("/"));
    }

    return atlasBasePath.replace(/\/$/, "");
}

export function getAssetPath(assetPath: string) {
    return `${getAtlasBasePath()}/${assetPath.replace(/^\//, "")}`;
}
