import AtlasAlert from "./atlas-alert";
import { AlertType } from "./types";

const MESSAGE_CONTAINER_CLASS = "atlas-alert-container";
const MESSAGE_CONTAINER_SELECTOR = `.${MESSAGE_CONTAINER_CLASS}`;
const MAX_ALERTS_IN_QUEUE = 4;
const CLOSE_TIMEOUT_TIME = 5000;
const TIMEOUT_KEY = "atlas-alert-timeout-id";

const getAllAlerts = function (): Array<AtlasAlert> {
    const container = document.querySelector(MESSAGE_CONTAINER_SELECTOR);
    const alertList = container?.querySelectorAll("atlas-alert");

    return [].concat(...(alertList || []));
};

const createContainerIfNeeded = function () {
    if (document.querySelector(MESSAGE_CONTAINER_SELECTOR)) {
        return;
    }

    const container = document.createElement("div");

    container.classList.add(MESSAGE_CONTAINER_CLASS);
    document.body.append(container);
};

const removeEqualAlerts = function (message: string) {
    getAllAlerts().forEach((element) => {
        if (element.innerHTML.trim() === message.trim()) {
            element.destroy();
        }
    });
};

const createAlertElement = function (message: string, type: AlertType, ignoreTimeout: boolean): HTMLElement {
    const element = document.createElement("atlas-alert");

    element.setAttribute("type", type);
    element.toggleAttribute("ignore-timeout", ignoreTimeout);
    element.innerHTML = message.trim();

    return element;
};

const removeLastVisibleAlert = function () {
    getAllAlerts().forEach((element, index) => {
        if (index >= MAX_ALERTS_IN_QUEUE - 1) {
            if (element.getAttribute("type") === "error") {
                return;
            }

            element.destroy();
        }
    });
};

const addInQueue = function (alertElement: HTMLElement) {
    if (alertElement.getAttribute("type") === "error") {
        document.querySelector(MESSAGE_CONTAINER_SELECTOR).prepend(alertElement);
        return;
    }

    const alertsWithErrorList = getAllAlerts().filter((element) => element.getAttribute("type") === "error");

    if (alertsWithErrorList.length > 0) {
        const nextElementAfterLastAlertWithError = alertsWithErrorList[alertsWithErrorList.length - 1].nextSibling;

        document
            .querySelector(MESSAGE_CONTAINER_SELECTOR)
            .insertBefore(alertElement, nextElementAfterLastAlertWithError);
    } else {
        document.querySelector(MESSAGE_CONTAINER_SELECTOR).prepend(alertElement);
    }
};

const setAlertTimeout = function (element: AtlasAlert) {
    const timeoutId = setTimeout(() => {
        element.destroy();
        localStorage.removeItem(TIMEOUT_KEY);
    }, CLOSE_TIMEOUT_TIME);

    localStorage.setItem(TIMEOUT_KEY, String(timeoutId));

    return timeoutId;
};

const closeAlertsTimeout = function () {
    const alertTriggerList = getAllAlerts();

    if (!alertTriggerList) {
        return;
    }

    clearTimeout(parseInt(localStorage.getItem(TIMEOUT_KEY), 10));

    alertTriggerList.forEach((element) => {
        if (element.getAttribute("type") === "error" || element.getAttribute("type") === "warning") return;

        if (element.hasAttribute("ignore-timeout")) return;

        let timeoutId = setAlertTimeout(element);

        element.addEventListener("mouseover", () => {
            clearTimeout(timeoutId);
        });

        element.addEventListener("mouseout", () => {
            timeoutId = setAlertTimeout(element);
        });
    });
};

/**
 * Exibe um alerta flutuante na tela
 * @param message A mensagem a ser exibida
 * @param type O tipo do alerta. Deve ser uma das strings ("success" | "error" | "warning" | "info")
 * @param shouldIgnoreTimeout Indica se deve ignorar o timeout do alerta quando o tipo for "info"
 */
export function showAlert(message: string, type: string, shouldIgnoreTimeout?: boolean) {
    if (!message || !type) return;

    createContainerIfNeeded();
    removeEqualAlerts(message);

    const alertType = type?.toLowerCase() as AlertType;
    const ignoreTimeout = shouldIgnoreTimeout && alertType === "info" ? shouldIgnoreTimeout : false;
    const element = createAlertElement(message, alertType, ignoreTimeout);
    const visibleAlertsCount = getAllAlerts().length;

    if (visibleAlertsCount >= MAX_ALERTS_IN_QUEUE) {
        removeLastVisibleAlert();
    }

    addInQueue(element);
    closeAlertsTimeout();
}

/**
 * Fecha todos os alertas flutuantes na tela
 */
export function closeAllAlerts() {
    getAllAlerts().forEach((element) => {
        element.destroy();
    });
}
