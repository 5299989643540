import { customElement, property } from "lit/decorators.js";
import AtlasFloatInput, { FloatInputProps } from "@/components/form/atlas-float-input/atlas-float-input";

export type PercentageProps = FloatInputProps;

/**
 * @extends atlas-float-input
 *
 * @tag atlas-percentage
 */
@customElement("atlas-percentage")
export default class AtlasPercentage extends AtlasFloatInput {
    /** O tamanho máximo de caracteres do input */
    @property({ type: Number }) maxlength: number;

    /** @internal */
    public connectedCallback() {
        super.connectedCallback?.();
        this.inputGroupPrefix = "%";
        this.maxlength = this.calculateMaxlength();
    }

    private calculateMaxlength(): number {
        const minlength = this.decimalPrecision + 2;
        const maxlength = this.maxlength || this.decimalPrecision + 3;

        if (maxlength >= minlength) return maxlength;

        return minlength;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-percentage": AtlasPercentage;
    }
}
