import { removeNonNumeric } from "./formatters";

export enum CardBrandEnum {
    ELO = "ELO",
    CABAL = "CABAL",
    HIPERCARD = "HIPERCARD",
    DINERS = "DINERS",
    AMEX = "AMEX",
    JCB = "JCB",
    DISCOVER = "DISCOVER",
    VISA = "VISA",
    VISA_ELECTRON = "VISA_ELECTRON",
    MASTERCARD = "MASTERCARD",
    MAESTRO = "MAESTRO",
    BANESCARD = "BANESCARD",
    SOROCRED = "SOROCRED",
    CREDZ = "CREDZ",
    CREDSYSTEM = "CREDSYSTEM",
    UNKNOWN = "UNKNOWN"
}

export const CREDIT_CARD_BRANDS = [
    CardBrandEnum.ELO,
    CardBrandEnum.CABAL,
    CardBrandEnum.HIPERCARD,
    CardBrandEnum.DINERS,
    CardBrandEnum.AMEX,
    CardBrandEnum.JCB,
    CardBrandEnum.DISCOVER,
    CardBrandEnum.VISA,
    CardBrandEnum.MASTERCARD,
    CardBrandEnum.BANESCARD,
    CardBrandEnum.SOROCRED,
    CardBrandEnum.CREDZ,
    CardBrandEnum.CREDSYSTEM
];

export const DEBIT_CARD_BRANDS = [CardBrandEnum.VISA_ELECTRON, CardBrandEnum.MAESTRO];

export type CardBrandInfo = {
    brandName: string;
    brandFlag: string;
    mask: string;
    regex?: RegExp;
};

export type CardType = "credit" | "debit";

export type CardTier = "gold" | "business";

/* eslint-disable max-len */
export const CARD_BRANDS = {
    [CardBrandEnum.ELO]: {
        brandName: "Elo",
        brandFlag: "assets/images/card-brands/elo.svg",
        mask: "9999 9999 9999 9999",
        regex: /^(4(0117[89]|3(1274|8935)|5(1416|7(393|63[12])))|50(4175|6(699|7([0-6]\d|7[0-8]))|9\d{3})|6(27780|36(297|368)|5(0(0(3[1-35-9]|4\d|5[01])|4(0[5-9]|([1-3]\d|8[5-9]|9\d))|5([0-2]\d|3[0-8]|4[1-9]|[5-8]\d|9[0-8])|7(0\d|1[0-8]|2[0-7])|9(0[1-9]|[1-6]\d|7[0-8]))|16(5[2-9]|[67]\d)|50([01]\d|2[1-9]|[34]\d|5[0-8]))))/
    },
    [CardBrandEnum.MASTERCARD]: {
        brandName: "Mastercard",
        brandFlag: "assets/images/card-brands/mastercard.svg",
        mask: "9999 9999 9999 9999",
        regex: /^(5[1-5]\d{4}|2(2(2[1-9]\d{2}|[3-9]\d{3})|[3-6]\d{4}|7([01]\d{3}|20\d{2})))/
    },
    [CardBrandEnum.MAESTRO]: {
        brandName: "Mastercard Maestro",
        brandFlag: "assets/images/card-brands/maestro.svg",
        mask: "9999 9999 9999 9999",
        regex: /^(5018|5020|5038|5893|6304|6759|6761|6762|6763)[0-9]/
    },
    [CardBrandEnum.DISCOVER]: {
        brandName: "Discover",
        brandFlag: "assets/images/card-brands/discover.svg",
        mask: "9999 9999 9999 9999",
        regex: /^65[4-9][0-9]|64[4-9][0-9]|6011[0-9]|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9])/
    },
    [CardBrandEnum.CABAL]: {
        brandName: "Cabal",
        brandFlag: "assets/images/card-brands/cabal.svg",
        mask: "9999 9999 9999 9999",
        regex: /^(60420[1-9]|6042[1-9][0-9]|6043[0-9]{2}|604400)/
    },
    [CardBrandEnum.HIPERCARD]: {
        brandName: "Hipercard",
        brandFlag: "assets/images/card-brands/hipercard.svg",
        mask: "9999 9999 9999 9999",
        regex: /^(?:3841[046]0|6(?:06282|37(?:095|5(?:68|99)|6(?:09|12))))/
    },
    [CardBrandEnum.DINERS]: {
        brandName: "Diners",
        brandFlag: "assets/images/card-brands/diners.svg",
        mask: "9999 999999 9999",
        regex: /^3(?:0[0-5]|[68][0-9])/
    },
    [CardBrandEnum.AMEX]: {
        brandName: "American Express",
        brandFlag: "assets/images/card-brands/amex.svg",
        mask: "9999 999999 99999",
        regex: /^3[47]\d{4}/
    },
    [CardBrandEnum.JCB]: {
        brandName: "JCB",
        brandFlag: "assets/images/card-brands/jcb.svg",
        mask: "9999 9999 9999 9999",
        regex: /^(?:2131|1800|35\d{3})/
    },
    [CardBrandEnum.VISA]: {
        brandName: "Visa",
        brandFlag: "assets/images/card-brands/visa.svg",
        mask: "9999 9999 9999 9999",
        regex: /^4\d{5}/
    },
    [CardBrandEnum.VISA_ELECTRON]: {
        brandName: "Visa",
        brandFlag: "assets/images/card-brands/visa-electron.svg",
        mask: "9999 9999 9999 9999",
        regex: /^4\d{5}/
    },
    [CardBrandEnum.BANESCARD]: {
        brandName: "Banescard",
        brandFlag: "assets/images/card-brands/banescard.svg",
        mask: "9999 9999 9999 9999",
        regex: /^636117/
    },
    [CardBrandEnum.SOROCRED]: {
        brandName: "Sorocred",
        brandFlag: "assets/images/card-brands/sorocred.svg",
        mask: "9999 9999 9999 9999",
        regex: /^627892|^636414/
    },
    [CardBrandEnum.CREDZ]: {
        brandName: "Credz",
        brandFlag: "assets/images/card-brands/credz.svg",
        mask: "9999 9999 9999 9999",
        regex: /^636760/
    },
    [CardBrandEnum.CREDSYSTEM]: {
        brandName: "Banescard",
        brandFlag: "assets/images/card-brands/credsystem.svg",
        mask: "9999 9999 9999 9999",
        regex: /^628028/
    },
    [CardBrandEnum.UNKNOWN]: {
        brandName: "Desconhecido",
        brandFlag: "",
        mask: "9999 9999 9999 9999"
    }
};
/* eslint-enable max-len */

export function getCardBrandInfo(brand: CardBrandEnum) {
    return CARD_BRANDS[brand];
}

export function detectCardBrand(cardNumberValue: string, isDebitCard: boolean, acceptedBrands: Array<CardBrandEnum>) {
    const cardNumber = removeNonNumeric(cardNumberValue);

    if (cardNumber.length < 6) return CardBrandEnum.UNKNOWN;

    let brands = isDebitCard ? DEBIT_CARD_BRANDS : CREDIT_CARD_BRANDS;

    if (acceptedBrands && acceptedBrands.length > 0) {
        brands = brands.filter((brand: CardBrandEnum) => acceptedBrands.includes(brand));
    }

    const cardBrand = brands.find((brand: CardBrandEnum) => {
        if (brand === CardBrandEnum.UNKNOWN) return false;

        return CARD_BRANDS[brand].regex.test(cardNumber);
    });

    return cardBrand || CardBrandEnum.UNKNOWN;
}
