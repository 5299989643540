import { filterEmptyNodes } from "./elements";

export const isEmptySlot = (host: HTMLElement, slotName?: string) => {
    const query = slotName ? `slot[name=${slotName}]` : "slot:not([name])";

    const slotElement = host.shadowRoot.querySelector(query) as HTMLSlotElement;
    if (!slotElement) return true;

    const filteredNodes = filterEmptyNodes(slotElement.assignedNodes({ flatten: true }));
    return filteredNodes.length === 0;
};

export function getSlotTextContent(slot: HTMLSlotElement, includeTextFromElements?: boolean): string {
    const nodes = slot.assignedNodes({ flatten: true });

    let text = "";

    [...nodes].forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            text += node.textContent;
        } else if (includeTextFromElements && node.nodeType === Node.ELEMENT_NODE) {
            text += node.textContent;
        }
    });

    return text;
}

export function getSlotInnerHTML(slot: HTMLSlotElement): string {
    const nodes = slot.assignedNodes({ flatten: true });

    let html = "";

    [...nodes].forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            html += node.textContent;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            html += (node as Element).outerHTML;
        }
    });

    return html;
}
