import { LitElement, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

import { getFormValues, reportFormValidity, resetFormData } from "@/helpers/form";
import { emit } from "@/internals/events";

import type AtlasFormStep from "@/components/form/atlas-form-step/atlas-form-step";
import styles from "./atlas-form.scss";

/**
 * Formulário do atlas
 *
 * @prop {string} action - URL que será alvo do formulário
 * @prop {string} method - Método HTTP que será usado para o envio do formulário (get | post)
 * @prop {string} target - Onde será exibido o corpo da resposta do formulário
 *
 * @slot - Conteúdo do formulário, de onde serão buscados os valores
 *
 * @tag atlas-form
 */
@customElement("atlas-form")
export default class AtlasForm extends LitElement {
    static styles = styles;

    @property({ type: String }) action: string;

    @property({ type: String }) method: "get" | "post" = "get";

    @property({ type: String }) target: string;

    @query("form") associatedForm: HTMLFormElement;

    @state() private customAction = "";

    @state() private _steps: AtlasFormStep[] = [];

    @state() private _currentStepName: string;

    @state() private _currentStepIndex: number = 0;

    connectedCallback(): void {
        super.connectedCallback?.();
        this.handleFormData = this.handleFormData.bind(this);
        this.onSlottedButtonClick = this.onSlottedButtonClick.bind(this);

        this.updateComplete.then(() => {
            this.associatedForm.addEventListener("formdata", this.handleFormData);
            this.addEventListener("atlas-button-click", this.onSlottedButtonClick);
        });
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();
        this.associatedForm.removeEventListener("formdata", this.handleFormData);
        this.removeEventListener("atlas-button-click", this.onSlottedButtonClick);
    }

    getStepByName(stepName: string) {
        return this._steps.find((step) => step.name === stepName);
    }

    getStepByIndex(index: number) {
        return this._steps[index];
    }

    getCurrentStep() {
        return this._steps[this._currentStepIndex];
    }

    getFormValues(asFormData?: boolean) {
        return getFormValues(this, asFormData);
    }

    getFormStepValues(stepName: string, asFormData?: boolean) {
        return this.getStepByName(stepName)?.getStepValues(asFormData);
    }

    getFormValuesWithStep() {
        return this._steps.reduce(
            (acc, step) => ({
                ...acc,
                [step.name]: step.getStepValues()
            }),
            {}
        );
    }

    reset() {
        resetFormData(this);
    }

    submit(formAction?: string) {
        const fieldsContainer = this._steps.length > 0 ? this.getCurrentStep() : this;
        const isValid = reportFormValidity(fieldsContainer);
        this.customAction = "";

        if (isValid) {
            const submitEvent = emit(this, "atlas-form-submit");

            if (!submitEvent.defaultPrevented) {
                this.customAction = formAction || "";
                this.associatedForm.requestSubmit();
            }
        }
    }

    handleFormSubmit() {
        this.associatedForm.action = this.customAction || this.action;
    }

    handleFormData(event: FormDataEvent) {
        const formValues = this.getFormValues(true) as FormData;

        formValues.forEach((value, key) => {
            event.formData.append(key, value);
        });
    }

    onSlottedButtonClick(event: CustomEvent) {
        const targetButton = event.target as HTMLElement;

        if (targetButton.hasAttribute("data-atlas-form-next-step")) {
            this.goToNextStep();
        } else if (targetButton.hasAttribute("data-atlas-form-previous-step")) {
            this.goToPreviousStep();
        } else if (targetButton.hasAttribute("data-atlas-form-submit")) {
            this.submit();
        }
    }

    async onChangeSlot() {
        await this.updateComplete;

        this.syncSteps();
        if (this._steps.length === 0) return;

        this._currentStepIndex = 0;
        this.changeStep(this._currentStepIndex);
    }

    syncSteps() {
        this._steps = this.shadowRoot
            .querySelector("slot")
            .assignedElements({ flatten: true })
            .filter((element) => element.tagName === "ATLAS-FORM-STEP")
            .map((element) => element as AtlasFormStep);
    }

    goToNextStep() {
        const nextStepEvent = emit(this, "atlas-form-next-step", {
            detail: {
                currentStepIndex: this._currentStepIndex,
                nextStepIndex: this._currentStepIndex + 1
            }
        });

        if (!nextStepEvent.defaultPrevented) {
            this.changeStep(this._currentStepIndex + 1);
        }
    }

    goToPreviousStep() {
        const previousStepEvent = emit(this, "atlas-form-previous-step", {
            detail: {
                currentStepIndex: this._currentStepIndex,
                nextStepIndex: this._currentStepIndex - 1
            }
        });

        if (!previousStepEvent.defaultPrevented) {
            this.changeStep(this._currentStepIndex - 1);
        }
    }

    canChangeStep(stepIndex: number): boolean {
        if (stepIndex <= this._currentStepIndex) {
            return true;
        }

        return this.getCurrentStep().validate();
    }

    async changeStep(stepIndex: number) {
        const canChange = this.canChangeStep(stepIndex);

        if (!canChange) return;

        const changeStepEvent = emit(this, "atlas-form-change-step", {
            detail: {
                step: this.getStepByIndex(stepIndex),
                previousStep: this.getCurrentStep()
            }
        });

        if (changeStepEvent.defaultPrevented) return;

        this._currentStepIndex = stepIndex;
        this._currentStepName = this.getCurrentStep().name;
        this.showCurrentStep();
    }

    showCurrentStep() {
        this._steps.forEach((step: AtlasFormStep) => {
            const isVisible = step.name === this._currentStepName;

            step.toggleStep(isVisible);
        });
    }

    render() {
        return html`
            <form method=${this.method} target=${this.target} @submit=${this.handleFormSubmit}></form>
            <slot @slotchange=${this.onChangeSlot}></slot>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-form": AtlasForm;
    }
}
