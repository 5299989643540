import { Theme } from "@/internals/theme";
import type AtlasModal from "@/components/display/atlas-modal/atlas-modal";

export { showAlert, closeAllAlerts } from "@/components/display/atlas-alert/alert-system";

type ConfirmationOptions = {
    illustration: string;
    onConfirm?: (modal: AtlasModal) => void;
    onOpenModal?: (modal: AtlasModal) => void;
    title?: string;
    content?: string;
    size?: "default" | "large" | "extra-large" | "fullscreen";
    templateSelector?: string;
    confirmButton?: {
        description?: string;
        theme?: Theme;
    };
    disableAutoClose?: boolean;
    hideCloseButton?: boolean;
};

const setModalContent = (options: ConfirmationOptions, modalContent: HTMLElement) => {
    if (options.content) {
        const modalText = document.createElement("atlas-text");
        modalText.alignment = "center";
        modalText.innerHTML = options.content || "";

        modalContent.appendChild(modalText);
    } else if (options.templateSelector) {
        const templateElement = document.querySelector(options.templateSelector) as HTMLTemplateElement;
        const templateContent = templateElement.content.cloneNode(true);

        modalContent.appendChild(templateContent);
    }
};

export const showConfirmation = (options: ConfirmationOptions) => {
    const modal = document.createElement("atlas-modal");
    modal.destroyOnClose = true;
    modal.headerIllustration = options.illustration;
    modal.disableAutoClose = options.disableAutoClose;
    modal.hideCloseButton = options.hideCloseButton;
    modal.size = options.size || "default";

    const modalTitle = document.createElement("atlas-heading");
    modalTitle.size = "h4";
    modalTitle.alignment = "center";
    modalTitle.innerHTML = options.title;

    const modalContent = document.createElement("atlas-layout");
    modalContent.gap = 6;
    modalContent.alignment = "center";
    setModalContent(options, modalContent);
    modalContent.prepend(modalTitle);

    const cancelButton = document.createElement("atlas-button");
    cancelButton.theme = "secondary";
    cancelButton.description = "Cancelar";
    cancelButton.slot = "actions";
    cancelButton.addEventListener("atlas-button-click", () => {
        modal.closeModal();
    });

    const confirmButton = document.createElement("atlas-button");
    confirmButton.theme = options.confirmButton?.theme || "primary";
    confirmButton.description = options.confirmButton?.description || "Confirmar";
    confirmButton.slot = "actions";
    confirmButton.addEventListener("atlas-button-click", () => {
        options.onConfirm?.(modal);
    });

    modal.appendChild(modalContent);
    modal.appendChild(cancelButton);
    modal.appendChild(confirmButton);

    document.body.appendChild(modal);
    modal.openModal();

    setTimeout(() => {
        options.onOpenModal?.(modal);
    }, 0);
};
