import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import VanillaCalendar from "vanilla-calendar-pro";
import type { FormatDateString } from "vanilla-calendar-pro/types";
import { autoUpdate, computePosition, flip, offset, shift } from "@floating-ui/dom";

import { Watch } from "@/decorators/watch";
import DateValidator from "@/internals/validators/date-validator";
import Inputmask from "@/vendors/inputmask-utils";
import { emit } from "@/internals/events";
import {
    formatDate,
    formatDateToYearMonthDay,
    getDateFromString,
    getTodayDate,
    parseDate
} from "@/internals/date-utils";

import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";
import styles from "./atlas-date-picker.scss";
import "@/components/display/atlas-button/atlas-button";

export type DatePickerProps = InputProps & {
    "min-date": string;
    "max-date": string;
    "prevent-past-date": string;
    "prevent-later-date": string;
};

/**
 * @dependency atlas-button
 *
 * @event {CustomEvent} atlas-date-picker-change - Evento disparado quando a data do campo é alterada
 *
 * @tag atlas-date-picker
 */
@customElement("atlas-date-picker")
export default class AtlasDatePicker extends AtlasInput {
    static styles = styles;

    /** A mensagem que aparecerá quando no campo quando ele estiver vazio */
    @property({ type: String }) placeholder = "___/___/____";

    /** A data mínima aceita pelo campo */
    @property({ type: String, attribute: "min-date" }) minDate: string;

    /** A data máxima aceita pelo campo */
    @property({ type: String, attribute: "max-date" }) maxDate: string;

    /** Indica se as datas anteriores ao dia atual serão desabilitadas */
    @property({ type: Boolean, reflect: true, attribute: "prevent-past-date" }) preventPastDate: boolean;

    /** Indica se as datas posteriores ao dia atual serão desabilitadas */
    @property({ type: Boolean, reflect: true, attribute: "prevent-later-date" }) preventLaterDate: boolean;

    @state() private _showCalendar = false;

    @state() private _selectedDate = "";

    @query(".calendar-wrapper") private _calendarWrapper: HTMLInputElement;

    private _maskInstance: Inputmask.Instance;

    private _calendarInstance: VanillaCalendar;

    private _dateMinDigits = 8;

    private _floatingUiCleanup?: () => void;

    constructor() {
        super();

        this.updateCalendarPosition = this.updateCalendarPosition.bind(this);
        this.onChangeScreenType = this.onChangeScreenType.bind(this);
        this.onClickCalendarIcon = this.onClickCalendarIcon.bind(this);
        this.onInitAndUpdateCalendar = this.onInitAndUpdateCalendar.bind(this);
        this.onClickCalendarDay = this.onClickCalendarDay.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);
    }

    connectedCallback(): void {
        super.connectedCallback?.();

        this._deviceController.setScreenChangeCallback(this.onChangeScreenType);

        this.updateComplete.then(() => {
            this.updateIconType();

            this.inputMode = "numeric";
            this.addValidator(new DateValidator());

            this.createMask();
            this.createCalendar();
        });
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this._floatingUiCleanup?.();
        this._calendarInstance.destroy();

        this.removeEventListener("atlas-input-icon-click", this.onClickCalendarIcon);
    }

    /**
     * Método que retorna o valor do campo sem a máscara
     * @returns Valor do campo sem a máscara
     */
    public getUnmaskedValue() {
        return this._maskInstance.unmaskedvalue();
    }

    /**
     * Método que retorna a instância do calendário
     * @returns Instância do calendário
     */
    public getCalendarInstance() {
        return this._calendarInstance;
    }

    /**
     * @internal
     * @override
     */
    public onChangeValue() {
        if (this.value.length >= this._dateMinDigits && !Inputmask.isValid(this.value, { alias: "datepicker" })) {
            this.value = Inputmask.format(this.value, { alias: "datepicker" });
        }

        if (this.value !== this._input.value) {
            this._input.value = "";
            this._input.value = this.value;

            emit(this, "atlas-date-picker-change");
        }

        this.updateCalendarSelectedDate();

        this.resetInputPlaceholder();
        super.onChangeValue();
    }

    /** @internal */
    @Watch(["minDate", "maxDate", "preventPastDate", "preventLaterDate"], true)
    public updateCalendarDateLimits() {
        this._calendarInstance.settings.range.min = formatDateToYearMonthDay(this.getCalendarMinDate());

        this._calendarInstance.settings.range.max = formatDateToYearMonthDay(this.getCalendarMaxDate());

        this._calendarInstance.settings.selected = this.getCalendarSettingsSelectedDate();

        this._calendarInstance.update({ dates: true, month: true, year: true });

        if (!!this.value) this.reportValidity();
    }

    private createMask() {
        this._maskInstance = Inputmask({ alias: "datepicker" }).mask(this._input);
    }

    private createCalendar() {
        this._calendarInstance = new VanillaCalendar(this._calendarWrapper, {
            jumpToSelectedDate: true,
            date: {
                min: "" as FormatDateString,
                max: "" as FormatDateString
            },
            settings: {
                lang: "pt-BR",
                iso8601: false,
                range: {
                    min: formatDateToYearMonthDay(this.getCalendarMinDate()),
                    max: formatDateToYearMonthDay(this.getCalendarMaxDate())
                },
                visibility: {
                    daysOutside: false
                }
            },
            actions: {
                clickDay: this.onClickCalendarDay,
                initCalendar: this.onInitAndUpdateCalendar,
                updateCalendar: this.onInitAndUpdateCalendar
            },
            DOMTemplates: {
                default: this.getCalendarDaysTemplate(),
                month: this.getCalendarMonthsTemplate(),
                year: this.getCalendarYearsTemplate()
            },
            CSSClasses: {
                arrowPrev: "atlas-icon ati-chevron-left",
                arrowNext: "atlas-icon ati-chevron-right"
            }
        });

        this._calendarInstance.init();
        this.registerFloatingUi();
        this.updateCalendarSelectedDate();
    }

    private getCalendarHeaderTemplate(renderArrows?: boolean) {
        return `
            <div class="vanilla-calendar-header">
                ${renderArrows ? "<#ArrowPrev />" : ""}
                <div class="vanilla-calendar-header__content">
                    <#Month />
                    <#Year />
                </div>
                ${renderArrows ? "<#ArrowNext />" : ""}
            </div>
        `;
    }

    private getCalendarFooterTemplate() {
        return `
            <div class="vanilla-calendar-footer">
                <atlas-button type="ghost" description="Fechar" class="close-button"></atlas-button>
                <atlas-button type="ghost" description="Confirmar" class="confirm-button"></atlas-button>
            </div>
        `;
    }

    private getCalendarDaysTemplate() {
        return `
            ${this.getCalendarHeaderTemplate(true)}
            <div class="vanilla-calendar-wrapper">
                <#WeekNumbers />
                <div class="vanilla-calendar-content">
                    <#Week />
                    <#Days />
                </div>
            </div>
            ${this.getCalendarFooterTemplate()}
        `;
    }

    private getCalendarMonthsTemplate() {
        return `
            ${this.getCalendarHeaderTemplate(false)}
            <div class="vanilla-calendar-wrapper">
                <div class="vanilla-calendar-content">
                    <#Months />
                </div>
            </div>
            ${this.getCalendarFooterTemplate()}
        `;
    }

    private getCalendarYearsTemplate() {
        return `
            ${this.getCalendarHeaderTemplate(true)}
            <div class="vanilla-calendar-wrapper">
                <div class="vanilla-calendar-content">
                    <#Years />
                </div>
            </div>
            ${this.getCalendarFooterTemplate()}
        `;
    }

    private getCalendarSettingsSelectedDate() {
        if (Inputmask.isValid(this.value, { alias: "datepicker" })) {
            return { dates: [formatDateToYearMonthDay(this.value)] };
        }

        const today = getTodayDate();
        const minDate = this.getCalendarMinDate();
        const maxDate = this.getCalendarMaxDate();

        const minDateIsAfterToday = minDate && minDate > today;
        const maxDateIsBeforeToday = maxDate && maxDate < today;

        if (!minDateIsAfterToday && !maxDateIsBeforeToday) {
            return { year: today.getFullYear(), month: today.getMonth() };
        }

        if (minDateIsAfterToday) {
            return { year: minDate.getFullYear(), month: minDate.getMonth() };
        }

        return { year: maxDate.getFullYear(), month: maxDate.getMonth() };
    }

    private updateCalendarSelectedDate() {
        const calendarSettingsSelectedDate = this.getCalendarSettingsSelectedDate();
        this._calendarInstance.settings.selected = calendarSettingsSelectedDate;
        this._calendarInstance.update({ dates: true, month: true, year: true });
        this._selectedDate = calendarSettingsSelectedDate.dates?.[0] ? this.value : "";
    }

    private getCalendarMaxDate() {
        if (this.maxDate) {
            return parseDate(this.maxDate);
        }

        const currentDate = getTodayDate();
        const currentYear = currentDate.getFullYear();

        const maxDate = new Date(
            this.preventLaterDate ? currentYear : currentYear + 80,
            currentDate.getMonth(),
            currentDate.getDate()
        );

        return maxDate;
    }

    private getCalendarMinDate() {
        if (this.minDate) {
            return parseDate(this.minDate);
        }

        if (this.preventPastDate) {
            return getTodayDate();
        }

        return null;
    }

    private registerFloatingUi() {
        this._floatingUiCleanup?.();
        this._calendarWrapper.style.top = "";
        this._calendarWrapper.style.left = "";

        if (!this._deviceController.isMobile) {
            this._floatingUiCleanup = autoUpdate(this._formControl, this._calendarWrapper, this.updateCalendarPosition);
        }
    }

    private updateCalendarPosition() {
        computePosition(this._formControl, this._calendarWrapper, {
            placement: "bottom-start",
            strategy: "fixed",
            middleware: [offset({ mainAxis: 4, crossAxis: 0 }), flip(), shift({ padding: 8 })]
        }).then(({ x, y, placement }) => {
            this._calendarWrapper.setAttribute("data-popper-placement", placement);

            Object.assign(this._calendarWrapper.style, {
                left: `${x}px`,
                top: `${y}px`
            });
        });
    }

    private showCalendar() {
        if (this._showCalendar) return;

        this.updateCalendarSelectedDate();

        this._showCalendar = true;
        this._calendarWrapper.removeAttribute("aria-hidden");

        if (!this._deviceController.isMobile) {
            setTimeout(() => {
                document.addEventListener("click", this.onDocumentClick);
            }, 350);
        }
    }

    private hideCalendar() {
        this._showCalendar = false;
        this._calendarWrapper.setAttribute("aria-hidden", "true");

        document.removeEventListener("click", this.onDocumentClick);
    }

    private resetInputPlaceholder() {
        this._input.placeholder = this.placeholder;
    }

    private updateIconType() {
        if (this._deviceController.isMobile) {
            this.addEventListener("atlas-input-action-button-click", this.onClickCalendarIcon);

            this.icon = "";
            this.actionButtonIcon = "calendar";
        } else {
            this.removeEventListener("atlas-input-action-button-click", this.onClickCalendarIcon);

            this.icon = "calendar";
            this.actionButtonIcon = "";
        }
    }

    /** @override */
    protected async handleInputKeyDown(event: KeyboardEvent) {
        super.handleInputKeyDown(event);

        switch (event.key) {
            case "ArrowUp":
            case "ArrowDown":
                this.incrementOrDecrementCurrentDate(event);
                event.preventDefault();
                break;
            case "Tab":
                this.hideCalendar();
                break;
        }
    }

    /** @override */
    protected handleFocus() {
        super.handleFocus();

        if (!this._deviceController.isMobile) {
            this.showCalendar();
        }
    }

    /** @override */
    protected handleInputClick() {
        super.handleInputClick();

        if (!this._deviceController.isMobile) {
            this.showCalendar();
        }
    }

    private incrementOrDecrementCurrentDate(event: KeyboardEvent) {
        const todayDate = getTodayDate();
        const factor = event.key === "ArrowUp" ? 1 : -1;
        let newDate = todayDate;

        if (!!this.value) {
            newDate = getDateFromString(this.value);
            newDate.setDate(newDate.getDate() + factor);
        }

        if (event.key === "ArrowUp" && newDate > this.getCalendarMaxDate()) return;
        if (event.key === "ArrowDown" && newDate < this.getCalendarMinDate()) return;

        this.value = formatDate(newDate);
    }

    private bindCalendarCustomButtons() {
        this._calendarWrapper.addEventListener("atlas-button-click", (event: CustomEvent) => {
            const button = event.target as HTMLElement;

            if (button.classList.contains("confirm-button")) {
                this.value = this._selectedDate;
            } else {
                this._selectedDate = "";
            }

            this.hideCalendar();
        });

        this._calendarWrapper.addEventListener("mouseover", (event: MouseEvent) => {
            const target = event.target as HTMLElement;

            const isDayDiv = target.classList.contains("vanilla-calendar-day");
            const dayButton = isDayDiv ? target.querySelector(".vanilla-calendar-day__btn") : target;

            const formattedDate = dayButton?.getAttribute("data-calendar-day");

            if (this._input && !this._deviceController.isMobile) {
                this._input.placeholder = formatDate(formattedDate) || this.placeholder;
            }
        });

        this._calendarWrapper.addEventListener("mouseout", () => {
            if (this._input && !this._deviceController.isMobile) {
                this.resetInputPlaceholder();
            }
        });
    }

    private async onChangeScreenType() {
        await this.updateComplete;

        this.updateIconType();
        this.registerFloatingUi();

        if (this._showCalendar && !this._deviceController.isMobile) {
            document.addEventListener("click", this.onDocumentClick);
        } else {
            document.removeEventListener("click", this.onDocumentClick);
        }
    }

    private onClickCalendarIcon() {
        if (this._showCalendar) {
            this.hideCalendar();
        } else {
            this.showCalendar();
        }
    }

    private onInitAndUpdateCalendar() {
        this.bindCalendarCustomButtons();

        this._calendarWrapper
            .querySelectorAll("button, atlas-button, [tabindex='0']")
            .forEach((el) => el.setAttribute("tabindex", "-1"));
    }

    private onClickCalendarDay(event: MouseEvent) {
        const target = event.target as HTMLElement;
        const selectedDate = target.getAttribute("data-calendar-day");

        this._selectedDate = formatDate(selectedDate);

        if (!this._deviceController.isMobile) {
            this.value = this._selectedDate;
            this.focus();
            this.hideCalendar();
        }
    }

    private async onDocumentClick(event: MouseEvent) {
        if (!this._showCalendar) return;

        const composedPath = event.composedPath();
        const isCalendarTarget = composedPath.includes(this._calendarWrapper);
        const isInputTarget = composedPath.includes(this._formControl);

        if (isCalendarTarget || isInputTarget) {
            return;
        }

        this.hideCalendar();
    }

    public render() {
        const calendarClass = {
            "calendar-wrapper": true,
            "fade-and-scale": true,
            "show": this._showCalendar
        };

        const calendarBackdropClass = {
            "calendar-backdrop": true,
            "show": this._showCalendar
        };

        return html`
            ${super.render()}
            ${when(this._deviceController.isMobile, () => html`<div class=${classMap(calendarBackdropClass)}></div>`)}
            <div class=${classMap(calendarClass)} aria-hidden="true"></div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-date-picker": AtlasDatePicker;
    }
}
