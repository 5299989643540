import { LitElement, html } from "lit";
import { customElement, queryAssignedElements } from "lit/decorators.js";

import AtlasSummaryTableRow from "@/components/wizard/atlas-summary-table-row/atlas-summary-table-row";
import AtlasSummaryTableCol from "@/components/wizard/atlas-summary-table-col/atlas-summary-table-col";
import styles from "./atlas-summary-table.scss";

/**
 * Componente que serve como container das tabelas usadas no resumo da wizard
 *
 * @slot header - Slot onde deve ficar a linha do cabeçalho
 * @slot body - Slot onde deve ficar as linhas do corpo da tabela
 *
 * @tag atlas-summary-table
 */
@customElement("atlas-summary-table")
export default class AtlasSummaryTable extends LitElement {
    static styles = styles;

    @queryAssignedElements({ slot: "header", selector: "atlas-summary-table-row" })
    private _headerRows: AtlasSummaryTableRow[];

    @queryAssignedElements({ slot: "body", selector: "atlas-summary-table-row" })
    private _bodyRows: AtlasSummaryTableRow[];

    getHeadingColumns() {
        return this._headerRows[0].getColumns();
    }

    async onBodyRowsChange() {
        await this.updateComplete;

        const headingColumns = this.getHeadingColumns();

        this._bodyRows.forEach((row: AtlasSummaryTableRow) => {
            row.getColumns().forEach((column: AtlasSummaryTableCol, index: number) => {
                column.setAttribute("size", headingColumns[index].size.toString());
                column.setAttribute("column-name", headingColumns[index].innerText);
            });
        });
    }

    render() {
        return html`
            <div class="summary-table">
                <slot name="header"></slot>
                <slot name="body" @slotchange=${this.onBodyRowsChange}></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-summary-table": AtlasSummaryTable;
    }
}
