import { html } from "lit";
import { property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";

import FieldValidator from "@/internals/validators/field-validator";
import RequiredValidator from "@/internals/validators/required-validator";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import { CHECK_ELEMENTS } from "@/helpers/form";

type FormElementStatus = "none" | "info" | "success" | "warning" | "error";

export type FormElementProps = AtlasElementProps & {
    "id": string;
    "name": string;
    "value": string;
    "disabled": boolean;
    "required": boolean;
    "default-value": string;
    "default": boolean;
    "required-error-message": string;
};

/**
 * Classe base para definir elementos de um formulário
 */
export default class FormElement extends AtlasElement {
    /** O nome do elemento */
    @property({ type: String }) name = "";

    /** O valor do elemento */
    @property({ type: String }) value = "";

    /** Mensagem que é exibida quando o campo é requerido, mas não foi preenchido */
    @property({ type: String, attribute: "required-error-message" }) requiredErrorMessage = "";

    /** Indica se o elemento é obrigatório */
    @property({ type: Boolean, reflect: true }) required = false;

    /** Indica se o elemento está desabilitado */
    @property({ type: Boolean, reflect: true }) disabled = false;

    /** Coloca o elemento como seleção padrão no contexto de filtro */
    @property({ type: Boolean }) default: boolean;

    /** É o valor padrão do elemento no contexto de filtro */
    @property({ type: String, attribute: "default-value" }) defaultValue = "";

    /** Indica se as validações devem ser ignoradas */
    @property({ type: Boolean, attribute: "ignore-validations" }) ignoreValidations = false;

    @state() _status: FormElementStatus = "none";

    @state() _showStatusMessage = false;

    @state() _statusMessage = "";

    @state() _valid = true;

    @state() _validationObject: { [key: string]: boolean } = {};

    @state() _validationMessage = "";

    @state() _showValidationState = false;

    @state() _initialValue: any;

    @state() _useOnlyGlobalValidators = false;

    private _validators: FieldValidator[] = [];

    constructor() {
        super();
        this.syncInitialValue();
    }

    /** @internal */
    public connectedCallback(): void {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            this.addValidator(new RequiredValidator(this.requiredErrorMessage));

            this.checkValidity();
        });
    }

    /** @internal */
    @Watch("value", true)
    public onChange() {
        this.checkValidity();

        emit(this, "atlas-form-element-value-change", { trackDisable: true });
        emit(this, "atlas-form-element-touch", { trackDisable: true });
    }

    /** @internal */
    @Watch("disabled", true)
    public onChangeDisabled() {
        if (this.disabled) this.removeStatusMessage();

        emit(this, "atlas-form-element-disabled-change", { trackDisable: true });
    }

    /** @internal */
    @Watch("required", true)
    public onChangeRequired() {
        emit(this, "atlas-form-element-required-change", { trackDisable: true });
    }

    /**
     * Define o valor inicial do elemento
     * @param {any} value - O valor a ser definido
     */
    public setInitialValue(value: any) {
        this._initialValue = value;
    }

    /**
     * Reseta o valor do elemento para o valor inicial
     */
    public reset() {
        if (CHECK_ELEMENTS.includes(this.tagName.toLowerCase())) {
            this.toggleAttribute("checked", Boolean(this._initialValue));
        } else {
            this.value = this._initialValue;
        }

        this.updateComplete.then(() => {
            this.removeStatusMessage();
        });
    }

    /**
     * Define o estado de validação do elemento com uma mensagem de erro
     * @param message - A mensagem de erro
     */
    public setErrorMessage(message: string) {
        this.setStatusWithMessage("error", message);
    }

    /**
     * Define o estado de validação do elemento com uma mensagem de aviso
     * @param message - A mensagem de aviso
     */
    public setWarningMessage(message: string) {
        this.setStatusWithMessage("warning", message);
    }

    /**
     * Define o estado de validação do elemento com uma mensagem de sucesso
     * @param message - A mensagem de sucesso
     */
    public setSuccessMessage(message: string) {
        this.setStatusWithMessage("success", message);
    }

    /**
     * Define o estado de validação do elemento com uma mensagem de informação
     * @param message - A mensagem de informação
     */
    public setInfoMessage(message: string) {
        this.setStatusWithMessage("info", message);
    }

    /**
     * Remove a mensagem de estado de validação do elemento
     */
    public removeStatusMessage() {
        this._status = "none";
        this._statusMessage = "";
        this._validationObject = {};
        this._showStatusMessage = false;
        this._valid = true;
    }

    /**
     * Define o estado de validação do elemento com uma mensagem
     * @param status - O estado de validação
     * @param message - A mensagem de validação
     */
    public setStatusWithMessage(status: FormElementStatus, message: string) {
        this._status = status;
        this._statusMessage = message;
        this._showStatusMessage = true;
        this._valid = status !== "error";
    }

    /**
     * Define o estado de validação do elemento
     * @param validationState - Variável booleana que indica se o elemento está com o estado de validação ou não
     * @param status - O estado de validação
     */
    public setValidationState(validationState: boolean, status?: FormElementStatus) {
        if (!status) {
            this._status = validationState ? "none" : "error";
        } else {
            this._status = status;
        }

        this._valid = validationState;
        this._showStatusMessage = !validationState;
    }

    /**
     * Adiciona um validador ao elemento
     * @param validator - O validador a ser adicionado
     */
    public addValidator(validator: FieldValidator): void {
        this._validators.push(validator);
    }

    /**
     * Remove um validador do elemento
     * @param name - O nome do validador a ser removido
     */
    public removeValidator(name: string): void {
        this._validators = this._validators.filter((validator) => validator.name !== name);
    }

    /**
     * Obtém um validador do elemento
     * @param name - O nome do validador a ser obtido
     * @returns {FieldValidator | undefined} O validador ou undefined, caso não seja encontrado
     */
    public getValidator(name: string): FieldValidator | undefined {
        return this._validators.find((validator) => validator.name === name);
    }

    /**
     * Obtém uma lista de validadores do elemento
     * @returns {FieldValidator[]} - A lista de validadores
     */
    public getValidators(): FieldValidator[] {
        return this._validators;
    }

    /**
     * Habilita o elemento
     */
    public enable(): void {
        this.disabled = false;
    }

    /**
     * Desabilita o elemento
     */
    public disable(): void {
        this.disabled = true;
    }

    /**
     * Alterna o estado de desabilitado do elemento
     * @param forceState - Caso seja passado um valor booleano, força o estado do elemento, caso contrário, alterna o estado atual
     */
    public toggleDisabled(forceState?: boolean): void {
        if (typeof forceState === "boolean") {
            this.disabled = forceState;
        } else {
            this.disabled = !this.disabled;
        }
    }

    /**
     * Obtém o valor do elemento
     * @returns {any} - O valor do elemento
     */
    public getElementValue(): any {
        return this.value;
    }

    /**
     * Verifica se o elemento é válido
     * @returns {boolean} - Indica se o elemento é válido
     */
    public checkValidity(): boolean {
        if (this.ignoreValidations) return true;

        let hasError = false;

        if (this._status === "error") {
            this.removeStatusMessage();
        }

        if (!this.disabled) {
            for (const validator of this._validators) {
                if (!this._useOnlyGlobalValidators || validator.global) {
                    const isValid = validator.validate(this);

                    if (!isValid) {
                        this._validationObject[validator.name] = !isValid;

                        if (!hasError) {
                            hasError = true;
                            this._valid = validator.status !== "error";
                            this._statusMessage = validator.getInvalidMessage();
                            this._status = validator.status;
                        }

                        if (validator.reportOnChange) {
                            this._showStatusMessage = true;
                        }
                    }
                }
            }
        }

        return this._valid;
    }

    /**
     * Verifica se o elemento é válido e exibe uma mensagem de acordo com o estado de validação
     * @returns {boolean} - Indica se o elemento é válido
     */
    public reportValidity(): boolean {
        if (this.ignoreValidations) return true;

        this.checkValidity();

        this._showStatusMessage = this._status !== "none";

        return this._valid;
    }

    /**
     * Retorna um objeto com informações sobre o estado de validação do elemento
     * @returns {object} - O objeto com informações sobre o estado de validação do elemento
     */
    public getValidationsReport() {
        return this._validationObject;
    }

    protected getStatusTheme(): Theme {
        if (!this.getShowStatus() || this._status === "none") return null;

        return this._status === "error" ? "danger" : (this._status as Theme);
    }

    protected getShowStatus() {
        return this._status && this._status !== "none" && this._showStatusMessage;
    }

    protected renderValidationMessage() {
        return when(
            this._showValidationState && this._validationMessage !== "",
            () => html`<div class="invalid-feedback">${this._validationMessage}</div>`
        );
    }

    protected renderStatusMessage() {
        const statusClass = {
            "status-feedback": true,
            [`status-${this._status}`]: !!this._status
        };

        return when(
            this.getShowStatus() && this._statusMessage !== "",
            () => html`<div class=${classMap(statusClass)}>${this._statusMessage}</div>`
        );
    }

    private async syncInitialValue() {
        await this.updateComplete;

        if (CHECK_ELEMENTS.includes(this.tagName.toLowerCase())) {
            this._initialValue = this.hasAttribute("checked");
        } else {
            this._initialValue = this.value;
        }
    }
}
