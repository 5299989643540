import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { StyleInfo, styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import DeviceController from "@/controllers/device-controller";

import type AtlasPageTab from "@/components/layout/atlas-page-tab/atlas-page-tab";
import AtlasElement, { type AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-page-tabs-wrapper.scss";
import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon/atlas-icon";

export type PageTabsWrapperProps = AtlasElementProps & {
    "active-tab-id": string;
};

/**
 * @dependency atlas-badge
 * @dependency atlas-heading
 * @dependency atlas-icon
 *
 * @event {CustomEvent} atlas-page-tab-change - Evento disparado quando é alterada a aba ativa
 *
 * @tag atlas-page-tabs-wrapper
 */
@customElement("atlas-page-tabs-wrapper")
export default class AtlasPageTabsWrapper extends AtlasElement {
    static styles = styles;

    /** Id da aba que está ativa (Por padrão vem a primeira aba selecionada) */
    @property({ type: String, attribute: "active-tab-id", reflect: true }) activeTabId: string;

    @state() private _tabs: AtlasPageTab[] = [];

    @state() private _showScroll: boolean = false;

    @state() private _leftScrollButtonDisabled: boolean = false;

    @state() private _rightScrollButtonDisabled: boolean = false;

    @state() private _dividerActiveMarkStyles: StyleInfo = {};

    @state() private _focusedTabIndex: number | null = null;

    private _resizeObserver: ResizeObserver | null = null;

    private _deviceController: DeviceController = new DeviceController(this);

    disconnectedCallback(): void {
        super.disconnectedCallback();
        this._resizeObserver?.disconnect();
    }

    /**
     * Define a aba ativa pelo id
     * @param tabId - Id da aba que será ativada
     */
    public setActiveTabById(tabId: string) {
        if (!tabId) return;

        const tabWithId = this._tabs.find((tab) => tab.id === tabId);
        if (!tabWithId || tabWithId.disabled) return;

        this.activeTabId = tabId;
    }

    /**
     * Define a aba ativa pelo índice
     * @param index - Índice da aba que será ativada
     */
    public setActiveTabByIndex(index: number) {
        const tab = this._tabs[index];

        if (tab) {
            this.setActiveTabById(tab.id);
        }
    }

    /**
     * Retorna a referência da aba ativa
     * @returns A referência da aba ativa
     */
    public getActiveTab() {
        return this._tabs.find((tab) => tab.active);
    }

    /**
     * Retorna o índice da aba ativa
     * @returns O índice da aba ativa
     */
    public getActiveTabIndex() {
        return this._tabs.findIndex((tab) => tab.active);
    }

    /** @internal */
    @Watch("activeTabId", true)
    public async onActiveTabChange() {
        await this.updateComplete;

        this._tabs.forEach((tab) => {
            tab.active = tab.id === this.activeTabId;
        });

        emit(this, "atlas-page-tab-change", { detail: { tabId: this.activeTabId } });

        await this.scrollToActiveTab();
        await this.updateDividerActiveMark();
        this._focusedTabIndex = this.getActiveTabIndex();
    }

    private onClickTab(event: MouseEvent) {
        const target = event.currentTarget as HTMLElement;
        const tabId = target.getAttribute("data-tab-id");

        this.setActiveTabById(tabId);
    }

    private onKeyDownTab(event: KeyboardEvent) {
        let tabIndex = null;

        switch (event.key) {
            case "Home":
                tabIndex = 0;
                break;
            case "End":
                tabIndex = this._tabs.length - 1;
                break;
            case "ArrowLeft":
                tabIndex = this._focusedTabIndex - 1;
                tabIndex = tabIndex < 0 ? 0 : tabIndex;
                break;
            case "ArrowRight":
                tabIndex = this._focusedTabIndex + 1;
                tabIndex = tabIndex >= this._tabs.length ? this._tabs.length - 1 : tabIndex;
                break;
            default:
                return;
        }

        const focusTabSelector = `.page-tab-button[data-tab-index="${tabIndex}"]`;
        const tabToFocus = this.shadowRoot.querySelector(focusTabSelector) as HTMLElement;

        tabToFocus.focus();
        tabToFocus.scrollIntoView({ behavior: "smooth", block: "nearest" });
        this._focusedTabIndex = tabIndex;
    }

    private onClickNavigationButton(direction: "left" | "right") {
        const tabsNavigation = this.shadowRoot.querySelector(".page-tabs-navigation-scroll") as HTMLElement;
        const scrollAmount = tabsNavigation.offsetWidth / 2;

        tabsNavigation.scroll({
            left:
                direction === "left"
                    ? tabsNavigation.scrollLeft - scrollAmount
                    : tabsNavigation.scrollLeft + scrollAmount,
            behavior: "smooth"
        });

        setTimeout(() => {
            this.toggleNavigationButtonDisabledState();
        }, 500);
    }

    private async onChangeSlottedContent() {
        await this.updateComplete;

        const slot = this.shadowRoot.querySelector("slot");
        const tabs = slot
            .assignedElements()
            .filter((element) => element.tagName === "ATLAS-PAGE-TAB") as AtlasPageTab[];

        this._tabs = tabs;

        if (this._tabs.length > 0) {
            this.observeTabsResize();

            if (this.activeTabId) {
                this.onActiveTabChange();
            } else {
                this.setActiveTabById(this._tabs[0].id);
            }
        }
    }

    private async observeTabsResize() {
        await this.updateComplete;
        const tabsNavigation = this.shadowRoot.querySelector(".page-tabs-navigation-scroll") as HTMLElement;

        this._resizeObserver = new ResizeObserver(async () => {
            await this.showScrollIfNecessary();
            await this.updateDividerActiveMark();
        });

        this._resizeObserver.observe(tabsNavigation);
    }

    private async showScrollIfNecessary() {
        await this.updateComplete;
        const tabsNavigation = this.shadowRoot.querySelector(".page-tabs-navigation-scroll") as HTMLElement;

        this._showScroll = tabsNavigation.scrollWidth > tabsNavigation.offsetWidth;
        await this.toggleNavigationButtonDisabledState();
    }

    private async toggleNavigationButtonDisabledState() {
        await this.updateComplete;
        const tabsNavigation = this.shadowRoot.querySelector(".page-tabs-navigation-scroll") as HTMLElement;

        this._leftScrollButtonDisabled = tabsNavigation.scrollLeft === 0;
        this._rightScrollButtonDisabled =
            tabsNavigation.scrollLeft + tabsNavigation.offsetWidth === tabsNavigation.scrollWidth;
    }

    private async updateDividerActiveMark() {
        await this.updateComplete;
        const activeTabSelector = `.page-tab-button[data-tab-id=${this.activeTabId}]`;
        const activeTabButton = this.shadowRoot.querySelector(activeTabSelector) as HTMLElement;

        if (!activeTabButton) {
            this._dividerActiveMarkStyles = {};
            return;
        }

        const paddingModifier = this._deviceController.isMobile ? 8 : 16;
        const activeTabIndex = parseInt(activeTabButton.getAttribute("data-tab-index") || "0", 10);
        const widthModifier = this.getWidthModifier(activeTabIndex, paddingModifier);
        const leftModifier = activeTabIndex === 0 ? 0 : paddingModifier;

        this._dividerActiveMarkStyles = {
            left: `${activeTabButton.offsetLeft + leftModifier}px`,
            width: `${activeTabButton.offsetWidth - widthModifier}px`
        };
    }

    private getWidthModifier(activeTabIndex: number, paddingModifier: number) {
        const hasMoreThenOneTab = this._tabs.length > 1;

        if (activeTabIndex === 0 && hasMoreThenOneTab) {
            return paddingModifier;
        }

        if (activeTabIndex === 0 && !hasMoreThenOneTab) {
            return 0;
        }

        return activeTabIndex === this._tabs.length - 1 ? paddingModifier : paddingModifier * 2;
    };

    private async scrollToActiveTab() {
        await this.updateComplete;
        const activeTabSelector = `.page-tab-button[data-tab-id=${this.activeTabId}]`;
        const activeTabButton = this.shadowRoot.querySelector(activeTabSelector) as HTMLElement;

        if (!activeTabButton) return;

        const tabsNavigation = this.shadowRoot.querySelector(".page-tabs-navigation-scroll") as HTMLElement;
        const scrollLeft = tabsNavigation.scrollLeft;
        const scrollRight = scrollLeft + tabsNavigation.offsetWidth;

        if (activeTabButton.offsetLeft < scrollLeft) {
            tabsNavigation.scrollLeft = activeTabButton.offsetLeft - 4;
        } else if (activeTabButton.offsetLeft + activeTabButton.offsetWidth > scrollRight) {
            tabsNavigation.scrollLeft =
                activeTabButton.offsetLeft + activeTabButton.offsetWidth - tabsNavigation.offsetWidth + 4;
        }

        await this.toggleNavigationButtonDisabledState();
    }

    private renderNavigationButton(direction: "left" | "right") {
        const isDisabled = direction === "left" ? this._leftScrollButtonDisabled : this._rightScrollButtonDisabled;

        return when(
            this._showScroll,
            () => html`
                <button
                    class="page-tab-scroll-button"
                    @click=${() => this.onClickNavigationButton(direction)}
                    ?disabled=${isDisabled}
                    tabindex="-1"
                >
                    <atlas-icon name=${direction === "left" ? "chevron-left" : "chevron-right"} size="3x"></atlas-icon>
                </button>
            `
        );
    }

    private renderPageTab(tab: AtlasPageTab, index: number) {
        const tabClasses = {
            "page-tab-button": true,
            "active": tab.active,
            "disabled": tab.disabled
        };

        return html`
            <button
                class=${classMap(tabClasses)}
                @click=${this.onClickTab}
                @keydown=${this.onKeyDownTab}
                data-tab-id=${tab.id}
                data-tab-index=${index}
                tabindex=${tab.active ? "1" : "-1"}
            >
                <div class="page-tab-info">
                    <atlas-icon name=${tab.icon} size="3x"></atlas-icon>
                    <atlas-heading size="h6">${tab.name}</atlas-heading>
                    ${when(tab.isNew, () => html`<atlas-badge text="1" is-counter></atlas-badge>`)}
                </div>
            </button>
        `;
    }

    private renderDividerActiveMark() {
        return when(
            !!this.activeTabId,
            () => html`<div class="divider-active-mark" style=${styleMap(this._dividerActiveMarkStyles)}></div>`
        );
    }

    private renderPageTabs() {
        return when(
            this._tabs.length > 0,
            () => html`
                <div class="page-tabs-navigation-wrapper">
                    <div class=${classMap({ "page-tabs-navigation": true, "has-scroll": this._showScroll })}>
                        ${this.renderNavigationButton("left")}
                        <div class="page-tabs-navigation-scroll">
                            ${this._tabs.map((tab, index) => this.renderPageTab(tab, index))}
                            ${this.renderDividerActiveMark()}
                        </div>
                        ${this.renderNavigationButton("right")}
                    </div>
                </div>
            `
        );
    }

    public render() {
        return html`
            <div class="page-tabs-wrapper">
                ${this.renderPageTabs()}
                <slot @slotchange=${this.onChangeSlottedContent}></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-page-tabs-wrapper": AtlasPageTabsWrapper;
    }
}
