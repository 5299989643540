import { customElement, property } from "lit/decorators.js";

import { Watch } from "@/decorators/watch";
import { getMaskInputMode, getMaskPlaceholder, MaskAliasType } from "@/internals/mask-config";
import MaskValidator from "@/internals/validators/mask-validator";
import Inputmask from "@/vendors/inputmask-utils";

import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";

export type MaskedInputProps = InputProps & {
    "mask-alias": MaskAliasType;
    "invalid-mask-error-message": string;
    "mask": string;
};

/**
 * @extends atlas-input
 *
 * @tag atlas-masked-input
 */
@customElement("atlas-masked-input")
export default class AtlasMaskedInput extends AtlasInput {
    /** Alias de máscaras pré-definidas que poderão ser usadas no input */
    @property({ type: String, attribute: "mask-alias" }) maskAlias: MaskAliasType;

    /** Mensagem de erro customizada que será exibida quando o valor do input for inválido */
    @property({ type: String, attribute: "invalid-mask-error-message" }) invalidMaskErrorMessage: string;

    /** Máscara customizada que poderá ser usada no input */
    @property({ type: String, attribute: "mask" }) mask: string;

    private _maskInstance: Inputmask.Instance;

    private _configPlaceholder = "";

    /** @internal */
    public connectedCallback() {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            this._configPlaceholder = this.placeholder;
            this.buildMask();
        });
    }

    /**
     * @internal
     * @override
     */
    public onChangeValue() {
        if (this._maskInstance && !Inputmask.isValid(this.value, (this._maskInstance as any).userOptions)) {
            this.value = Inputmask.format(this.value, (this._maskInstance as any).userOptions);
        }

        super.onChangeValue();
    }

    /** @internal */
    @Watch(["maskAlias", "mask"], true)
    public onChangeMask() {
        this.value = "";
        this.buildMask();
    }

    /**
     * Retorna o valor do campo sem a máscara
     * @returns {string} - O valor do campo sem a máscara
     */
    public getUnmaskedValue(): string {
        return this._maskInstance.unmaskedvalue();
    }

    private buildMask() {
        if (this.mask) {
            this.buildCustomMask();
        } else if (this.maskAlias) {
            this.buildAliasMask();
        }
    }

    private buildAliasMask() {
        const maskObject = { alias: this.maskAlias };

        this.placeholder = this._configPlaceholder || getMaskPlaceholder(this.maskAlias);
        this.inputMode = getMaskInputMode(this.maskAlias);
        this._maskInstance = Inputmask(maskObject).mask(this._input);
        if (this.value) this.value = Inputmask.format(this.value, maskObject);

        // @ts-expect-error
        this._maskInstance.shadowRoot = this.shadowRoot;

        const maskValidator = this.getValidator("mask") as MaskValidator;

        if (!maskValidator) {
            this.addValidator(new MaskValidator(this.maskAlias, this.invalidMaskErrorMessage));
        } else {
            maskValidator.type = this.maskAlias;
            maskValidator.customErrorMessage = this.invalidMaskErrorMessage;
        }
    }

    private buildCustomMask() {
        const maskObject = {
            mask: [this.mask],
            jitMasking: true,
            keepStatic: true,
            showMaskOnHover: false
        };

        this._maskInstance = Inputmask(maskObject).mask(this._input);

        this.placeholder = this._configPlaceholder || this.mask.replace(/[1-9]/g, "0");
        if (this.value) this.value = Inputmask.format(this.value, maskObject);
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-masked-input": AtlasMaskedInput;
    }
}
