import Datepicker from "vanillajs-datepicker/Datepicker";

type LocaleOptions = {
    days: string[];
    daysShort: string[];
    daysMin: string[];
    months: string[];
    monthsShort: string[];
    today: string;
    monthsTitle: string;
    clear: string;
    format: string;
};

type DatepickerLocale = {
    "en"?: LocaleOptions;
    "pt-BR"?: LocaleOptions;
};

export default class DatepickerExtended extends Datepicker {
    static get dateFormat(): string {
        return "dd/mm/yyyy";
    }

    static get locales(): DatepickerLocale {
        return {
            ...Datepicker.locales,
            "pt-BR": {
                days: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
                daysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
                daysMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
                months: [
                    "Janeiro",
                    "Fevereiro",
                    "Março",
                    "Abril",
                    "Maio",
                    "Junho",
                    "Julho",
                    "Agosto",
                    "Setembro",
                    "Outubro",
                    "Novembro",
                    "Dezembro"
                ],
                monthsShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
                today: "Hoje",
                monthsTitle: "Meses",
                clear: "Limpar",
                format: DatepickerExtended.dateFormat
            }
        };
    }

    static getDateTitle(date: any) {
        let dateObject = date;

        if (!date) {
            dateObject = new Date();
        }

        let title = `${dateObject.getFullYear()}/`;

        title += `${this.locales["pt-BR"].daysShort[dateObject.getDay()]}, `;
        title += `${dateObject.getDate()} de `;
        title += this.locales["pt-BR"].months[dateObject.getMonth()];

        return title;
    }

    static getDatepickerMinDate(preventPastDate: boolean, minDate: string): Date {
        if (minDate) {
            const parsedDate = Datepicker.parseDate(minDate, DatepickerExtended.dateFormat);

            return new Date(parsedDate);
        }

        if (preventPastDate) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            return currentDate;
        }

        return null;
    }

    static getDatepickerMaxDate(preventLaterDate: boolean, maxDate: string): Date {
        if (maxDate) {
            const parsedDate = Datepicker.parseDate(maxDate, DatepickerExtended.dateFormat);

            return new Date(parsedDate);
        }

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        return new Date(
            preventLaterDate ? currentYear : currentYear + 80,
            currentDate.getMonth(),
            currentDate.getDate()
        );
    }

    static getDateAsTimestamp(date: string | number | Date) {
        const parsedDate = Datepicker.parseDate(date, DatepickerExtended.dateFormat);
        const dateObj = new Date(parsedDate);

        dateObj.setHours(0, 0, 0, 0);

        return dateObj.getTime();
    }

    static getTodayDate(): Date {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        return currentDate;
    }

    /**
     * @override
     * Overwriting the method to not revert the date on closing the picker
     */
    update(options?: { clear: boolean; render: boolean }): void {
        // @ts-expect-error -- Revert is not mapped in the base type
        super.update(Object.assign(options || {}, { revert: false }));
    }
}
