import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { WithPopoverMixin } from "@/internals/mixins/with-popover-mixin";
import type { ThemeVariation } from "@/internals/theme";

import type { SummaryItemSize, SummaryItemTheme } from "./types";
import styles from "./atlas-summary-item.scss";

import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/display/atlas-text/atlas-text";
import "@/components/layout/atlas-layout/atlas-layout";

/**
 * @dependency atlas-heading
 * @dependency atlas-icon-button
 * @dependency atlas-text
 * @dependency atlas-layout
 *
 * @tag atlas-summary-item
 */
@customElement("atlas-summary-item")
export default class AtlasSummaryItem extends WithPopoverMixin(LitElement) {
    static styles = styles;

    /** Texto da label */
    @property({ type: String }) label: string;

    /** Texto que aparece abaixo da label, em negrito */
    @property({ type: String, attribute: "label-highlight" }) labelHighlight: string;

    /** Texto do conteúdo */
    @property({ type: String }) description: string;

    /** Tamanho do texto do conteúdo (só para o modo horizontal) */
    @property({ type: String, attribute: "size" }) size: SummaryItemSize = "h4";

    /** Tema do texto do conteúdo */
    @property({ type: String, attribute: "theme" }) theme: SummaryItemTheme;

    /** Variação do tema do texto do conteúdo */
    @property({ type: Number, attribute: "theme-variation" }) themeVariation: ThemeVariation = 500;

    /** Indica se o conteúdo deve aparecer ao lado da label, ao invés de abaixo */
    @property({ type: Boolean }) horizontal: boolean;

    private renderHighLightLabel() {
        return when(
            this.labelHighlight,
            () => html`<atlas-text bold muted class="highlight">${this.labelHighlight}</atlas-text>`
        );
    }

    private renderDescription() {
        return when(
            this.horizontal,
            () => html`
                <atlas-heading
                    class="description"
                    size=${this.size}
                    theme=${this.theme}
                    theme-variation=${this.themeVariation}
                >
                    ${this.description}
                </atlas-heading>
            `,
            () => html`
                <atlas-text class="description" bold theme=${this.theme} theme-variation=${this.themeVariation}>
                    ${this.description}
                </atlas-text>
            `
        );
    }

    private renderLabel() {
        return html`
            <atlas-layout auto>
                <atlas-text muted>${this.label}</atlas-text>
                ${this.renderHighLightLabel()}
            </atlas-layout>
        `;
    }

    /** @internal */
    public render() {
        const wrapperClass = {
            wrapper: true,
            horizontal: this.horizontal
        };

        return html`
            <div class=${classMap(wrapperClass)}>
                <div class="label-wrapper">${this.renderLabel()} ${this.renderPopoverInfoIcon()}</div>
                ${this.renderDescription()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-summary-item": AtlasSummaryItem;
    }
}
