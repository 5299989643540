import { LitElement, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";
import { customElement, property, state } from "lit/decorators.js";

import { WithBadgeMixin, type WithBadgeProps } from "@/internals/mixins/with-badge-mixin";

import styles from "./atlas-page-header.scss";
import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-heading/atlas-heading";

export type PageHeaderProps = WithBadgeProps & {
    "page-name": string;
};

/**
 * @dependency atlas-heading
 *
 * @slot default - Slot padrão
 * @slot breadcrumb - Slot onde deve ficar o breadcrumb da página
 * @slot actions - Slot onde devem ficar os botões de ação da página
 *
 * @tag atlas-page-header
 */
@customElement("atlas-page-header")
export default class AtlasPageHeader extends WithBadgeMixin(LitElement) {
    static styles = styles;

    /** Nome da página, que irá aparecer abaixo do breadcrumb */
    @property({ type: String, attribute: "page-name" }) pageName: string;

    /** Indica que é uma página nova, adicionando uma badge ao lado do nome da página */
    @property({ type: Boolean, attribute: "is-new" }) isNew: boolean;

    @state() private _hasActions = false;

    @state() private _hasBreadcrumb = false;

    private onActionsChange() {
        const actionsSlot = this.shadowRoot.querySelector("slot[name=actions]") as HTMLSlotElement;

        this._hasActions = actionsSlot.assignedElements().length > 0;
    }

    private onBreadcrumbChange() {
        const breadcrumbSlot = this.shadowRoot.querySelector("slot[name=breadcrumb]") as HTMLSlotElement;

        this._hasBreadcrumb = breadcrumbSlot.assignedElements().length > 0;
    }

    /**
     * @internal
     * @override
     */
    public renderBadge() {
        if (this.isNew) {
            this.badgeText = "Novo";
            this.badgeTheme = "danger";
            this.badgeTooltip = "";
        }

        return super.renderBadge();
    }

    private renderBreadcrumb() {
        return html`
            <div class="page-breadcrumb">
                <slot name="breadcrumb" @slotchange=${this.onBreadcrumbChange}></slot>
            </div>
        `;
    }

    private renderPageName() {
        return when(
            !!this.pageName,
            () => html`
                <div class="header-with-badge">
                    <atlas-heading size="h4" line-clamp="2">${this.pageName}</atlas-heading>
                    ${this.renderBadge()}
                </div>
            `
        );
    }

    private renderActions() {
        return html`
            <div class="page-actions">
                <slot name="actions" @slotchange=${this.onActionsChange}></slot>
            </div>
        `;
    }

    private renderDefaultSlot() {
        return html` <slot></slot> `;
    }

    /** @internal */
    public render() {
        const headerClass = {
            "page-header": true,
            "has-actions": this._hasActions,
            "has-page-name": !!this.pageName,
            "has-breadcrumb": this._hasBreadcrumb
        };

        return html`
            <div class=${classMap(headerClass)}>
                ${this.renderBreadcrumb()}
                <div class="page-title">${this.renderPageName()} ${this.renderActions()}</div>
                ${this.renderDefaultSlot()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-page-header": AtlasPageHeader;
    }
}
