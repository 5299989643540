import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { emit } from "../../../internals/events";
import { Watch } from "@/decorators/watch";
import AtlasInput, { InputProps } from "../atlas-input/atlas-input";

export type SearchInputProps = InputProps & {};

/**
 * @event {CustomEvent} atlas-input-trigger-search - Evento lançado quando a pesquisa é acionada (Através do botão | Ao apertar "Enter" no campo | Ao clicar para limpar o campo)
 *
 * @tag atlas-search-input
 */
@customElement("atlas-search-input")
export default class AtlasSearchInput extends AtlasInput {
    /** @internal */
    public connectedCallback(): void {
        super.connectedCallback?.();

        this.onInputKeyDown = this.onInputKeyDown.bind(this);
        this.onClickClear = this.onClickClear.bind(this);
        this.triggerSearch = this.triggerSearch.bind(this);

        this.hasIconEvent = true;
        this.actionButtonIcon = "magnifier";
        this.actionButtonLabel = "Pesquisar";

        this.updateComplete.then(() => {
            this.addEventListener("atlas-input-icon-click", this.onClickClear);
            this.addEventListener("atlas-input-action-button-click", this.triggerSearch);
            this._input.addEventListener("keydown", this.onInputKeyDown);
        });
    }

    /** @internal */
    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-input-icon-click", this.onClickClear);
        this.removeEventListener("atlas-input-action-button-click", this.triggerSearch);

        this.updateComplete.then(() => {
            this._input.removeEventListener("keydown", this.onInputKeyDown);
        });
    }

    /** @internal */
    @Watch("value")
    public setClearIcon() {
        this.icon = this.value ? "x" : "";
    }

    /** 
     * Aciona a pesquisa 
     */
    public triggerSearch() {
        if (this.readonly || this.disabled || this.loading) return;

        emit(this, "atlas-input-trigger-search", {
            detail: this.value
        });
    }

    /** @override */
    protected renderSkeleton() {
        const inputSkeletonClass = {
            "skeleton": true,
            "form-control": true,
            "form-control-sm": this.size === "sm",
            "form-control-lg": this.size === "lg" || this._deviceController.isMobile
        };

        return html` <div class="${classMap(inputSkeletonClass)}" style="width: 100%;"></div> `;
    }

    private onInputKeyDown(event: KeyboardEvent) {
        if (event.key === "Enter") {
            this.triggerSearch();
        }
    }

    private onClickClear() {
        this.value = "";
        this.triggerSearch();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-search-input": AtlasSearchInput;
    }
}
