import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";
import { ifDefined } from "lit/directives/if-defined.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import styles from "./atlas-summary-card.scss";
import "@/components/display/atlas-button/atlas-button";
import "@/components/display/atlas-heading/atlas-heading";

/**
 * @dependency atlas-button
 * @dependency atlas-heading
 *
 * @prop {string} header - Título do card
 * @prop {string} related-step - Step da wizard que o usuário será levado ao clicar em editar
 * @prop {string} related-step-element - Elemento da step informada que o usuário será levado ao clicar em editar
 * @prop {boolean} hidden - Indica se o card está visível
 * @prop {boolean} expanded - Indica se o card está expandido horizontalmente, ocupando todas as colunas da grid do container
 * @prop {boolean} always-expanded - Indica se o card deverá ser expandido horizontalmente sempre, ocupando todas as colunas da grid do container
 * @prop {boolean} expanded-vertically - Indica se o card deverá ser expandido verticalmente ocupando duas linhas (Isso ocorre sempre no primeiro card e no segundo quando necessário)
 *
 * @tag atlas-summary-card
 */
@customElement("atlas-summary-card")
export default class AtlasSummaryCard extends LitElement {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: String, attribute: "related-step" }) relatedStep: string;

    @property({ type: String, attribute: "related-step-element" }) relatedStepElement: string;

    @property({ type: Boolean, attribute: "hidden" }) isHidden: boolean;

    @property({ type: Boolean }) expanded: boolean;

    @property({ type: Boolean, attribute: "always-expanded" }) alwaysExpanded: boolean;

    @property({ type: Boolean, attribute: "expanded-vertically" }) expandedVertically: boolean;

    @property({ type: Boolean }) disabled: boolean;

    @property({ type: String, attribute: "disabled-message" }) disabledMessage: string;

    @property({ type: Boolean, attribute: "has-table" }) hasTable: boolean;

    @Watch("isHidden")
    onChangeHidden() {
        emit(this, "atlas-summary-card-change-visibility", { trackDisable: true });
    }

    onClickEdit() {
        if (this.disabled) return;

        emit(this, "atlas-wizard-step-go", {
            detail: {
                step: this.relatedStep,
                element: this.relatedStepElement
            }
        });
    }

    renderEditButton() {
        return when(
            this.relatedStep,
            () => html`
                <atlas-button
                    type="ghost"
                    size="sm"
                    icon="pencil"
                    description="Editar"
                    @atlas-button-click=${this.onClickEdit}
                    ?disabled=${this.disabled}
                    tooltip=${ifDefined(this.disabled ? this.disabledMessage : undefined)}
                ></atlas-button>
            `
        );
    }

    render() {
        const summaryCardClass = {
            "summary-card": true,
            "has-table": this.hasTable
        };

        return html`
            <div class=${classMap(summaryCardClass)}>
                <div class="card-header">
                    <atlas-heading size="h5" theme="primary">${this.header}</atlas-heading>
                    ${this.renderEditButton()}
                </div>
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-summary-card": AtlasSummaryCard;
    }
}
