import { html, LitElement, TemplateResult } from "lit";
import { property, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { generateUniqueId } from "@/helpers/id";

import type { Constructor } from "../util-types";

import "../../components/form/atlas-helper-text/atlas-helper-text";

export type WithCharsCounterProps = {
    "enable-chars-counter": boolean;
};

export declare class WithCharsCounterInterface {
    enableCharsCounter: boolean;

    renderCharsCounter(): TemplateResult;

    renderCharsCounterSkeleton(): TemplateResult;

    updateCharsCounterIfEnabled(value: number): void;
}

export const WithCharsCounterMixin = <T extends Constructor<LitElement>>(superClass: T) => {
    class WithCharsCounterClass extends superClass implements WithCharsCounterInterface {
        /** Indica se o contador de caracteres disponíveis deve ser exibido (deve ser utilizado em conjunto com o atributo `maxlength`) */
        @property({ type: Boolean, reflect: true, attribute: "enable-chars-counter" }) enableCharsCounter = false;

        @state() private _counter = 0;

        @state() private _label: string;

        private _charsCounterId: string;

        public connectedCallback(): void {
            super.connectedCallback?.();

            if (this.isEnabled() && !this._charsCounterId) {
                this._charsCounterId = generateUniqueId("chars-counter", this.getAttribute("id"));
            }
        }

        public renderCharsCounter(): TemplateResult {
            return when(
                this.isEnabled(),
                () => html` <atlas-helper-text id=${this._charsCounterId}> ${this._label} </atlas-helper-text> `
            );
        }

        public renderCharsCounterSkeleton() {
            return when(this.isEnabled(), () => html` <div class="helper-text skeleton"></div> `);
        }

        public updateCharsCounterIfEnabled(value: number): void {
            if (!this.isEnabled()) return;

            this._counter = value;
            this._label = this.getAvailableCharsLabel();
        }

        private isEnabled() {
            return this.enableCharsCounter && this.hasAttribute("maxlength");
        }

        private getAvailableCharsLabel() {
            const maxlength = parseInt(this.getAttribute("maxlength"), 10);
            const availableChars = Math.max(maxlength - this._counter, 0);
            const isPlural = availableChars !== 1;
            return isPlural ? `${availableChars} caracteres disponíveis` : `${availableChars} caractere disponível`;
        }
    }

    return WithCharsCounterClass as Constructor<WithCharsCounterInterface> & T;
};
