import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { map } from "lit/directives/map.js";
import { range } from "lit/directives/range.js";
import { when } from "lit/directives/when.js";

import FormElement, { FormElementProps } from "@/components/form/form-element";
import styles from "./atlas-rating.scss";

import "@/components/form/atlas-helper-text/atlas-helper-text";

export type RatingProps = FormElementProps & {
    "rating": 5 | 10;
    "min-value-label": string;
    "max-value-label": string;
    "helper-text": string;
    "allow-zero": boolean;
};

/**
 * @prop {number} rating - Escala de avaliação que indica a quantidade de opções que irão aparecer, deve ser 5 ou 10
 * @prop {string} min-value-label - Label para o valor mínimo da escala
 * @prop {string} max-value-label - Label para o valor máximo da escala
 * @prop {string} helper-text - Texto de ajuda para o campo
 * @prop {boolean} allow-zero - Permite que o usuário selecione a opção 0
 *
 * @tag atlas-rating
 */
@customElement("atlas-rating")
export default class AtlasRating extends FormElement {
    static styles = styles;

    @property({ type: Number }) rating: 5 | 10 = 10;

    @property({ type: String, attribute: "min-value-label" }) minValueLabel: string;

    @property({ type: String, attribute: "max-value-label" }) maxValueLabel: string;

    @property({ type: String, attribute: "helper-text" }) helperText = "";

    @property({ type: Boolean, attribute: "allow-zero" }) allowZero: boolean;

    @state() private _focusedRating: number = 0;

    handleRatingButtonClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        const value = target.getAttribute("data-rating");

        this.value = value;
    }

    handleRatingButtonKeyDown(event: KeyboardEvent) {
        const previousRatingKeys = ["ArrowDown", "ArrowLeft"];
        const nextRatingKeys = ["ArrowUp", "ArrowRight"];

        if (![...previousRatingKeys, ...nextRatingKeys].includes(event.key)) return;

        if (previousRatingKeys.includes(event.key)) {
            this._focusedRating = this._focusedRating === 0 ? 0 : this._focusedRating - 1;
        } else if (nextRatingKeys.includes(event.key)) {
            this._focusedRating = this._focusedRating === this.rating ? this.rating : this._focusedRating + 1;
        }

        const ratingButton = this.shadowRoot.querySelector(
            `.rating-button[data-rating="${this._focusedRating}"]`
        ) as HTMLButtonElement;

        ratingButton?.focus();
    }

    handleRatingButtonFocus(event: FocusEvent) {
        const target = event.target as HTMLElement;

        this._focusedRating = parseInt(target.getAttribute("data-rating"), 10);
    }

    renderLabels() {
        return when(
            this.minValueLabel && this.maxValueLabel,
            () => html`
                <div class="rating-labels">
                    <atlas-text size="xs">${this.minValueLabel}</atlas-text>
                    <atlas-text size="xs">${this.maxValueLabel}</atlas-text>
                </div>
            `
        );
    }

    renderRatingButtons() {
        const rangeStart = this.allowZero ? 0 : 1;
        const ratingRange = this.rating === 5 ? range(rangeStart, 6) : range(rangeStart, 11);

        return map(ratingRange, (value) => {
            const selected = this.value === value.toString();
            const buttonClasses = {
                "rating-button": true,
                "is-selected": selected
            };

            const buttonLabel = `Botão de opção ${value} ${selected ? "marcado" : "não marcado"}, ${value} de ${this.rating}.`;

            return html`
                <button
                    class=${classMap(buttonClasses)}
                    @click=${this.handleRatingButtonClick}
                    @keydown=${this.handleRatingButtonKeyDown}
                    @focus=${this.handleRatingButtonFocus}
                    data-rating="${value}"
                    role="radio"
                    aria-checked=${selected}
                    aria-posinset=${value}
                    aria-setsize=${this.rating}
                    aria-label=${buttonLabel}
                    ?disabled=${this.disabled}
                >
                    ${value}
                </button>
            `;
        });
    }

    renderHelperText() {
        return when(
            this.helperText,
            () => html`<atlas-helper-text id=${this.id}>${this.helperText}</atlas-helper-text>`,
            () => html`<slot name="helper-text"></slot>`
        );
    }

    render() {
        const buttonGroupClasses = {
            "rating-buttons-group": true,
            "has-labels": this.minValueLabel && this.maxValueLabel,
            [`is-${this._status}`]: this.getShowStatus()
        };

        return html`
            <div class="atlas-rating">
                ${this.renderLabels()}
                <div class=${classMap(buttonGroupClasses)}>${this.renderRatingButtons()}</div>
                ${this.renderStatusMessage()} ${this.renderHelperText()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-rating": AtlasRating;
    }
}
