import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import styles from "./atlas-checklist.scss";

export type ChecklistProps = {
    gap: number;
};

/**
 * @slot - Usado para colocar os ChecklistItems
 *
 * @prop {number} gap - Propriedade que define o gap entre os itens do checklist
 *
 * @tag atlas-checklist
 */
@customElement("atlas-checklist")
export default class AtlasChecklist extends LitElement {
    static styles = styles;

    @property({ type: Number }) gap: number;

    render() {
        const checklistClass = {
            "atlas-checklist": true,
            [`gap-${this.gap}`]: this.gap
        };

        return html`
            <div class="${classMap(checklistClass)}" role="list">
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-checklist": AtlasChecklist;
    }
}
