import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-page-tab.scss";
import { Watch } from "@/decorators/watch";

export type PageTabProps = AtlasElementProps & {
    "name": string;
    "icon": string;
    "active": boolean;
    "disabled": boolean;
    "is-new": boolean;
};

/**
 * @tag atlas-page-tab
 */
@customElement("atlas-page-tab")
export default class AtlasPageTab extends AtlasElement {
    static styles = styles;

    /** Nome da aba */
    @property({ type: String }) name: string;

    /** Ícone da aba */
    @property({ type: String }) icon: string;

    /** Indica se a aba está ou não ativa */
    @property({ type: Boolean, reflect: true }) active: boolean;

    /** Indica se a aba está ou não desabilitada */
    @property({ type: Boolean, reflect: true }) disabled: boolean;

    /** Indica se a aba é nova ou possui algum conteúdo novo */
    @property({ type: Boolean, attribute: "is-new", reflect: true }) isNew: boolean;

    /** @internal */
    @Watch(["isNew", "disabled"], true)
    public async onPropertiesChange() {
        await this.updateComplete;
        this.closest("atlas-page-content")?.requestUpdate();
    }

    render() {
        return html`
            <div class="page-tab-content">
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-page-tab": AtlasPageTab;
    }
}
