import { v4 as uuidv4 } from "uuid";
import qs from "qs";

import { isEmptyValue } from "@/helpers/validations";

type RefreshPageOptions = {
    params?: { [key: string]: any };
    keepParams?: boolean;
}

type RedirectOptions = {
    path: string;
    params?: { [key: string]: any };
    external?: boolean;
    target?: string;
}

type PopupProperties = {
    width: number;
    height: number;
};

type OpenWindowOptions = RedirectOptions & {
    popup?: boolean | PopupProperties;
};

const isExternalPage = (options: OpenWindowOptions) =>
    options.external || options.popup || (options.target && !["_self", "_top", "_parent"].includes(options.target));

const getCenteredPopupAttributes = (width: number, height: number) => {
    if (!width || !height) return null;

    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;

    return {
        top,
        left,
        width,
        height
    };
};

export const refreshPage = (options: RefreshPageOptions) => {
    if (isEmptyValue(options)) {
        window.location.reload();
        return;
    }

    let lastUrlParams;
    let optionsParams;

    if (options.hasOwnProperty("keepParams") && options.keepParams) {
        lastUrlParams = Object.fromEntries(new URLSearchParams(window.location.search));
    }

    if (options.hasOwnProperty("params")) {
        optionsParams = options.params;
    }

    const urlParams = qs.stringify({
        ...lastUrlParams,
        ...optionsParams
    }, { indices: false });

    window.location.assign(`${window.location.pathname}${urlParams ? `?${urlParams}` : ""}`);
};

export const redirect = (options: RedirectOptions) => {
    openWindow(options);
}

export const openWindow = (options: OpenWindowOptions): Window => {
    let params;
    if (options.hasOwnProperty("params")) {
        params = qs.stringify(options.params, { indices: false });
    }

    const path = `${options.path}${params ? `?${params}` : ""}`;
    const windowFeatures = [];
    const baseTarget = isExternalPage(options) ? "_blank" : "_self";

    if (options.external) {
        windowFeatures.push("noreferrer");
    }

    if (options.popup) {
        if (typeof options.popup === "object") {
            const popupAttributes = getCenteredPopupAttributes(options.popup.width, options.popup.height);

            if (popupAttributes) {
                windowFeatures.push(`width=${popupAttributes.width}`);
                windowFeatures.push(`height=${popupAttributes.height}`);
                windowFeatures.push(`top=${popupAttributes.top}`);
                windowFeatures.push(`left=${popupAttributes.left}`);
            } else {
                windowFeatures.push("popup");
            }
        } else {
            windowFeatures.push("popup");
        }
    }

    return window.open(path, options.target || baseTarget, windowFeatures.join(","));
};

export const stealthOpenWindow = (options: OpenWindowOptions) => {
    const form = document.createElement("form");
    form.method = "POST";
    form.target = options.target || "_self";
    form.action = options.path;
    form.style.display = "none";

    Object.entries(options.params || {}).forEach(([key, value]) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = value;
        form.appendChild(input);
    });

    if (isExternalPage(options)) {
        const windowName = `atlas-window-${options.target || uuidv4()}`;

        form.onsubmit = () => {
            openWindow({ path: "about:blank", target: windowName, popup: options.popup });
            form.target = windowName;
        };
    }

    document.body.appendChild(form);
    form.requestSubmit();
    document.body.removeChild(form);
};

const removeEmptyValues = (obj: { [key: string]: any }): { [key: string]: any } | Array<any> => {
    const cleanedEntry = Object.fromEntries(
        Object.entries(obj)
            .map(([key, value]) => [key, value === Object(value) ? removeEmptyValues(value) : value])
            .filter(([, value]) => !isEmptyValue(value))
    );
    return Array.isArray(obj) ? Object.values(cleanedEntry) : cleanedEntry;
};

export const replaceUrlParamsWithoutReload = (params: { [key: string]: any }) => {
    const urlParams = qs.stringify(removeEmptyValues(params), { indices: false });
    const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${urlParams}`;

    window.history.pushState({ path: newurl }, "", newurl);
};
