import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";
import { size } from "@floating-ui/dom";

import DeviceController from "@/controllers/device-controller";
import { ApplySizeArgs } from "@/components/display/overlay";
import { isEmptySlot } from "@/internals/slot";

import OverlayElement, { OverlayElementProps } from "@/components/display/overlay-element";
import "@/components/display/atlas-dropdown-item/atlas-dropdown-item";
import "@/components/display/atlas-desktop-dropdown/atlas-desktop-dropdown";
import "@/components/display/atlas-navbar-dropdown/atlas-navbar-dropdown";
import "@/components/display/atlas-bottom-sheet/atlas-bottom-sheet";

export type DropdownProps = OverlayElementProps & {
    "header": string;
    "width": number;
    "max-width": string;
    "max-height": number;
    "spaced-content": boolean;
    "no-gap": boolean;
    "auto-close": boolean;
    "auto-close-trigger": "any" | "inside" | "outside";
    "overflow": boolean;
    "has-footer": boolean;
    "loading": boolean;
    "loading-text": string;
};

/**
 * Dropdowns servem para exibir conteúdos dentro de uma caixa, que sobrepõe os outros elementos da tela, dando destaque ao seu conteúdo
 *
 * @dependency atlas-dropdown-item
 * @dependency atlas-desktop-dropdown
 * @dependency atlas-navbar-dropdown
 * @dependency atlas-bottom-sheet
 *
 * @slot - Slot padrão usado para definir o conteúdo do dropdown
 * @slot header - Slot para adicionar um conteúdo extra no cabeçalho (Apenas para o bottom sheet)
 *
 * @event {CustomEvent} atlas-dropdown-opened - Evento disparado quando o dropdown é aberto
 * @event {CustomEvent} atlas-dropdown-closed - Evento disparado quando o dropdown é fechado
 *
 * @tag atlas-dropdown
 */
@customElement("atlas-dropdown")
export default class AtlasDropdown extends OverlayElement {
    /** Define um header para o dropdown */
    @property({ type: String }) header: string;

    /** Define a largura do dropdown */
    @property({ type: Number }) width: number;

    /** Define a largura máxima do dropdown */
    @property({ type: String, attribute: "max-width" }) maxWidth: string;

    /** Define a altura máxima do dropdown */
    @property({ type: Number, attribute: "max-height" }) maxHeight: number;

    /** Define se o conteúdo do dropdown deve possuir um espaçamento interno */
    @property({ type: Boolean, attribute: "spaced-content" }) spacedContent: boolean;

    /** Define se os conteúdos do dropdown não devem possuir espaçamento entre eles */
    @property({ type: Boolean, attribute: "no-gap" }) noGap: boolean;

    /** Define se o dropdown deve abrir em tela cheia na versão mobile */
    @property({ type: Boolean, attribute: "mobile-fullscreen" }) mobileFullScreen: boolean;

    /** Define se o dropdown deve fechar automaticamente após um click */
    @property({ type: Boolean, attribute: "auto-close" }) autoClose: boolean;

    /** Define onde deve ser clicado para fechar o dropdown (Apenas quando a flag de auto-close está habilitada) */
    @property({ type: String, attribute: "auto-close-trigger" }) autoCloseTrigger: "any" | "inside" | "outside" = "any";

    /** Define se o dropdown deve possuir overflow */
    @property({ type: Boolean }) overflow: boolean;

    /** Define se o dropdown deve possuir footer com botões, uso exclusivo de botom sheet */
    @property({ type: Boolean, attribute: "has-footer" }) hasFooter: boolean;

    /** Define se o dropdown está em estado de loading */
    @property({ type: Boolean }) loading: boolean;

    /** Define o texto que deve ser exibido quando o dropdown está em loading */
    @property({ type: String, attribute: "loading-text" }) loadingText: string;

    /** Define se o dropdown deve exibir o botão de fechar */
    @property({ type: Boolean, attribute: "show-close-button" }) showCloseButton: boolean;

    @state() private _onNavbarContext = false;

    @state() private _hasSlottedSubheading = false;

    @state() private _modifierMaxHeight: number;

    private _deviceController = new DeviceController(this);

    constructor() {
        super("click", "bottom-start");

        this._floatingUiController.getCustomMiddlewares = this.getFloatingCustomMiddlewares.bind(this);

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.syncTriggerWithContent = this.syncTriggerWithContent.bind(this);

        this.updateComplete.then(() => {
            this._onNavbarContext = !!this.closest("atlas-navbar");
        });
    }

    /**
     * @internal
     */
    public connectedCallback(): void {
        super.connectedCallback?.();

        this.addEventListener("atlas-changed-is-new", this.syncTriggerWithContent);
        this.addEventListener("atlas-dropdown-trigger-close", this.hide);
        this.addEventListener("atlas-bottom-sheet-close", this.hide);
        this.addEventListener("atlas-open-advanced-filter", this.hide);
        this.addEventListener("atlas-filter-close", this.hide);
    }

    /**
     * @internal
     */
    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-changed-is-new", this.syncTriggerWithContent);
        this.removeEventListener("atlas-dropdown-trigger-close", this.hide);
        this.removeEventListener("atlas-bottom-sheet-close", this.hide);
        this.removeEventListener("atlas-open-advanced-filter", this.hide);
        this.removeEventListener("atlas-filter-close", this.hide);
    }

    /**
     * @internal
     */
    public getFloatingElement() {
        return (this.shadowRoot.querySelector(".dropdown-wrapper") as HTMLElement) || this;
    }

    /**
     * @internal
     */
    public syncTriggerElement() {
        super.syncTriggerElement();
        this.syncTriggerWithContent();
    }

    /**
     * @internal
     */
    public updatePosition() {
        this._modifierMaxHeight = null;

        this.updateComplete.then(() => {
            super.updatePosition();
        });
    }

    protected async onOpenOverlay() {
        this._modifierMaxHeight = null;
        await super.onOpenOverlay();

        document.addEventListener("click", this.onDocumentClick);
        document.body.classList.toggle("disable-scroll-dropdown", this.isOnNavbarContext() || this.isBottomSheet());

        this._triggerController.triggerElement.setAttribute("active", "");
        this._triggerController.triggerElement.setAttribute("aria-expanded", "true");
    }

    protected async onCloseOverlay() {
        await super.onCloseOverlay();

        document.removeEventListener("click", this.onDocumentClick);
        document.body.classList.remove("disable-scroll-dropdown");

        this._triggerController.triggerElement.removeAttribute("active");
        this._triggerController.triggerElement.setAttribute("aria-expanded", "false");
    }

    private isOnNavbarContext() {
        return this._onNavbarContext && this._deviceController.isMobile;
    }

    private isBottomSheet() {
        return !this._onNavbarContext && this._deviceController.isMobile;
    }

    private syncTriggerWithContent(): void {
        const { triggerElement } = this._triggerController;

        setTimeout(() => {
            if (triggerElement.tagName === "ATLAS-AVATAR") {
                const defaultSlot = this.shadowRoot.querySelector("slot:not([name])") as HTMLSlotElement;
                const dropdownItems = defaultSlot.assignedElements({ flatten: true });
                const hasItemWithNewTag = dropdownItems.some((item) => item.hasAttribute("is-new"));

                triggerElement.toggleAttribute("show-badge", hasItemWithNewTag);
            }
        }, 0);
    }

    private getFloatingCustomMiddlewares() {
        const isDesktop = !this.isOnNavbarContext() && !this.isBottomSheet();
        const { triggerElement } = this._triggerController;
        const customMiddlewares = [];

        customMiddlewares.push(
            size({
                apply: ({ availableHeight }: ApplySizeArgs) => {
                    const applyScrennMaxHeight = !this.maxHeight || availableHeight < this.maxHeight;

                    this._modifierMaxHeight = applyScrennMaxHeight ? availableHeight - 8 : null;
                }
            })
        );

        if (!isDesktop) {
            customMiddlewares.push({
                name: "fullScreenMiddleware",
                fn: () => ({ x: 0, y: 0 })
            });
        }

        if (isDesktop && triggerElement.classList.contains("form-control")) {
            customMiddlewares.push({
                name: "sameSizeMiddleware",
                fn: () => {
                    this.width = triggerElement.getBoundingClientRect().width;
                    this.maxWidth = "unset";
                    return {};
                }
            });
        }

        return customMiddlewares;
    }

    private onDocumentClick(event: PointerEvent) {
        if (!this.autoClose || !this.open || this.isBottomSheet()) {
            return;
        }

        const composedPath = event.composedPath();
        const isToggleTarget = composedPath.includes(this._triggerController.triggerElement);
        const isMenuTarget = composedPath.includes(this.getFloatingElement());

        if (
            isToggleTarget ||
            (this.autoCloseTrigger === "inside" && !isMenuTarget) ||
            (this.autoCloseTrigger === "outside" && isMenuTarget)
        ) {
            return;
        }

        this.hide();
    }

    private onSubheadingChange() {
        this._hasSlottedSubheading = !isEmptySlot(this, "subheading");
    }

    private renderLoading() {
        return when(
            this.loading,
            () => html`<atlas-dropdown-item loading>${this.loadingText || "Carregando opções"}</atlas-dropdown-item>`
        );
    }

    private renderDropdownContent() {
        const defaultSlotStyle = {
            display: this.loading ? "none" : "contents"
        };

        return html`
            <slot name="subheading" slot="subheading" @slotchange=${this.onSubheadingChange}></slot>
            ${this.renderLoading()}
            <slot style=${styleMap(defaultSlotStyle)}></slot>
        `;
    }

    /**
     * @internal
     */
    public render() {
        const dropdownClass = {
            "dropdown-wrapper": true,
            "loading": this.loading,
            "has-subheading": this._hasSlottedSubheading
        };

        if (this.isOnNavbarContext()) {
            return html`
                <atlas-navbar-dropdown
                    class=${classMap(dropdownClass)}
                    header=${this.header}
                    ?spaced-content=${this.spacedContent}
                    ?no-gap=${this.noGap}
                    ?open=${this.open}
                >
                    ${this.renderDropdownContent()}
                </atlas-navbar-dropdown>
            `;
        }

        if (this.isBottomSheet()) {
            return html`
                <atlas-bottom-sheet
                    class=${classMap(dropdownClass)}
                    header=${this.header}
                    ?open=${this.open}
                    ?fullscreen=${this.mobileFullScreen}
                    ?has-footer=${this.hasFooter}
                    ?spaced-content=${this.spacedContent}
                    ?show-close-button=${this.showCloseButton}
                >
                    ${this.renderDropdownContent()}
                </atlas-bottom-sheet>
            `;
        }

        return html`
            <atlas-desktop-dropdown
                class=${classMap(dropdownClass)}
                header=${this.header}
                width=${this.width}
                max-width=${this.maxWidth}
                max-height=${this._modifierMaxHeight || this.maxHeight}
                ?spaced-content=${this.spacedContent}
                ?no-gap=${this.noGap}
                ?open=${this.open}
                ?scale-vertically=${this._triggerController.triggerElement?.classList.contains("form-control")}
                ?overflow=${this.overflow}
            >
                ${this.renderDropdownContent()}
            </atlas-desktop-dropdown>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-dropdown": AtlasDropdown;
    }
}
