import { LitElement, html, HTMLTemplateResult } from "lit";
import { property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import type { Constructor } from "@/internals/util-types";

import { emit } from "@/internals/events";

import "@/components/display/atlas-collapse-button/atlas-collapse-button";
import "@/components/layout/atlas-collapse/atlas-collapse";

export type WithCollapseProps = {
    collapsible: boolean;
    collapsed: boolean;
    expanded: boolean;
};

export declare class WithCollapseInterface {
    collapsible: boolean;

    collapsed: boolean;

    expanded: boolean;

    hideCollapsibleButton: boolean;

    toggleCollapse(event: CustomEvent): void;

    renderContentWithCollapse(template: HTMLTemplateResult): HTMLTemplateResult;

    renderCollapseButton(theme?: string, iconColor?: string): HTMLTemplateResult;
}

export const WithCollapseMixin = <T extends Constructor<LitElement>>(superClass: T) => {
    /**
     * @dependency atlas-collapse
     * @dependency atlas-collapse-button
     *
     * @prop {boolean} collapsible - Indica se o elemento é colapsável
     * @prop {boolean} collapsed - Indica se o elemento está colapsado
     * @prop {boolean} expanded - Indica que a seção deve iniciar expandida
     * @prop {boolean} hide-collapsible-button - Indica que o botão responsável por alternar o estado colapsado/expandido do componente deve ser oculto.
     */
    class WithCollapseClass extends superClass {
        @property({ type: Boolean }) collapsible: boolean;

        @property({ type: Boolean, reflect: true }) collapsed: boolean = false;

        @property({ type: Boolean, reflect: true }) expanded: boolean = false;
      
        @property({ type: Boolean, reflect: true, attribute: "hide-collapsible-button" }) hideCollapsibleButton = false;

        collapse() {
            if (!this.collapsible) return;

            this.collapsed = true;
        }

        expand() {
            if (!this.collapsible) return;

            this.collapsed = false;
        }

        toggleCollapse(event: CustomEvent): void {
            if (!this.collapsible) return;

            event.stopPropagation();

            this.collapsed = !this.collapsed;

            emit(this, "atlas-collapse-button-click", {
                detail: {
                    collapsed: this.collapsed
                }
            });
        }

        renderContentWithCollapse(template: HTMLTemplateResult): HTMLTemplateResult {
            return html`
                <atlas-collapse ?show=${this.collapsible ? !this.collapsed : true}> ${template} </atlas-collapse>
            `;
        }

        renderCollapseButton(theme?: string, iconColor?: string): HTMLTemplateResult {
            return when(
                !this.hideCollapsibleButton,
                () => html`
                    <atlas-collapse-button
                        icon-color=${iconColor || "secondary"}
                        theme=${theme || "primary"}
                        @click=${this.toggleCollapse}
                        ?collapsed=${this.collapsed}
                    ></atlas-collapse-button>
                `
            );
        }
    }

    return WithCollapseClass as Constructor<WithCollapseInterface> & T;
};
