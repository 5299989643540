import { LitElement } from "lit";
import { html, literal } from "lit/static-html.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { emit } from "@/internals/events";
import { isEmptySlot } from "@/internals/slot";

import styles from "./atlas-product-card.scss";

import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-text/atlas-text";
import "@/components/display/atlas-image/atlas-image";

import { WithDescriptionMixin, type WithDescriptionProps } from "@/internals/mixins/with-description-mixin";

export type ProductCardProps = WithDescriptionProps;

const ComposedClass = WithDescriptionMixin(LitElement);

/**
 * O Product Card é o item que aparece no dropdown de central de navegação, presente na navbar, ele serve renderizar as informações referente a um produto
 * @dependency atlas-heading
 * @dependency atlas-text
 * @dependency atlas-image
 *
 * @prop {string} productLogo - A logotipo utilizada dentro do card
 * @prop {string} productLogoDescription - Descrição da logotipo do card
 * @prop {string} header - O título que será exibido no card de produto
 * @prop {string} footerText - O texto exibido no footer do card
 * @prop {string} href - Link que será aberto ao clicar no card
 *
 * @tag atlas-product-card
 */
@customElement("atlas-product-card")
export default class AtlasProductCard extends ComposedClass {
    static styles = styles;

    @property({ type: String, attribute: "product-logo" }) productLogo: string;

    @property({ type: String, attribute: "product-logo-description" }) productLogoDescription: string;

    @property({ type: String }) header: string;

    @property({ type: String, attribute: "footer-text" }) footerText: string;

    @property({ type: String }) href: string;

    @state() private _hasFooterText = false;

    handleClick() {
        emit(this, "atlas-product-card-click");
    }

    onChangeSlotFooterText() {
        this._hasFooterText = !isEmptySlot(this, "footer-text");
    }

    renderFooterText() {
        return when(
            !!this.footerText,
            () => html`<span class="product-link">${this.footerText}</span>`,
            () => html`
                <atlas-text size="xs" class="footer-text-wrap">
                    <slot name="footer-text" @slotchange=${this.onChangeSlotFooterText}></slot>
                </atlas-text>
            `
        );
    }

    render() {
        const productCardLogo = {
            "product-card": true,
            "hide-footer-text": !this._hasFooterText
        };

        const tag = this.href ? literal`a` : literal`button`;
        /* eslint-disable lit/binding-positions, lit/no-invalid-html */
        return html`
            <${tag}
                class="atlas-card-product-container"
                href=${ifDefined(this.href ? this.href : undefined)}
                target=${ifDefined(this.href ? "_blank" : undefined)}
                rel=${ifDefined(this.href ? "noreferrer" : undefined)}
                @click=${this.handleClick}
            >
                <div class=${classMap(productCardLogo)}>
                    <atlas-image
                        class="product-logo"
                        width="146"
                        src=${this.productLogo}
                        alt=${this.productLogoDescription}
                    ></atlas-image>
                    <div class="product-info">
                        ${when(!!this.header, () => html`<atlas-heading size="h6">${this.header}</atlas-heading>`)}
                        ${this.renderDescription({ size: "xs" })}
                        ${this.renderFooterText()}
                    </div>
                </div>
            </${tag}>
        `;
        /* eslint-enable lit/binding-positions, lit/no-invalid-html */
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-product-card": AtlasProductCard;
    }
}
