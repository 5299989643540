import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { when } from "lit/directives/when.js";
import { emit } from "@/internals/events";
import DeviceController from "@/controllers/device-controller";

import style from "./atlas-navbar.scss";

import "@/components/display/atlas-icon/atlas-icon";

export type NavbarProps = {
    "page-title": string;
};

/**
 * @dependency atlas-icon
 *
 * @prop {string} page-title - Título da página, é exibido quando a tela é do tamanho de um dispositivo móvel
 *
 * @slot actions - Slot usado para incluir as ações da navbar, ex: Central de apps, Notificações, User menu
 * @slot - Slot padrão usado para incluir conteúdos na navbar
 *
 * @tag atlas-navbar
 */
@customElement("atlas-navbar")
export default class AtlasNavbar extends LitElement {
    static styles = style;

    @property({ type: String, attribute: "page-title" }) pageTitle = "";

    private deviceController = new DeviceController(this);

    handleToggleSidebarClick(event: Event) {
        event.stopPropagation();

        emit(this, "atlas-sidebar-toggle-click");
    }

    renderNavbarTitle() {
        return when(
            this.deviceController.isMobile,
            () => html`
                <div class="title-wrapper">
                    <atlas-icon
                        name="bars"
                        size="3x"
                        theme="primary"
                        @click=${this.handleToggleSidebarClick}
                    ></atlas-icon>
                    <span class="title">${this.pageTitle}</span>
                </div>
            `
        );
    }

    render() {
        return html`
            <div class="navbar-wrapper">
                <div class="navbar">
                    ${this.renderNavbarTitle()}
                    <div class="content">
                        <slot></slot>
                    </div>
                    <div class="actions-group">
                        <slot name="actions"></slot>
                    </div>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-navbar": AtlasNavbar;
    }
}
