import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { Theme, ThemeVariation } from "@/internals/theme";
import { formatMoney } from "@/helpers/formatters";

import { ChartObject, ChartType } from "./types";
import { BillingType, BillingTypeTooltips } from "./billing-type";

import { WithTooltipMixin, WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-chart.scss";
import "@/components/dashboard/atlas-bar-chart-item/atlas-bar-chart-item";

export type ChartProps = AtlasElementProps &
    WithTooltipProps & {
        "type": ChartType;
        "theme": Theme;
        "values": string;
        "initial-theme-variation": ThemeVariation;
        "total-value": number;
    };

/**
 * @dependency atlas-bar-chart-item
 */
@customElement("atlas-chart")
export default class AtlasChart extends WithTooltipMixin(AtlasElement) {
    static styles = styles;

    /** Define o tipo do gráfico */
    @property({ type: String }) type: ChartType = "bar";

    /** Define o tema do gráfico */
    @property({ type: String }) theme: Theme;

    /** Recebe o objeto de tipo 'valores' a serem renderizados no gráfico */
    @property({ type: Object }) values: object;

    /** Define se o gráfico deve ignorar a configuração padrão de BillingType */
    @property({ type: Boolean, attribute: "ignore-billing-type", reflect: true }) ignoreBillingType = false;

    /** Recebe o objeto de tooltips que deve ser levado em consideração */
    @property({ type: Object, attribute: "custom-tooltip-values" }) customTooltipValues: object;

    /** Define a variação de cor do primeiro item do gráfico, baseado do tema */
    @property({ type: Number, attribute: "initial-theme-variation" }) initialThemeVariation: ThemeVariation;

    /** Define o valor total do gráfico (caso não seja definido, será calculado com base na soma dos valores) */
    @property({ type: Number, attribute: "total-value" }) totalValue: number;

    @state() private _hasValues: boolean;

    @state() private _chartValues: Array<ChartObject> = [];

    @state() private _billingTypeArray: Array<string> = BillingType;

    private _defaultTooltipMessage = "Não há dados para visualizar";

    private _itemMinWidth = 10;

    public connectedCallback() {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            if (this._chartValues.length === 0) {
                this.tooltip = this._defaultTooltipMessage;
            }
        });
    }

    /**
     * Define os valores do gráfico
     * @param {object} options - Objeto que contém os valores a serem exibidos e atribuídos ao gráfico
     */
    public setValues(values: object) {
        this.values = values;
    }

    /** Limpa os valores do gráfico, restaurando o estado inicial */
    public cleanValues() {
        this._hasValues = false;

        setTimeout(() => {
            this.tooltip = this._defaultTooltipMessage;
            this._chartValues = [];
            this.values = [];
        }, 350);
    }

    /** @internal */
    @Watch("values")
    public onChangeChartValues() {
        this._hasValues = this.values && Object.keys(this.values).length > 0;
        this.tooltip = !this._hasValues ? this._defaultTooltipMessage : "";

        this.buildChartValuesArray();
        this.sortDescendingOrderValues();
    }

    private buildChartValuesArray() {
        this._chartValues = [];

        if (this.ignoreBillingType) {
            const objKeys = Object.keys(this.values);

            objKeys.forEach((key: string) => {
                const value = parseFloat(this.values[key as keyof object]);

                if (value > 0) this._chartValues.push({ type: key, value });
            });
            return;
        }

        this._billingTypeArray.forEach((type: string) => {
            if (!this.values[type as keyof object]) return;

            const value = parseFloat(this.values[type as keyof object]);

            if (value > 0) this._chartValues.push({ type, value });
        });
    }

    private sortDescendingOrderValues() {
        this._chartValues.sort((objectA: any, objectB: any) => objectB.value - objectA.value);
    }

    private getTotalValue() {
        if (this._chartValues.length === 0) return 0;

        const sumValues = this._chartValues.reduce((acc: number, object: ChartObject) => acc + object.value, 0);

        if (typeof this.totalValue === "undefined") return sumValues;

        return sumValues > this.totalValue ? sumValues : this.totalValue;
    }

    private getWidth(index: number) {
        const width = (this._chartValues[index].value / this.getTotalValue()) * 100;

        return width < this._itemMinWidth ? this._itemMinWidth : width;
    }

    private getVariation(index: number) {
        const maxChartItems = 7;
        const themeVariation = this.initialThemeVariation / 100 || maxChartItems;

        return `${themeVariation - index}00`;
    }

    private getTooltip(chartObject: ChartObject) {
        if (this.customTooltipValues && this.customTooltipValues[chartObject.type as keyof object]) {
            return `R$ 
                ${formatMoney(chartObject.value)} 
                ${this.customTooltipValues[chartObject.type as keyof object]}
            `;
        }

        if (BillingTypeTooltips[chartObject.type as keyof typeof BillingTypeTooltips]) {
            return `R$ 
                ${formatMoney(chartObject.value)}
                ${BillingTypeTooltips[chartObject.type as keyof typeof BillingTypeTooltips]}
            `;
        }

        return "";
    }

    protected renderBar() {
        return this._chartValues.map(
            (chartObject: ChartObject, index: number) => html`
                <atlas-bar-chart-item
                    theme="${this.theme}"
                    variation="${this.getVariation(index)}"
                    width="${this.getWidth(index)}"
                    tooltip="${this.getTooltip(chartObject)}"
                ></atlas-bar-chart-item>
            `
        );
    }

    protected renderElement() {
        const chartClass = {
            "atlas-chart": true,
            [`bg-${this.theme}-200`]: true,
            "has-chart": this._hasValues
        };

        return html`
            <div class="${classMap(chartClass)}" data-atlas-tooltip="chart-tooltip">
                <div class="chart-wrap">${when(this.type === "bar", () => this.renderBar())}</div>
            </div>
            ${this.renderTooltip("chart-tooltip")}
        `;
    }

    protected renderSkeleton() {
        return html` <div class="chart skeleton"></div> `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-chart": AtlasChart;
    }
}
