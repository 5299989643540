import AtlasUploader from "@/components/form/atlas-uploader/atlas-uploader";
import FieldValidator, { FieldValidatorStatus } from "./field-validator";

export default class UploaderValidator implements FieldValidator {
    name = "uploader";

    status: FieldValidatorStatus = "error";

    getInvalidMessage(): string {
        return "Aguarde a conclusão do carregamento";
    }

    validate(element: AtlasUploader): boolean {
        return !element.isUploadingFiles();
    }
}
