import type { LitElement, PropertyValueMap } from "lit";

type UpdateHandler = (oldValue?: unknown, newValue?: unknown) => void;

type NonUndefined<A> = A extends undefined ? never : A;

type UpdateHandlerFunctionKeys<T extends object> = {
    [K in keyof T]-?: NonUndefined<T[K]> extends UpdateHandler ? K : never;
}[keyof T];

/**
 * Decorator usado para monitorar alterações nas propriedades de uma classe e executar a função que foi decorada caso uma propriedade mude
 *
 * @param propName - Propriedade que está sendo monitorada
 * @param waitFirstUpdate - Indica se deve aguardar a primeira atualização/renderização do componente antes de chamar a função decorada
 */
export function Watch(propName: string | string[], waitFirstUpdate = false) {
    return <ElementClass extends LitElement>(
        proto: ElementClass,
        decoratedFunction: UpdateHandlerFunctionKeys<ElementClass>
    ): void => {
        // @ts-expect-error -- update is a protected property
        const { update } = proto;
        const props = Array.isArray(propName) ? propName : [propName];
        const filteredProps = props.filter((prop) => prop in proto);

        if (filteredProps.length > 0) {
            /* eslint-disable func-names */
            // @ts-expect-error -- update is a protected property
            proto.update = function (this: ElementClass, changedProps: PropertyValueMap<any>) {
                const changedPropsMap = new Map();

                filteredProps.forEach((prop) => {
                    if (changedProps.has(prop)) {
                        const propNameKey = prop as keyof ElementClass;
                        const oldValue = changedProps.get(prop);
                        const newValue = this[propNameKey];

                        if (oldValue !== newValue) {
                            changedPropsMap.set(prop, { oldValue, newValue });
                        }
                    }
                });

                if (changedPropsMap.size > 0 && (!waitFirstUpdate || this.hasUpdated)) {
                    if (changedPropsMap.size === 1) {
                        const firstEntry = changedPropsMap.entries().next().value;

                        (this[decoratedFunction] as unknown as UpdateHandler)(
                            firstEntry[1].oldValue,
                            firstEntry[1].newValue
                        );
                    } else {
                        (this[decoratedFunction] as unknown as UpdateHandler)(changedPropsMap);
                    }
                }

                update.call(this, changedProps);
            };
            /* eslint-enable func-names */
        }
    };
}
