import { html } from "lit";
import { customElement, property, queryAsync, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";
import { autoUpdate, computePosition, offset } from "@floating-ui/dom";

import { Watch } from "@/decorators/watch";
import { Theme } from "@/internals/theme";
import { emit } from "@/internals/events";
import DeviceController from "@/controllers/device-controller";
import type AtlasButtonGroup from "@/components/display/atlas-button-group/atlas-button-group";
import type AtlasTableCol from "@/components/table/atlas-table-col/atlas-table-col";

import { WithVisibilityIconMixin, WithVisiblityIconProps } from "@/internals/mixins/with-visibility-icon-mixin";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-table-row.scss";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/form/atlas-checkbox/atlas-checkbox";
import "@/components/table/atlas-table-col/atlas-table-col";

export type TableRowProps = AtlasElementProps &
    WithVisiblityIconProps & {
        "href": string;
        "has-actions": boolean;
        "selectable": boolean;
        "selected": boolean;
        "enable-line-break": boolean;
    };

/**
 * @dependency atlas-icon
 * @dependency atlas-checkbox
 * @dependency atlas-table-col
 *
 * @prop {string} href - Link da página ao qual o usuário será redirecionado ao clicar sobre a linha
 * @prop {boolean} has-actions - Indica se a linha tem ações
 * @prop {boolean} selectable - Indica se a linha permite múltipla seleção
 * @prop {boolean} selected - Indica se a linha está selecionada
 * @prop {boolean} enable-line-break - Indica se a linha deve quebrar linhas longas
 *
 * @tag atlas-table-row
 */
@customElement("atlas-table-row")
export default class AtlasTableRow extends WithVisibilityIconMixin(AtlasElement) {
    static styles = styles;

    @property({ type: String }) theme: Theme = "primary";

    @property({ type: String }) href: string;

    @property({ type: Boolean, attribute: "has-actions" }) hasActions = false;

    @property({ type: Boolean }) selectable = false;

    @property({ type: Boolean }) selected = false;

    @property({ type: Boolean, attribute: "enable-line-break" }) enableLineBreak: boolean = false;

    @queryAsync("slot:not([name])") private _defaultSlot: HTMLSlotElement;

    @queryAsync("slot[name=actions]") private _actionsSlot: HTMLSlotElement;

    @queryAsync("slot[name=hotspot]") private _hotspotSlot: HTMLSlotElement;

    @state() private _showLeftFade: boolean = false;

    @state() private _showRightFade: boolean = false;

    private _deviceController = new DeviceController(this);

    private _floatingUiCleanup?: () => void;

    connectedCallback(): void {
        super.connectedCallback?.();

        this.setPropsOnButtonGroup = this.setPropsOnButtonGroup.bind(this);
        this.onActionClick = this.onActionClick.bind(this);
        this.updateHotspotPosition = this.updateHotspotPosition.bind(this);

        this._deviceController.setScreenChangeCallback(this.setPropsOnButtonGroup);
        this.addEventListener("atlas-button-group-button-click", this.onActionClick);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-button-group-button-click", this.onActionClick);
    }

    @Watch(["enableLineBreak"])
    async applyPropsInCols() {
        (await this.getSlottedCols()).forEach(async (col: AtlasTableCol) => {
            col.toggleAttribute("enable-line-break", this.enableLineBreak);
        });
    }

    async getSlottedCols() {
        return (
            (await this._defaultSlot)
                ?.assignedElements({ flatten: true })
                .filter((element) => element.tagName === "ATLAS-TABLE-COL") || []
        );
    }

    async getSlottedButtonGroup() {
        return (await this._actionsSlot)?.assignedElements()[0] as AtlasButtonGroup;
    }

    async getSlottedHotspot() {
        return (await this._hotspotSlot)?.assignedElements()[0] as HTMLElement;
    }

    toggleLeftFade(forceState?: boolean) {
        this._showLeftFade = typeof forceState === "boolean" ? forceState : !this._showLeftFade;
    }

    toggleRightFade(forceState?: boolean) {
        this._showRightFade = typeof forceState === "boolean" ? forceState : !this._showRightFade;
    }

    toggleSelection(selected: boolean) {
        this.selected = selected;
    }

    onClickSelect() {
        setTimeout(() => {
            this.selected = !this.selected;
            emit(this, "atlas-table-row-select", { trackDisable: true });
        }, 0);
    }

    onActionClick(event: CustomEvent) {
        const actionClickEvent = emit(this, "atlas-table-action-click", {
            detail: {
                row: this,
                ...event.detail
            }
        });

        if (actionClickEvent.defaultPrevented) event.preventDefault();
    }

    adjustRowFade(tableWrapperTop: number) {
        if (this._deviceController.isMobile) return;

        const rowElement = this.shadowRoot.querySelector<HTMLElement>(".atlas-table-row");
        const rowRect = rowElement.getBoundingClientRect();

        const fadeList = this.shadowRoot.querySelectorAll<HTMLElement>(".atlas-table-row-fade");

        fadeList.forEach((fade) => {
            fade.style.height = `${rowRect.height}px`;
            fade.style.top = `${rowRect.top - tableWrapperTop}px`;
        });
    }

    updateHotspotPosition() {
        this.getSlottedHotspot().then((hotspot) => {
            computePosition(this.shadowRoot.querySelector(".atlas-table-row"), hotspot, {
                placement: "top-end",
                strategy: "fixed",
                middleware: [offset({ mainAxis: -20, crossAxis: 20 })]
            }).then(({ x, y }) => {
                Object.assign(hotspot.style, {
                    left: `${x}px`,
                    top: `${y}px`
                });
            });
        });
    }

    async registerFloatingHotspot() {
        await this.updateComplete;
        const hotspot = await this.getSlottedHotspot();

        if (!hotspot) {
            this._floatingUiCleanup?.();
            return;
        }

        this._floatingUiCleanup = autoUpdate(
            this.shadowRoot.querySelector(".atlas-table-row"),
            hotspot,
            this.updateHotspotPosition
        );
    }

    async setPropsOnButtonGroup() {
        await this.updateComplete;
        const slottedButtonGroup = await this.getSlottedButtonGroup();

        if (!slottedButtonGroup) return;

        slottedButtonGroup.setAttribute("more-button-type", "icon");
        slottedButtonGroup.setAttribute("more-button-theme", this.theme);
        slottedButtonGroup.setAttribute("more-button-size", this._deviceController.isMobile ? "3x" : "2x");
        slottedButtonGroup.setAttribute("gap", "1");
    }

    renderSelectColumn() {
        return when(
            this.selectable,
            () => html`
                <atlas-table-col is-selection-column @atlas-table-col-click=${this.onClickSelect}>
                    <atlas-checkbox ?checked=${this.selected}></atlas-checkbox>
                </atlas-table-col>
            `
        );
    }

    renderActionsColumn() {
        return when(
            this.hasActions,
            () => html`
                <atlas-table-col is-action-column>
                    <slot name="actions" @slotchange=${this.setPropsOnButtonGroup}></slot>
                </atlas-table-col>
            `
        );
    }

    renderVisibilityIconColumn() {
        const visibilityIconSize = this._deviceController.isMobile ? "3x" : "2x";

        return when(
            this.hasVisibilityIcon,
            () => html`
                <atlas-table-col
                    has-visibility-icon
                    ?is-data-visible=${this.isDataVisible}
                    visibility-icon-size="${visibilityIconSize}"
                ></atlas-table-col>
            `
        );
    }

    renderFade(side: "left" | "right") {
        const fadeClass = {
            "atlas-table-row-fade": true,
            [`${side}`]: true,
            "hide": side === "left" ? !this._showLeftFade : !this._showRightFade
        };

        return when(!this._deviceController.isMobile, () => html`<div class=${classMap(fadeClass)}></div>`);
    }

    render() {
        const rowClass = {
            "atlas-table-row": true,
            "selected": this.selected,
            "selectable": this.selectable,
            "has-actions": this.hasActions,
            "has-visibility-icon": this.hasVisibilityIcon,
            "has-link": this.href,
            [`theme-${this.theme}`]: !!this.theme
        };

        return html`
            ${this.renderFade("left")}
            <a class=${classMap(rowClass)} href=${ifDefined(this.href)}>
                ${this.renderSelectColumn()}
                <slot @slotchange=${this.applyPropsInCols}></slot>
                <div class="floating-mobile-actions">
                    ${this.renderActionsColumn()} ${this.renderVisibilityIconColumn()}
                </div>
                <slot name="hotspot" @slotchange=${this.registerFloatingHotspot}></slot>
            </a>
            ${this.renderFade("right")}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-table-row": AtlasTableRow;
    }
}
