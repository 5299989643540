import { removeNonNumeric } from "./formatters";

const LANDLINE_LENGTH = 10;
const CELL_PHONE_LENGTH = 11;

const splitAreaCodeAndPhoneNumber = (phoneValue: string) => {
    const phone = removeNonNumeric(phoneValue);
    const areaCode = phone.substring(0, 2);
    const phoneNumber = phone.substring(2, phone.length);

    return { areaCode, phoneNumber };
};

const validateAreaCode = (phoneValue: string) => {
    const { areaCode } = splitAreaCodeAndPhoneNumber(phoneValue);

    return parseInt(areaCode, 10) > 10;
};

const validateSize = (phoneValue: string, size: number) => {
    if (!phoneValue) {
        return false;
    }

    const phone = removeNonNumeric(phoneValue);

    return phone.length === size;
};

export function validateLandline(phoneValue: string) {
    if (!validateSize(phoneValue, LANDLINE_LENGTH)) {
        return false;
    }

    return validateAreaCode(phoneValue);
}

export function validateCellPhone(phoneValue: string) {
    if (!validateSize(phoneValue, CELL_PHONE_LENGTH) || !validateAreaCode(phoneValue)) {
        return false;
    }

    const { phoneNumber } = splitAreaCodeAndPhoneNumber(phoneValue);
    const firstDigit = phoneNumber[0];
    const cellPhonePrefix = [6, 7, 8, 9];

    return cellPhonePrefix.indexOf(parseInt(firstDigit, 10)) > -1;
}

export function validatePhone(phoneValue: string) {
    if (!phoneValue) {
        return false;
    }

    const phone = removeNonNumeric(phoneValue);

    if (phone.length === LANDLINE_LENGTH) {
        return validateLandline(phone);
    }

    if (phone.length === CELL_PHONE_LENGTH) {
        return validateCellPhone(phone);
    }

    return false;
}
