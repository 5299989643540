import { html, nothing } from "lit";
import { customElement, property, query, queryAsync } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import { Watch } from "@/decorators/watch";
import { getAllFormElements } from "@/helpers/form";
import { showConfirmation } from "@/helpers/notifications";
import { closeAllOverlays } from "@/helpers/overlay";
import { emit } from "@/internals/events";

import type AtlasButton from "@/components/display/atlas-button/atlas-button";
import type AtlasForm from "@/components/form/atlas-form/atlas-form";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";

import { WithBadgeMixin, type WithBadgeProps } from "@/internals/mixins/with-badge-mixin";
import { WithCollapseMixin, type WithCollapseProps } from "@/internals/mixins/with-collapse-mixin";
import { WithVisibilityIconMixin, type WithVisiblityIconProps } from "@/internals/mixins/with-visibility-icon-mixin";
import { WithDescriptionMixin, type WithDescriptionProps } from "@/internals/mixins/with-description-mixin";

import styles from "./atlas-form-panel.scss";
import "@/components/display/atlas-button/atlas-button";
import "@/components/form/atlas-form/atlas-form";
import "@/components/layout/atlas-layout/atlas-layout";
import "@/components/layout/atlas-panel/atlas-panel";

const ComposedClass = WithDescriptionMixin(WithVisibilityIconMixin(WithBadgeMixin(WithCollapseMixin(AtlasElement))));

export type FormPanelProps = AtlasElementProps &
    WithBadgeProps &
    WithCollapseProps &
    WithVisiblityIconProps &
    WithDescriptionProps & {
        "header": string;
        "description": string;
        "submit-button-label": string;
        "action": string;
        "method": "get" | "post";
        "target": string;
        "editing": boolean;
        "hide-badge": boolean;
        "ignore-reset-on-cancel": boolean;
        "show-confirmation-on-cancel": boolean;
    };

/**
 * @event {CustomEvent} atlas-form-panel-start-editing - Evento lançado quando a edição do formulário é iniciada
 * @event {CustomEvent} atlas-form-panel-end-editing - Evento lançado quando a edição do formulário é finalizada
 * @event {CustomEvent} atlas-form-panel-cancel - Evento lançado quando o cancel do formulário será executado
 * @event {CustomEvent} atlas-form-panel-submit - Evento lançado quando o submit do formulário será executado
 *
 * @slot - Conteúdo do painel
 * @slot actions - Ações do painel, aparecem ao lado do cabeçalho
 */
@customElement("atlas-form-panel")
export default class AtlasFormPanel extends ComposedClass {
    static styles = styles;

    /** Título do painel */
    @property({ type: String }) header: string;

    /** Descrição do painel */
    @property({ type: String }) description: string;

    /** Label do botão que faz o submit do formulário do painel */
    @property({ type: String, attribute: "submit-button-label" }) submitButtonLabel: string;

    /** URL que será alvo do formulário */
    @property({ type: String }) action: string;

    /** Método HTTP que será usado para o envio do formulário (get | post) */
    @property({ type: String }) method: "get" | "post" = "get";

    /** Onde será exibido o corpo da resposta do formulário */
    @property({ type: String }) target: string;

    /** Indica se o panel está em modo de edição, se passado ao renderizar o elemento, o painel já vem em modo de edição */
    @property({ type: Boolean }) editing: boolean = false;

    /** Indica se a badge "Em edição" deve ser oculta do painel */
    @property({ type: Boolean, attribute: "hide-badge" }) hideBadge: boolean = false;

    /** Indica se ao clicar em cancelar o formulário não deve ser resetado */
    @property({ type: Boolean, attribute: "ignore-reset-on-cancel" }) ignoreResetOnCancel: boolean = false;

    /** Indica se deve ser exibido um modal de confirmação ao clicar em cancelar */
    @property({ type: Boolean, attribute: "show-confirmation-on-cancel" }) showConfirmationOnCancel: boolean = false;

    @query(".action-button-submit") private _submitButton: AtlasButton;

    @query(".action-button-cancel") private _cancelButton: AtlasButton;

    @queryAsync("atlas-form") private _panelForm: AtlasForm;

    private _deviceController: DeviceController = new DeviceController(this);

    private _intersectionObserver: IntersectionObserver;

    public connectedCallback(): void {
        super.connectedCallback?.();

        this.onActionButtonClick = this.onActionButtonClick.bind(this);
        this.onChangeEditing = this.onChangeEditing.bind(this);
        this.onStartEditing = this.onStartEditing.bind(this);
        this.onEndEditing = this.onEndEditing.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.observeFooterActions = this.observeFooterActions.bind(this);

        this.addEventListener("atlas-button-click", this.onActionButtonClick);
        this.addEventListener("atlas-dropdown-item-click", this.onActionButtonClick);
        this.addEventListener("atlas-form-submit", this.onFormSubmit);

        this._deviceController.setScreenChangeCallback(this.observeFooterActions);
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-button-click", this.onActionButtonClick);
        this.removeEventListener("atlas-dropdown-item-click", this.onActionButtonClick);
        this.removeEventListener("atlas-form-submit", this.onFormSubmit);

        this._intersectionObserver?.disconnect();
    }

    /**
     * Cancela a edição do painel
     */
    public async cancelEditing() {
        this.editing = false;

        if (!this.ignoreResetOnCancel) {
            (await this._panelForm).reset();
        }
    }

    /**
     * Finaliza o modo de edição do painel
     */
    public finishEditing() {
        this.editing = false;
    }

    /**
     * Ativa os botões do painel
     */
    public enablePanelButtons() {
        this._submitButton.loading = false;
        this._cancelButton.enable();
    }

    private onActionButtonClick(event: CustomEvent) {
        const target = event.target as HTMLElement;

        if (target.hasAttribute("data-panel-start-editing")) {
            this.editing = true;
        }
    }

    private onStartEditing() {
        this.togglePanelFormElements(true);
        this.enablePanelButtons();

        emit(this, "atlas-form-panel-start-editing", { trackDisable: true });
    }

    private onEndEditing() {
        this.togglePanelFormElements(false);

        emit(this, "atlas-form-panel-end-editing", { trackDisable: true });
    }

    private onFormSubmit(event: CustomEvent) {
        event.stopPropagation();

        this._submitButton.loading = true;
        this._cancelButton.disable();

        const submitEvent = emit(this, "atlas-form-panel-submit", { trackDisable: true });

        if (submitEvent.defaultPrevented) {
            event.preventDefault();
        }
    }

    /** @internal */
    @Watch("editing")
    public async onChangeEditing() {
        await this.updateComplete;
        closeAllOverlays();
        this.observeFooterActions();

        if (this.editing) {
            this.onStartEditing();
        } else {
            this.onEndEditing();
        }
    }

    private async onClickCancelButton() {
        const cancelEvent = emit(this, "atlas-form-panel-cancel", { trackDisable: true });
        if (cancelEvent.defaultPrevented) return;

        if (!this.showConfirmationOnCancel) {
            this.cancelEditing();
            return;
        }

        showConfirmation({
            illustration: "triangle-exclamation-mark-siren",
            size: "large",
            title: "Descartar alterações",
            content:
                "Algumas alterações não foram salvas. Ao cancelar, todas as informações serão perdidas. Tem certeza que deseja descartar as alterações?",
            confirmButton: {
                theme: "danger",
                description: "Descartar alterações"
            },
            disableAutoClose: true,
            hideCloseButton: true,
            onConfirm: async (modal) => {
                modal.closeModal();
                this.cancelEditing();
            }
        });
    }

    private async onClickSubmitButton() {
        (await this._panelForm).submit();
    }

    private togglePanelFormElements(enable: boolean) {
        setTimeout(() => {
            getAllFormElements(this).forEach((element) => {
                if (enable && element.hasAttribute("data-atlas-form-panel-keep-disabled")) return;

                element.toggleDisabled(!enable);
            });
        }, 0);
    }

    private async observeFooterActions() {
        await this.updateComplete;

        if (!this._deviceController.isMobile || !this.editing) {
            this._intersectionObserver?.disconnect();
            return;
        }

        this._intersectionObserver = new IntersectionObserver(this.onIntersection, {
            root: null,
            threshold: 0
        });

        this._intersectionObserver.observe(this.shadowRoot.querySelector(".footer-actions-anchor"));
    }

    private onIntersection(entries: IntersectionObserverEntry[]) {
        entries.forEach((entry: IntersectionObserverEntry) => {
            entry.target.classList.toggle("is-intersecting", entry.isIntersecting || entry.boundingClientRect.top < 0);
        });
    }

    private renderEditActions() {
        return html`
            <atlas-layout
                gap="4"
                inline
                mobile-inline
                slot=${ifDefined(!this._deviceController.isMobile ? "actions" : undefined)}
            >
                <atlas-button
                    description="Cancelar"
                    theme="secondary"
                    @atlas-button-click=${this.onClickCancelButton}
                    class="action-button-cancel"
                ></atlas-button>
                <atlas-button
                    description=${this.submitButtonLabel || "Salvar"}
                    theme="success"
                    @atlas-button-click=${this.onClickSubmitButton}
                    class="action-button-submit"
                ></atlas-button>
            </atlas-layout>
        `;
    }

    private renderPanelActions() {
        if (this._deviceController.isMobile && this.editing) return nothing;

        return when(
            this.editing,
            () => this.renderEditActions(),
            () => html`<slot name="actions" slot="actions"></slot>`
        );
    }

    private renderPanelDescriptionSlot() {
        return html` <slot name="description" slot="description"></slot> `;
    }

    private renderFooterActions() {
        return when(
            this._deviceController.isMobile && this.editing,
            () => html`
                <div class="footer-actions-anchor">
                    <div class="footer-actions">${this.renderEditActions()}</div>
                </div>
            `
        );
    }

    private getBadgeProps() {
        return {
            badgeType: this.editing && !this.hideBadge ? "outlined" : this.badgeType,
            badgeText: this.editing && !this.hideBadge ? "Em edição" : this.badgeText,
            badgeIcon: this.editing && !this.hideBadge ? "pencil" : this.badgeIcon,
            badgeTheme: this.editing && !this.hideBadge ? "primary" : this.badgeTheme,
            badgeTooltip: this.editing && !this.hideBadge ? "" : this.badgeTooltip,
            badgeTooltipPlacement: this.editing && !this.hideBadge ? undefined : this.badgeTooltipPlacement,
            badgeTooltipTrigger: this.editing && !this.hideBadge ? undefined : this.badgeTooltipTrigger
        };
    }

    public render() {
        const {
            badgeType,
            badgeText,
            badgeIcon,
            badgeTheme,
            badgeTooltip,
            badgeTooltipPlacement,
            badgeTooltipTrigger
        } = this.getBadgeProps();

        return html`
            <atlas-form action=${this.action} method=${this.method} target=${this.target}>
                <atlas-panel
                    header=${this.header}
                    description=${this.description}
                    badge-type=${badgeType}
                    badge-text=${badgeText}
                    badge-icon=${badgeIcon}
                    badge-theme=${badgeTheme}
                    badge-tooltip=${badgeTooltip}
                    badge-tooltip-placement=${ifDefined(badgeTooltipPlacement)}
                    badge-tooltip-trigger=${ifDefined(badgeTooltipTrigger)}
                    ?collapsible=${this.collapsible}
                    ?collapsed=${this.collapsed}
                    ?has-visibility-icon=${this.hasVisibilityIcon}
                    ?expanded=${this.expanded}
                    ?hide-collapsible-button=${this.hideCollapsibleButton}
                >
                    ${this.renderPanelActions()} ${this.renderPanelDescriptionSlot()}
                    <atlas-layout gap="6">
                        <slot></slot>
                        ${this.renderFooterActions()}
                    </atlas-layout>
                </atlas-panel>
            </atlas-form>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-form-panel": AtlasFormPanel;
    }
}
