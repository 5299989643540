import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { getSlotTextContent } from "@/internals/slot";
import { emit } from "@/internals/events";
import { TextSize } from "@/components/display/atlas-text/types";
import { WithTooltipMixin } from "@/internals/mixins/with-tooltip-mixin";

import AtlasElement from "@/components/atlas-element";
import styles from "./atlas-link.scss";
import "@/components/display/atlas-icon/atlas-icon";

/**
 * @event {CustomEvent} atlas-link-click - Evento disparado ao clicar no link
 *
 * @tag atlas-link
 */
@customElement("atlas-link")
export default class AtlasLink extends WithTooltipMixin(AtlasElement) {
    static styles = styles;

    /** Link que será aberto ao clicar no item */
    @property({ type: String }) href: string;

    /** Ícone que irá aparecer ao lado esquerdo do texto */
    @property({ type: String }) icon: string;

    /** O tamanho do texto desejado para o link */
    @property({ type: String }) size: TextSize = "md";

    /** Indica onde o link deve ser aberto */
    @property({ type: String }) target: string;

    /** Indica se o link que vai ser aberto ao clicar sobre o item é um link externo */
    @property({ type: Boolean }) external: boolean;

    /** Indica se o link vai aparecer como desabilitado */
    @property({ type: Boolean }) disabled: boolean;

    /** Indica se o link deve aparecer como um bloco, ocupando 100% do espaço disponível */
    @property({ type: Boolean }) block: boolean;

    /** Indica se o link deve respeitar o tamanho do conteúdo, deve ser usado apenas em conjunto com o atributo block */
    @property({ type: Boolean, attribute: "content-width" }) contentWidth: boolean;

    @state() private _trimmedText: string = "";

    private onLinkClick() {
        if (this.disabled) return;

        emit(this, "atlas-link-click", { detail: { content: this._trimmedText } });
    }

    private async onSlotChange() {
        await this.updateComplete;

        if (this.skeletonLoading) return;

        const slotContent = getSlotTextContent(this.shadowRoot.querySelector("slot"));
        this._trimmedText = slotContent.trim();
    }

    private targetValue() {
        if (this.external) return "_blank";

        return this.target;
    }

    protected renderSkeleton() {
        const skeletonClass = {
            link: true,
            skeleton: true,
            [this.size]: !!this.size
        };

        return html`<div class="${classMap(skeletonClass)}"></div>`;
    }

    protected renderElement() {
        const linkClass = {
            "link": true,
            "disabled": this.disabled,
            "block": this.block,
            "content-width": this.contentWidth,
            "has-icon": !!this.icon,
            [this.size]: !!this.size
        };

        return html`
            <a
                href="${ifDefined(this.href ? this.href : undefined)}"
                class=${classMap(linkClass)}
                rel="${ifDefined(this.external ? "noreferrer" : undefined)}"
                target=${ifDefined(this.target || this.external ? this.targetValue() : undefined)}
                @click=${this.onLinkClick}
                data-atlas-tooltip="link-tooltip"
            >
                ${when(!!this.icon, () => html`<atlas-icon name=${this.icon} size="auto"></atlas-icon>`)}
                <span>${this._trimmedText}</span>
                <slot @slotchange=${this.onSlotChange}></slot>
            </a>
            ${this.renderTooltip("link-tooltip")}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-link": AtlasLink;
    }
}
