import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { live } from "lit/directives/live.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import FormControl, { FormControlProps } from "@/components/form/form-control";
import EmojiValidator from "@/internals/validators/emoji-validator";
import MultipleValueValidator from "@/internals/validators/multiple-value-validator";
import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import { WithCharsCounterMixin, type WithCharsCounterProps } from "@/internals/mixins/with-chars-counter-mixin";
import { WithTooltipMixin, WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";

import { InputType, InputSize, InputMultipleValue, InputWidth } from "./types";
import inputStyles from "./atlas-input.scss";

import "@/components/display/atlas-button/atlas-button";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-tag/atlas-tag";

export type InputProps = FormControlProps &
    WithCharsCounterProps &
    WithTooltipProps & {
        "type": InputType;
        "size": InputSize;
        "width": InputWidth;
        "placeholder": string;
        "icon": string;
        "has-icon-event": boolean;
        "action-button-icon": string;
        "action-button-description": string;
        "action-button-label": string;
        "prefix": string;
        "maxlength": number;
        "readonly": boolean;
        "loading": boolean;
        "enable-emoji": boolean;
        "multiple": boolean;
        "max-values": number;
        "max-values-error-message": string;
        "tag-max-length": number;
    };

const ComposedClass = WithCharsCounterMixin(WithTooltipMixin(FormControl));

/**
 * @event {CustomEvent} atlas-input-focus - Evento lançado quando é dado foco no input
 * @event {CustomEvent} atlas-input-blur - Evento lançado quando é retirado o foco do input
 * @event {CustomEvent} atlas-input-change - Evento lançado quando é o valor do input é alterado
 * @event {CustomEvent} atlas-input-icon-click - Evento lançado quando é clicado sobre o ícone do input
 * @event {CustomEvent} atlas-input-action-button-click - Evento lançado quando é clicado sobre o botão de ação do input
 *
 * @tag atlas-input
 */
@customElement("atlas-input")
export default class AtlasInput extends ComposedClass {
    static styles = inputStyles;

    /** O tipo de input */
    @property({ type: String }) type: InputType = "text";

    /** O tamanho do input */
    @property({ type: String }) size: InputSize = "md";

    /** A largura do input */
    @property({ type: String }) width: InputWidth = "auto";

    /** A mensagem que aparecerá quando o input está vazio */
    @property({ type: String }) placeholder: string;

    /** Prefixo que aparecerá no input */
    @property({ type: String, attribute: "prefix" }) inputGroupPrefix: string;

    /** Ícone exibido ao lado direito do input */
    @property({ type: String }) icon: string;

    /** Imagem exibida ao lado direito do input */
    @property({ type: String, attribute: "side-image" }) sideImage: string;

    /** Indica se o ícone exibido no input terá alguma ação */
    @property({ type: Boolean, attribute: "has-icon-event" }) hasIconEvent: boolean;

    /** Ícone em forma de botão que irá aparecer ao lado do input que quando clicado emite um evento */
    @property({ type: String, attribute: "action-button-icon" }) actionButtonIcon: string;

    /** Texto do botão que irá aparecer ao lado do input que quando clicado emite um evento */
    @property({ type: String, attribute: "action-button-description" }) actionButtonDescription: string;

    /** Descrição de acessibilidade para o botão de ação */
    @property({ type: String, attribute: "action-button-label" }) actionButtonLabel: string;

    /** O tamanho máximo de caracteres do input */
    @property({ type: Number }) maxlength: number;

    /** Indica se o input é apenas para leitura */
    @property({ type: Boolean, reflect: true }) readonly = false;

    /** Indica se o input está em estado de loading */
    @property({ type: Boolean }) loading = false;

    /** Indica se o input pode conter emoji */
    @property({ type: Boolean, attribute: "enable-emoji" }) enableEmoji = false;

    /** Indica se o input pode conter múltiplos valores */
    @property({ type: Boolean }) multiple = false;

    /** Número máximo de valores que o input pode conter */
    @property({ type: Number, attribute: "max-values" }) maxValues: number;

    /** Mensagem de erro que será exibida quando o número máximo de valores é atingido */
    @property({ type: String, attribute: "max-values-error-message" }) maxValuesErrorMessage: string;

    /** Tamanho máximo de caracteres que cada tag pode conter */
    @property({ type: Number, attribute: "tag-max-length" }) tagMaxLength: number;

    @state() private _multipleValues: InputMultipleValue[] = [];

    @state() private _inputValue = "";

    @query(".form-control") _formControl: HTMLElement;

    @query("input") _input: HTMLInputElement;

    protected _deviceController = new DeviceController(this);

    /** @internal */
    public connectedCallback(): void {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            if (!this.enableEmoji && this.tagName === "ATLAS-INPUT") {
                this.addValidator(new EmojiValidator());
            }

            if (this._inputValue !== this.value) {
                this._inputValue = this.value;
            }

            this.updateCharsCounterIfEnabled(String(this.value).length);
            this.onChangeMultiple();
        });
    }

    /** @internal */
    @Watch("value", true)
    public onChangeValue() {
        if (this._inputValue !== this.value) {
            this._inputValue = this.value;
        }

        this.updateCharsCounterIfEnabled(String(this.value).length);

        emit(this, "atlas-input-change", { detail: this.value, trackDisable: true });
    }

    /** @internal */
    @Watch("multiple", true)
    public onChangeMultiple() {
        if (this.isInputMultiple()) {
            this.addValidator(new MultipleValueValidator());
            this._useOnlyGlobalValidators = true;
        } else {
            this.removeValidator("multiple-value");
            this._useOnlyGlobalValidators = false;
        }
    }

    /** @override */
    public getElementValue(): string | string[] {
        return this.multiple ? this.getMultipleValues() : this.value;
    }

    /**
     * Obtém os valores do input quando o input é do tipo múltiplo
     */
    public getMultipleValues(): string[] {
        return this._multipleValues.map((value) => value.value);
    }

    /**
     * Obtém uma lista de objetos com os valores do input quando o input é do tipo múltiplo
     */
    public getMultipleValuesObject(): InputMultipleValue[] {
        return this._multipleValues;
    }

    /**
     * Define o foco no input
     */
    public focus() {
        this._input.focus();

        setTimeout(() => {
            const valueLength = this._inputValue ? `${this._inputValue}`.length : 0;

            this._input.selectionStart = valueLength;
            this._input.selectionEnd = valueLength;
        }, 0);
    }

    /**
     * Remove o foco do input
     */
    public blur() {
        this._input.blur();
    }

    protected handleFocus() {
        emit(this, "atlas-input-focus", { trackDisable: true });
    }

    protected async handleBlur() {
        await this.updateComplete;

        if (this.isInputMultiple()) {
            setTimeout(() => {
                this.createTag();
                this.reportValidity();
            }, 0);
        } else {
            this.reportValidity();
        }

        emit(this, "atlas-input-blur", { trackDisable: true });
        emit(this, "atlas-form-element-touch", { trackDisable: true });
    }

    protected async handleInput() {
        await this.updateComplete;

        this._inputValue = this._input.value;

        if (!this.isInputMultiple()) {
            this.value = this._input.value;
        }
    }

    protected async handleInputKeyDown(event: KeyboardEvent) {
        if (!this.isInputMultiple()) return;

        await this.updateComplete;

        const validKeysToCreateTag = ["Enter", " ", ";", ","];

        if (validKeysToCreateTag.includes(event.key)) {
            this.createTag();
        }

        if (event.key === "Backspace" && !this._inputValue) {
            this.removeLastTag();
        }
    }

    protected async handlePaste() {
        if (!this.isInputMultiple()) return;

        await this.updateComplete;

        setTimeout(() => this.createTag(), 0);
    }

    protected handleInputClick() {
        emit(this, "atlas-input-click", { trackDisable: true });
    }

    protected onInputIconClick() {
        emit(this, "atlas-input-icon-click", { trackDisable: true });
    }

    protected onActionButtonClick() {
        emit(this, "atlas-input-action-button-click");
    }

    protected onClickFormControl() {
        this._input.focus();
    }

    protected onTagRemoveClick(event: CustomEvent) {
        const tagElement = event.target as HTMLElement;

        this._multipleValues = this._multipleValues.filter((_value, index) => index !== Number(tagElement.dataset.id));
        this.syncValueWithMultipleValues();
    }

    protected renderIcon() {
        return when(this.loading || !!this.icon, () => {
            const iconClass = {
                "input-icon": true,
                "has-click": !this.loading && this.hasIconEvent
            };

            return html`
                <atlas-icon
                    name=${this.loading ? "loader" : this.icon}
                    size="2x"
                    class=${classMap(iconClass)}
                    @click=${this.onInputIconClick}
                ></atlas-icon>
            `;
        });
    }

    private isInputMultiple() {
        return this.multiple && ["ATLAS-INPUT", "ATLAS-MASKED-INPUT"].includes(this.tagName);
    }

    private validateNewValue(value: string) {
        const specificValidators = this.getValidators().filter((validator) => !validator.global);

        const validationClone = this.cloneNode(true) as AtlasInput;
        validationClone.value = value;
        validationClone.multiple = false;

        for (const validator of specificValidators) {
            const isValid = validator.validate(validationClone);

            if (!isValid) {
                return { isValid: false, errorMessage: validator.getInvalidMessage() };
            }
        }

        return { isValid: true, errorMessage: "" };
    }

    private createTag() {
        if (!this._inputValue) return;

        const newValueValidation = this.validateNewValue(this._inputValue);

        const newValue = {
            value: this._inputValue,
            isValid: newValueValidation.isValid,
            errorMessage: newValueValidation.errorMessage
        };

        this._multipleValues = [...this._multipleValues, newValue];
        this.syncValueWithMultipleValues();

        setTimeout(() => {
            this._inputValue = "";
            this.scrollBottom();
        }, 0);
    }

    private removeLastTag() {
        this._multipleValues = this._multipleValues.slice(0, -1);
        this.syncValueWithMultipleValues();

        this.scrollBottom();
    }

    private scrollBottom() {
        const formControlMultiple = this.shadowRoot.querySelector(".form-control-multiple");

        formControlMultiple.scrollTo({
            top: formControlMultiple.scrollHeight,
            behavior: "smooth"
        });
    }

    private syncValueWithMultipleValues() {
        this.value = this._multipleValues.map((value) => value.value).join(",");
    }

    private renderInputPrefix() {
        return when(
            !!this.inputGroupPrefix,
            () => html`<span class="input-group-text">${this.inputGroupPrefix}</span>`
        );
    }

    private renderSideImage() {
        return when(
            !this.loading && !!this.sideImage,
            () => html`<img class="side-image" src=${this.sideImage} alt="" />`
        );
    }

    private renderTags() {
        return this._multipleValues.map(
            (value, index) => html`
                <atlas-tag
                    text=${value.value}
                    data-id=${index}
                    maxlength=${this.tagMaxLength}
                    theme=${value.isValid ? "secondary" : "danger"}
                    @atlas-tag-close-click=${this.onTagRemoveClick}
                ></atlas-tag>
            `
        );
    }

    private renderInput() {
        const ariaLabel = this.disabled || this.readonly ? this.placeholder : "";
        const ariaDescribedBy = this.helperText ? `${this.id}-helper` : "";

        const formControlClass = {
            "form-control": true,
            "form-control-sm": this.size === "sm",
            "form-control-lg": this.size === "lg",
            "form-control-multiple": this.isInputMultiple(),
            "has-icon": this.loading || !!this.icon,
            "has-action-button": !!this.actionButtonIcon || !!this.actionButtonDescription,
            "hide-placeholder": this.isInputMultiple() && this._multipleValues.length > 0,
            [`is-${this._status}`]: this.getShowStatus()
        };

        const inputClass = {
            "form-control-input": true
        };

        return html`
            <div class=${classMap(formControlClass)} @click=${this.onClickFormControl}>
                ${this.renderTags()}
                <input
                    aria-describedby="${ariaDescribedBy}"
                    aria-label="${ariaLabel}"
                    id="${this.id}"
                    name="${this.name}"
                    class=${classMap(inputClass)}
                    type=${this.type}
                    inputmode=${ifDefined(this.inputMode)}
                    placeholder=${this.placeholder}
                    maxlength=${ifDefined(this.maxlength || undefined)}
                    .value=${live(this._inputValue)}
                    ?disabled=${this.disabled}
                    ?readonly=${this.readonly}
                    ?required=${this.required}
                    @focus=${this.handleFocus}
                    @blur=${this.handleBlur}
                    @input=${this.handleInput}
                    @keydown=${this.handleInputKeyDown}
                    @paste=${this.handlePaste}
                    @click=${this.handleInputClick}
                    autocomplete="off"
                />
            </div>
            ${this.renderIcon()} ${this.renderSideImage()} ${this.renderStatusMessage()}
        `;
    }

    private renderActionButton() {
        return when(
            !!this.actionButtonIcon || !!this.actionButtonDescription,
            () => html`
                <atlas-button
                    ?disabled=${this.disabled || this.loading}
                    icon=${this.actionButtonIcon}
                    description=${this.actionButtonDescription}
                    size=${this.size}
                    theme="secondary"
                    type="outlined"
                    in-group-last
                    @atlas-button-click=${this.onActionButtonClick}
                    aria-label=${this.actionButtonLabel}
                ></atlas-button>
            `
        );
    }

    /**
     * @internal
     * @override
     */
    public render() {
        const inputGroupClass = {
            "input-group": true,
            "skeleton": this.skeletonLoading
        };

        return html`
            ${this.renderLabel()}
            <div class="input-wrapper">
                <div class=${classMap(inputGroupClass)} data-atlas-tooltip="input-tooltip">
                    ${this.renderInputPrefix()} ${this.renderInput()}
                </div>
                ${this.renderActionButton()}
            </div>
            ${this.renderCharsCounter()} ${this.renderHelperText()} ${this.renderTooltip("input-tooltip")}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-input": AtlasInput;
    }
}
