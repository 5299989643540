import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { live } from "lit/directives/live.js";

import { emit } from "@/internals/events";
import { Watch } from "@/decorators/watch";

import AtlasElement, { type AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-toggle-item.scss";

export type ToggleItemProps = AtlasElementProps & {
    "value": string;
    "label": string;
    "helper-text": string;
    "disabled": boolean;
};

/**
 * Componente de toggle-item, serve para delimitar quantas opções existem dentro de um toggle
 *
 * @prop {string} value - O valor que está atrelado ao input do componente
 * @prop {string} label - A label que será exibida na visualização do componente
 * @prop {string} helper-text - O texto informativo auxiliar que diz respeito ao item
 * @prop {boolean} disabled - Indica se o item está desabilitado
 * @prop {boolean} checked - Indica se o item está marcado
 *
 * @tag atlas-toggle-item
 */
@customElement("atlas-toggle-item")
export default class AtlasToggleItem extends AtlasElement {
    static styles = styles;

    @property({ type: String }) value = "";

    @property({ type: String }) label = "";

    @property({ type: String, attribute: "helper-text" }) helperText = "";

    @property({ type: Boolean, reflect: true }) disabled = false;

    @property({ type: Boolean, reflect: true }) checked = false;

    check() {
        this.checked = true;
    }

    uncheck() {
        this.checked = false;
    }

    onItemClick() {
        if (!this.checked) this.check();
    }

    @Watch("checked", true)
    onChangeChecked() {
        const eventObject = {
            detail: {
                checked: this.checked
            }
        };

        emit(this, `atlas-toggle-item-${this.checked ? "check" : "uncheck"}`, eventObject);

        emit(this, "atlas-toggle-item-change", eventObject);
    }

    renderElement() {
        const btnClass = {
            "btn": true,
            "disabled": this.disabled,
            "btn-outline-primary": true
        };

        return html`
            <input
                id="toggle-item"
                class="btn-check"
                type="radio"
                .value="${this.value}"
                .checked=${live(this.checked)}
                ?disabled=${this.disabled}
                @click=${this.onItemClick}
            />
            <label class=${classMap(btnClass)} for="toggle-item">${this.label}</label>
        `;
    }

    renderSkeleton() {
        const skeletonClass = {
            "skeleton": true,
            "btn-md": true,
            "btn-block": true
        };

        return html` <div class="${classMap(skeletonClass)}"></div> `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-toggle-item": AtlasToggleItem;
    }
}
