import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { OverlayPlacement, OverlayTrigger } from "@/components/display/overlay";
import { Theme, ThemeVariation } from "@/internals/theme";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import iconStyles from "./atlas-icon.scss";

import "@/components/display/atlas-tooltip/atlas-tooltip";

export type IconProps = AtlasElementProps & {
    "name": string;
    "theme": Theme;
    "theme-variation": ThemeVariation;
    "size": "auto" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x";
    "disabled": boolean;
    "tooltip": string;
    "tooltip-placement": OverlayPlacement;
    "tooltip-trigger": OverlayTrigger;
};

/**
 * Ícones servem para reforço visual de forma rápida e direta, os ícones também servem como símbolos que representam algo como uma aplicação ou função do sistema.
 *
 * @dependency atlas-tooltip
 *
 * @tag atlas-icon
 */
@customElement("atlas-icon")
export default class AtlasIcon extends AtlasElement {
    static styles = iconStyles;

    /** O nome do ícone */
    @property({ type: String }) name: string;

    /** O tema do ícone */
    @property({ type: String }) theme: Theme;

    /** A variação da tonalidade da cor do tema */
    @property({ type: Number, attribute: "theme-variation" }) themeVariation: ThemeVariation;

    /** Indica se o ícone terá a cor branca */
    @property({ type: Boolean }) white: boolean;

    /** O tamanho do ícone */
    @property({ type: String }) size: "auto" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x" = "3x";

    /** Indica se o ícone aparece com os estilos de desabilitado */
    @property({ type: Boolean }) disabled = false;

    /** O texto do tooltip */
    @property({ type: String }) tooltip: string;

    /** A posição em relação ao elemento que o tooltip será exibido */
    @property({ type: String, attribute: "tooltip-placement" }) tooltipPlacement: OverlayPlacement = "bottom";

    /** O gatilho que irá acionar o tooltip */
    @property({ type: String, attribute: "tooltip-trigger" }) tooltipTrigger: OverlayTrigger = "hover focus";

    private getThemeClass() {
        if (this.white) {
            return "color-white";
        }

        if (this.theme) {
            if (this.themeVariation) {
                return `color-${this.theme}-${this.themeVariation}`;
            }

            return `color-${this.theme}`;
        }

        return "";
    }

    private renderTooltip() {
        return when(
            !!this.tooltip,
            () => html`
                <atlas-tooltip id="icon-tooltip" placement=${this.tooltipPlacement} trigger=${this.tooltipTrigger}>
                    ${this.tooltip}
                </atlas-tooltip>
            `
        );
    }

    protected renderElement() {
        const themeClass = this.getThemeClass();

        const iconClass = {
            "atlas-icon": true,
            [`ati-${this.name}`]: true,
            [`ati-${this.size}`]: true,
            [themeClass]: !!themeClass,
            "disabled": this.disabled
        };

        return html`
            <i
                class="${classMap(iconClass)}"
                aria-hidden="true"
                data-atlas-tooltip=${ifDefined(this.tooltip ? "icon-tooltip" : undefined)}
                data-chromatic=${ifDefined(this.name === "loader" ? "ignore" : undefined)}
            ></i>
            ${this.renderTooltip()}
        `;
    }

    protected renderSkeleton() {
        return html` <div class="skeleton atlas-icon ati-${this.size}"></div> `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-icon": AtlasIcon;
    }
}
