import { LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

/**
 * @tag atlas-option
 */
@customElement("atlas-option")
export default class AtlasOption extends LitElement {
    /** O valor da opção */
    @property({ type: String }) value = "";

    /** O texto que irá descrever a opção */
    @property({ type: String }) label = "";

    /** Agrupamento ao qual a opção pertence, caso exista */
    @property({ type: String }) group = "";

    /** Booleano que define se a opção está desabilitada */
    @property({ type: Boolean, reflect: true }) disabled = false;

    /** Booleano que informa se a opção está selecionada */
    @property({ type: Boolean, reflect: true }) selected = false;
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-option": AtlasOption;
    }
}
